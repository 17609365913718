.checkout-pane-completion-message {
  .checkout-complete {
    span.order-number {
      color: $sja_green;
    }
    > div {
      margin-bottom: 1rem;
    }
  }
}

.commerce-checkout-flow {
  .form-item {
    label.is-required::after {
      content: '*';
      vertical-align: unset;
      display: inline-block;
      color: $sja_red;
    }
  }
}
