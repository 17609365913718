.node--type-job-posting {
  .node__content {
    .small-banner {
      position: relative;
      text-align: center;

      .field--name-field-banner-image {
        @include mappy-bp(large) {
          margin-bottom: 40px;
        }

        .field__item {
          img {
            width: 100%;
          }
        }
      }

      .field--name-field-banner-text {
        margin: 0 auto;
        // display: inline-block;

        .field__item {
          font-size: 24px;
          font-weight: 900;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.42;
          letter-spacing: .6px;
          text-align: left;
          color: #171918;
          padding: rem(10px) rem(14px);
          box-shadow: 0 0 24px 0 rgba(0, 0, 0, .16);
          background-color: #ffffff;
          // position: absolute;
          // left: 5%;
          transform: translateY(-50%);
          max-width: 90%;
          margin: 0 auto;
          display: inline-block;
          width: auto;

          @include mappy-bp(med) {
            padding: rem(20px) rem(40px);
            font-size: 36px;
            line-height: 1.14;
            letter-spacing: .9px;
          }

          @include mappy-bp(large) {
            font-size: 36px;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: .9px;
            color: #000000;
            box-shadow: 0 0 24px 0 rgba(0, 0, 0, .16);
            background-color: #ffffff;
            padding: rem(20px) rem(40px);
            top: 80%;
            position: absolute;
            // transform: translateX(0);
            transform: translateY(-80%);
            left: rem(80px);
            max-width: 50%;
          }

          @include mappy-bp(2500px) {
            top: 87%;
          }
        }
      }
    }

    .job-posting-content-wrapper {
      height: auto;
      line-height: 1.63;
      text-align: left;
      padding: 0 16px;
      margin-bottom: 2rem;

      h2 {
        margin-bottom: 1.5rem;
      }

      @include mappy-bp(med) {
        width: 648px;
        margin-left: auto;
        margin-right: auto;
        height: auto;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
      }

      @include mappy-bp(larger) {
        width: 690px;
      }

      @include mappy-bp(widest) {
        width: 1000px;
      }
    }
  }
}
