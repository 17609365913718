// html,
// body,
// .dialog-off-canvas-main-canvas,
// .layout-container {
//   height: 100%;
// }

.region-alerts {}

.region-utility-navigation {
  background-color: $color__grey--lighter;
  padding: 1rem;

  @include mappy-bp(large) {
    padding: 1rem 0;
  }
}

// when sidebar region is used
.sidebar-first {

  .region-prefix {
    margin: 0 0 1rem 0;

    @include mappy-bp(med) {
      margin: 0 0 3rem 0;
    }
  }

  .layout-content {
    @extend %cf;
    max-width: $content-max-width;
    margin: 0 auto;
    padding: 0 1rem;
    transition: .5s all ease-in;

    @include mappy-bp(med) {
      padding: 0 3.75rem;
    }

    @include mappy-bp($content-max-width + 32px) {
      padding: 0;
    }
  }

  .layout-sidebar-first {
    margin-bottom: 2rem;
    @include mappy-bp(large) {
      @include span(first 3 of 12 after);
      margin-bottom: 4rem;
    }
  }

  .region-content {
    @include mappy-bp(large) {
      @include span(first 9 of 12 before);
    }
  }
}

.region-footer {
  background-color: #f0f0f0 !important;
  padding: 3rem 1rem;

  @include mappy-bp(large) {
    padding: 1rem 0;
  }
}

.region-copyright {
  // background-color: #f0f0f0;
  padding: 1rem;

  @include mappy-bp(large) {
    padding: 1rem 0;
  }
}

// Adds max-width to l-constrain elements.
// See region.html.twig for all regions that have constrains

.l-constrain {
  @include container(large);
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin-top: 155px;
  @extend %cf;
  @include mappy-bp(1200px) {
    margin-top: 156px;
  }
}

.gin--horizontal-toolbar .wrapper {
  @include mappy-bp(1200px) {
    margin-top: 220px;
  }
}

// Do not set height 100% on ie. Messes up styles.
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
/* IE10+ CSS styles go here */
  .wrapper {
    height: auto;
  }
}

.adminimal-admin-toolbar {
  .wrapper {
    // 160px + 39px
    // margin-top: 195px;
    margin-top: 156px;

    @include mappy-bp(wide) {
      // 191px + 39px
      // margin-top: 225px;
    }
  }

  &.toolbar-tray-open {
    .wrapper {
      top: 79px;
      // 160px + 79px
      // margin-top: 235px;
      margin-top: 195px;

      @include mappy-bp(large) {
        // 191px + 79px
        // margin-top: 156px;
        // margin-top: 156px;
        margin-top: 115px;
      }

      @include mappy-bp(wide) {
        // 191px + 79px
        // margin-top: 225px;
        margin-top: 156px;
      }
    }
  }
}

main {
  @extend %cf;
}

.region-header {
  position: relative;

  .main-nav {
    display: inline-block;
    position: relative;
  }
}

header[role='banner'] {
  .contextual {
    display: none;
    position: static;
  }
}

.layout-container {
  @extend %cf;
}

.container-inline {
  div, label {
    display: inline;
  }
}

.layout-builder-configure-block {
  .form-item--radio {
    .form-radio {
      display: none !important;
    }
  }
}

.layout-builder-remove-section, .layout-builder-remove-block {
  &.confirmation {
    .form-actions {
      margin-top: 1rem !important;
      a.button.dialog-cancel {
        width: fit-content !important;
      }
    }
  }
}

// .entity-browser-modal {
//   .media-image-edit-form.media-form {
//     .form-managed-file{
//       input {
//         width: auto;
//         padding: 0.3rem 1rem;
//       }
//     }
//   }
// }
