// Overrides layout region margin top and bottom.
// No padding since this is a full width element
.layout__region-container > .layout__region > .block--call-to-action {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

.cta_block__content--green {
  width: 100%;
  background-color: #006633;
  padding: rem(24px) rem(16px);
  .cta-text-container {
    color: #FFFFFF;
    @include mappy-bp(land) {
      padding: rem(60px) 2rem;
      margin: rem(24px) auto;
    }
    @include mappy-bp(med) {
      max-width: $content-max-width;
      margin: 0 auto;
    }

    .cta_block__title {
      color: #FFFFFF;
      font-size: rem(20px);
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      text-align: left;
      @include mappy-bp(med) {
        max-width: rem(648px);
      }
      @include mappy-bp (large) {
        font-size: rem(32px);
        max-width: rem(816px);
      }
    }
    .cta-subtext-container {
      .cta_block__subtitle {
        color: #FFFFFF;
        font-size: rem(14px);
        padding-top: rem(34px);
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.57;
        letter-spacing: normal;
        text-align: left;
        @include mappy-bp(med) {
          padding-top: rem(24px);
          max-width: rem(648px);
        }
        @include mappy-bp(large) {
          font-size: rem(20px);
          line-height: 1.5;
          padding-top: rem(56px);
          max-width: rem(816px);
        }
      }

      .cta_block__link_text {
        color: #FFFFFF;
        padding-top: rem(70px);
        padding-bottom: rem(24px);
        a {
          display: inline-block;
          padding: rem(10px) rem(36px);
          border-radius: rem(20px);
          border: solid rem(2px) #FFF;
          text-align: center;
          text-decoration: none;
          text-transform: uppercase;
          font-size: rem(12px);
          line-height: 1.36;
          font-weight: 900;
          letter-spacing: rem(1.05px);
          color: $color__white;
          transition: .2s all;

          @include mappy-bp (large) {
            font-size: rem(14px);
            padding: rem(10px) rem(44px);
          }

          &:hover {
            background-color: $color__white;
            color: $color__primary;
          }
        }
        @include mappy-bp (land) {
          padding-top: rem(50px);
          padding-bottom: 0;
        }
        @include mappy-bp (large) {
          padding-top: rem(80px);
        }
      }
    }
  }
}

.cta_block__content--white {
  @extend %cf;
  width: 100%;
  height: auto;
  min-height: rem(540px);
  background-color: none;
  overflow-y: hidden;
  position: relative;

  @include mappy-bp(land) {
    height: auto;
    min-height: rem(700px);
  }

  .cta_block__content--image {
    position: absolute;
    width: 100%;

    @include mappy-bp(land) {
      max-height: rem(700px);
    }

    img {
      position: absolute;
      width: 100%;
    }
  }

  .cta-text-container {
    padding-top: rem(195px);
    padding-left: rem(16px);
    padding-right: rem(16px);
    padding-bottom: rem(16px);
    position: relative;
    @include mappy-bp(land) {
      padding-top: rem(190px);
      margin: 0 auto;
    }
    .cta_block__title {
      @extend %heading_box-shadow;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      color: $black;
      font-size: rem(20px);
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: rem(.8px);
      text-align: left;
      @include mappy-bp (land) {
        margin-left: 0;
        padding-left: rem(60px);
        padding-right: rem(60px);
        font-size: rem(32px);
      }
    }

    .cta-subtext-container {
      @extend %cards;
      max-width: rem(584px);
      .cta_block__subtitle {
        color: $black;
        font-size: rem(14px);
        padding-top: rem(23px);
        padding-left: rem(24px);
        padding-right: rem(24px);
        margin-top: rem(34px);
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.57;
        letter-spacing: normal;
        text-align: left;
        @include mappy-bp(mobile) {
        }
        @include mappy-bp(land) {
          font-size: 1.25rem;
          padding-top: rem(58px);
          margin-left: rem(40px);
          margin-right: rem(60px);
        }
        @include mappy-bp(large) {
          font-size: rem(20px);
          line-height: 1.5;
        }
      }
      .cta_block__link_text {
        padding-left: 1.625rem;
        padding-right: 1.625rem;
        padding-top: rem(29px);
        padding-bottom: rem(26px);
        @include mappy-bp (mobile) {
          padding-left: rem(26px);
        }
        @include mappy-bp (land) {
          padding-bottom: rem(60px);
          padding-left: rem(60px);
        }
        a {
          @extend %green-button-mobile;
          display: inline-block;
          padding: rem(10px) rem(36px);
          border-radius: rem(20px);
          text-align: center;
          text-decoration: none;
          text-transform: uppercase;
          font-size: rem(12px);
          line-height: 1.36;
          font-weight: 900;
          letter-spacing: rem(1.05px);
          color: $sja_green;
          transition: .2s all;
          @include mappy-bp (land) {
            @include green-button-desktop;
            font-size: rem(14px);
            padding: rem(10px) rem(44px);
          }
          &:hover {
            background-color: $sja_green;
            color: $color__white;
          }
        }
      }
    }
  }
}
