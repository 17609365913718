.region-sidebar-first.products,
.region-sidebar-first.des-produits,
.region-sidebar-first.produits {
	box-shadow: none;
	.block-views-exposed-filter-blockproducts-block-1 {
		#views-exposed-form-products-block-1 {
			.form-item--id-keyword {
				input {
					margin-top: 1rem;
					background: url('/themes/custom/sja/src/000_assets/icons/search_n.svg') no-repeat right 1rem center;

				}
			}
			.form-actions {
				input {
					font-size: 1rem;
					font-weight: 900;
					color: $medium_grey;
					font-stretch: normal;
					text-align: center;
					border: none;
					padding: 0;
					margin-top: 2rem;

					height: auto;
					background-repeat: no-repeat;
					background-size: 0;
					background-position: 0 1.5rem;
					border-radius: 0;

					@media all and (-ms-high-contrast: none),
					(-ms-high-contrast: active) {
						background-position: 0 1.35em;
					}

					padding-bottom: .35rem;
					overflow: hidden;
					transition: background-position .2s linear .2s,
					box-shadow .2s linear 0s;

					&[type='submit'].button:hover,
					&[type='submit'].button:focus {
						cursor: pointer;
						background-color: transparent;
						background-size: 100%;
						color: $color__black;
						background-image: linear-gradient(to left, $color__black 0%, $color__black 100%);
						transition: background-size .2s linear 0s, box-shadow .2s linear .2s;
					}

					&:first-of-type {
						margin-right: 2rem;
					}
				}
			}
		}
	}
	.block-facet--checkbox {
		margin-top: 2rem;
		.facets-widget-checkbox {
			font-size: .75rem;
			>h3 {
				font-size: 1rem;
				letter-spacing: 1.2px;
				text-transform: uppercase;
			}
			>ul {
				list-style-type: none;
				.facet-item {
					margin-top: 1rem;
					>label {
						display: block;
						.facet-item__value {
							margin-left: .5rem;
							letter-spacing: .35px;
							display: inline-block;
							text-transform: none;
						}
					}
				}
			}
		}
	}
}
.layout-sidebar-first.products,
.layout-sidebar-first.des-produits {
  @include mappy-bp(large) {
			margin-top: 3.75rem;

	}
}

.block--page-title.products {
	text-align: center;
	@include mappy-bp(large) {
		text-align: left;
	}
}

.user-logged-out {
	 .layout-sidebar-first.products {
				margin-top: 3.75rem;
		}
}