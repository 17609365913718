.block-views-blockindividual-certifications-expired,
.block-views-blockindividual-certifications-active {

  a.download-pdf-image-class {
    width: 2rem;
    display: block;
    text-align: center;
    margin: 0 auto;
  }

  .download-pdf-image {
    // 450
    @include mappy-bp(mobile) {
      width: 2.5rem;
      // width: 25rem;
    }
  }

  .views-exposed-form {
    .form-item--id-combine {
      margin-bottom: 1rem;
    }
    #edit-actions,
    .form-actions {
      input {
        margin: .5rem 0;
        @include mappy-bp(mobile) {
          margin: 1.5rem 0;
        }
      }
    }
  }

  .view__content {
    overflow-x: auto;
    .tablesaw-bar.tablesaw-mode-swipe {
      margin-top: 0 !important;
    }
  }
  .view__empty {
    margin-bottom: 2rem;
  }
  .feed-icons {
    margin: 1rem 0 3rem;

    .csv-feed {
      a {
        display: inline-block;
        width: auto;
        background: none;
        text-indent: 0;
        font-size: .875rem;
        font-weight: 800;
        color: $color__black;
        text-decoration: none;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }
}

.webform-submission-individual-certifications-filter-form {
  margin-bottom: 0 !important;
  padding: 0 !important;
  max-width: 100% !important;
  #employee-registrations-filter {
    select {
      width: 100%;
    }
    @include mappy-bp(med) {
      width: 50%;
    }
  }
}
