.layout--onecolumnfullwidth-section {
  max-width: none;

  .layout-builder__region {
    width: 100%;
  }

  .layout__region {
    width: 100%;
  }
}
