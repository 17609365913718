.block--page-title {
  max-width: $content-max-width;
  margin: 0 auto;
  padding: 0 1rem;
  transition: .5s all ease-in;

  @include mappy-bp($content-max-width + 32px) {
    padding: 0;
  }
}
