.path--user-payment-methods {
  .block--primary-admin-actions {
    nav li {
      list-style-type: none;

      a {
        @extend %green-button-desktop;
      }
    }
  }

  .block--main-page-content {
    table {
      .links {
        li {
          list-style-type: none;

          a {
            color: $sja_green;
            font-weight: bold;
            text-decoration: none;

            &:hover,
            &:focus {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

.stripe-form {
  margin-bottom: 1rem;

  .stripe-payment-images {
    width: 100%;

    .stripe-payment-logo {
      width: 30px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.commerce-payment-method-add-form {
  max-width: 769px;

  #edit-payment-method-type--wrapper {
    margin: 0;
  }

  .stripe-form {
    margin-bottom: 1rem;

    .stripe-payment-images {
      width: 100%;

      .stripe-payment-logo {
        height: 30px;
        display: inline-block;
      }
    }

    @include mappy-bp(med) {
      display: flex;
      flex-wrap: wrap;
    }

    #payment-errors {
      flex-basis: 100%;
    }

    > .form-item {
      margin-right: 1rem;
    }
  }

  .purchase-order-form {
    margin-bottom: 1rem;
  }

  #edit-payment-method-billing-information {
    > .form-item,
    #edit-payment-method-billing-information-address-0-address > .form-item,
    #edit-payment-method-billing-information-address-0-address > .form-wrapper {
      margin-bottom: 1rem;
    }

    #edit-payment-method-billing-information-address-0-address > .address-container-inline {
      margin-bottom: 1rem;

      .form-item {
        display: block;
        margin-bottom: 1rem;
        margin-right: 0;

        &:last-of-type {
          margin-bottom: 0;
        }
        @include mappy-bp(wide) {
          display: inline-block;
          margin-right: 1rem;
          margin-bottom: 0;
        }
      }
    }
  }

  .form-submit {
    margin-top: 1rem;
  }
}

.form-item--id-payment-information-add-payment-method-payment-details-expiration,
.form-item--id-payment-information-add-payment-method-payment-details-security-code {
  vertical-align: top;
}
