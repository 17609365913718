// @import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");

.course-cards-block {
  padding: 20px;
  background-size: cover; /* Ensures the image covers the entire block */
  font-family: "Lato", sans-serif;
  border-radius: 0.8rem; /* Optional: to add some rounded corners */
  overflow: hidden; /* Ensures content stays within the block */
  margin: 1rem 1rem 1rem 1rem;
}

@media (min-width: 1800px) {
  .course-cards-block {
    margin-left: 15%;
    margin-right: 15%;
  }
}

.course-cards-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header-title-container {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}

.course-cards-header h2 {
  margin: 0;
  font-size: 1.5em;
  color: #333;
}

.section-subtitle {
  margin: 5px 0 0 0;
  font-size: 1em;
  color: #777;
}

.show-all-link {
  color: #006633;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 0.9em;
  font-weight: 800;
  background-color: white;
  border-radius: 3px;
  padding: 6px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.course-cards-card-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.course-card {
  width: 22%;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  margin-bottom: 20px;
  text-decoration: none;
  color: inherit;
  position: relative;
}

.course-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.course-cards-main-card-img img {
  width: 100%;
  height: auto;
}

.title-subtitle-container {
  padding: 15px;
}

.course-cards-main-title {
  font-size: 1em;
  color: #333;
  margin: 10px 0;
}

.course-cards-main-title:hover {
  color: #006633;
}

.course-cards-subtitle {
  font-size: 0.8em;
  color: #555;
  margin-bottom: 40px; /* Added margin to make space for the duration at the bottom */
}

.course-cards-duration {
  font-size: 0.8em;
  color: #888;
  position: absolute;
  bottom: 10px;
  left: 15px;
  display: flex;
  align-items: center;
}

.course-cards-duration::before {
  content: "";
  display: inline-block;
  width: 19px;
  height: 19px;
  margin-left: 5px;
  background-image: url("../../src/000_assets/icons/course-duration-grey.svg");
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 5px;
}

/* Media Queries */

@media (min-width: 992px) {
  .course-cards-card-container {
    justify-content: center; /* Center the cards */
    gap: 10px; /* Reduce the space between the cards */
  }

  .course-card {
    width: calc(
      25% - 10px
    ); /* Calculate width to fit four cards in a row with reduced gap */
    margin-bottom: 20px;
  }
}
/* Tablets and medium-width screens */
@media (max-width: 992px) {
  .course-card {
    width: 48%;
  }
}

/* Mobile screens */
@media (max-width: 600px) {
  .course-cards-card-container {
    flex-direction: row;
    // margin: 2rem;
  }

  .course-card {
    width: 49%;
  }

  .course-cards-block {
    margin: 1rem 0 0 0;
    border-radius: 0;
  }

  .course-cards-main-title {
    font-size: 1.2em;
    color: #333;
    margin: 10px 0;
  }

  .course-cards-subtitle {
    font-size: 1em;
  }

  .course-cards-block {
    background-position: center center; /* Adjusts the background position for small screens */
    background-size: contain; /* Ensures the entire image fits within the smaller viewport */
  }
}
