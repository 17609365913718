fieldset {
  border: none;
  margin: 2rem 0;
  padding: 0;

  legend {
    border: 0;
    margin: 0 0 1rem;
    padding: 0;
    font-size: 1rem;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 1.2px;
  }
}
