.user-order-modal  {
  padding: 3rem 4rem;
  background-color: #FFF;
  box-shadow: 0 0 24px rgba(0, 0, 41, .2);
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: -1;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: .5s ease-in-out;

  &.fade-in-modal {
    opacity: 1;
    z-index: 9999;
  }

  .user-order-modal__close {
    border: none;
    width: 1.5rem;
    height: 1.5rem;
    background-color: none;
    background-image: url('/themes/custom/sja/src/000_assets/icons/close_dark_grey.svg');
    background-repeat: no-repeat;
    background-position: center;
    font-size: 0;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .user-order-modal__title {
    margin-bottom: 2rem;

     h3 {
       margin: 0;
     }
  }

  .user-order-modal__label {
    font-size: 1rem;
    font-weight: 800;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    margin: 0 0 .75rem 0;
  }

  .user-order-modal__content {
    font-size: .875rem;
    font-weight: bold;
  }

  .user-order-modal__details-content {
    @include mappy-bp(land) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      > div {
        flex: 0 1 48%;
        flex-basis: 48%;
        margin-bottom: 2rem;
      }
    }
  }

  // Modal customer contact.
  .user-order-modal__cc-content {
    > * {
      margin: 0;
      font-size: .875rem;
      font-weight: bold;

      &:first-of-type {
        margin-top: 0;
      }

      &:last-of-type {
        margin-top: 0;
      }
    }
  }

  // Modal special instruction.
  .user-order-modal__special_instructions {
    margin-bottom: 2rem;
  }

  // Modal submit buttons.
  .user-order-modal__buttons {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    .user-order-modal__buttons-download {
      @extend %green-button-desktop;
      width: auto;
      padding: .5rem 2.75rem;
      cursor: pointer;
    }

    .user-order-modal__button-email {
      color: $color__black;
      font-size: .875rem;
      font-weight: bold;
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: 1.74px;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}
