.node--type-company-registration-order-class.node--display-mode-full {
  @include mappy-bp(med) {
    padding: 2rem 3.75rem 1rem;
  }

  @include mappy-bp($content-max-width + 32px) {
    padding: 2rem 0 1rem;
  }
  .registration-order-class-details {
    padding-top: 1rem;
    .field--label-above {
      .field__label {
        float: none;
      }
    }
    > div {
      margin-bottom: 1rem;
    }

    .field.field--label-inline {
      > .field__label,
      > .field__item {
        display: inline-block;
        margin-right: .25rem;
      }
    }

    hr + .class__title {
      margin-bottom: 1rem;

      @include mappy-bp(large) {
        margin-bottom: 0 0 2rem;
      }
    }

    .class__title {
      margin-top: 0;
    }

    .field-details,
    .field--name-field-address,
    .field--name-field-class-directions {
      margin-bottom: 1rem;
    }

    .field--name-field-address {
      margin-top: 1rem;
    }

    .class__label {
      font-weight: bold;
    }

    .field--name-field-branch {
      > .field__item {
        .vocabulary-branch {
          > .field {
            margin-top: 1rem;
          }
        }
      }
    }
    .field--name-field-class-location {
      .field__label {
        display: block;
        float: none;
      }
      .field--name-field-branch {
        display: none;
      }
    }

    .class__location_branch {
      .field__label {
        display: block;
        float: none;
      }
      .field.field--label-inline {
        > .field__label,
        > .field__item {
          display: inline-block;
          margin-right: 0;
        }
      }
      .vocabulary-branch {
        > .field {
          margin-top: 1rem;
        }
      }
      .field--name-field-class-location {
        >.field__label {
          display: none;
        }
        .field__item {
          .vocabulary-class-location {
            >span,
            .field--name-field-address,
            .field--name-field-class-directions {
              display: none;
            }
          }
        }
      }
    }
  }
}