// Overrides layout region margin top and bottom and adds
// padding between elements is 60px (mobile) and 190px (desktop)
// So we don't have to account for too many scenarios, just halfing both and adding to the top and bottom of each element.
.layout__region-container > .layout__region {
  > .block--three-column-layout-,
  > .block--three-column-layout-:first-of-type,
  > .block--three-column-layout-:last-of-type {
    padding-top: rem(30px);
    padding-bottom: rem(30px);
    margin: 0 auto;
    @include mappy-bp(med) {
      padding-top: rem(60px);
      padding-bottom: rem(60px);
    }
  }
}
.three-column-layout {
  .block__title {
    font-size: 1rem !important;
    letter-spacing: .12375rem !important;
    margin-bottom: 0 !important;
    display: block;
    @extend %full-width-green-heading;
  }

  .container--three-column-layout {
    @extend %cf;
    @include mappy-bp(1700px) {
      max-width: 1540px;
      margin: 0 auto;
    }

    .field--name-field-title {
      display: block;
      @extend %full-width-green-heading;
    }

    .content--container {
      @extend %cf;
      padding: 0 0 rem(36px)  0;
      margin-top: 2rem;

      .node:nth-of-type(2),
      .node:nth-of-type(3) {
        padding-top: rem(56px);
      }
      @include mappy-bp(med) {
        padding: rem(98px) 0;
        padding-top: 0 !important;
        .node:nth-of-type(1) {
          margin-left: 0;
        }

        .node:nth-of-type(3) {
          margin-left: 0;
          padding-top: rem(98px);
          clear: left;
        }

        .node:nth-of-type(2) {
          padding-top: 0;
        }
      }
      @include mappy-bp(larger) {
        padding: rem(98px) 0;
        .node:nth-of-type(3) {
          clear: none;
          // @include span (3 of 12 2);
          padding-top: 0;
        }
      }

      .three_col_container {
        .three_col_text_container {
          margin-top: 1.5rem;
          .three_col_title {
            @extend %h4;
          }
          .three_col_summary {
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.57;
            letter-spacing: normal;
            text-align: left;
            color: #000000;
            @include mappy-bp(large) {
              padding-top: rem(18px);
              font-size: 16px;
              line-height: 1.63;
              letter-spacing: normal;
            }
          }
          .three_col_link {
            padding-top: rem(25px);

            a {
              @extend %slide-in-green;
            }
          }
        }
      }

      .node {
        .content_title {
          @extend %h4;
        }
        @include mappy-bp(med) {
          @include span(6 of 12 2);
        }
        @include mappy-bp(larger) {
          @include gallery(4 of 12 2);
        }
        .content--subtext {
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.57;
          letter-spacing: normal;
          text-align: left;
          color: #000000;
          @include mappy-bp(large) {
            padding-top: rem(18px);
            font-size: 16px;
            line-height: 1.63;
            letter-spacing: normal;
          }
        }

        .node--link {
          padding-top: rem(25px);

          a {
            @extend %slide-in-green;
          }
        }
      }
    }
  }
  > .without-image {
    .container--three-column-layout {
      .three_col_image {
        display: none;
      }
    }
  }
}
