/// Map icon names to font unicode characters
/// @author Adrian Rylski
/// @group map
/// @type map
/// @example
/// 	.test {
///   	@include icon(before, address);
/// 	}
$linkedin: url('../../src/000_assets/icons/linkedin.svg');
$linkedin_hover: url('../../src/000_assets/icons/linkedin_h.svg');
$facebook: url('../../src/000_assets/icons/facebook.svg');
$facebook_hover: url('../../src/000_assets/icons/facebook_h.svg');
$more_normal: url('../../src/000_assets/icons/more-n.svg');
$more_hover-up: url('../../src/000_assets/icons/more-h.svg');
$more_hover-down: url('../../src/000_assets/icons/more-h-down.svg');
$search_arrow: url('../../src/000_assets/icons/search-arrow.svg');
$search-icon_normal: url('../../src/000_assets/icons/search-h.svg');
$search-icon_hover: url('../../src/000_assets/icons/facebook_h.svg');
$twitter: url('../../src/000_assets/icons/twitter.svg');
$twitter-hover: url('../../src/000_assets/icons/twitter_h.svg');
$youtube: url('../../src/000_assets/icons/youtube.svg');
$youtube_hover: url('../../src/000_assets/icons/youtube-h.svg');
$double-arrow_hover: url('../../src/000_assets/icons/double_arrow_h.svg');
$double-arrow_normal: url('../../src/000_assets/icons/double_arrow_n.svg');
$close-dark_grey: url('../../src/000_assets/icons/close_dark_grey.svg');
$close-dark_grey--compact: url('../../src/000_assets/icons/close_dark_grey--compact.svg');
$right-green-arrow: url('../../src/000_assets/icons/right_green_arrow.svg');
$right-black-arrow: url('../../src/000_assets/icons/right_black_arrow.svg');
$inactive_arrow_left: url('../../src/000_assets/icons/inactive_arrow_left.svg');
$sort_icon: url('../../src/000_assets/icons/sort.svg');
$full_icon: url('../../src/000_assets/icons/full.svg');
$available_icon: url('../../src/000_assets/icons/available.svg');
$white-filter: url('../../src/000_assets/icons/white-filter.svg');
$error-icon: url('../../src/000_assets/icons/error-icon.svg');
$share-white-icon: url('../../src/000_assets/icons/share-w.svg');
$twitter-white-icon: url('../../src/000_assets/icons/twitter-w.svg');
$facebook-white-icon: url('../../src/000_assets/icons/facebook-w.svg');
