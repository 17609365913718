
.contact-message-request-access-form,
.user-register-form,
.contact-form {
  max-width: 695px;

  input.form-text,
  input.text-full,
  input[type='text'],
  input[type='email'] {
    max-width: 100%;
    width: 100%;
  }
}


.form-item__description,
.fieldset__description {
  font-size: .8rem;
  color: $color__text--light;
  margin-top: vr(.1);
}

.form-item--managed-file {
  label {
    margin-bottom: 1rem;
  }
}

main .form-managed-file {
  .managed-file-preview {
    display: inline-block;
  }
  input {
    // width: auto;
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    border: none;
    cursor: pointer;
  }
  span {
    font-size: 1rem;
  }
  // remove button(s)
  [id^='edit-relevant-documentation-remove-button'] {
    font-size: 1rem;
    font-weight: 400;
    text-transform: none;
    letter-spacing: 1.2px;
    background-color: $sja_green;
    color: #FFFFFF;
    border-radius: 0;
    border: 1px solid $sja_green;
    padding: .25rem;
    margin-left: 1rem;

    &:hover,
    &:focus {
      background-color: #FFFFFF;
      color: $sja_green;
    }
  }

  .webform-managed-file-preview + input[type='submit'] {
    margin-left: 1rem;
  }
}


// date picker
input.hasDatepicker {
  background-image: url('/themes/custom/sja/src/000_assets/icons/icon__calendar-w-bg.svg');
  background-size: 43px;
  background-position: calc(100% + 1px) center;
  background-repeat: no-repeat;
}
