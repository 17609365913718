// Overrides layout region margin top and bottom
// padding between elements is 60px (mobile) and 190px (desktop)
// So we don't have to account for too many scenarios, just halfing both and adding to the top and bottom of each element.
.layout__region-container > .layout__region {
  > .slider-custom-block,
  > .slider-custom-block:first-of-type,
  > .slider-custom-block:last-of-type {
    padding-top: rem(30px);
    padding-bottom: rem(30px);
    margin: 0 auto;

    @include mappy-bp(med) {
      padding-top: rem(60px);
      padding-bottom: rem(60px);
    }
  }
}

.slider-custom-block {
  margin-top: 2rem;
  padding-bottom: 2rem;
  .slider-custom-block-header,
  >.block__content {
    margin-top: 2rem;
    margin-bottom: 2rem;
    @include mappy-bp(widest) {
      max-width: rem(1540px);
      margin: 2rem auto;
    }
  }

  .slider-custom-block-header {
    padding-top: rem(13px);
    padding-bottom: rem(13px);
    margin: 0 auto;

    @include mappy-bp(med) {
      padding-top: rem(62px);
      padding-bottom: rem(62px);
    }
    @include mappy-bp(widest) {
      max-width: rem(1540px);
    }
    .slider-custom-block-title {
      display: inline-block;
      font-size: rem(14px);
      font-weight: 900;
      line-height: 1.36;
      letter-spacing: rem(1.74px);
      position: relative;
      text-transform: uppercase;
      overflow: hidden;
      color: #006633;
      width: 100%;

      @include mappy-bp(large) {
        font-size: rem(16px);
        letter-spacing: rem(1.98px);
        // max-width: 30%;
      }

      &::after {
        content: ' ';
        width: 100%;
        margin-left: rem(12px);
        transform: translateY(-50%);
        top: 50%;
        position: absolute;
        height: rem(2px);
        display: inline-block;
        background-color: #787e7c;

      }

      .field--name-field-slider-title {
        max-width: 60%;
        display: inline-block;

        @include mappy-bp(large) {
          max-width: 30%;

        }
      }
    }
  }
  .field--name-field-slider-items {
    .slick-slider {
      >.slick-list {
        @include mappy-bp(larger widest) {
          margin: 0 2rem;
        }
        // margin: 0 2rem;
      }

      .slick-slide {
        padding-left: .5rem;
        padding-right: .5rem;
        cursor: pointer;

        img {
          height: 256px;
          object-fit: cover;

          @include mappy-bp(larger) {
            height: 440px;
          }
        }
      }

      .slick-dots {
        bottom: -50px !important;

        @include mappy-bp(wide) {
          display: none !important;
          bottom: rem(-15px);
        }

        li {
          margin: 0 rem(2px);

          button {
            &:focus {
              outline: 5px auto lightgreen;
            }

            &::before {
              font-size: rem(16px);
              color: $color__primary;
              opacity: .25;
            }
          }

          &.slick-active {
            button {
              &::before {
                font-size: rem(16px);
                color: $color__primary;
                opacity: 1;
              }
            }
          }
        }
      }

      .slick-prev,
      .slick-next {

        // background-image: url('../../src/000_assets/icons/right_green_arrow.svg');
        &::before {
          content: url('../../src/000_assets/icons/right_green_arrow.svg');
        }

        // &:focus {
        //   outline: lightgreen solid 2px;
        // }
      }

      .slick-prev,
      .slick-next {
        transform: scale(0);

        &::before {
          display: inline-block;
        }

        @include mappy-bp(larger) {
          transform: scale(1.5);
        }
      }

      .slick-prev {
        right: rem(10px);
        // padding-left: 3rem;

        &::before {
          // display: inline-block;
          // transform: scaleX(-1);
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -o-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          transform: rotate(180deg);
        }
        @include mappy-bp(larger widest) {
          left: 0;
        }
        @include mappy-bp(widest) {
          left: -3rem;
        }
      }

      .slick-next {
        @include mappy-bp(larger widest) {
          right: 0;
        }
        @include mappy-bp(widest) {
          right: -3rem;
        }
      }

      @media all and (-ms-high-contrast: none),
      (-ms-high-contrast: active) {

        .slick-prev,
        .slick-next {
          @include mappy-bp(wide) {
            transform: scale(.8);
            height: auto;
          }

          &::before {
            display: block;
            width: 20px;
            height: auto;

          }
        }
      }
    }
  }
}
