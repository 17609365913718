$spinner-height: 80px;

.active-spinner-bg {
  background-color: rgba(0, 0, 0, .6);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;

  > span {
    color: #fff;
    font-weight: 600;
    left: 50%;
    margin-top: $spinner-height;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
