.block-views-blockcompany-registration-order-class-registration-list-block-1 {
  max-width: $content-max-width;
  margin: 0 auto;
  padding: 2rem 1rem 1rem;
  transition: .5s all ease-in;

  @include mappy-bp(med) {
    padding: 0 3.75rem 6.5rem;
  }

  @include mappy-bp($content-max-width + 32px) {
    padding: 0 0 6.5rem;
  }
  .view--company-registration-order-class-registration-list {
    .view__content {
      .tablesaw {
        max-width: 100% !important;
      }
      .register-attendee {
        >.webform-dialog.button.edit {
          background: url('/themes/custom/sja/src/000_assets/icons/icon__edit.svg') center no-repeat;
          background-size: auto;
          border-radius: 50%;
          overflow: hidden;
          width: 30px;
          height: 30px;
          margin-left: .75rem;
          display: inline-block;
          vertical-align: bottom;
          &:hover {
            filter: sepia(100);
          }
        }
        > .webform-dialog.webform-dialog-large.button.assign {
          background: url('/themes/custom/sja/src/000_assets/icons/icon__add.svg') center no-repeat;
          background-size: auto;
          border-radius: 50%;
          display: block;
          overflow: hidden;
          width: 30px;
          height: 30px;
          margin-left: 2rem;
          &:hover,
          &:focus,
          &:active {
            background-image: url('/themes/custom/sja/src/000_assets/icons/icon__add-hover.svg');
          }
        }
      }
    }
  }
}