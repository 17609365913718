// Overrides layout region margin top and bottom
// padding between elements is 60px (mobile) and 190px (desktop)
// So we don't have to account for too many scenarios, just halfing both and adding to the top and bottom of each element.
.layout__region-container>.layout__region {

  >.block--heading-block,
  >.block--heading-block:first-of-type,
  >.block--heading-block:last-of-type {
    padding-top: rem(30px);
    padding-bottom: rem(30px);
    margin: 0 auto;

    @include mappy-bp(med) {
      padding-top: rem(60px);
      padding-bottom: rem(60px);
    }
  }
}

.heading-block--container {
  @extend %cf;
  padding-right: 16px;

  .heading-block--title {
    font-size: 24px;
    font-weight: 900;
    line-height: 1.33;
    letter-spacing: 1.2px;
    text-align: left;
    padding-top: 12px;
    padding-bottom: 12px;
    min-height: 56px;
    height: auto;
    width: 100%;
    color: $color__black;
    background-color: $color__grey--lighter;

    @include mappy-bp(med) {
      min-height: 124px;
      height: auto;
      max-width: 1095px;
      padding-top: 38px;
      padding-bottom: 38px;
      font-size: 36px;
    }
  }

  .heading-block--title,
  .heading-block--body,
  .heading-block--link {
    padding-left: 16px;

    @include mappy-bp(med) {
      padding-left: 80px;
    }
  }

  .heading-block--body {
    margin-top: 32px;
    font-size: 14px;
    line-height: 1.57;

    @include mappy-bp(med) {
      margin-top: 80px;
      font-size: 20px;
      line-height: 1.5;
      max-width: rem(900px);
    }
  }

  .heading-block--link {
    margin: 36px 0;

    a {
      @extend %green-button-mobile;
    }

    @include mappy-bp(med) {
      margin-top: 60px;

      a {
        @include green-button-desktop;
      }
    }
  }
}

.block--block-heading-component {
  padding-top: rem(13px);
  padding-bottom: rem(13px);
  margin: 0 auto;

  @include mappy-bp(med) {
    padding-top: rem(20px);
    padding-bottom: rem(0);
  }
  @include mappy-bp(widest) {
    max-width: rem(1540px);
  }
  .block--header {
    .block--title {
      display: inline-block;
      font-size: rem(14px);
      font-weight: 900;
      line-height: 1.36;
      letter-spacing: rem(1.74px);
      position: relative;
      overflow: hidden;
      color: #006633;
      width: 100%;
      text-transform: uppercase;

      @include mappy-bp(large) {
        font-size: rem(16px);
        letter-spacing: rem(1.98px);
        // max-width: 30%;
      }

      &::after {
        content: ' ';
        width: 100%;
        margin-left: rem(12px);
        transform: translateY(-50%);
        top: 50%;
        position: absolute;
        height: rem(2px);
        display: inline-block;
        background-color: #787e7c;

      }

      .title {
        max-width: 50%;
        display: inline-block;

        @include mappy-bp(large) {
          max-width: 30%;
          // max-width: 30%;
        }
      }
    }

  }
}

.block--donate,
.block--wysiwyg-text,
.block--quick-links > .quick_links,
.block--two-column-card-and-image-layout,
.block--three-column-layout-,
.block--product-link,
.block--contact-us {
  >.block--header {
    padding-top: rem(13px);
    padding-bottom: rem(13px);
    margin: 0 auto;
    text-transform: uppercase;

    @include mappy-bp(med) {
      padding-top: rem(30px);
      padding-bottom: rem(30px);
    }
    @include mappy-bp(widest) {
      max-width: rem(1540px);
    }
    .block--title {
      display: inline-block;
      font-size: rem(14px);
      font-weight: 900;
      line-height: 1.36;
      letter-spacing: rem(1.74px);
      position: relative;
      overflow: hidden;
      color: #006633;
      width: 100%;

      @include mappy-bp(large) {
        font-size: rem(16px);
        letter-spacing: rem(1.98px);
        // max-width: 30%;
      }

      &::after {
        content: ' ';
        width: 100%;
        margin-left: rem(12px);
        transform: translateY(-50%);
        top: 50%;
        position: absolute;
        height: rem(2px);
        display: inline-block;
        background-color: #787e7c;

      }

      .title {
        max-width: 50%;
        display: inline-block;

        @include mappy-bp(large) {
          max-width: 30%;
          // max-width: 30%;
        }
      }
    }

  }
}
