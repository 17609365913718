.form-item--radio {
  position: relative;
  margin-bottom: 1rem;
}

input[type='radio'],
#drupal-off-canvas input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
  line-height: normal;
  margin: 1px \9 0 0;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  border-color: transparent;

  &:focus {
    border: 1px solid $color__grey--lighter;
    min-height: 100%;
    height: auto;
  }
}

input[type='radio']:checked + label,
#drupal-off-canvas input[type='radio']:checked + label {
  background: url('../../src/000_assets/radio/icon__radio--checked.svg') no-repeat;
  background-size: 20px 20px;
  background-position-y: center;

  ///IE10 hack
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    background-image: url('../../src/000_assets/radio/icon__radio--checked.png');
    background-size: 20px 20px;
  }
}

input[type='radio'] + label,
#drupal-off-canvas input[type='radio'] + label {
  background: url('../../src/000_assets/radio/icon__radio.svg') no-repeat;
  background-size: 20px 20px;
  background-position-y: center;

  ///IE10 hack
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    background-image: url('../../src/000_assets/radio/icon__radio.png');
    background-size: 20px 20px;
  }
}

input[type='radio'] + label,
#drupal-off-canvas input[type='radio'] + label {
  display: inline;
  font-weight: 400;
  padding: 1px 0 1px 26px;
}

input[type='radio']:focus + label,
#drupal-off-canvas input[type='radio']:focus + label {
  outline: 1px auto $sja-green;
}