.user-registered-classes-modal  {
  padding: 3rem 4rem;
  background-color: #FFF;
  box-shadow: 0 0 24px rgba(0, 0, 41, .2);
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: -1;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: .5s ease-in-out;
  max-width: 769px;

  &.fade-in-modal {
    opacity: 1;
    z-index: 9999;
  }

  .user-registered-classes-modal__close {
    border: none;
    width: 1.5rem;
    height: 1.5rem;
    background-color: none;
    background-image: url('/themes/custom/sja/src/000_assets/icons/close_dark_grey.svg');
    background-repeat: no-repeat;
    background-position: center;
    font-size: 0;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
}
