
.block-views-blockrelated-products-block-1 {

  max-width: 80%;
  margin: 64px auto 82px;

  @include mappy-bp(0 812px) {
    padding: 0 1rem;
  }

  @include mappy-bp(widest) {
    margin-bottom: 190px;
    max-width: 1540px;
  }

  .title_related_products {
    position: relative;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 1.98px;
    color: $sja_green;
    margin: 3.5rem 0 2rem 0;
    overflow-x: hidden;

    > h3 {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 1rem;
    &::after {
        content: ' ';
        display: inline-block;
        width: 100%;
        margin-left: 12px;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        top: 50%;
        position: absolute;
        background-color: #787e7c;
        height: 2px;
    }
    }
  }

  .block__content {

    .view__content {
        display: flex;
        margin: 0 auto 25px;
        height: auto;
        width: 100%;
        flex-wrap: wrap;

        justify-content: space-between;

        @include mappy-bp(mega) {
          justify-content: flex-start;
        }


      >.view__row {
        margin-left: 0;
        min-height: 380px;
        padding-bottom: 0;
        width: 100%;

        @include mappy-bp(land) {
          width: 44%;
        }
        @include mappy-bp(mega) {
          max-width: 1540px;
          width: 20%;
          margin-right: 3.75rem;
        }
        margin-bottom: 1.5rem;

        .views-field-title {
          a {
            color: $sja_green;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.43;
            letter-spacing: normal;
            text-align: left;
            position: relative;
            text-decoration: none;
            &:hover {
              color: black;
            }
          }
        }

        #views-field-field-feature-image {
          #field-content {
            img {
              border: 1px solid #E5EBE9;
              margin-bottom: 20px;
            }
          }
        }

        .views-field-variations {
          .field--widget-commerce-product-variation-attributes {
            display: none;
          }
          .field--name-quantity {
            display: none;
          }
        }

      }
      @include mappy-bp(mega) {
        >div:nth-of-type(4n) {
          margin-right: 0;
        }
      }
    }
  }
}
.product-tags-container {
      display: none !important;
}