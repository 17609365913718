.webform-submission-individual-registration-with-fil-form {
  margin-bottom: 0 !important;
  padding: 0 !important;
  max-width: 100% !important;
  #individual-registrations-filter {
    select {
      width: 100%;
    }
    @include mappy-bp(med) {
      width: 50%;
    }
  }
}

.block-views-blockregistrations-individual-upcoming-classes,
.block-views-blockregistrations-individual-previous-classes {
  .views-exposed-form {
    .form-item--id-combine {
      margin-bottom: 1rem;
    }
    #edit-actions,
    .form-actions {
      input {
        margin: .5rem 0;
        @include mappy-bp(mobile) {
          margin: 1.5rem 0;
        }
      }
    }
  }
  .view__empty {
    margin-bottom: 2rem;
  }
  .feed-icons {
    margin: 1rem 0 3rem;

    .csv-feed {
      a {
        display: inline-block;
        width: auto;
        background: none;
        text-indent: 0;
        font-size: .875rem;
        font-weight: 800;
        color: $color__black;
        text-decoration: none;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }
}
