$color__date-picker--bkg:        #FAFAFA;
$color__date-picker--header:     #EEEEEE;

//dafault date picker
.ui-datepicker {
  font-family: $base-font-family;
  border-radius: 0;
  padding: 0;

  table {
    width: 100%;
  }

  th {
    padding: 1rem .5rem;
  }

  select.ui-datepicker-month,
  select.ui-datepicker-year {
    font-size: 1rem;
    padding: .5rem 1.5rem .5rem 1rem;
  }
}

.ui-datepicker-header {
  border-radius: 0;
  padding: 0;
}

.ui-widget-header {
  background: none;
  background-color: $color__date-picker--header;
  border: 0;
}

.ui-state-default,
.ui-widget-content
.ui-state-default {
  background: none;
  background-color: $color__date-picker--bkg;
}

.ui-widget-content {
  max-width: 100%;
}

.ui-datepicker-prev,
.ui-datepicker-next {
  width: 44px;
  height: 44px;
}

