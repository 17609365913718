// User page top navigation
.region-prefix {
  overflow: hidden;
  .menu--user-page-navigation {
    background-color: $light_light_grey;
    transition: 0.5s all ease-in;
    overflow-x: scroll;
  }
  ul.nav--user-page-navigation {
    max-width: $content-max-width;
    width: 375px; // set based on the length of the ul
    margin: 0 auto;
    list-style-type: none;
    padding: rem(10px) 1rem;
    transition: 0.5s all ease-in;

    @include mappy-bp(375px) {
      width: auto;
    }

    @include mappy-bp(med) {
      padding: rem(8px) 3.75rem rem(10px);
    }

    @include mappy-bp($content-max-width + 32px) {
      padding: rem(10px) 0;
    }

    > li {
      display: inline-block;
      margin: 0 1rem;

      @include mappy-bp(med) {
        margin: 0 rem(30px);
      }

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
    span {
      display: block;
    }

    a {
      display: block;
      font-size: rem(14px);
      color: $color__black;
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }

      &.is-active-trail {
        font-weight: bold;
      }
    }
  }
}

// User page bottom navigation

.region-sidebar-first {
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.16);
  margin-bottom: 2rem;

  @include mappy-bp(med) {
    margin-bottom: 0;
  }

  ul.nav--user-page-navigation {
    display: none;
    position: relative;
    z-index: 1;
    list-style-type: none;
    transition: none;
    margin-top: 12px;

    @include mappy-bp(med) {
      display: block;
      margin-top: 0;
    }

    li {
      > a {
        display: block;
        font-size: rem(14px);
        background-color: white;
        color: $color__black;
        text-decoration: none;
        padding: 1.25rem 2.5rem;
        transition: 0.5s all ease-in;

        &.is-active-trail {
          background-color: $light_light_grey;
          font-weight: bold;
        }
      }

      &:hover,
      &:focus {
        > a {
          background-color: $light_light_grey;
        }
      }
    }
  }

  .menu--user-page-navigation[aria-expanded="true"] {
    transition: none;

    .nav--user-page-navigation {
      display: block;

      @include mappy-bp(med) {
        display: block;
        max-height: 100%;
      }
    }
  }
  .nav__subnav {
    list-style-type: none;
  }
}

.user-page-navigation-sidebar__btn {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  background: none;
  border: none;
  border-top: 2px solid #e5ebe9;
  text-align: left;
  padding: 1rem 3rem 1rem 2.5rem;
  font-size: 0.875rem;
  font-weight: bold;

  &::after {
    content: "";
    width: 12px;
    height: 12px;
    display: block;
    background-image: url("/themes/custom/sja/src/000_assets/arrows/icon__arrow--down.svg");
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    transition: 0.5s all ease-in;
  }

  @include mappy-bp(med) {
    display: none;
  }
}

.menu--user-page-navigation.show-navigation {
  .user-page-navigation-sidebar__btn::after {
    transform: rotate(180deg);
  }
}
