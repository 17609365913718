// @import url('https://fonts.googleapis.com/css?family=Lato:400,700');

.hero-block {
  background-color: #f5f5f5;
  padding-top: 2.5rem;
  margin-bottom: -1rem;
}

.hero-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Adjusted to 'center' for general layout, will be overridden in media query */
  align-items: stretch;
  margin: 0 4rem;
  font-family: "Nunito Sans", sans-serif;
}

.hero-main-card,
.hero-side-cards {
  display: flex;
  flex-direction: column;
}

.hero-main-card {
  flex: 2; /* Larger flex basis for the main card */
  margin: 10px;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  max-width: 55%;
  height: fit-content;
}

.hero-side-cards {
  flex: 1; /* Smaller flex basis for the side cards */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: fit-content;
}

.hero-side-card {
  margin: 10px;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.hero-main-title,
.hero-side-title {
  padding-left: 2rem;
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  color: #2f2f2f;
}
.hero-main-title,
.hero-side-title {
  position: relative;
  text-decoration: none;
}

/* Animated underline effect */
.hero-main-title::after,
.hero-side-title::after {
  content: "";
  position: absolute;
  width: 0;
  height: 3px; /* Underline thickness */
  bottom: 4px; /* Distance from the text */
  left: 2rem;
  background-color: #000; /* Underline color */
  transition: width 0.3s ease-in-out;
}

.hero-side-title:hover::after {
  width: 90%;
}

.hero-main-title:hover::after {
  width: 90%;
}

.hero-category {
  padding-left: 2rem;
  text-transform: uppercase;
  color: #818181;
  font-size: 14px;
  position: relative;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.hero-main-card img {
  width: 100%;
  border-radius: 5px 5px 0 0;
  margin-bottom: 0;
  object-fit: cover;
  height: 45vh;
}

.hero-main-card-img .field__label {
  display: none;
}

.hero-side-card img {
  width: 100%;
  height: 18vh;
  border-radius: 5px 5px 0 0;
  object-fit: cover;
  margin-bottom: 0;
}

.hero-side-card-img .field__label {
  display: none;
}

.hero-side-card > .hero-category {
  top: 0.8rem;
}

.title-button-container,
.side-title-button-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 1rem;
  // min-height: 4rem;
}

.hero-side-title {
  margin-right: 10px;
}

/* Style the button as inline-block */
.hero-card-button,
.hero-side-card-button {
  background-color: #e0e1e2;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  font-family: "Lato", sans-serif !important;
  text-decoration: none;
  color: inherit;
  white-space: nowrap;
  margin: 0;
  position: relative;
  bottom: 1rem;
}

.hero-card-button::after,
.hero-side-card-button::after {
  content: "";
  display: inline-block;
  width: 19px;
  height: 19px;
  margin-left: 5px;
  background-image: url("../../src/000_assets/icons/nav-cta-right-stream-30px.svg");
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 0.7rem;
  top: 4px;
  position: relative;
}

/* Responsive design for screens 1200px or smaller */

@media (max-width: 1200px) {
  .hero-block {
    padding-top: 2px;
    margin-top: -10px;
    margin-bottom: -2rem;
  }

  .hero-card-container {
    flex-direction: column;
    margin: 2rem;
  }

  .hero-side-cards {
    flex-direction: column;
    justify-content: space-between;
  }

  .hero-side-card {
    margin-top: 10px;
  }

  .hero-side-card:nth-child(2) {
    margin-top: 21px;
  }

  .hero-main-title,
  .hero-side-title {
    font-size: large;
    padding-left: 1rem;
  }

  .hero-card-button {
    float: right;
    margin: 1rem;
    font-weight: 700;
    font-size: 1rem;
    display: inherit;
  }

  .hero-main-card {
    max-width: 100%;
  }
  .hero-main-card img {
    height: 22vh;
  }
  .hero-category {
    font-size: 0.8rem;
    padding-left: 1rem;
  }

  .hero-side-card-button {
    bottom: 4px;
    font-weight: 700;
  }

  .title-button-container,
  .side-title-button-container {
    padding-right: 0.5rem;
    min-height: 3.5rem;
  }
}

/* Responsive design for screens between 620px and 1200px */

@media (min-width: 620) and (max-width: 1200px) {
  .hero-card-container {
    flex-direction: row;
    margin-left: 8rem;
    margin-right: 8rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .hero-side-cards .hero-side-card:last-child {
    width: 100%;
    margin: 6rem;
  }
}

@media (min-width: 1200px) and (max-width: 1356px) {
  .hero-side-title {
    font-size: 19px !important;
  }
}

/* Responsive design for screens between 667px or smaller */

@media (max-width: 667px) {
  .hero-card-container {
    margin: 1rem;
  }

  .hero-side-title,
  .hero-main-title {
    font-size: 15px;
    margin: 0;
  }

  .hero-category {
    margin-top: 0.75rem;
    margin-bottom: 0.25rem;
  }

  .title-button-container,
  .side-title-button-container {
    min-height: 3rem;
  }

  .hero-side-card-button {
    padding: 10px 11px;
  }
  .hero-card-button {
    padding: 10px 11px;
  }
}

/* Responsive design for screens for 1800px or larger */
@media (min-width: 1800px) {
  .hero-card-container {
    margin: 0 16%;
  }
}

@media (min-width: 1800px) {
  .hero-card-container {
    margin: 0 16%;
  }
}

@media (min-width: 1200px) {
  .hero-side-card-button {
    margin-left: 0.5rem;
  }
}
