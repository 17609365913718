// Fonts
$system-font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, sans-serif;
$base-font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, sans-serif;
$lato: 'Lato', 'Helvetica Neue', Arial, sans-serif;
$secondary-font-family: 'Nunito Sans', BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, sans-serif;
$monospace-font-family: Consolas, 'Menlo', 'Liberation Mono', 'Courier New', monospace, sans-serif;

///Initializing Typi library
@include typi-init;

///Perfect fourth
$ms-ratio: 1.333;

// font-weights
$font-normal: 400;
$font-semi-bold: 500;
$font-bold: 700;
$font-black: 800;
