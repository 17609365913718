.block--language-switcher {
  margin-top: rem(-50px); // align with second header region
  margin-bottom: rem(6px);

  @include mappy-bp(wide) {
    padding-right: rem(12px);
    margin: 0;
  }

  ul {
    list-style: none;
    li {
      position: relative;
      a {
        display: inline-flex; /* Use inline-flex to keep the icon and text side by side */
        align-items: center; /* Vertically center the content */
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 1.74px;
        color: black;
        text-align: center;
        text-decoration: none;
        line-height: 1.2;
        background-color: #F5F5F5;
        padding: 0 .5rem;
        border-radius: 2px;
        font-weight: 600;
        font-family: 'Lato', sans-serif;
        transition: box-shadow .3s ease; /* Smooth transition for the shadow */
        height: rem(28px);

        @include mappy-bp(wide) {
          font-size: 14px;
        }

        &:hover {
          box-shadow: 0 0 1.5px rgba(0, 0, 0, .2); /* Slightly darker shadow on hover */
        }

        /* Add this to insert the icon before the <div> */
        &::before {
          content: '';
          display: inline-block;
          width: rem(16px);
          height: rem(16px);
          background-image: url('data:image/svg+xml,%3Csvg id="earth-1--planet-earth-globe-world" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"%3E%3Cpath id="Union" d="M1.5,7a5.5,5.5,0,0,1,7.7-5.039v.276a1.68,1.68,0,0,1-1.68,1.68,1.68,1.68,0,0,0-1.68,1.736V7.044a1.68,1.68,0,0,1-1.68,1.68H1.778A5.5,5.5,0,0,1,1.5,7Zm8.214,4.783A5.515,5.515,0,0,0,12.235,8.7L12.1,8.6a3.176,3.176,0,0,0-1.417-.354H8.81a1.543,1.543,0,0,0,0,3.075,1.09,1.09,0,0,1,.907.47ZM7,0a7,7,0,1,0,7,7A7,7,0,0,0,7,0Z" transform="translate(-0.002 -0.002)" fill="%2318573a" fill-rule="evenodd"/%3E%3C/svg%3E');
          background-repeat: no-repeat;
          background-size: cover;
          margin-right: 5px;
        }
      }
    }
  }

}
html[lang='en'] {
  .block--language-switcher {
    li.en {
      display: none;
    }
  }
}
html[lang='fr'] {
  .block--language-switcher {
    li.fr {
      display: none;
    }
  }
}
