.page-container-404 {
  font-family: 'Lato', sans-serif;
  background-color: #F2F2F2;
}

.logo-404 {
  margin: 0 auto 20px;
}

.bilingual-container-404 {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  background-color: #ffffff;
  text-align: center;
  margin: 1rem;
  padding: 1rem;
  border-radius: 5px;
}

.message-container-404 {
  flex-basis: 45%; /* Adjust this value based on your design preference */
  box-sizing: border-box;
}

.message-header-404 {
  font-family: 'Nunito Sans', sans-serif;
  font-size: x-large;
}

.message-404 {
  font-size: smaller;
  line-height: 25px;
}

.logo-svg-404 {
  height: 11vh;
  margin-top: 1rem;
  width: -webkit-fill-available;
}

.svg-container {
  background-color: #F2F2F2;
}

svg-404 {
  width: -webkit-fill-available;
  height: 60vh;
}

/* CSS for buttons */
.buttons-container-404 {
    display: flex;
    justify-content: center;
    margin-top: 20px; /* Adjust margin as needed */
}

.button-404 {
    margin: 0 10px; /* Adjust margin between buttons */
    font-weight: 600;
    z-index: 1000;
}

.button-404 a {
    display: inline-block;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    background-color: #006633;
    color: #fff;
    border-radius: 5px;
}

.image-404 {
  width: -webkit-fill-available;
}

/* Responsive styles */
@media (max-width: 767px) {
    .buttons-container-404 {
        flex-direction: row;
    }

    .button-404 {
        margin: 2px 10px;
        font-weight: 600;
        font-size: 1rem;
    }
}


@media (max-width: 1200px) {
  .bilingual-container-404 {
      flex-direction: column;
      align-items: center;
  }

  .message-container-404 {
      flex-basis: 80%; /* Adjust for mobile */
  }

   .bilingual-container-404 {
   font-size: 14px;
   }

   .message-404 {
    font-size: larger;
}

}

      @media (max-width: 460px) {
      .image-404 {
      margin-top: -5rem;
}
}

      @media (min-width: 1200px) {
       .image-404 {
      margin-top: -4rem;
      }
      .message-404 {
      font-size: 1em;
      }
      }


      /* Responsive styles */
@media (max-width: 400px) {
  .buttons-container-404 {
      flex-direction: column;
  }
}
@media (max-width: 500px) {
.message-header-404 {
  font-size: 1.4rem;
}
}