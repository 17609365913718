// @file
// Styles for progress bars.


$color__progress-bar_border:     #004174;
$color__progress-bar_bkg:        rgba(0, 65, 116, .1);
$color__progress-bar_bar:        #004174;

.progress {
  position: relative;
}

.progress__track {
  background-color: $color__progress-bar_bkg;
  border: 1px solid $color__progress-bar_bar;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, .15);
  height: 1rem;
  margin: .2em 0;
  max-width: 100%;
  min-width: 6em;
  overflow: hidden;
}

.progress__bar {
  background-color: $color__progress-bar_bar;
  height: 1rem;
  transition-duration: .5s;
  transition-property: width;
  transition-timing-function: ease-out;
  width: 3%;
}

.progress__description,
.progress__percentage {
  color: $color__grey--dark;
  overflow: hidden;
}

.progress__description {
  float: left; // LTR
}

.progress__percentage {
  float: right; // LTR
}
