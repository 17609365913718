.block_categories {
  width: full-width;
  margin-left: 83px;
  .block_title {
    @extend %full-width-green-heading;
  }

  .featured {
    @include span(12 of 12);
  }

  .not_featured {
    @include span(3 of 12);
    width: 350px;
  }
}
