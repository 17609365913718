main label {
  display: block;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  color: #000000;
  font-size: 1rem;
  font-weight: 800;
}

.form-item--textarea {
  label {
    margin-bottom: 1rem;
  }
}
