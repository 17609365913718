.layout-container > .wrapper {

    .menu--back-to-store {
        padding-left: 1rem;

        @include mappy-bp(med) {
          padding: 0 3.75rem;
        }
        @include mappy-bp(wide) {
          padding: 0 3.75rem 60px;
        }
        @include mappy-bp(widest) {
          margin: 0 5% 0 5%;
        }

        @include mappy-bp($content-max-width + 32px) {
        padding: 0 0 60px;
        }
    }

    .nav--back-to-store {

        list-style-type: none;
        max-width: 1540px;
        margin: 0 auto;

        .nav__link {

        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: $sja_green;
        position: relative;
        text-decoration: none;
        padding-left: 18px;

        &:hover {
            color: black;
        }

        &::before {
            content: ' ';
            display: inline-block;
            background-image: url('../../src/000_assets/icons/double_arrow_n.svg');
            background-repeat: no-repeat;
            background-position: center;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 12px;
            height: 12px;
            left: 0;
        }

        &:hover {
            &::before {
            background-image: url('../../src/000_assets/icons/double_arrow_h.svg');
            }
        }
        }
    }
}