.path-user,
.path-portal {
  // Style for user page edit
  .user-form {
    > fieldset,
    .field--name-field-how-may-we-contact-you > fieldset {
      .fieldset__legend {
        width: 100%;
        position: relative;
        overflow: hidden;
      }

      .legend-wrapper {
        display: inline-block;
        font-size: rem(14px);
        font-weight: 900;
        line-height: 1.36;
        letter-spacing: rem(1.74px);
        position: relative;
        text-transform: uppercase;
        overflow: hidden;
        color: #006633;
        width: 100%;
        &::after {
          content: ' ';
          width: 100%;
          margin-left: rem(12px);
          transform: translateY(-50%);
          top: 50%;
          position: absolute;
          height: rem(2px);
          display: inline-block;
          background-color: #787e7c;

        }
        .fieldset__legend-text {
          max-width: 55%;
          display: inline-block;
          @include mappy-bp(360px) {
            max-width: 50%;
          }

          @include mappy-bp(380px) {
            max-width: 90%;
          }

          @include mappy-bp(large) {
            max-width: 80%;
          }
        }

        @include mappy-bp(large) {
          letter-spacing: rem(1.98px);
        }
      }

      > .fieldset__content {
        > div {
          margin-bottom: 1rem;
        }
      }
    }

    #edit-group-change-password {
      .fieldset__legend {
        .fieldset__legend-text {
          max-width: 80%;
        }
      }
      .fieldset__content {
        width: 100%;
        > .form-wrapper {
          > div {
            margin-bottom: 1rem;
          }
          #edit-pass {
            .form-item--id-pass-pass1 {
              margin-bottom: 1rem;
            }
          }
          @include mappy-bp (larger) {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            justify-content: space-between;
            > div {
             width: 47%;
            }
            #edit-pass {
              width: 100%;
              display: flex;
              align-items: flex-start;
              flex-wrap: wrap;
              justify-content: space-between;
              > div {
                width: 47%;
              }
            }
          }
        }
        .field--name-user-picture {
          .form-managed-file {
            img {
              margin-bottom: 1rem;
            }
            input[type='submit'] {
              width: 50%;
              padding: .25rem;
              margin-top: 1rem;
            }
          }
        }
      }
    }

    > fieldset:not(:first-of-type) {
      .fieldset__content {
        width: 40%;
      }
    }

    #edit-group-contact-information,
    #edit-group-settings {
      .fieldset__content {
        details {
          summary {
            display: none;
          }
        }

        select {
          width: 100%;
        }
      }
    }

    #edit-group-corporate-information,
    #edit-field-company-address-wrapper {
      .fieldset__content {
        details {
          summary {
            display: none;
          }
        }

        select {
          width: 100%;
        }
      }
    }

    #edit-group-settings {
      .fieldset__content {
        width: 100%;
        @include mappy-bp(larger) {
          width: 47%;
        }
        > details {
          margin-bottom: 1rem;
        }
      }
    }

    #edit-group-contact-information, #edit-group-corporate-information {
      .fieldset__content {
        width: 100%;
        .field--name-field-address, .field--name-field-company-address {
          margin-bottom: 0;

          details {
            .details__content {
              > div {
                > #edit-field-address-0-address,
                > div[data-drupal-selector='edit-field-address-0-address'], div[data-drupal-selector='edit-field-company-address-0-address'] {
                  > div {
                    margin-bottom: 1rem;
                  }
                }

              }

              #edit-field-address-0-address-container1,
              .address-container-inline {
                margin-bottom: 0 !important;
                > .form-item {
                  margin-bottom: 1rem;
                  width: 100%;
                }
              }
            }
          }
        }
        @include mappy-bp(larger) {
          display: flex;
          align-items: flex-start;
          flex-wrap: wrap;
          justify-content: space-between;

          > .form-wrapper {
            width: 47%;
            .form-item--error-message {
              font-size: .75rem;
              color: #e50020;

              strong {
                font-weight: bold;
              }
            }
          }

          .field--name-field-address, .field--name-field-company-address {
            flex-basis: 100%;
            margin-right: 0;

            details {
              .details__content {
                > div {
                  > #edit-field-address-0-address, #edit-field-company-address-0-address
                  > div[data-drupal-selector='edit-field-address-0-address'], div[data-drupal-selector='edit-field-company-address-0-address'] {
                    display: flex;
                    align-items: flex-start;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .form-item--id-field-address-0-address-address-line1, .form-item--id-field-company-address-0-address-address-line1 {
                      width: 100% !important;
                    }
                    > div {
                      width: 47%;
                    }

                    .address-container-inline,
                    #edit-field-address-0-address-container1, #edit-field-company-address-0-address-container1 {
                      display: flex;
                      align-items: flex-start;
                      flex-wrap: wrap;
                      justify-content: space-between;
                      width: 100%;
                      > .form-item {
                        position: relative;
                        width: 47%;
                        margin-right: 0;
                        margin-bottom: 1rem;
                        > .form-item--error-message {
                          position: absolute;
                        }
                      }
                    }
                  }

                  > div[data-drupal-selector='edit-field-address-0-address'], div[data-drupal-selector='edit-field-company-address-0-address'] {
                    > div {
                      margin-bottom: 1rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    > fieldset,
    > div {
      input[disabled='disabled'],
      select[disabled='disabled'] {
        background-color: $light_light_grey;
        color: $color__black;
        opacity: .7;
        option {
          color: $color__black;
        }
      }
      label.is-required::after,
      label.form-required::after,
      .form-item--id-field-branch > label::after,
      .form-item--id-field-address-0-address-country-code > label::after,
      label.edit-preferred-langcode::after {
        content: '*';
        vertical-align: unset;
        display: inline-block;
        color: $sja_red;
      }
    }

    .field--name-field-dynamics-contact-id {
      display: none;
    }

  }

  .user {
    > h1 {
      margin-top: 2rem;
      font-size: 2.25rem;

      @include mappy-bp(med) {
        margin-top: 0;
      }
    }

    > fieldset,
    > .field--name-field-how-may-we-contact-you {
      .fieldset__legend {
        width: 100%;
        position: relative;
        overflow: hidden;

        .legend-wrapper {
          display: inline-block;
          font-size: rem(14px);
          font-weight: 900;
          line-height: 1.36;
          letter-spacing: rem(1.74px);
          position: relative;
          text-transform: uppercase;
          overflow: hidden;
          color: #006633;
          width: 100%;
          &::after {
            content: ' ';
            width: 100%;
            margin-left: rem(12px);
            transform: translateY(-50%);
            top: 50%;
            position: absolute;
            height: rem(2px);
            display: inline-block;
            background-color: #787e7c;

          }
          .fieldset__legend-text {
            max-width: 75%;
            display: inline-block;
            font-size: 1.25rem;
          }

          @include mappy-bp(large) {
            letter-spacing: rem(1.98px);
          }
        }
      }

      .fieldset__content {
        > div {
          margin-bottom: 1rem;
          font-size: 1.125rem;
          > .field__label {
            display: block;
            letter-spacing: 1.2px;
            text-transform: uppercase;
            color: #000000;
            font-weight: 800;
          }
        }
      }
    }

    > fieldset:first-of-type {
      .fieldset__content {
        @include mappy-bp(larger) {
          display: flex;
          align-items: flex-start;
          flex-wrap: wrap;

          > div {
            width: 40%;
            margin-right: 2rem;
            margin-bottom: 1rem;
          }

          .field--name-field-address {
            flex-basis: 100%;
          }
        }
      }
    }
    fieldset.fieldset-contact-info {
      .fieldset__content > .field--corporate {
        .field--name-field-corporate.field--type-entity-reference {
          .vocabulary-organization {
            .field__label {
              text-transform: uppercase;
              letter-spacing: 1.2px;
              color: #000000;
              font-weight: 800;
            }
            .field_corporate_name_wrapper {
              display: flex;
              align-items: flex-start;
              flex-wrap: nowrap;
              a.edit.button {
                background: url('/themes/custom/sja/src/000_assets/icons/icon__edit.svg')
                center no-repeat;
                background-size: auto;
                border-radius: 50%;
                overflow: hidden;
                width: 30px;
                height: 30px;
                margin-left: .25rem;
                vertical-align: bottom;
                &:hover {
                  filter: sepia(100);
                }
              }
            }

            > div,
            p {
              margin-bottom: 1rem;
            }
            @include mappy-bp(larger) {
              display: flex;
              align-items: flex-start;
              flex-wrap: wrap;

              > div,
              p {
                width: 40%;
                margin-right: 2rem;
              }

              .field--name-field-adress {
                flex-basis: 100%;
              }
            }
          }
        }
      }
    }

    > .user-edit {
      a {
        @extend %green-button-desktop;
        width: auto;
        margin-top: 3rem;
        padding: .5rem 2.75rem;
        cursor: pointer;
        color: $sja_green;
        text-decoration: none;
        &:hover,
        &:focus,
        &:active {
          color: $color__white;
        }
      }
    }
  }
  .ui-dialog {
    @media (max-width: 850px) {
      width: 550px !important;
      left: calc(50vw - 250px) !important;
    }
    @media (max-width: 568px) {
      width: 300px !important;
      left: calc(50vw - 150px) !important;
    }

  }
  .webform-submission-edit-corporate-account-form {
    .form-item--id-corporate {
      input[readonly='readonly'] {
        background: $light_light_grey;
        color: $color__grey--dark;
      }
    }
    .form-item--id-site-store-branch-transit {
      .field__label {
        word-break: break-all;
      }
    }
    .form-item--id-country,
    .form-item--id-province {
      select {
        width: 100%;
        padding: 0 3.5rem 0 1rem;
      }
    }
    fieldset.webform-type-fieldset {
      margin-bottom: 0;
    }
    @include mappy-bp (larger) {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      justify-content: space-between;
      > .messages--error {
        width: 100%;
      }
      > .form-item {
        width: 48%;
      }
      .webform-type-fieldset {
        .fieldset__content {
          display: flex;
          align-items: flex-start;
          flex-wrap: wrap;
          justify-content: space-between;
          > div {
            width: 48%;
          }
        }
      }
    }
  }
}

.path-portal.sidebar-first {
  .layout-content {
    @include mappy-bp(med) {
      padding: 0 3.75rem 3.75rem 3.75rem;
    }
  }
}