.view--employees-of-corporate {
  .view__header {
    margin-bottom: 2rem;
    a.add-new-employee {
      @include green-button-desktop;
    }
  }
  .view__content {
    table {
      td.views-field-field-first-name  {
        .first-name-wrapper {
          display: flex;
          align-items: flex-start;
          flex-wrap: nowrap;
        }
      }
    }
    .tablesaw-swipe th.tablesaw-swipe-cellhidden,
    .tablesaw-swipe td.tablesaw-swipe-cellhidden {
      display: none !important;
    }
    .views-field-field-first-name {
      a {
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
      a.edit {
        background: url('/themes/custom/sja/src/000_assets/icons/icon__edit.svg')
        center no-repeat;
        background-size: auto;
        border-radius: 50%;
        overflow: hidden;
        width: 30px;
        height: 30px;
        margin-right: .25rem;
        vertical-align: bottom;
        &:hover {
          filter: sepia(100);
        }
      }
    }
    .views-field-field-email {
      > a.webform-dialog.edit {
        background: url('/themes/custom/sja/src/000_assets/icons/icon__edit.svg') center no-repeat;
        background-size: auto;
        border-radius: 50%;
        overflow: hidden;
        width: 30px;
        height: 30px;
        margin-left: .75rem;
        display: inline-block;
        vertical-align: bottom;
        &:hover {
          filter: sepia(100);
        }
      }

      > .webform-dialog.webform-dialog-narrow.assign-button {
        background: url('/themes/custom/sja/src/000_assets/icons/icon__add.svg')
          center no-repeat;
        background-size: auto;
        border-radius: 50%;
        display: block;
        overflow: hidden;
        width: 30px;
        height: 30px;
        margin-left: 2rem;
        &:hover,
        &:focus,
        &:active {
          background-image: url('/themes/custom/sja/src/000_assets/icons/icon__add-hover.svg');
        }
      }
    }
  }
  .feed-icons {
    margin: 1rem 0 3rem;

    .csv-feed {
     a {
      display: inline-block;
      width: auto;
      background: none;
      text-indent: 0;
      font-size: .875rem;
      font-weight: 800;
      color: $color__black;
      text-decoration: none;

      &:hover,
      &:focus {
       text-decoration: underline;
      }
     }
    }
  }
}

.path-node-2306 {
  .ui-dialog {
    @media (max-width: 850px) {
      width: 500px !important;
      left: calc(50vw - 250px) !important;
    }
    @media (max-width: 550px) {
      width: 300px !important;
      left: calc(50vw - 150px) !important;
    }
  }
}

.webform-submission-corporate-user-edit-employee-form {
  input[disabled='disabled'] {
    background: $light_light_grey;
    color: $color__grey--dark;
  }
}

.webform-submission-add-new-employee-form,
.webform-submission-corporate-user-edit-employee-form {
  .form-item {
    width: 100%;
    > select {
      width: 100%;
      padding: 0 3.5rem 0 1rem;
    }
  }
  @media (min-width: 850px) {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    .form-item {
      width: 48%;
    }

    .form-item--managed-file {
      width: 100%;
    }

    > .form-item--id-phone-number {
        margin-right: 4%;
    }
    .form-item--id-email {
      flex: 0 0 100%;
    }
  }
}