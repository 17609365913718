.view--corporate-user-registered-classes.view--display-online_class {
  .view__content {
    > .tablesaw {
      thead {
        th {
         padding: 1.25rem 1rem;
         font-size: 1rem;

         a {
          color: $color__black;
          text-decoration: none;

          &:hover,
          &:focus {
           text-decoration: underline;
           color: $sja_green;
          }
         }
        }
       }

       tbody {
        td {
         padding: 2rem 1rem;
         font-size: 1rem;
         font-weight: bold;

         a {
          color: $sja_green;
          text-decoration: none;

          &:hover,
          &:focus {
           text-decoration: underline;
          }
         }
        }
       }

      .views-field-field-attendee {
        .employees {
          margin-top: .5rem;
          > p {
            margin-bottom: .5rem;
          }
          > ol li {
            margin: 1rem 0;
            @include mappy-bp(widest) {
              margin: 2rem 0;
            }
          }
          a.webform-dialog.button {
          background: url('/themes/custom/sja/src/000_assets/icons/icon__edit.svg') center no-repeat;
          background-size: auto;
          border-radius: 50%;
          overflow: hidden;
          width: 30px;
          height: 30px;
          margin-left: .75rem;
          display: inline-block;
          vertical-align: bottom;
            &:hover {
              filter: sepia(100);
            }
          }
        }
        > .webform-dialog.webform-dialog-narrow.button.assign {
          background: url('/themes/custom/sja/src/000_assets/icons/icon__add.svg') center no-repeat;
          background-size: auto;
          border-radius: 50%;
          display: block;
          overflow: hidden;
          width: 30px;
          height: 30px;
          margin-left: 2rem;
          &:hover,
          &:focus,
          &:active {
            background-image: url('/themes/custom/sja/src/000_assets/icons/icon__add-hover.svg');
          }
        }
      }
    }
  }
  .feed-icons {
    margin: 1rem 0 3rem;

    .csv-feed {
     a {
      display: inline-block;
      width: auto;
      background: none;
      text-indent: 0;
      font-size: .875rem;
      font-weight: 800;
      color: $color__black;
      text-decoration: none;

      &:hover,
      &:focus {
       text-decoration: underline;
      }
     }
    }
   }
}

.ui-front.ui-dialog-content.ui-widget-content {
  .webform-submission-register-employees-contacts-form,
  .webform-submission-edit-employee-form,
  .webform-submission-online-class-edit-employee-form,
  .webform-submission-assign-employee-to-online-class--form {
    margin: 3rem auto;
    .form-item--select {
      select {
        font-size: 1rem;
      }
    }
  }
}

.block-views-blockcorporate-user-registered-classes-online-class {
  margin-bottom: 3.5rem !important;
  >.block__title {
   font-size: 1.5rem;
   margin-bottom: 2rem;
 }
}
