.block-views-blockcareer-page-block-block-1 {
  .careers--block--title {

    letter-spacing: .12375rem;

    >h2 {
      display: inline-block;
      font-size: .75rem;

      @include mappy-bp(large) {
        font-size: 1rem;
      }

      font-weight: 900;
      line-height: 1.36;
      letter-spacing: rem(1.74px);
      position: relative;
      text-transform: uppercase;
      overflow: hidden;
      color: #006633;
      width: 100%;

      @include mappy-bp(large) {
        font-size: rem(16px);
        letter-spacing: rem(1.98px);
        // max-width: 30%;
      }

      &::after {
        content: ' ';
        width: 100%;
        margin-left: rem(12px);
        transform: translateY(-50%);
        top: 50%;
        position: absolute;
        height: rem(2px);
        display: inline-block;
        background-color: #787e7c;

      }

      >span {
        max-width: 80%;

        @include mappy-bp(land) {
          max-width: 50%;
        }

        display: inline-block;

        @include mappy-bp(large) {
          max-width: 30%;
          // max-width: 30%;
        }
      }
    }
  }

  .careers--block--content {
    margin-top: 2rem;

    @include mappy-bp(large) {
      margin-top: 3rem;
    }

    .view--career-page-block {
      .item-list {
        >ul {
          padding-top: 0;
          padding-bottom: 0;
          list-style: none;

          @include mappy-bp(med) {
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
            flex-wrap: wrap;
            flex-flow: row wrap;
            align-content: flex-end;
          }

          li {
            margin-bottom: 1rem;
            padding: 0;
            border-bottom: none;
            width: 100%;
            @include mappy-bp(med) {
              width: 47%;
              max-width: 47%;
              flex-grow: 1;
              margin-right: 5%;
              &:nth-of-type(2n) {
                margin-right: 0;
              }
            }
            @include mappy-bp(larger) {
              width: 30%;
              max-width: 30%;
              flex-grow: 1;
              margin-right: 3%;
              &:nth-of-type(2n) {
                margin-right: 3%;
              }
            }

            article {
              >h3 {
                font-size: 1.25rem;

                margin-top: 0;
                margin-bottom: .75rem;

                @include mappy-bp(large) {
                  font-size: 1.75rem;
                  margin-bottom: 1rem;
                }

                a {
                  text-decoration: none;
                  color: $color__black;
                  background-repeat: no-repeat;
                  background-size: 0;
                  background-position: 0 1.25em;
                  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    background-position: 0 1.35em;
                  }
                  padding-bottom: .35rem;
                  overflow: hidden;
                  transition: background-position .2s linear .2s, box-shadow .2s linear 0s;

                  &:hover,
                  &:focus {
                    cursor: pointer;
                    background-size: 100%;
                    background-image: linear-gradient(to left, $color__primary 0%, $color__primary 100%);
                    transition: background-size .2s linear 0s, box-shadow .2s linear .2s;
                  }
                }
              }

              .node__content {
                margin-bottom: 2rem;
                font-size: .875em;
                line-height: 1.57;
                @include mappy-bp(med) {
                  font-size: 1rem;
                  line-height: 1.63;
                  margin-bottom: 3.5rem;
                }
              }
            }
          }

          >li:nth-of-type(3n) {
            @include mappy-bp(larger) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}