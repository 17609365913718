.user-order-items-class-registration {
  .view--order-items-table-user-view {
    .views-exposed-form {
      .form-item--id-combine {
        margin-bottom: 1rem;
      }
      #edit-actions,
      .form-actions {
        input {
          margin: .5rem 0;
          @include mappy-bp(mobile) {
            margin: 1.5rem 0;
          }
        }
      }
    }
    .view__content {
      overflow-x: auto;
      .tablesaw-bar.tablesaw-mode-swipe {
        margin-top: 0 !important;
      }
      table {
        thead {
          background-color: $light_light_grey;
          th {
            padding: 1.25rem 1rem;
            font-size: 1rem;
            > a {
              display: flex;
              align-items: center;
              > .tablesort {
                background-position: center;
                background-repeat: no-repeat;
                background-size: auto;
              }
            }
          }
        }
        .register-attendee {
          >.webform-dialog.button.edit {
            background: url('/themes/custom/sja/src/000_assets/icons/icon__edit.svg') center no-repeat;
            background-size: auto;
            border-radius: 50%;
            overflow: hidden;
            width: 30px;
            height: 30px;
            margin-left: .75rem;
            display: inline-block;
            vertical-align: bottom;
            &:hover {
              filter: sepia(100);
            }
          }
          > .webform-dialog.webform-dialog-large.button.assign {
            background: url('/themes/custom/sja/src/000_assets/icons/icon__add.svg') center no-repeat;
            background-size: auto;
            border-radius: 50%;
            display: block;
            overflow: hidden;
            width: 30px;
            height: 30px;
            margin-left: 2rem;
            &:hover,
            &:focus,
            &:active {
              background-image: url('/themes/custom/sja/src/000_assets/icons/icon__add-hover.svg');
            }
          }
        }
      }
    }
    .view__empty {
      margin-bottom: 2rem;
    }
    .feed-icons {
      margin: 1rem 0 3rem;

      .csv-feed {
        a {
          display: inline-block;
          width: auto;
          background: none;
          text-indent: 0;
          font-size: .875rem;
          font-weight: 800;
          color: $color__black;
          text-decoration: none;

          &:hover,
          &:focus {
            text-decoration: underline;
          }
        }
      }
    }
  }
}