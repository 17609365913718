.block-views-blockfind-your-courses-block-1,
.block--main-page-content {
  input:focus, a:focus, button:focus, [tabindex='0']:focus {
    outline: 1px solid $color__primary;
  }

  .block__title {
    display: block;
    font-size: rem(20px);
    font-weight: 900;
    line-height: 1.36;
    letter-spacing: rem(1.74px);
    position: relative;
    overflow: hidden;
    margin-right: rem(2px);
    margin-bottom: rem(20px);
    @include mappy-bp(med) {
      font-size: rem(36px);
      letter-spacing: rem(1.98px);
      margin-bottom: rem(65px);
    }
    &::after {
      content: ' ';
      display: inline-block;
      width: 100%;
      margin-left: rem(12px);
      transform: translateY(-50%);
      top: 50%;
      position: absolute;
      background-color: $color__primary;
      height: rem(2px);
      @include mappy-bp(land) {
        display: inline-block;
      }
    }
  }

  .view--find-your-courses {
    margin-bottom: rem(24px);
    @include mappy-bp(med) {
      position: relative;
      margin-bottom: rem(48px);
    }
    @include mappy-bp(widest) {
      max-width: $content-max-width;
      margin: 0 auto;
    }
    .view__empty,
    .view__header {
      display: none;
      @include mappy-bp(med) {
        padding-top: 50px;
        display: block;
        font-size: rem(20px);
        font-weight: bold;
      }
    }

    // Filters
    .view__filters {
      @include mappy-bp(med) {
        position: relative;
        margin-bottom: rem(42px);
      }
      .views-exposed-form {
        .form-children {
          @include mappy-bp(med) {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
          }
          @include mappy-bp(wide) {
            max-width: $content-max-width;
            flex-wrap: nowrap;
          }
        }
        .form-item--id-title {
          @include mappy-bp(med) {
            margin-bottom: 1.25rem;
            flex-basis: 100%;
          }
          @include mappy-bp(wide) {
            flex-basis: 100%;
          }
          label {
            position: absolute;
            font-size: .875rem;
            font-weight: bold;
            margin-bottom: .25rem;
            margin-top: -2rem;
            @include mappy-bp(med) {
              font-size: 1.25rem;
              margin-bottom: .75rem;
            }
          }
          .form-text {
            background-image: url('../../src/000_assets/icons/search-n.svg');
            background-repeat: no-repeat;
            background-position: right rem(16px) center;
            margin-bottom: rem(24px);
            min-height: rem(52px);
            font-size: rem(11px);
            @include mappy-bp(mobile) {
              font-size: .825rem;
            }
            @include mappy-bp(med) {
              margin-bottom: 0;
            }
          }
        }
        details {
          margin-bottom: 1.25rem;
          @include mappy-bp(med wide) {
            width: 49%;
            display: inline-block;
            position: relative;
            //
            // &:nth-of-type(odd) {
            //   margin-right: 1%;
            // }
            // &:nth-of-type(even) {
            //   margin-left: 1%;
            // }
          }
          @include mappy-bp(wide) {
            width: 32%;
            // flex: 0 1 32%;
            display: inline-block;
            position: relative;
            // margin: 0 0 1.25rem;
          }
          .details__summary {
            display: block;
            border: rem(1px) solid $color__grey--light-medium;
            background-image: url('../../src/000_assets/icons/more-n.svg');
            background-repeat: no-repeat;
            background-position: right rem(16px) center;
            margin-bottom: 0;
            color: $color__black;
            font-size: rem(14px);
            width: 100%;
            min-height: rem(52px);
            padding: rem(17px) rem(21px);
            &::before {
              background: none;
              font-size: 1rem;
            }
          }
          .details__content {
            cursor: pointer;
            box-shadow: 0 0 rem(8px) 0 rgba(0, 0, 0, .16);
            @include mappy-bp(med) {
              position: absolute;
              background-color: white;
              width: 100%;
              z-index: 10;
            }
            .form-checkboxes {
              .form-item--checkbox {
                min-height: rem(52px);
                padding: rem(17px) rem(37px);
                border-top: rem(1px) solid $color__grey--lighter;
                position: relative;
                margin-bottom: 0;
                &:hover {
                  background-color: $color__grey--lighter;
                  cursor: pointer;
                }
                input[type='checkbox'] + label {
                  display: block;
                  background-position: right center;
                  width: 100%;
                  padding: 0;
                  text-transform: capitalize;
                  font-size: rem(14px);
                  padding-right: 4rem;
                  cursor: pointer;
                }
                input[type='checkbox'] {
                  display: block;
                  width: 100%;
                  position: absolute;
                  height: 100%;
                  top: 0;
                  left: 0;
                  opacity: 1 !important;
                  z-index: -1;

                  &::-ms-check {
                    display: none;
                  }

                  &:hover {
                    background-color: $color__grey--lighter;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
        details[open] .details__summary {
          background-image: url('../../src/000_assets/icons/more-n-up.svg');
          background-color: $color__grey--light-medium;
        }
      }
      .form-actions {
        margin-left: -16px;
        min-width: 100vw;
        box-shadow: 0 0 rem(24px) 0 rgba(0, 0, 0, .16);
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        align-content: center;
        justify-content: space-between;
        width: 100%;
        top: 85%;
        left: 0;
        @include mappy-bp(med) {
          display: inline-block;
          margin-left: 0;
          box-shadow: none;
          position: absolute;
          bottom: 0;
          right: 0;
          top: 100%;
        }
        .button {
          color: $color__black;
          background-color: $color__white;
          border: none;
          border-radius: 0;
          margin-top: 0;
          min-height: rem(52px);
          width: 100%;
          margin: 0 auto;
          &:nth-of-type(2) {
            color: $color__grey--medium;
            border-color: $color__grey--medium;
          }
          @include mappy-bp(med) {
            padding: 9px 27px;
            border-radius: 22px;
            border: solid 2px #006633;
            text-align: center;
            text-decoration: none;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 900;
            line-height: 1.36;
            letter-spacing: 1.74px;
            color: $color__primary;
            background-color: $color__white;
            transition: .2s all;
            display: inline-block;
            min-height: 0;
            min-width: 154px;
            width: auto;
            margin-right: 2rem;
            &:hover,
            &:focus {
              background-color: $color__primary;
              color: $color__white;
            }
          }
        }
      }
    }

    // Content
    .view__content {
      @include mappy-bp(med) {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
      }
      .view__individual-category {
        @include mappy-bp(med) {
          width: 48%;
          margin-top: rem(40px);
        }
        h3 {
          min-height: rem(48px);
          padding: rem(14px) rem(20px);
          color: $color__black;
          background-color: $color__grey--lighter;
          font-size: rem(14px);
          font-weight: 900;
          @include mappy-bp(med) {
            font-size: rem(20px);
            padding: rem(16px);
            padding-bottom: rem(13px);
          }
        }
        .view__row {
          padding: rem(12px) rem(6px);
          border-bottom: rem(1px) solid $color__grey--lighter;
          &:nth-last-of-type(1) {
            border: none;
          }
          .views-field-title {
            a {
              font-weight: normal;
              font-size: rem(14px);
              color: $color__black;
              text-decoration: none;
              border-bottom: 2px solid transparent;
              transition: all .2s;

              &:hover,
              &:focus {
                color: $sja_green;
                border-bottom: 2px solid $sja_green;
              }
              @include mappy-bp(med) {
                font-size: rem(16px);
              }
            }
          }
        }
      }
    }
  }

  // flexbox for new form layout
  @include mappy-bp(wide) {
    #edit-title {
      min-width: 28%;
    }
    .js-title--province {
      margin-left: 2rem;
      min-width: 20%;
      max-width: 195px;
    }
    .js-title--category {
      margin-left: 2rem;
      max-width: 350px;
      min-width: 28%;
    }
    .js-title--delivery-method {
      margin-left: 2rem;
      min-width: 20%;
      max-width: 233px;
    }
  }

  .overlay {
    width: 100%;
    position: fixed;
    z-index: 999;
    top: 100%;
    left: 0;
    overflow-y: scroll;
    background-color: white;
    transition: .4s;
    transition-timing-function: ease-in;
  }

  .overlay-content {
    position: relative;
    margin: 0 auto;
    top: 4rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 30px;
    text-align: center;

  }

  .overlay .closebtn {
    position: absolute;
    font-size: 36px;
    top: -120px;
    right: -16px;
    padding: 2rem;
  }


  @include mappy-bp(med) {
    .overlay {
      position: relative;
      z-index: auto;
      background-color: none;
      left: 0;
      overflow-y: visible;
    }
    .overlay-content {
      position: relative;
      top: 0;
      width: 100%;
      text-align: inherit;
      margin-top: 0;
      margin-left: 0;
      margin-right: 0;
    }

    .overlay .closebtn {
      display: none;
      position: absolute;
      top: 20px;
      right: 45px;
      font-size: 60px;
    }
  }
  .filterGroupBtn.show {
    opacity: 1;
  }



  .filterGroupBtn {
    // opacity: 0;
    text-transform: uppercase;
    color: $color__white;
    background-color: $color__grey--dark;
    display: block;
    min-height: rem(48px);
    font-size: rem(16px);
    font-weight: bold;
    line-height: 1.88;
    letter-spacing: rem(1.2px);
    padding: rem(9px) rem(21px);
    background-image: url('../../src/000_assets/icons/white-filter.svg');
    background-repeat: no-repeat;
    background-position: right rem(16px) center;
    margin-bottom: rem(16px);
    transition: all .1s ease;
    cursor: pointer;
    @include mappy-bp(med) {
      display: none;
      background-image: none;
      background-color: $color__white;
      color: $color__black;
      font-size: rem(20px);
      padding: 0;
      position: absolute;
      top: rem(128px);
    }

    +.views-exposed-form {
      display: none;
      @include mappy-bp(med) {
        display: block;
      }
    }

    &.active {
      color: $color__black;
      background-color: $color__white;
      background-image: url('../../src/000_assets/icons/close_dark_grey.svg');
      padding: 0;
      background-position: top rem(8px) right;
      @include mappy-bp(med) {
        background-image: none;
      }
      +.views-exposed-form {
        display: block;
      }
    }
  }

}

.filter-button-group {
  padding-top: 20px;
  max-width: 100%;
  @include mappy-bp(large) {
    padding-top: 0;
    max-width: $content-max-width;
  }
  .courseFilterBtn {
    font-size: 14px;
    line-height: 19px;
    font-weight: bold;
    letter-spacing: .35px;
    color: $color__grey--dark;
    background-color: $color__filter-tag;
    border: 2px solid $color__filter-tag;
    border-radius: rem(18px);
    margin-right: 20px;
    margin-bottom: 1.25rem;
    padding: .5rem 1rem;
    &::after {
      margin-left: 10px;
      content: $close-dark_grey--compact;
    }
    &:hover {
      cursor: pointer;
    }
  }
}
