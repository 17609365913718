
// When not on a webform node page.
.webform-submission-form {
  max-width: $content-max-width-sm;
  margin: 64px auto 82px;

  @include mappy-bp(0 812px) {
    padding: 0 1rem;
  }

  @include mappy-bp(large) {
    margin-bottom: 190px;
  }

  .webform-section-title {
    position: relative;
    font-size: 1rem;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 1.98px;
    color: $sja_green;
    margin: 3.5rem 0 0 0;
    overflow-x: hidden;

    &::after {
      content: ' ';
      display: inline-block;
      width: 100%;
      margin-left: 12px;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      top: 50%;
      position: absolute;
      background-color: #787e7c;
      height: 2px;
    }
  }

  .webform-section-wrapper {
    .form-required::after,
    .is-required::after {
      content: '*';
      vertical-align: bottom;
      display: inline-block;
      color: $sja_red;
    }
  }

  .form-item {
    #required {
    content: '*';
    color: $sja_red;
    }
  }
  .form-item__error-message {
  // .item__error-message {
    margin-bottom: -19px !important;
    margin-top: 0 !important;
  }

  .has-error input,
  .has-error textarea,
  .has-error select {
    margin-bottom: 0 !important;
  }

  // need to override fieldset.webform-composite-hidden-title from contrib module
  #edit-event-location--wrapper {
    margin-top: 0;
    margin-bottom: 0;
  }

  //remove the datepicker icon
  .ui-datepicker-trigger {
    display: none;
  }

  // one column layout
  .flexbox-container-one-column.webform-section,
  .flexbox-container-one-column.webform-address--wrapper,
  //.flexbox-container-one-column .flexbox-container-one-column.webform-address--wrapper
  .flexbox-container-two-column.webform-section .flexbox-container-one-column {

    .webform-section-wrapper, .fieldset__content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .form-item {
        display: flex;
        flex-direction: column;
        width: 100%;
        flex: 0 1 100%;
        flex-basis: 100%;
        margin-top: 2rem;
        label {
          flex-grow: 1;
        }
      }

      .one-column-50-percent {
        flex-basis: 100%;

        input,
        textarea,
        select {
          width: 100%;

          @include mappy-bp(land) {
            width: 48%;
          }
        }
      }

      .one-column-100-percent {
        flex-basis: 100%;

        input,
        textarea,
        select {
          width: 100%;
        }
      }

      .one-column-auto {
        flex-basis: 100%;

        input,
        textarea,
        select {
          width: auto;
        }
      }
    }
  }

  // two column layout
  .flexbox-container-two-column.webform-section ,
  .flexbox-container-two-column.webform-address--wrapper,
  .flexbox-container-one-column.webform-section .flexbox-container-two-column {
    .webform-section-wrapper,
    .fieldset__content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      > .form-item {
        width: 100%;
        flex: 0 1 100%;
        flex-basis: 100%;
        margin-top: 2rem;
        margin-bottom: 0;
        vertical-align: bottom;

        @include mappy-bp(land) {
          width: 48%;
          flex-basis: 48%;
        }
         input,
         textarea,
         select {
          width: 100%;
         }

      }

      .one-column-50-percent {
        flex-basis: 100%;

        input,
        textarea,
        select {
          width: 100%;

          @include mappy-bp(land) {
            width: 48%;
          }
        }
      }

      .one-column-100-percent {
        flex-basis: 100%;

        input,
        textarea,
        select {
          width: 100%;
        }
      }

      .one-column-auto {
        flex-basis: 100%;

        input,
        textarea,
        select {
          width: auto;
        }
      }
    }
  }

  // Automatically set margins of .form-item and fieldset.
  .form-item {
    margin-top: 2rem;
    margin-bottom: 0;
  }
  .form-item--id-inquiry-branch,
  .form-item--id-branch {
    margin-bottom: 0;
  }

  fieldset {
    margin-top: 4rem;
    margin-bottom: 0;
    .form-item {
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .form-item--checkbox,
    .form-item--radio {
      margin-bottom: .5rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  .form-item--date {
    input {
      &:hover {
        cursor: pointer;
      }
    }
  }
}

// Webform node page.
.page-node-type-webform {
  .node__content,
  .block--page-title,
  .block--help,
  .block--tabs,
  .messages {
    // max-width: $content-max-width;
    max-width: $content-max-width-sm;
    margin: 0 auto;
    transition: all .5s ease-in;

    @include mappy-bp(0 812px) {
      padding: 0 1rem;
    }
  }

  #block-sja-breadcrumbs {
    margin-top: 1.625rem;
    transition: all .5s ease-in;

    // 812 is 780 + 32. Included the padding so there's a smoother transition from when max-width is reached and padding is applied.
    @include mappy-bp(0 812px) {
      padding: 0 1rem;
    }

    @include mappy-bp(812px large) {
      max-width: $content-max-width-sm;
      margin-left: auto;
      margin-right: auto;
      padding: 0;
    }

    @include mappy-bp(large) {
      margin-top: 2rem;
    }
  }

  .node__content {
    margin-bottom: 82px;

    @include mappy-bp(large) {
      margin-bottom: 190px;
    }
  }

  .block--page-title {
    margin-top: 2rem;
    margin-bottom: 3rem;
  }

  // The webform itself.
  .webform-submission-form {
    margin: 4rem auto 0;

    @include mappy-bp(0 812px) {
      padding: 0;
    }
  }
  .messages__content,
  .webform-confirmation__message {
    margin: 0 0 1em 0;
    // color: green;
  }

  // .webform-confirmation__back {

  // }

  .webform-managed-file-preview {
    text-transform: none;
  }
}

.webform-submission-form {
  .webform-section-wrapper {
   .form-item--webform-document-file {
    label {
      text-transform: none;
    }
   }
  }
}

.webform-submission-edit-employee-form,
.webform-submission-edit-contact-form,
#webform-submission-register-employees-contacts-form-ajax {
  .form-item--select {
    select {
      padding: .5rem 3.5rem .5rem 1rem;
      font-weight: normal;
    }
  }
  .form-item--checkbox {
    >label {
      padding: 1px 1px 2px 26px;
    }
  }
}

// allow oversized text labels to align vertically (bottom)
.webform-section-wrapper {
  .form-item {
    display: flex;
    flex-direction: column;
    .form-item__label {
      flex-grow: 1;
    }
  }
}

.ui-datepicker {
  .ui-datepicker-calendar {
    .ui-datepicker-current-day {
      a {
        color: $sja_green;
      }
    }
    a.ui-state-hover {
      border: 1px solid $sja_green;
    }
  }
}

.webform-submission-add-new-employee-form {
  .form-item__error-message {
    margin-bottom: 0 !important;
  }
}