.sja--course-wizard--modal-container {
  width: 90% !important;
  max-width: rem(500px) !important;
  @include mappy-bp(land) {
    max-width: rem(600px) !important;
  }

  .ui-dialog-titlebar {
    background-color: $color__white;
    margin-top: rem(20px);
    margin-right: rem(15px);
    .ui-dialog-title {
      display: none;
    }
    .ui-button {
      border: none !important;
      background-color: transparent !important;
      .ui-icon {
        background-image: url('../../src/000_assets/icons/close_dark_grey.svg') !important;
        background-position: center;
      }
    }
  }

  .ui-dialog-content {
    padding: 0 rem(32px);
    @include mappy-bp(med) {
      padding: 0 rem(64px);
    }
  }

  .webform-submission-course-wizard-form {
    margin: 0;
    padding: 0;
    @include mappy-bp(med) {
      font-size: rem(20px);
    }
    h3 {
      margin-top: 0;
      margin-bottom: rem(24px);
      @include mappy-bp(med) {
        font-size: rem(28px);
      }
    }
  }

  .shs-container {
    .shs-field-container {
      .shs-widget-container {
        margin: rem(16px) 0;
        width: 100%;
        label {
          margin-bottom: rem(8px);
        }
        select {
          width: 100%;
          min-height: rem(50px);
          font-size: rem(16px);
          font-weight: normal;
        }
      }
    }
  }
  .ui-dialog-buttonpane {
    border: none;
    position: relative;
    bottom: rem(36px);
    margin-top: 1.5rem;
    .ui-dialog-buttonset {
      float: left;
      padding: 0 rem(32px);
      @include mappy-bp(med) {
        padding: 0 rem(64px);
      }
      button {
        @extend %green-button-mobile;
        background-color: transparent;
        @include mappy-bp(med) {
          @include green-button-desktop;
        }
      }
    }
  }
}

.webform-submission-course-wizard-form {
  .webform-actions {
    margin-top: 1rem;
  }
}