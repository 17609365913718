// Align classes.
.align-left,
.align-right,
.align-center {
  @include mappy-bp(0 land) {
    float: none;
    margin: 1rem auto;

    img {
      margin: 0 auto;
    }
  }
}

.align-left {
  @include mappy-bp(land) {
    float: left;
    padding: 0 1rem 0 0;
  }
}

.align-right {
  @include mappy-bp(land) {
    float: right;
    padding: 0 0 0 1rem;
  }
}

.align-center {
  @include mappy-bp(land) {
    display: table;
    margin: 0 auto;
  }
}

// Media only. No Caption
.embedded-entity,
.caption-container {
  width: auto;
  margin: 1rem auto;
  display: inline;

  .video-embed-field-responsive-video::after {
    padding-bottom: 0;
  }

  p {
    margin: 0;
  }

  img {
    width: auto;
  }

  figure {
    display: table;
    margin: 0 auto;
  }

  .embedded-entity {
    display: table-cell;
    vertical-align: bottom;
  }

  figcaption {
    display: table-caption;
    caption-side: bottom;
  }

  .video-embed-field-responsive-video {
    position: relative;

    iframe {
      position: relative;
      margin: 0 auto;
      display: block;
      height: 250px;

      @include mappy-bp(med) {
        height: 400px;
      }

      @include mappy-bp(wide) {
        height: 600px;
      }
    }
  }

  // Align classes.
  &.align-left,
  &.align-right,
  &.align-center {
    @include mappy-bp(0 land) {
      float: none;
      margin: 1rem auto;

      img {
        margin: 0 auto;
      }
    }
  }

  &.align-left,
  &.align-right {
    img {
      width: auto;
    }

    @include mappy-bp(wide) {
      .video-embed-field-responsive-video iframe {
        height: 400px;
      }
    }
  }

  &.align-left {
    @include mappy-bp(land) {
      float: left;
      padding: 0 1rem 0 0;
    }

    .video-embed-field-responsive-video {
      iframe {
        @include mappy-bp(wide) {
          padding-left: .75rem;
        }
      }
    }
  }

  &.align-right {
    @include mappy-bp(land) {
      float: right;
      padding: 0 0 0 1rem;
    }

    .video-embed-field-responsive-video {
      iframe {
        @include mappy-bp(wide) {
          padding-left: .75rem;
        }
      }
    }
  }

  &.align-center {
    display: table;
    clear: both;

    img {
      margin: 0 auto;
    }

    figure {
      margin: 0 auto;
    }

    figcaption {
      text-align: center;
    }
    @include mappy-bp(wide) {
      .video-embed-field-responsive-video iframe {
        height: 400px;
      }
    }
  }
  #file--application-pdf {
    ::before {
      width: 1rem;
      height: 1rem;
      background-size: 1rem 1rem;
      @include mappy-bp(med) {
        width: 1.25rem;
        height: 1.25rem;
        background-size: 1.25rem 1.25rem;
      }
    }
    a {
      font-weight: 150;
      @include mappy-bp(med) {
        font-weight: 300;
      }
      @include mappy-bp(med) {
        font-weight: 500;
      }
    }
  }
}
