.webform-submission-resend-email-confirmations-form {
  margin: auto;
  .form-actions {
    input {
      margin-top: auto;
      padding: 0;
      border: none;
      background-color: transparent;
      color: $sja_green;
      text-decoration: none;
      font-size: 1rem;
      font-weight: bold;
      letter-spacing: 0;
      text-transform: capitalize;
      &:hover,
      &:focus {
        text-decoration: underline;

      }
    }
  }
}