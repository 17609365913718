.sales-banner {
  background-color: #e50020;
  color: white;
  padding: 10px;
  text-align: center;
  font-weight: 700;
  border-radius: 5px;
  width: 60%;
  position: relative;
  margin-right: auto;
  margin-left: auto;
  bottom: 20px;
}

.sales-banner::before {
  display: inline-block !important;
  font-family: 'FontAwesome';
  content: '\f02b' !important;
  font-weight: 900 !important;
  font-size: 20px !important;
  margin-right: 10px !important;
  vertical-align: middle !important;
  text-align: left !important;
}

/* Small screens */
@media only screen and (max-width: 476px) {
  .sales-banner {
    position: relative;
    // bottom: 78rem;
    right: 0%;
    font-size: 12px;
    width: 90%;
    bottom: 1px;
  }
}

/* Medium screens */
@media only screen and (min-width: 477px) and (max-width: 568px) {
  .sales-banner {
    // bottom: 76rem;
    width: 85%;
    margin: 0 auto;
    right: 0%;
    font-size: 13px;
    bottom: 0;
  }
}

/* Large screens */
@media only screen and (min-width: 569px) and (max-width: 692px) {
  .sales-banner {
    // bottom: 76rem;
    right: 0%;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 669px) and (max-width: 792px) {
  .sales-banner {
    // bottom: 76rem;
    right: 0%;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 769px) and (max-width: 892px) {
  .sales-banner {
    // bottom: 77rem;
    right: 0%;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 869px) and (max-width: 992px) {
  .sales-banner {
    // bottom: 77rem;
    margin: 0 auto;
    right: 0;
  }
}

@media only screen and (min-width: 969px) and (max-width: 1100px) {
  .sales-banner {
    // bottom: 9rem;
  }
}

@media only screen and (min-width: 1100px) {
  .sales-banner {
    // bottom: 8rem;
  }
}

@media only screen and (min-width: 1200px) {
  .sales-banner {
    position: relative;
    width: 60%;
    bottom: 40px;
  }
}
