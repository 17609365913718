.block-views-blockcourse-categories-block-1 {
  .block__title {
    display: block;
    font-size: rem(14px);
    font-weight: 900;
    line-height: 1.36;
    text-transform: uppercase;
    color: $color__primary;
    letter-spacing: rem(1.74px);
    position: relative;
    overflow: hidden;
    margin-right: rem(2px);
    margin-bottom: rem(20px);
    @include mappy-bp(med) {
      font-size: rem(16px);
      letter-spacing: rem(1.98px);
      margin-bottom: rem(65px);
    }
    &::after {
      content: ' ';
      display: inline-block;
      width: 100%;
      margin-left: rem(12px);
      transform: translateY(-50%);
      top: 50%;
      position: absolute;
      background-color: #787e7c;
      height: rem(2px);
      @include mappy-bp(land) {
        display: inline-block;
      }
    }
  }
}

.view--our-impact,
.view--course-categories {
  max-width: $content-max-width;
  margin: 0 auto;

  .view__content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .view__row {
      margin-top: rem(36px);
      @include mappy-bp(med) {
        width: 30%;
        margin: rem(40px) 0;
      }

      &.view__row-2, &.view__row-5, &.view__row-8 {
        @include mappy-bp(med) {
          margin: rem(40px) 5%;
        }
      }

      .views-field-field-banner-image {
        width: 100%;

        img {
          height: auto;
          width: 100%;
        }
      }
      .views-field-custom--our-impact-details,
      .views-field-custom--course-categories-details {
        @extend %block-container;
        font-size: rem(14px);
        width: 100%;
        @include mappy-bp(med) {
          margin: rem(36px) 0;
          padding: 0;
          font-size: rem(16px);
        }
        .our-impact--title,
        .course-categories--name {
          @include mappy-bp(med) {
            margin-bottom: rem(24px);
          }
          h2 {
              font-size: rem(20px);
              font-weight: 900;
              margin-top: rem(12px);
              margin-bottom: rem(9px);
            @include mappy-bp(med) {
              font-size: rem(24px);
            }
            a {
              @extend %green-link;
            }
          }
        }
      }
    }
  }
  .attachment--before {
    margin: 0;
    @include mappy-bp(med) {
      margin-bottom: rem(30px);
    }
    .view__content .view__row {
      width: 100%;
      @include mappy-bp(med) {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
      }

      .views-field-field-banner-image,
      .views-field-custom--our-impact-details,
      .views-field-custom--course-categories-details {
        @include mappy-bp(med) {
          width: 48%;
        }
      }
      .views-field-field-banner-image {
        @include mappy-bp(med) {
          margin: auto 0;
        }
        img {
          width: 100%;
          height: auto;
        }
      }
      .views-field-custom--our-impact-details,
      .views-field-custom--course-categories-details {
        @include mappy-bp(med) {
          font-size: rem(20px);
          margin: auto 0;
        }
        .our-impact--title,
        .course-categories--name {
          @include mappy-bp(med) {
            margin-bottom: rem(40px);
          }
          h2 {
            @include mappy-bp(med) {
              font-size: rem(32px);
            }
          }
        }
      }
    }
  }
}