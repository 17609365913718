// @file
// Styles for the breadcrumb trail.

#block-sja-breadcrumbs {
  @extend %cf;
  padding-left: rem(16px);

  @include mappy-bp (med) {
    padding-left: rem(81px);
  }

}

.breadcrumb {
  clear: both;
  height: auto;
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: .6px;
  border: none;
  display: block;
  left: 0;
  margin: 0 0 16px;
  overflow: hidden;
  padding: 0;
  position: relative;
  top: 0;
  width: auto;
  @include mappy-bp(large) {
    margin: 0 auto ;
  }

  &__intro {
    display: inline-block;
    float: left;
  }

&__title {
  @extend %visually-hidden;
}

&__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

&__item
    a  {
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #006633;
  position: relative;
  text-decoration: none;
  padding-left: 18px;

  &::before {
    content: ' ';
    display: inline-block;
    background-image: url('../../src/000_assets/icons/double_arrow_n.svg');
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
    left: 0;

  }

  &:hover {
    color: black;
  }

  &:hover {
    &::before {
    background-image: url('../../src/000_assets/icons/double_arrow_h.svg');
    }
  }
  // &:visited {
  //   color: #006633;
  // }
}

}
