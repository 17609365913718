#geolocation-message-container {
  background: $light_light_grey 0% 0% no-repeat padding-box;
  height: 5rem;
  padding: 0 1rem;
  @include mappy-bp(med) {
    padding: 0 3.75rem;
    background-color: white;
  }

  @include mappy-bp($content-max-width + 32px) {
    padding: 0;
  }
  .messages-geolocation.messages--warning {
    background-color: $light_light_grey;
    background-image: url('../../src/000_assets/icons/error_outline-24px.svg');
    background-size: 20px 20px;
    background-position: 0 center;
    color: $color__geo-location-text;
    font-size: .875rem;
    font-weight: bold;
    letter-spacing: .4px;
    height: 100%;
    @include mappy-bp(wide) {
      font-size: 1rem;
    }
    .messages__content {
      margin-left: 1.25rem;
      display: table;
      height: 100%;
      #geolocation-message-block {
        display: table-cell;
        vertical-align: middle;
      }
    }
  }
}
