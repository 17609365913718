.product-onlineclass {
  margin-top: 3rem;
  > div {
    max-width: 100%;
    margin: 64px auto 82px;

    @include mappy-bp(land) {
      max-width: 80%;
    }

    @include mappy-bp(0 812px) {
      padding: 0 1rem;
    }

    @include mappy-bp(widest) {
      max-width: 1540px;
    }
  }
  .product-title {
    @include mappy-bp(larger) {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 4rem;
    }
    .price-desktop {
      display: none;
      font-weight: 600;
      .currency {
        font-size: .75rem;
      }
      @include mappy-bp(larger) {
        display: block;
      }
    }
    .field--name-title {
      @include mappy-bp(larger) {
        h1 {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
  }
  .shippable-product-details {
    @include mappy-bp(larger) {
      display: flex;
      justify-content: space-between;
      margin-top: 0;
    }
    .shippable-product-details-information {
      margin-bottom: 1.5rem;
      @include mappy-bp(larger) {
        width: 60%;
      }
      .product-description-container {
        .description-title {
          position: relative;
          font-size: 25px;
          letter-spacing: 0;
          //margin: 3.5rem 0 2rem 0;
          overflow-x: hidden;
          > h2 {
              margin-top: 0;
              margin-bottom: 0;
              font-size: 32px;
              letter-spacing: 0;
              //font-weight: bolder;
          }
        }
        p {
          font-size: 16px;
        }
      }
    }
    .shippable-product-variation-details {
      @include mappy-bp(larger) {
        width: 30%;
        .price-mobile {
          display: none;
        }
      }
      .product--variation-field--variation-price {
        font-size: 1rem;
        display: flex;
        opacity: 1;
        margin-bottom: 20px;
        span#price {
          background: #E5EBE9 0% 0% no-repeat padding-box;
          font-weight: 700;
          font-size: 28px;
        }
        >span {
          padding: .5rem 1rem;
        }

        #list_price {
          text-decoration: line-through;
          margin-left: 25px;
          color: $color__grey--dark;
          font-size: 20px;
          margin-top: 7px;
        }
      }
      .commerce-order-item-add-to-cart-form {
        // display: flex;
        // flex-wrap: wrap;
        @include mappy-bp(larger) {
          display: block;
        }
        margin-top: 20px;
        .field--name-purchased-entity {
            flex: 0 0 100%;
          .attribute-widgets {
            >.form-item--select {
              margin-bottom: 1.5rem;
              .form-item__select {
                width: 70%;
              }
            }
          }
        }
        .field--name-purchased-entity {
          display: none;
        }
        .field--name-quantity {
          //width: 94px;
          width: 198px;
          margin-right: 2rem;

          input {
            padding: .75rem 1.5rem .75rem 1rem;
          }
        }
      }
      .form-actions {
        input {
          margin-top: 1.5rem;
        }
      }

    }
  }
}