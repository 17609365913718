// Overrides layout region margin top and bottom.
// No padding-top since this is a full width element
.layout__region-container > .layout__region > .paragraph--type-banner-big {
  margin-top: 0;
  margin-bottom: 0;
}



.paragraph--type-banner-big {
  position: relative;
  margin-bottom: 2rem;
  display: inline-box;

  @include mappy-bp(med) {
    margin-bottom: 4rem;
  }

  .paragraph--type-banner-big-item-container {
    margin-top: -320px;

    .field--name-field-banner-item {
      z-index: 10;
    }

    h2 {
      word-break: break-word;

      @media all and (-ms-high-contrast: none),
      (-ms-high-contrast: active) {
        word-break: break-all;
      }

    }
  }

  .paragraph--type-banner-big-content-title-empty {
    // Height calculated based off of text size and padding for title card with text on mobile view.
    height: 77px;

    @include mappy-bp(large) {
      // Height calculated based off of text size and padding for title card with text on desktop view.
      height: 98px;
    }
  }

  .paragraph--type-banner-big-image-container {
    height: 565px;
    overflow: hidden;
  }

  .field--name-field-banner-image {
    position: relative;
    z-index: 0;
  }

  .field--name-field-banner-image img {
    position: relative;
    width: 100%;
  }

  .field--name-field-content-title {
    position: relative;
    display: block;
    padding: .25rem .75rem;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, .16);
    background-color: #ffffff;
    margin: 0 1rem;


    @include mappy-bp(med) {
      margin: 0 2.25rem;
    }

    width: fit-content;

    @include mappy-bp(large) {
      margin: 0 5.25rem;
      padding: 1.25rem 2rem;
      width: fit-content;
      word-break: break-word;
    }

    h1 {
      margin: 0;
      margin-bottom: 0;
      font-weight: 900;
    }

    @extend h1;
  }

  .field--name-field-content-title-green {
    .field--name-field-content-heading {
      .field__item {
        color: $color__primary;
      }
    }
  }
}

.field--name-field-banner-item {
  .field__items {
    // margin: 1.75rem 2.25rem;
    margin: 1.75rem 1rem;

    @include mappy-bp(med) {
      margin: 1.75rem 2.25rem;
    }

    @include mappy-bp(large) {
      display: grid;
      grid-template-columns: .5fr .5fr;
      grid-auto-columns: minmax(500px, auto);
      grid-auto-rows: 1fr;
      margin: 1.75rem 5.25rem;

      .field__item:first-of-type {
        margin-right: 2rem;
        margin-bottom: 0;
      }
    }
  }

  .field__item {
    display: grid;
  }

  .field__item:first-of-type {
    margin-right: 0;
    margin-bottom: 2rem;
  }
}

//IE Grid Styles
@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
  .paragraph--type-banner-big {
    .field--name-field-content-title {
      display: table;
      width: auto;
    }
  }

  .paragraph--type-banner-big-item {
    overflow-x: auto;
    width: 100%;
  }

  .field--name-field-banner-item {
    .field__items {
      @include mappy-bp(large) {
        display: -ms-grid;
        -ms-grid-columns: .5fr 2rem .5fr;
        -ms-grid-rows: auto;
      }
    }

    .field__item {
      display: -ms-grid;
    }

    .field__item:first-of-type {
      -ms-grid-column: 1;
      -ms-grid-row: 1;
      grid-column: 1;
      grid-row: 1;
      display: flex;
    }

    .field__item:nth-of-type(2) {
      -ms-grid-column: 3;
      -ms-grid-row: 1;
      grid-column: 3;
      grid-row: 1;
      display: flex;
    }
  }
}

.paragraph--type-banner-big-item {
  display: grid;
  padding: 1.5rem;

  @include mappy-bp(large) {
    padding: 3.75rem;
  }

  box-shadow: 0 0 24px 0 rgba(0, 0, 0, .16);
  background-color: #ffffff;
  z-index: 0;

  .l-constrain {
    // Overwrites drupal default centering
    margin-left: 0;
    margin-right: 0;
  }

  .field--name-field-featured-content {
    position: relative;
    top: -.5rem;
    left: -.5rem;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.71;
    letter-spacing: .7px;
    text-align: left;
    color: #474b4a;
    text-transform: uppercase;

    @include mappy-bp(large) {
      top: -1rem;
      margin-top: -2rem;
      margin-left: -2rem;
      left: 0;
    }
  }

  .field--name-field-link {
    .field__item {
      margin-top: 2rem;

      a {
        display: block;
        font-size: 14px;
        font-weight: 900;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.36;
        letter-spacing: 1.74px;
        text-align: center;
        color: #006633;
        padding: 9px 27px 9px 27px;
        text-transform: uppercase;
        text-decoration: none;
        border-radius: 22px;
        border: solid 2px #006633;
        width: fit-content;
        transition: .2s all;
        word-break: break-word;

        @media all and (-ms-high-contrast: none),
        (-ms-high-contrast: active) {
          word-break: break-all;
        }
      }

      a:hover,
      a:focus {
        background-color: #006633;
        color: #ffffff;
      }
    }
  }

  h2 {
    font-size: 1.25rem;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: .8px;
    text-align: left;
    color: #000000;
    margin-top: 0;
    margin-bottom: .75rem;

    @include mappy-bp(large) {
      font-size: 2rem;
      margin-bottom: 1.5rem;
    }
  }

  .field--name-field-content-summary {
    font-size: 1rem;
  }
}
