.block--single-stat {

  .block__content {
    width: 80%;
    margin: auto;
    padding-bottom: 2rem;

    @include mappy-bp(large) {
      max-width: 465px;
    }

    .stat-container {
      text-align: center;

      @include mappy-bp(large) {
        text-align: left;
        display: flex;
        align-items: flex-start;
        flex-wrap: nowrap;
      }

      .stat_icon {
        display: block;

        @include mappy-bp (large) {
          vertical-align: top;
          padding-right: 1rem;
        }

        img {
          height: 65px;
          width: 65px;
          margin: auto;
          object-fit: cover;
        }
      }

      .stat_content {
        word-break: break-word;

        @media all and (-ms-high-contrast: none),
        (-ms-high-contrast: active) {
          word-break: break-all;
        }

        @include mappy-bp (large) {
          width: calc(100% - 80px);
        }


        .field--name-field-number-callout {
          font-size: 34px;
          font-weight: 900;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.21;
          letter-spacing: .85px;
          text-align: center;
          color: #006633;
          margin-top: .5rem;
          margin-bottom: .5rem;

          @include mappy-bp (large) {
            font-size: 44px;
            line-height: .93;
            letter-spacing: 1.1px;
            text-align: left;
            color: #006633;
            margin-top: 0;
          }
        }

        .field--name-field-stat-text-callout {
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.57;
          letter-spacing: 1.05px;
          text-align: center;
          color: #000000;
          text-transform: uppercase;

          @include mappy-bp (large) {
            font-size: 16px;
            line-height: 1.38;
            letter-spacing: 1.2px;
            text-align: left;
          }
        }

      }
    }

    .textarea {
      padding-top: 30px;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.36;
      // letter-spacing: 1.05px;
      text-align: left;
      color: #000000;

      @include mappy-bp (large) {
        padding-top: 61px;
        font-size: 20px;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #000000;
      }
    }
  }
}