.path-user,
.path-user.sidebar-first {

  // Adjusting layout for user pages
  .layout-content {
    padding-top: 1rem;
    padding-bottom: 1rem;

    @include mappy-bp(med) {
      padding: 3rem 3.75rem rem(188px);
      display: flex;
      justify-content: space-between;
    }

    @include mappy-bp($content-max-width + 32px) {
      padding: 3rem 0 rem(188px);
    }

    .layout-sidebar-first {
      float: none;
      margin: 0 auto;

      @include mappy-bp(med) {
        max-width: 290px;
        flex: 0 1 35%;
        flex-basis: 35%;
        margin-right: rem(30px);
      }

      @include mappy-bp(wide) {
        flex-basis: 25%;
        margin-right: rem(60px);
      }
    }
    .region-content {
      float: none;
      @include mappy-bp(med) {
        flex: 0 1 65%;
        flex-basis: 65%;
        margin-left: rem(30px);
      }

      @include mappy-bp(wide) {
        flex-basis: 75%;
        margin-left: rem(60px);
      }
    }
  }
}

.user--display-mode-user-sidebar {
  padding: 1rem;
  display: flex;

  @include mappy-bp(med) {
    display: block;
    padding: 1.875rem 2.5rem;
  }

  .user__sidebar-picture {
    display: inline-block;

    @include mappy-bp(med) {
      display: block;
    }

    img {
      width: 100%;
      max-width: 40px;
      height: 40px;
      border-radius: 50%;
      @include mappy-bp(med) {
        max-width: 80px;
        margin: 0 auto;
        height: 80px;
      }
    }
  }
  .user__sidebar-text {
    display: inline-block;
    margin-left: 1rem;

    @include mappy-bp(med) {
      display: block;
      margin-left: 0;
    }
  }

  .user__sidebar-name,
  .user__sidebar-edit-link {
    display: block;

    @include mappy-bp(med) {
      text-align: center;
    }
  }

  .user__sidebar-name {
    font-size: .75rem;
    font-weight: bold;
    @include mappy-bp(med) {
      font-size: 1rem;
      margin-top: .75rem;
      overflow-wrap: break-word;
    }
  }

  .user__company-account-name {

      margin-top: .25rem;
      display: block;
      color: $color__black;
      font-size: rem(13px);
      height: auto;
      @include mappy-bp(med) {
        text-align: center;
        font-size: 1rem;
      }
  }

  .user__sidebar-edit-link {
    margin-top: .25rem;
    a {
      display: block;
      color: $color__black;
      font-size: rem(13px);
      text-decoration: none;

      @include mappy-bp(med) {
        font-size: .75rem;
      }

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}
