.product-card-container.related-product-block {
  margin-bottom: 0;
  margin-top: 1.5rem;
  max-width: $content-max-width;
  margin: 0 auto;
  padding: 2rem 1rem 1rem;
  transition: .5s all ease-in;

  @include mappy-bp(med) {
    padding: 0 3.75rem 6.75rem;
  }

  @include mappy-bp($content-max-width + 32px) {
    padding: 0 0 6.75rem;
  }
  .product-card-container-title {
    margin: 1.5rem 0 3rem 0;
    @include mappy-bp(large) {
      margin: 1.5rem 0 6rem 0;
    }
  }
  .view--related-products-cross-sale {
    > .view__content {
      > .item-list {
        > ul {
          list-style-type: none;
          > .leaf {
            margin-left: 0;
            margin-bottom: 5rem;
            .views-field-field-feature-image {
              border: 1px solid $light_light_grey;
            }
            .views-field-variations-target-id {
              .field-content {
                > .product-card-product {
                  display: none;
                }
                > div:first-of-type {
                  display: block;
                  > div {
                    display: none;
                  }
                  > .field-prices {
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    margin: 2rem 0 1rem 0;
                    > .field--name-list-price {
                      margin-right: 1rem;
                      text-decoration: line-through;
                      color: $medium_dark_grey;
                    }
                  }
                }
              }
            }
            .views-field-title {
              margin-bottom: .75rem;
              a {
                text-decoration: none;
                letter-spacing: .4px;
                color: $sja_green;
                font-weight: bold;
                border-bottom: 2px solid white;
                transition: .5s;
                display: inline;
                cursor: pointer;
                &:hover,
                &:focus {
                  border-bottom: 2px solid $sja_green;
                }
                &:visited {
                  color: $color__primary;
                }
              }
            }
            .views-field-body {
              margin-bottom: 1.5rem;
            }
          }
          @include mappy-bp(land) {
            @media all and (-ms-high-contrast: none),
              (-ms-high-contrast: active) {
              display: flex;
              align-items: flex-start;
              flex-wrap: wrap;
            }
            display: grid;
            overflow: hidden;
            grid-template-columns: repeat(2, 1fr);
            grid-auto-rows: 1fr;
            grid-column-gap: 3rem;
            grid-row-gap: 4rem;
            > .leaf {
              width: 100%;
              height: 100%;
            }
            @media all and (-ms-high-contrast: none),
              (-ms-high-contrast: active) {
              .leaf {
                width: 30%;
              }
              > li:nth-of-type(3n + 2) {
                margin: 0 5%;
              }
            }
          }
          @include mappy-bp(mega) {
            grid-template-columns: repeat(4, 1fr);
            grid-column-gap: 4rem;
          }
        }
      }
    }
  }
}
