.customer-information-user-view,
.order-information-user-view {
  > div {
    margin-bottom: 2rem;
    h5 {
      margin-top: 0;
    }
  }
  .customer-information__shipping {
    > div {
      margin-bottom: 2rem;
    }
    .field__label {
      margin-bottom: 0;
    }
  }
  .user-customer-contact,
  .customer-billing,
  .customer-information__shipping {
    .address {
      .given-name,
      .locality,
      .administrative-area {
        margin-right: .5rem;
      }
    }

  }
  @include mappy-bp(large) {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    .user-customer-contact,
    .customer-information__shipping {
      margin-right: 3rem;
    }
  }
  .user-order-items-class-registration {
    margin-top: 4rem;
  }
  .order-total-line {
    .order-total-line-label {
      margin-right: 1rem;
    }
    .order-total-line-value {
      width: auto;;
    }
  }
}
.order-information-user-view {
  div {
    width: 100%;
  }
}