.product-card-container {

    max-width: 80%;
    margin: 64px auto 82px;

    @include mappy-bp(0 812px) {
      padding: 0 1rem;
    }

    @include mappy-bp(widest) {
      margin-bottom: 190px;
      max-width: 1540px;
    }

    .product-card-container-title {
      position: relative;
      font-weight: 800;
      text-transform: uppercase;
      letter-spacing: 1.98px;
      color: $sja_green;
      margin: 3.5rem 0 3rem 0;
      @include mappy-bp(large) {
        margin: 3.5rem 0 6rem 0;
      }
      overflow-x: hidden;

      > h3 {
          margin-top: 0;
          margin-bottom: 0;
          font-size: 1rem;
        &::after {
          content: ' ';
          display: inline-block;
          width: 100%;
          margin-left: 12px;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          top: 50%;
          position: absolute;
          background-color: #787e7c;
          height: 2px;
        }
      }
    }

    .field--name-field-pcv {
      .field__items {
        display: flex;
        margin: 0 auto 25px;
        height: auto;
        width: 100%;
        flex-wrap: wrap;

        justify-content: space-between;

        @include mappy-bp(mega) {
          justify-content: flex-start;

          >div:nth-of-type(4n) {
            margin-right: 0;
          }
        }

        >.field__item {
          margin-left: 0;
          min-height: 380px;
          padding-bottom: 0;
          width: 100%;

          @include mappy-bp(land) {
            width: 44%;
          }
          @include mappy-bp(mega) {
            max-width: 1540px;
            // width: 23%;
            // margin-right: 1.75rem;
            width: 20%;
            margin-right: 3.75rem;
          }
          margin-bottom: 3.5rem;

          #field-item-list-price {
            text-decoration: line-through;
            margin-right: 20px;
          }

          #field-item-title {
            margin-top: 20px;
            a {
              color: $sja_green;
              font-weight: 600;
              font-style: normal;
              font-stretch: normal;
              line-height: 1.43;
              letter-spacing: normal;
              text-align: left;
              position: relative;
              text-decoration: none;
              //margin-bottom: 10px;

              &:hover {
                color: black;
              }
            }
          }

          .field-item-variation-image {
            border: 1px solid #E5EBE9;
            margin-bottom: 20px;
          }
          .search-result-description {
            margin-bottom: 1.5rem;
          }
          .field--name-quantity {
            display: none;
          }

          .variatons_add_to_cart_button {
            margin-top: -20px;
            .field--name-purchased-entity {
              display: none;
            }
            .form-item--checkbox {
              display: none;
            }
          }
        }

        @include mappy-bp(mega) {
          >div:nth-of-type(4n) {
            margin-right: 0;
          }
        }
      }



      .field__items {
        > .field__item {
          margin-left: 0;
          margin-bottom: 3rem;
          .field--name-field-variation-image {
            border: 1px solid #e5ebe9;
          }
          .field-prices {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin: 2rem 0 1rem 0;
            > .field--name-list-price {
              margin-right: 1rem;
              text-decoration: line-through;
              color: $medium_dark_grey;
            }
          }
          .field--name-title {
            margin-bottom: .75rem;
            a {
              text-decoration: none;
              letter-spacing: .4px;
              color: $sja_green;
              font-weight: bold;
              border-bottom: 2px solid white;
              transition: .5s;
              display: inline;
              cursor: pointer;
              &:hover,
              &:focus {
                border-bottom: 2px solid $sja_green;
              }
              &:visited {
                color: $color__primary;
              }
            }
          }
          .search-result-description {
            margin-bottom: 1.5rem;
          }
          .commerce-order-item-variation-cart-form-form {
            margin-top: 2rem;
            > div,
            > input {
              display: none;
            }
            > .form-actions {
              display: block;
              .button--add-to-cart {
                margin-top: 0;
                @include mappy-bp(large mega) {
                  padding: 9px 12px;
                }
              }
            }
          }
        }
        @include mappy-bp(land) {
          display: grid;
          overflow: hidden;
          grid-template-columns: repeat(2, 1fr);
          grid-auto-rows: 1fr;
          grid-column-gap: 3rem;
          grid-row-gap: 4rem;
          > .field__item {
            width: 100%;
            height: 100%;
            .product-card-product {
              position: relative;
              height: 100%;
            }
            .variatons_add_to_cart_button {
              position: absolute;
              bottom: 0;
              .commerce-order-item-variation-cart-form-form {
                @media all and (-ms-high-contrast: none),
                  (-ms-high-contrast: active) {
                  position: relative;
                }
              }
            }
          }
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            >.field__item {
              width: 23%;
              .variatons_add_to_cart_button {
                position: relative;
              }
            }
            > div:nth-of-type(3n + 2) {
              margin: 0 5%;
            }
          }
        }
        @include mappy-bp(mega) {
          grid-template-columns: repeat(4, 1fr);
          grid-column-gap: 4rem;
        }
      }

    }
  }