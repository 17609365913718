.block--product-link {

  .product-link__container {
    padding: 1rem;
  }
  .product-link__text {
    margin: 0 1rem;
    padding-bottom: 1rem;
    @include mappy-bp(land) {
      margin: 0;
    }
  }

  .product-link__image {
    margin-bottom: 1rem;
    @include mappy-bp(large) {
      margin-bottom: 5.5rem;
    }

    .image-zoom {
      display: block;
      width: 100%;
    }

    img {
      @include mappy-bp(land large) {
        max-width: 569px;
        width: 100%;
      }
      @include mappy-bp(wide) {
        margin: auto;
      }
    }
  }

  .product-link__title {
    margin-top: 0;
    margin-bottom: 1.25rem;
    font-size: 1.5rem;
    font-weight: bold;
    color: $sja_green;

    @include mappy-bp(med) {
      font-size: 2rem;
    }
  }

  .product-link__description {
    margin: 1.25rem 0 2.5rem 0;
  }

  .product-link__link {
    margin-top: 2.5rem;

    a {
      @extend %green-button-desktop;
    }
  }

  // Look variations
  .product-link__look-Boarded {
    padding: 1rem;

    @include mappy-bp(land) {
      box-shadow: 0 0 24px 0 rgba(0, 0, 0, .16);
      background-color: #ffffff;
    }

    @include mappy-bp(0 land) {
      .product-link__image {
        .image-zoom {
          display: block;
          width: 100%;
        }
        img {
          width: 100%;
        }
      }

      .product-link__text {
        box-shadow: 0 0 24px 0 rgba(0, 0, 0, .16);
        background-color: #ffffff;
        padding: 2rem 1.5rem;
        margin: 0 1rem;
      }
    }
  }

  // Layout variations.
  .product-link__layout-Fullwidth {
    padding: 0;
    max-width: 584px;
    margin: 1rem auto;
    margin-top: 0;

    &.product-link__look-Boarded {
      padding: 5rem 3.75rem;
      box-shadow: 0 0 24px 0 rgba(0, 0, 0, .16);
      background-color: #ffffff;
      margin-top: 2rem;
      .product-link__image {
        margin-top: 2rem;
        margin-bottom: 0;
      }
    }

    @include mappy-bp(0 land) {
      &.product-link__look-Boarded {
        padding: 0;
        box-shadow: none;
        background-color: transparent;
        .product-link__text {
          box-shadow: 0 0 24px 0 rgba(0, 0, 0, .16);
          background-color: #ffffff;
          padding: 2rem 1.5rem;
          margin: 0 1rem;
        }
      }
    }

    @include mappy-bp(med) {
      max-width: none;
      display: flex;
      justify-content: space-between;
      padding: 2rem 3.75rem 3rem;

      .product-link__image {
        width: 50%;
        margin: 0;
        align-self: center;
        margin-left: 2.5rem;
      }

      .product-link__text {
        width: 50%;
        align-self: center;
        flex-direction: column;
        justify-content: center;
        margin-right: 2.5rem;
      }
      &.product-link__look-Boarded {
        .product-link__image {
          margin-top: 0;
        }
      }
    }

    @include mappy-bp(large) {
      .product-link__image {
        margin-left: 5rem;
      }
      .product-link__text {
        margin-right: 5rem;
      }
    }
  }

  .product-link__layout-Stacked {
    padding-left: 0;
    padding-right: 0;

    @include mappy-bp(land large) {
      max-width: 584px;
      margin: 1rem auto;
    }
  }
}
