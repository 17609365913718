.block--cart {
  cursor: pointer;
  padding-right: rem(7px);
}

.cart-block--summary__count {
  background-color: $sja_red;
  border-radius: 50%;
  color: white;
  padding: 1px 6px;
  font-size: 10px;
  margin: -10px -7px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  font-weight: 600;
  z-index: 9999;
}

.cart--cart-block {
  position: relative;
}

.cart-block {
  &--contents__inner {
    padding: 0;
  }
  &--contents__items {
    overflow-x: none;
    overflow-y: auto;
  }
  &--link__expand {
    display: inline-block;
    position: relative;
    padding: 2px 5px;
    margin-top: rem(10px);
  }
  &--contents__links {
    max-width: 120px;
    height: 36px;
    margin: 0 auto;
    color: $sja_green;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    a {
      @extend %green-button-desktop;
      width: auto;
      padding: .5rem 2.75rem;
      cursor: pointer;
    }
  }
  &--contents {
    width: 100vw;
    border: 1px solid $light_light_grey;
    top: rem(32px);
    right: rem(-35px);

    @include mappy-bp(med) {
      max-width: 470px;
      right: rem(-16px);
    }

    &.is-outside-horizontal {
      right: rem(-35px);

      @include mappy-bp(med) {
        right: rem(-16px);
      }
    }
  }
  table.cols-3 {
    margin: 0;
    tr {
      th {
        padding: 1.2em;
        font-size: .8rem;
        text-align: left;
      }
      td {
        padding: 1.2em;
        font-size: .8rem;
        text-align: left;
        &.views-field-title {
          text-align: left;
        }
      }
    }
  }
  .views-field-title {
    font-weight: 700;
  }
  .views-field-total-price__number {
    text-align: right;
    color: $sja_green;
    font-weight: 700;
  }
  .views-field-quantity {
    color: $color__black;
  }
}
.user-logged-in {
  .block--cart.contextual-region {
    position: static;
   }
}
span.cart-block--summary__icon {
  bottom: 8px;
  position: relative;
  z-index: 999;
}
.class__layout {
  .product-details {
    width: 1400px;
    margin-left: 214px;
    margin-top: 48px;
}
.product-details-information {
  float: left;
  width: 800px;
}
.product-details-regitration {
  float: right;
  width: 480px;
}
.field__label {
  float: left;
  margin-right: 10px;
  font-weight: bold;
}
.well-style {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
}
.classVariationPrice {
    background-color: $color__aqua;
    width: 380px;
    height: 54px;
    border-radius: 10px;
    .field__label {
    margin-left: 97px !important;
    margin-top: 10px;
    }
    .field__item {
      padding: 10px;
    }
}
#edit-purchased-entity-wrapper {
  display: none;
}
.field--name-variations.field--type-entity-reference.field--label-above .field__label {
  display: none;
}
}
.product-details-regitration {
  input[type='submit'] {
    background-color: $sja_green;
    color: $color__white;
    border-radius: 10px;
    width: 380px;
    height: 54px;
  }
}
