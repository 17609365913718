.page-node-type-course-page {
  .region-content {
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
  .container {
    width: 80%;
    max-width: 600px;
    margin: 50px auto;
  }
  h1.js-quickedit-page-title {
    text-align: center;
}
  button.accordion {
    background-color: #ffffff;
    outline: none;
    text-align: left;
    padding: 1rem;
    color: #333;
    cursor: pointer;
    -webkit-transition: background-color .2s linear;
    transition: background-color .2s linear;
    border-radius: 4px;
    font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    border: 2px solid #d9e0de;
    margin: auto;
    width: 79%;
    display: block;
    margin-bottom: 20px;
    @include mappy-bp(land) {
      font-size: 1.5rem;
    }
  }

  button.accordion::after {
    content: '\f061';
    font-family: 'fontawesome';
    font-size: inherit;
    float: right;
    padding: 5px 5px 0 5px;
  }
  button.accordion.is-open::after {
    content: '\f063';
  }
  button.accordion:hover,
  button.accordion.is-open {
    background-color: #ddd;
    border: 2px solid #d9e0de !important;
  }
  .accordion-content {
  background-color: white;
  // border-left: 1px solid whitesmoke;
  // border-right: 1px solid whitesmoke;
  padding: 0 20px;
  max-height: 0;
  overflow: hidden;
  transition: max-height .2s ease-in-out;
  }
  .field-azure-map-block {
    @include mappy-bp(large) {
    width: inherit;
    // height: 56rem;
    }
}
canvas.mapboxgl-canvas.atlas-map-canvas {
  @include mappy-bp(large) {
  // width: inherit !important;
  height: 100rem;
}
}
canvas.mapboxgl-canvas.atlas-map-canvas {
  // width: inherit !important;
}
.flex-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
}
.flex-items:nth-child(1) {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 2;
  width: inherit;
}
.flex-items:nth-child(2) {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 1;
  width: inherit;
}
.field-azure-map-block {
  // width: 470px;
  @include mappy-bp(med) {
  width: inherit;
  margin-top: auto;
  }
}
  .flex-container {
    @include mappy-bp(large) {
    flex-direction: column;
    display: flex;
  }
  @include mappy-bp(larger) {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    // align-items: center;
    align-content: center;
    width: 100%;
    align-items: flex-start;
  }
  }
  .flex-items:nth-child(1) {
    @include mappy-bp(large) {
    display: block;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    order: 1;
    width: inherit;
    margin: 1rem;
    margin-top: -3rem;
  }
}
  .flex-items:nth-child(2) {
    @include mappy-bp(large) {
    display: flex;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    order: 2;
    // width: inherit;
    margin: 2rem;
    width: 100%;
    position: inherit;
    bottom: 20.9rem;
  }
  @include mappy-bp(larger) {
    display: flex;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    order: 2;
    margin: 2rem;
    margin-right: .1px;
    margin-top: -3rem;
    width: 47.4vw;
    align-self: flex-start;
  }
  }
  .block--page-title,
  .course-page--should-know-section,
  .course-page--description,
  .course-page--backed-by-experts {
    max-width: $content-max-width;
    margin: 0 auto;
    padding: 0 1rem;
    transition: .5s all ease-in;

    @include mappy-bp(med) {
      padding: 0 4.75rem;
    }

    @include mappy-bp($content-max-width + 32px) {
      padding: 0;
    }
  }

  .block--page-title {
    margin: 1.25rem auto;
    text-align: center;

    @include mappy-bp(med) {
      margin: rem(25px) auto;
    }
  }

  // setting max-widths for each element.
  .publish_info,
  .updated_date,
  .region-content .node__content {
    max-width: $content-max-width-sm;
    margin-left: auto;
    margin-right: auto;
  }

  .node--type-course-page {
    @extend %block-container;
    padding: 0;
    @include mappy-bp(widest) {
      max-width: $content-max-width;
      margin: 0 auto;
      padding: 0;
      overflow-x: visible;
    }
    h2 {
      @include mappy-bp(med) {
        display: block;
        position: relative;
        overflow: hidden;
        margin: 0 0 rem(64px);
        &::after {
          content: ' ';
          width: 100%;
          margin-left: rem(24px);
          transform: translateY(-50%);
          top: 50%;
          position: absolute;
          height: rem(2px);
          display: inline-block;
          background-color: $color__primary;
        }
      }
    }

    .course-page--description,
    .course-page--should-know-section,
    .course-page--backed-by-experts {
      padding-bottom: 1.5rem;

      @include mappy-bp(med) {
        padding-bottom: .5rem;
        margin-left: 3%;
        margin-right: 3%;
      }
    }

    .course-page--description {
      @include mappy-bp(med) {
        padding-bottom: .5rem;
      }

      @include mappy-bp(large) {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
        padding: 12px;
      }
      .course_description {
        @include mappy-bp(large) {
          width: 75%;
          margin: 0 auto;
        }
        .field--name-field-subtitle {
          font-size: rem(16px);
          line-height: 1.63;
          margin: rem(24px) 0;

          @include mappy-bp(med) {
            font-size: rem(20px);
            line-height: 1.5;
            margin: 0 0 rem(32px) 0;
          }
        }
        .field--name-field-course-description {
          font-size: rem(14px);
          line-height: 1.57;

          @include mappy-bp(med) {
            font-size: 1.25rem;
            line-height: 1.63;
          }
        }
      }
      .course_availability {
        width: 100%;
        font-size: rem(14px);
        line-height: 1.71;
        padding: rem(24px) rem(21px);
        margin: rem(48px) 0;
        background-color: $color__white;
        box-shadow: 0 0 rem(16px) 0 rgba(0, 0, 0, .16);
        display: inline-block;

        @include mappy-bp(med) {
          width: 100%;
          font-size: 1rem;
        }

        @include mappy-bp(large) {
          width: 70%;
          min-width: rem(300px);
          padding: rem(52px) rem(56px);
          margin: 20px auto;
          display: inline;
        }

        h5 {
          margin: 0;
          margin-bottom: rem(15px);
          text-align: center;
          color: #333;
          font-size: 1.2rem;
          line-height: 1.43;
          letter-spacing: rem(1.05px);
          padding: 20px;

          @include mappy-bp(med) {
            font-size: rem(20px);
            letter-spacing: rem(1.5px);
            line-height: 1;
          }
        }
        .field--name-field-course-availability {
          padding-top: rem(14px);
          .field__item {
            display: list-item;
            list-style-position: inside;
            list-style-type: disc;
          }
        }
      }
    }
    .course_availability, .field--name-field-course-availability {
      padding-top: 5px !important;
      display: inline-flex;
      border: 2px solid #f6f6f6;
      padding: 5px 5px 5px 5px;
      margin: 1%;
      border-radius: 3px;
      font-weight: 600;
  }
    .course-page--should-know-section {
      .course-page--details {
        @include mappy-bp(med) {
          display: flex;
          flex-wrap: wrap;
          margin-left: 2.8%;
          margin-right: .8%;
        }
        @include mappy-bp(mega) {
          margin-left: 11%;
        }
        > div {
          @include mappy-bp(med) {
            width: 45%;
            margin: 0 rem(16px) rem(32px) 0;
          }
        }
      }
    }

    .course-page--backed-by-experts {
      .field--name-field-backed-by-the-experts {
        margin: rem(31px) 0;
        > .field__items {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          align-items: center;
          align-content: center;
          > .field__item {
            width: 50%;
            padding: 5%;
            margin: auto 0;
            @include mappy-bp(land) {
              flex: 0 1 30%;
              padding: rem(10px);
              &:nth-of-type(n + 0) {
                margin-right: 5%;
              }
              &:nth-of-type(3n + 0) {
                margin-right: 0;
              }
            }
            @include mappy-bp(med) {
              flex: 0 1 23%;
              padding: rem(10px);
              &:nth-of-type(n + 0) {
                margin-right: 2.66%;
              }
              &:nth-of-type(4n + 0) {
                margin-right: 0;
              }
            }
            @include mappy-bp(large) {
              flex: 0 1 18%;
              &:nth-of-type(n + 0) {
                margin-right: 2.5%;
              }
              &:nth-of-type(5n + 0) {
                margin-right: 0;
              }
            }
          }
        }
      }
      img {
        height: auto;
        width: 100%;
      }
    }
    .course-page__class-link {
      padding: 2rem 0 0;
      margin: auto;
      width: fit-content;
      margin-bottom: 3rem;

      a {
        @extend %green-button-desktop;
      }
    }
  }
  .course-page--map-disclaimer {
    font-size: 1rem;
    display: block;
    text-align: center;
    border: 1px solid #e5ebe9;
    width: auto;
    margin: auto;
    padding: 5px 15px;
    border-radius: 3px;
    background-color: #e5ebe9;
  }
  .course-page--map-disclaimer::before {
    content: '\f05a';
    font-family: 'fontawesome';
    transform: translateY(27%);
    }
  .hide-map-disclaimer {
    display: none;
  }
  .ui-datepicker {
    .ui-datepicker-calendar {
      .ui-datepicker-current-day {
        a {
          color: $sja_green;
        }
      }
      a.ui-state-hover {
        border: 1px solid $sja_green;
      }
    }
  }
}

.preloader {
  display: block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 3.75rem auto 0;
  // margin: auto 0;
  // left: 49%;
  // top: 50%;
  // z-index: 100;
}
.preloader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid green;
  border-radius: 50%;
  animation: preloader 1.2s cubic-bezier(.5, .1, .5, 1) infinite;
  border-color: green transparent transparent transparent;
}
.preloader div:nth-child(1) {
  animation-delay: -.45s;
}
.preloader div:nth-child(2) {
  animation-delay: -.3s;
}
.preloader div:nth-child(3) {
  animation-delay: -.15s;
}
@keyframes preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

