.view {
  .view__content {
    overflow-x: auto;
    table {
      thead {
        background-color: $light_light_grey;
        th {
          padding: 1.25rem 1rem;
          font-size: 1rem;
          > a {
            display: flex;
            align-items: center;
            > .tablesort {
              background-position: center;
              background-repeat: no-repeat;
              background-size: auto;
            }
          }
        }
      }
    }
   }
}