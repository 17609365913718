.region-content {
  .block--quartered-text-and-stats-layout,
  .block--two-column-text-and-stats-block {
    @extend %cf;
    @include mappy-bp (widest) {
      max-width: $content-max-width;
      margin: 0 auto;
      padding: 0;
    }

    .block__content {
      float: left;
      width: 100%;

      .field--name-field-title {
        display: block;
        font-size: 14px;
        font-weight: 900;
        line-height: 1.36;
        letter-spacing: rem(1.74px);
        color: $color__primary;
        position: relative;
        text-transform: uppercase;
        overflow: hidden;
        @include mappy-bp (large) {
          font-size: 16px;
          letter-spacing: rem(1.98px);
        }

        &::after {
          content: ' ';
          display: inline-block;
          width: 100%;
          margin-left: 12px;
          transform: translateY(-50%);
          top: 50%;
          position: absolute;
          background-color: #787e7c;
          height: 2px;
          @include mappy-bp(large) {
            display: inline-block;
          }
        }
      }

      .block_stats {
        padding: 35px 0 0;
        float: left;
        width: 100%;
        @include mappy-bp (med) {
          padding: 95px 0 0;
        }
        @include mappy-bp (large) {
          padding: 120px 0 0;
        }

        .paragraph--type-stats-with-textarea:nth-child(1),
        .paragraph--type-stats-with-textarea:nth-child(3) {
          margin-left: 0;
        }

        .paragraph--type-stats-with-textarea:nth-child(1) {
          padding-top: 0;
        }

        .paragraph--type-stats-with-textarea:nth-child(2) {
          @include mappy-bp (med) {
            padding-top: 0;
          }
        }

        .paragraph--type-stats-with-textarea:nth-child(3),
        .paragraph--type-stats-with-textarea:nth-child(4) {
          @include mappy-bp (med) {
            padding-top: 120px;
          }
        }

        .paragraph--type-stats-with-textarea {
          width: 100%;
          padding-top: 56px;
          @include mappy-bp(med) {
            @include gallery (6 of 12 .6);
            max-width: 465px;
          }
          @include mappy-bp(wide) {
            @include gallery (6 of 12 .1);
          }
          .textarea {
            padding-top: 30px;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.36;
            // letter-spacing: 1.05px;
            text-align: left;
            color: #000000;
            @include mappy-bp (large) {
              padding-top: 61px;
              font-size: 20px;
              line-height: 1.5;
              letter-spacing: normal;
              text-align: left;
              color: #000000;
            }
          }
        }
      }
    }
  }
}
