.product-page-full {
  margin-top: 3rem;
  > div {
    max-width: 100%;
    margin: 10px auto 0;
    padding: 1rem 1rem 1rem;
    @include mappy-bp(med) {
      padding: 0 3.75rem 4rem;
      margin-bottom: 4rem;
    }
    @include mappy-bp(widest) {
      max-width: 1540px;
    }
    @include mappy-bp($content-max-width + 32px) {
      max-width: 1540px;
      padding: 0 0 4rem;
    }
  }
  .product-title {
    @include mappy-bp(larger) {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
    }
    .price-desktop {
      display: none;
      font-weight: 600;
      .currency {
        font-size: .75rem;
      }
      @include mappy-bp(larger) {
        display: block;
      }
    }
    .field--name-title {
      @include mappy-bp(larger) {
        h1 {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
    .product--variation-field--variation-price {
      font-size: 1rem;
      padding: .5rem 1rem;
      background: #e5ebe9 0% 0% no-repeat padding-box;
      opacity: 1;
    }
  }
  .shippable-product-details {
    @include mappy-bp(larger) {
      display: flex;
      justify-content: space-between;
      margin-top: 0;
    }
    .shippable-product-details-information {
      margin-bottom: 1.5rem;
      @include mappy-bp(larger) {
        width: 50%;
      }
      .image-zoom {
        width: 100%;
        border: 1px solid $color__grey--lighter;
      }

      .product-image-container {
        position: relative;
        display: inline-block;
      }

      .product-badge {
        position: absolute;
        top: 10px;
        right: 10px;
        background-color: #e50020;
        color: #fff;
        font-weight: bold;
        padding: 5px 10px;
        border-radius: 4px;
        font-size: 14px;
      }

      .field--name-field-province-availability {
        margin-top: 1rem;
        .field__label {
          font-size: 1rem;
          font-weight: bold;
        }
        .field__items {
          > .field__item {
            display: inline-block;
            font-size: 1rem;
          }
        }
      }

      .field--name-field-pdf-attachment {
        margin-top: 1.5rem;
        font-size: 1rem;
        > .field__label {
          text-transform: uppercase;
          font-weight: bold;
          letter-spacing: 1.2px;
        }
        > .field__item {
          margin-top: .5rem;
          a {
            color: $sja_green;
            text-decoration: none;
            font-weight: 500;
            transition: background-position .2s linear .2s,
              box-shadow .2s linear 0s;
            background-size: 0;
            background-repeat: no-repeat;
            background-position: 0 1.35em;
            padding-bottom: .4rem;
            overflow: hidden;
            &:hover,
            &:focus {
              cursor: pointer;
              background-size: 100%;
              background-image: linear-gradient(
                to left,
                $sja_green 0%,
                $sja_green 100%
              );
              transition: background-size .2s linear 0s,
                box-shadow .2s linear .2s;
            }
          }
          img {
            display: none;
          }
        }
      }
    }
    .shippable-product-variation-details {
      @include mappy-bp(larger) {
        width: 40%;
        .price-mobile {
          display: none;
        }
      }

      .product--variation-field--variation-price {
        font-size: 1rem;
        display: flex;
        opacity: 1;
        margin-bottom: 20px;
        span#price {
          background: #e5ebe9 0% 0% no-repeat padding-box;
          font-weight: 700;
          font-size: 28px;
        }
        > span {
          padding: .5rem 1rem;
        }

        #list_price {
          text-decoration: line-through;
          margin-left: 25px;
          color: $color__grey--dark;
          font-size: 20px;
          margin-top: 7px;
        }
      }
      .commerce-order-item-add-to-cart-form {
        display: flex;
        flex-wrap: wrap;
        .field--name-purchased-entity {
          flex: 0 0 100%;
          .attribute-widgets {
            .form-item--radios {
              display: none;
            }
            > .form-item--select {
              margin-bottom: 1.5rem;
              .form-item__select {
                width: 70%;
              }
            }
          }
        }
        .field--name-quantity {
          width: 94px;
          margin-right: 2rem;
          input {
            padding: .75rem 1.5rem .75rem 1rem;
          }
        }
      }
      .price-mobile {
        font-size: 1rem;
        padding: .5rem 1rem;
        background: #e5ebe9 0% 0% no-repeat padding-box;
        opacity: 1;
        margin-bottom: 1.5rem;
      }
      .form-actions {
        input {
          margin-top: 1.5rem;
        }
      }
    }
    .field--name-field-product-category,
    .field--name-field-product-type,
    .field--name-field-product-transportation,
    .field--name-field-product-regulatory {
      margin-top: 1.5rem;
      .field__label {
        display: block;
        letter-spacing: 1.2px;
        text-transform: uppercase;
        color: #000000;
        font-size: 1rem;
        font-weight: 800;
      }
      .field__item {
        font-size: 1rem;
      }
    }
  }
  .product-description-container {
    font-size: 14px;
    @include mappy-bp(larger) {
      font-size: 20px;
    }
    .description-title {
      position: relative;
      font-size: 32px;
      font-weight: 600;
      letter-spacing: 1.98px;
      margin: 3.5rem 0 2rem 0;
      overflow-x: hidden;

      > h2 {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 32px;
        font-weight: 800;
        &::after {
          content: ' ';
          display: inline-block;
          width: 100%;
          margin-left: 12px;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          top: 50%;
          position: absolute;
          background-color: $sja_green;
          height: 2px;
        }
      }
    }
    .description-wrapper {
      @include mappy-bp(larger) {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: flex-start;
        .description-left-clolumn {
          padding-right: rem(50px);
          width: 50%;
        }
        .description-right-clolumn {
          width: 50%;
          padding-left: rem(50px);
        }
      }
    }
    .field--name-field-pdf-attachment {
      margin-top: 1.5rem;
      font-size: 1rem;
      > .field__label {
        text-transform: uppercase;
        font-weight: 900;
        letter-spacing: 1.2px;
      }
      > .field__item {
        margin-top: .5rem;
        a {
          color: $sja_green;
          text-decoration: none;
          font-weight: 500;
          transition: background-position .2s linear .2s,
            box-shadow .2s linear 0s;
          background-size: 0;
          background-repeat: no-repeat;
          background-position: 0 1.35em;
          padding-bottom: .4rem;
          overflow: hidden;
          &:hover,
          &:focus {
            cursor: pointer;
            background-size: 100%;
            background-image: linear-gradient(
              to left,
              $sja_green 0%,
              $sja_green 100%
            );
            transition: background-size .2s linear 0s,
              box-shadow .2s linear .2s;
          }
        }
      }
      img {
        display: none;
      }
    }
  }
}
