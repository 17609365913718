.block-views-blockacquia-search-block-1 {
  .block--title--header {
    margin-bottom: 2rem;
    .block--title {
      display: inline-block;
      font-size: rem(14px);
      font-weight: 900;
      line-height: 1.36;
      letter-spacing: rem(1.74px);
      position: relative;
      text-transform: uppercase;
      overflow: hidden;
      color: $color__black;
      width: 100%;

      @include mappy-bp(large) {
        font-size: rem(16px);
        letter-spacing: rem(1.98px);
        // max-width: 30%;
      }

      &::after {
        content: ' ';
        width: 100%;
        margin-left: rem(12px);
        transform: translateY(-50%);
        top: 50%;
        position: absolute;
        height: rem(2px);
        display: inline-block;
        background-color: #787e7c;

      }

      .title {
        max-width: 50%;
        display: inline-block;
        font-size: 2rem;
        // @include mappy-bp(large) {
        //   max-width: 30%;
        //   // max-width: 30%;
        // }
      }
    }
  }

  .view--acquia-search {
    margin-bottom: 3rem;

    .view__filters {
      margin-bottom: 3rem;
      .views-exposed-form {
        @include mappy-bp(large) {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
        }

        .form-item {
          margin-bottom: 2rem;
          > select {
            width: 100%;
          }

          @include mappy-bp(large) {
            width: 33.5%;
            margin-bottom: 0;
            max-width: none;
            margin-right: 3%;
          }

          >label {
            font-weight: bold;
            font-size: 1rem;

          }
          &:nth-child(2) {
            @include mappy-bp(large) {
              padding-top: 0;
            }
          }
        }
        .form-item--id-search {
          flex: 0 0 70%;
          margin-bottom: 2rem;
        }

        .form-actions {

          @include mappy-bp(large) {
            align-self: center;
          }

          >input {
            font-size: 1rem;
            font-weight: 900;
            color: $medium_grey;
            font-stretch: normal;
            text-align: center;
            border: none;
            padding: 0;
            margin-top: 1rem;

            @include mappy-bp(large) {
              margin-top: 2rem;
            }

            height: auto;
            background-repeat: no-repeat;
            background-size: 0;
            background-position: 0 1.5rem;
            border-radius: 0;

            @media all and (-ms-high-contrast: none),
            (-ms-high-contrast: active) {
              background-position: 0 1.35em;
            }

            padding-bottom: .35rem;
            overflow: hidden;
            transition: background-position .2s linear .2s,
            box-shadow .2s linear 0s;

            &[type='submit'].button:hover,
            &[type='submit'].button:focus {
              cursor: pointer;
              background-color: transparent;
              background-size: 100%;
              color: $color__black;
              background-image: linear-gradient(to left, $color__black 0%, $color__black 100%);
              transition: background-size .2s linear 0s, box-shadow .2s linear .2s;
            }

            &:first-of-type {
              margin-right: 2rem;
            }
          }
        }
      }
    }

    .view__content {
      .item-list {
        ul {
          list-style-type: none;

          li {
            margin-bottom: 2rem;
            margin-left: 0;

            article {
              @include mappy-bp(large) {
                max-width: 960px;
              }

              >h2 {
                @extend h4;

                a {
                  color: $color__primary;
                  text-decoration: none;
                  background-repeat: no-repeat;
                  background-size: 0;
                  background-position: 0 1.25em;

                  @media all and (-ms-high-contrast: none),
                  (-ms-high-contrast: active) {
                    background-position: 0 1.35em;
                  }

                  padding-bottom: .35rem;
                  overflow: hidden;
                  transition: background-position .2s linear .2s,
                  box-shadow .2s linear 0s;

                  &:hover,
                  &:focus {
                    cursor: pointer;
                    background-size: 100%;
                    background-image: linear-gradient(to left, $color__primary 0%, $color__primary 100%);
                    transition: background-size .2s linear 0s, box-shadow .2s linear .2s;
                  }
                }
              }
            }
          }
        }
      }
    }

  }
}