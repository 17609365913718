.contextual-links-wrapper {

  a.contextual-links-trigger {
    border-bottom: 1px solid transparent;
  }

  ul.contextual-links li a {
    border-bottom: 1px solid transparent;
  }
}
