// ==========
// ADD-CONTEXT
// -----
// Adds current context to the $contexts map that is used
// to store all contexts for all susy items for its children
//
// USAGE:
// -----
// If parent context was previously declared,
// use with either nested or susy-breakpoint:
//
// .no-parent {
//   @include susy-breakpoint(600px, 9) {
//     @include add-context(content, med, 6)
//   }
// }
//
// If parent context was declared previously,
// use with get-context:
//
// .has-parent {
//   @include get-context(content, med) {
//     @include add-context(gallery, med, 3);
//   }
// }
// ==========


/// @author Vova Skok
/// @content Styles which go inside of chosen breakpoint
/// @throw 'Invalid breakpoint `#{$name}`'
/// @example
///  @include breakpoint(small);
/// @output
/// `@media (min-width: 480px) {
///		// Styles
/// }`
/// @require {variable} $breakpoints



@mixin add-context($layout, $breakpoint, $span, $location: null) {
  @if type-of($location) == 'string' {
    $contexts: _susy-deep-set($contexts, $layout, $breakpoint, $span of susy-get(columns) susy-get(gutter) $location) !global;
  } @else {
    $contexts: _susy-deep-set($contexts, $layout, $breakpoint, $span of susy-get(columns) susy-get(gutter) at $location) !global;
  }
}

// ==========
// GET-CONTEXT
// -----
// Gets the context from the $contexts map and include a
// nested wrapper to use with the contents of the context.
//
// Usage:
// ------
//
// .test {
//   @include breakpoint(large) {
//      @include get-context(content,large) {
//        @include span(6);
//      }
//   }
// }
//
// ==========
@mixin get-context($keys...) {
  $context: get-context($keys...);
  @include nested($context) {
    @content;
  }
}


/// Clearfix for IE8 & above
/// @source http://css-tricks.com/snippets/css/clear-fix/
@mixin cf {
  &::after {
    clear: both;
    content: '';
    display: table;
  }
}

@mixin cf-important {
  &::after {
    clear: both !important;
    content: '' !important;
    display: table !important;
  }
}


/// Image replacement mixin
/// Replaces text with background images.
/// Requires inline-block or block display
/// @source http://www.zeldman.com/2012/03/01/replacing-the-9999px-hack-new-image-replacement/
@mixin hide-text {
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}


/// Makes element invisible!
/// *Remains visible for accessibility*
/// @source http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
@mixin is-invisible {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
}



/// Turns off the element-invisible effect.
@mixin is-visible {
  position: static;
  clip: auto;
  height: auto;
  width: auto;
  overflow: auto;
}



/// Changes input Placeholder color
@mixin placeholder($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: $color;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
}


/// Changes background color and text color when text is selected
@mixin selection($bgColor, $textColor) {
  &::selection {
    background: $bgColor;
    color: $textColor;
  }
}


///Shapes mixin: circle
@mixin circle($width) {
  width: $width;
  height: $width;
  border-radius: 100%;
}

///Shapes mixin: triangle
@mixin triangle($dir, $size, $color) {
  border: $size transparent solid;
  @if $dir == 'top' or $dir == 'up' {
    border-bottom-color: $color;
  } @else if $dir == 'right' or $dir == 'after' {
    border-left-color: $color;
  } @else if $dir == 'bottom' or $dir == 'down' {
    border-top-color: $color;
  } @else if $dir == 'left' or $dir == 'before' {
    border-right-color: $color;
  }
}


/// Keep the footer at the bottom of the site
/// @example
///   @include sticky-footer(20em, '#site', '#push', '#footer');
/// @source http://codepen.io/zoerooney/pen/vtdFw
/// @author Zoe Rooney
@mixin sticky-footer($footer-height, $wrapper-selector: unquote('#site'), $container-selector: unquote('#wrap-all'), $footer-selector: unquote('#footer')) {
  html, body {
    height: 100%;
  }
  body {
    margin: 0;
    padding: 0;
  }
  #{$wrapper-selector} {
    min-height: 100%;
    height: 100%;
    margin-bottom: -$footer-height;
    #{$container-selector} {
      padding: 0 0 $footer-height 0;
    }
  }
  #{$footer-selector} {
    height: $footer-height;
  }
}

/// Generic heading styles helpers
/// @group helpers

@mixin heading {
  text-transform: uppercase;
  font-size: 1.4rem;
  display: block;
  margin-bottom: 2rem;
  letter-spacing: 2px;
  font-weight: 500;
}


/// Generic label styles helpers
/// @group helpers

@mixin label {
  text-transform: uppercase;
  font-size: .8rem;
  font-family: $font-secondary;
}


/// Generic date styles helpers
/// @group helpers
@mixin date {
  font-weight: 300;
  font-size: .8rem;
}

/// For adding font icons to elements using CSS pseudo-elements
/// @author Jayden Seric
/// @source http://jaydenseric.com/blog/fun-with-sass-and-font-icons
/// @example
///   @include icon(both) {
///     color: gray;
///    }
///
///   @include icon(before, profile, false) {
///    margin-right: 10px;
///   }
///
///   @include icon(after, address, false) {
///     margin-left: 10px;
///   }
///   a {
///     @include icon;
///   }

@mixin icon($position: before, $icon: false, $styles: true) {
  @if $position == both {
    $position: 'before, &:after';
  }
  // Either a :before or :after pseudo-element, or both, defaulting to :before
  &:#{$position} {
    @if $icon {
      // A particular icon has been specified
      content: '#{map-get($icons, $icon)}';
    }
    @if $styles {
      // Supportive icon styles required
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-family: 'gob-font';
    }
    // Include any extra rules supplied for the pseudo-element
    @content;
  }
}


// @file
// Accessibility mixins

// Makes an element visually hidden, but accessible.
// @see http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
@mixin visually-hidden {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
  word-wrap: normal;
}

@mixin visually-hidden-important {
  clip: rect(1px, 1px, 1px, 1px) !important;
  height: 1px !important;
  overflow: hidden !important;
  position: absolute !important;
  width: 1px !important;
  word-wrap: normal !important;
}

// Turns off the visually-hidden effect.
@mixin visually-hidden-off {
  clip: auto;
  height: auto;
  overflow: visible;
  position: static;
  width: auto;
}

@mixin visually-hidden-off-important {
  clip: auto !important;
  height: auto !important;
  overflow: visible !important;
  position: static !important;
  width: auto !important;
}

// Makes an element visible when focused if it also has the visually-hidden styles.
@mixin focusable {
  &:active,
  &:focus {
    @include visually-hidden-off;
  }
}

@mixin focusable-important {
  &:active,
  &:focus {
    @include visually-hidden-off-important;
  }
}

// Makes an element completely hidden, visually and to screen readers.
@mixin hidden {
  display: none;
}

@mixin hidden-important {
  display: none !important;
}

// Makes an element completely hidden, visually and to screen readers, but
// maintains its layout.
@mixin invisible {
  visibility: hidden;
}

@mixin invisible-important {
  visibility: hidden !important;
}

@mixin green-button-desktop {
  background-color: $color__white;
  border-radius: 22px;
  border: solid 2px #006633;
  color: $color__primary;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 1.74px;
  line-height: 1.36;
  padding: 9px 27px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: .2s all;

  &:hover,
  &:focus {
    background-color: $color__primary;
    color: $color__white;
  }
}

@mixin green-link {
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: .4px;
  text-align: left;
  color: #006633;
  border-bottom: 2px solid #006633;
  text-decoration: none;

  &:hover,
  &:focus {
    color: #000000;
    border-bottom: 2px solid #000000;
  }
}

@mixin black-link {
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: .4px;
  text-align: left;
  color: #000000;
  border-bottom: 2px solid #000000;
  text-decoration: none;

  &:hover,
  &:focus {
    color: #006633;
    border-bottom: 2px solid #006633;
  }
}

@mixin green-link-thick {
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: .4px;
  text-align: left;
  color: #006633;
  border-bottom: 4px solid #006633;
  text-decoration: none;

  &:hover {
    color: #000000;
    border-bottom: 4px solid #000000;
  }
}

@mixin black-link-thick {
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: .4px;
  text-align: left;
  color: #000000;
  border-bottom: 4px solid #000000;
  text-decoration: none;

  &:hover,
  &:focus {
    color: #006633;
    border-bottom: 4px solid #006633;
  }
}

// REM
// https://github.com/pierreburel/sass-rem
//

$rem-baseline: 16px !default;
$rem-fallback: false !default;
$rem-px-only: false !default;

@function rem-separator($list, $separator: false) {
  @if $separator == 'comma' or $separator == 'space' {
    @return append($list, null, $separator);
  }

  @if function-exists('list-separator') == true {
    @return list-separator($list);
  }

  // list-separator polyfill by Hugo Giraudel (https://sass-compatibility.github.io/#list_separator_function)
  $test-list: ();
  @each $item in $list {
    $test-list: append($test-list, $item, space);
  }

  @return if($test-list == $list, space, comma);
}

@mixin rem-baseline($zoom: 100%) {
  font-size: $zoom / 16px * $rem-baseline;
}

@function rem-convert($to, $values...) {
  $result: ();
  $separator: rem-separator($values);

  @each $value in $values {
    @if type-of($value) == 'number' and unit($value) == 'rem' and $to == 'px' {
      $result: append($result, $value / 1rem * $rem-baseline, $separator);
    } @else if type-of($value) == 'number' and unit($value) == 'px' and $to == 'rem' {
      $result: append($result, $value / $rem-baseline * 1rem, $separator);
    } @else if type-of($value) == 'list' {
      $value-separator: rem-separator($value);
      $value: rem-convert($to, $value...);
      $value: rem-separator($value, $value-separator);
      $result: append($result, $value, $separator);
    } @else {
      $result: append($result, $value, $separator);
    }
  }

  @return if(length($result) == 1, nth($result, 1), $result);
}

@function rem($values...) {
  @if $rem-px-only {
    @return rem-convert(px, $values...);
  } @else {
    @return rem-convert(rem, $values...);
  }
}

@mixin rem($properties, $values...) {
  @if type-of($properties) == 'map' {
    @each $property in map-keys($properties) {
      @include rem($property, map-get($properties, $property));
    }
  } @else {
    @each $property in $properties {
      @if $rem-fallback or $rem-px-only {
        #{$property}: rem-convert(px, $values...);
      }
      @if not $rem-px-only {
        #{$property}: rem-convert(rem, $values...);
      }
    }
  }
}
