// Based on core's stable theme and it's layout files

// layout builder gutter margin
$lb-region-top-margin: 2rem;
$lb-region-bottom-margin: 2rem;

$lb-gutter-parent-margin: -1rem;
$lb-gutter-child-padding: 1rem;

.node__content > .layout {
  margin-top: $lb-region-top-margin;
  margin-bottom: $lb-region-bottom-margin;

  &:first-of-type {
    margin-top: 0;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
}

// set max-widths

.layout--fourcol-section,
.layout--threecol-section,
.layout--twocol-section,
.layout--onecol {
  display: block;
  max-width: $content-max-width;
  margin-left: auto;
  margin-right: auto;
}

.layout__region-container {
  display: block;

  // adds margin top/bottom to layout regions
  > .layout__region {
    margin-top: $lb-region-top-margin;
    margin-bottom: $lb-region-bottom-margin;

      &:first-of-type {
        margin-top: 0;
      }
      &:last-of-type {
        margin-bottom: 0;
      }

    // adds margin to elements within the columns
    > * {
      margin-top: 1rem;
      margin-bottom: 1rem;

      &:first-of-type {
        margin-top: 0;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  @include mappy-bp(land) {
    // Sets the gutters. On it's own works on modern browsers.
    display: flex;
    flex-flow: wrap;

    > .layout__region {
      margin-top: 0;
      margin-bottom: 0;
    }

    // Adds side padding to offset the negative margins in the region-container.
    > * {
      padding-left: $lb-gutter-child-padding;
      padding-right: $lb-gutter-child-padding;
    }
  }

  // Removes the margin left and right when max-width is reached.
  // No longer needed since at $content-max-width + 32px enough
  // padding is given.
  @include mappy-bp($content-max-width + 32px) {
    margin-left: 0;
    margin-right: 0;

    > *:first-of-type {
      padding-left: 0;
    }

    > *:last-of-type {
      padding-right: 0;
    }
    // Compensate the lack of padding of the first and third element by removing
    // half of the padding in each side of the second column.
    .layout__region--second {
      padding-left: $lb-gutter-child-padding / 2;
      padding-right: $lb-gutter-child-padding / 2;
    }
  }
}

// one-col layout uses different classes, so these adjustments (same with flex) are no longer needed.
.layout--onecol {
  .layout__region-container {
    display: block;
    margin-left: auto;
    margin-right: auto;
    > * {
      // instead, Just apply the child gutter padding to .layout__region
      padding-left: $lb-gutter-child-padding;
      padding-right: $lb-gutter-child-padding;

      // one col layout uses different classes, so this is no longer needed.
      > *:first-of-type {
        padding-left: 0;
      }
      > *:last-of-type {
        padding-right: 0;
      }

      // 1540px + 2rem;
      @include mappy-bp(1572px) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

.layout--onecolumnfullwidth-section {
  .layout__region-container {
    margin-left: 0;
    margin-right: 0;
  }
  .layout__region--content {
    padding-left: 0;
    padding-right: 0;
  }
}
