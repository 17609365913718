.site-notice {
  display: none;  /* Hidden by default */
  top: 0;
  left: 0;
  right: 0;
  padding: 10px 25px;
  background: linear-gradient(276deg, rgba(30, 103, 89, 1) 0%, rgba(15, 113, 75, 1) 51%, rgba(0, 122, 61, 1) 100%);
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.site-notice-text {
  color: white;
  flex: 1;
  text-align: center;
  font-weight: 600;
  font-family: inherit;
  font-size: 1rem;
}

.site-notice-text a {
      border: 1.5px solid white;
      color: white;
      padding: 4px 8px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      border-radius: 4%;
}

.closebtn {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  margin-left: 10px;
}
