.block--testimonials-component {
  padding: rem(32px) rem(16px) !important;
  text-align: left;
  overflow: hidden;

  @include mappy-bp(med) {
    padding: rem(53px) rem(60px) !important;
  }

  @include mappy-bp(wide) {
    padding-bottom: rem(40px);
  }

  @include mappy-bp(widest) {

    margin: 0 auto;
  }

  .testimonials-header {

    @include mappy-bp(widest) {
      max-width: rem(1540px);
      margin: auto;
    }
    .testimonials-title {
      display: inline-block;
      font-size: rem(14px);
      font-weight: 900;
      line-height: 1.36;
      letter-spacing: rem(1.74px);
      position: relative;
      text-transform: uppercase;
      overflow: hidden;
      color: #006633;
      width: 100%;

      @include mappy-bp(large) {
        font-size: rem(16px);
        letter-spacing: rem(1.98px);
        // max-width: 30%;
      }

      &::after {
        content: ' ';
        width: 100%;
        margin-left: rem(12px);
        transform: translateY(-50%);
        top: 50%;
        position: absolute;
        height: rem(2px);
        display: inline-block;
        background-color: #787e7c;

      }

      .title {
        max-width: 50%;
        display: inline-block;

        @include mappy-bp(large) {
          max-width: 30%;
          // max-width: 30%;
        }
      }
    }
  }

  >.block__content {
    @include mappy-bp(widest) {
      max-width: rem(1540px);
      margin: auto;
    }
  }

  .testimonial-items {
    .testimonial-item {
      .field--type-string-long {
        position: relative;

        @include mappy-bp(med) {
          padding: rem(53px) rem(60px);
          margin: 0;

        }

        @include mappy-bp(wide) {
          padding-bottom: rem(40px);
          margin: 0;

        }

        margin: rem(30px) auto;
        padding-left: rem(2px);
        font-size: 14px;

        @include mappy-bp(med) {
          margin: rem(60px);
          width: auto;
          padding: rem(1px) rem(30px);
        }

        @include mappy-bp(wide) {
          font-size: 20px;
          margin: rem(60px) rem(230px);
        }

        &::before {
          top: rem(5px);
          left: rem(2px);
          content: url('../../src/000_assets/icons/quote-1.svg');
          position: absolute;

          @include mappy-bp(med) {
            top: auto;

            left: rem(-58px);
          }
        }

        &::after {
          bottom: rem(5px);
          right: rem(2px);
          position: absolute;
          content: url('../../src/000_assets/icons/quote-2.svg');

          @include mappy-bp(med) {
            bottom: auto;
            right: rem(-58px);
          }
        }

        // &::before {
        //   content: url('../../src/000_assets/icons/quote-1.svg');
        // }

        // &::after {
        //   content: url('../../src/000_assets/icons/quote-2.svg');
        // }
      }

      .field--type-string {
        font-size: rem(14px);
        line-height: 1.57;
        letter-spacing: rem(1.05px);
        text-align: right;
        text-transform: uppercase;
        margin-bottom: rem(30px);
        margin-right: 2px;

        @include mappy-bp(wide) {
          font-size: rem(16px);
        }

        .field__item::before {
          content: '\002D';
          margin-right: .75rem;
          height: rem(2px);
          display: inline-block;


        }

      }
    }

    .slick-prev,
    .slick-next {
      &::before {
        content: url('../../src/000_assets/icons/right_green_arrow.svg');
      }
    }

    .slick-dots {
      li {
        button {
          &::before {
            font-size: rem(16px);
            color: $color__primary;
            opacity: .25;
          }
        }

        &.slick-active {
          button {
            &::before {
              font-size: rem(16px);
              color: $color__primary;
              opacity: 1;
            }
          }
        }
      }
    }

  }

  &.testimonial--bg-green {
    background-color: $color__primary;
    color: $color__white;

    .testimonials-title {
      &::after {
        background-color: $color__white;
      }

      .title {
        color: $color__white;
      }

    }

    .testimonial-item {
      .field--type-string-long {
        color: $color__white;

        &::before {
          content: url('../../src/000_assets/icons/quote-1-white.svg');
          opacity: .65;
        }

        &::after {
          content: url('../../src/000_assets/icons/quote-2-white.svg');
          opacity: .65;
        }
      }
    }

    .slick-prev,
    .slick-next {
      &::before {
        content: url('../../src/000_assets/icons/right-white-arrow.svg');
      }
    }

    .slick-dots {
      li {
        button {
          &::before {
            font-size: rem(16px);
            color: $color__white;
            opacity: .25;
          }
        }

        &.slick-active {
          button {
            &::before {
              font-size: rem(16px);
              color: $color__white;
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &.testimonial--bg-white {
    background-color: $color__white;
    color: $color__secondary;

    .testimonial--title {
      color: $color__primary;

      &::after {
        background-color: $color__grey--medium;
      }
    }

  }

  .testimonial-items {
    margin-bottom: 0 !important;

    .testimonial-item {
      .field--type-string-long {

        margin: rem(30px) auto;
        padding-left: rem(2px);
        font-size: 14px;

        @include mappy-bp(med) {
          margin: rem(60px);
          width: auto;
          padding: rem(1px) rem(30px);
        }

        @include mappy-bp(wide) {
          font-size: 20px;
          margin: rem(60px) rem(230px);
        }

        &::before {
          top: rem(5px);
          left: rem(2px);

          @include mappy-bp(med) {
            top: rem(5px);
            left: rem(-58px);
          }
        }

        &::after {
          bottom: rem(5px);
          right: rem(2px);

          @include mappy-bp(med) {
            bottom: 0;
            right: rem(-58px);
          }
        }
        .field__item {
          padding: 3.6rem 0;
          @include mappy-bp(med) {
            padding: 0;
          }
        }

      }

      >.field--type-string {
        margin: rem(30px) auto;
        padding-left: rem(2px);
        font-size: 14px;

        @include mappy-bp(med) {
          margin: rem(60px);
          width: auto;
          padding: rem(1px) 0;
        }

        @include mappy-bp(wide) {
          font-size: 20px;
          margin: rem(60px) rem(230px);
        }
      }
    }

    .slick-prev,
    .slick-next {
      transform: scale(0);

      &::before {
        width: 10px;
        height: 16px;
        font-family: 'slick';
        line-height: 1;
        color: $color__white;
        opacity: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        display: inline-block;
      }

      @include mappy-bp(wide) {
        transform: scale(1.5);
      }
    }

    .slick-prev {
      right: rem(10px);

      &::before {
        transform: scaleX(-1);
      }
    }

    [dir='rtl'] .slick-next {
      left: rem(-10px);
      top: rem(70px);
      right: auto;

      &::before {
        transform: scaleX(-1);
      }
    }

    .slick-dots {
      bottom: rem(-10px);

      @include mappy-bp(wide) {
        display: none !important;
        bottom: rem(-15px);
      }

      li {
        margin: 0 rem(2px);

        button {
          &::before {
            font-size: rem(16px);
          }
        }

        &.slick-active {
          button {
            &::before {
              font-size: rem(16px);
            }
          }
        }
      }
    }
  }
}