.gallery--slideshow--wrapper {
  position: relative;

  .gallery--slideshow {
    overflow: hidden;
    @include mappy-bp(med) {
      margin-left: calc(-100vw / 2 + 690px / 2);
      margin-right: calc(-100vw / 2 + 690px / 2);
    }
  }

  .media--gallery--slideshow {
    max-width: 780px !important;
    margin: 0 auto;
    .field--name-field-caption {
      display: inline-block;
      margin-top: 10px;
      font-size: 16px;
      font-weight: normal;
      font-style: italic;
      font-stretch: normal;
      line-height: 1.88;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
    }
    .slick-list {
      overflow: visible !important;
      padding: 0 !important;

      .slick-slide {
      padding: 0 16px;
        @include mappy-bp(med) {
          padding: 0 44px;
        }
        img {
          width: 100%;
        }
      }
    }
  }

  .gallery--slideshow--thumbnail {
    margin: 0 50px;
    margin-top: 60px;
    .field--name-field-caption {
      display: none;

    }
    .slick-slide {
      margin: 0 10px;
    }

    .slick-list {
      margin: 0 -10px;
    }

    .slick-active {
      .media--display-mode-slideshow {
        position: relative;
        &::after {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: .5;
          background-color: white;
        }
      }
    }

    .slick-current {
      .media--display-mode-slideshow {
        &::after {
          background-color: transparent;
        }
      }
    }
  }

  .next-gallery {
    font-size: 0;
    width: 15.4px;
    height: 24px;
    display: inline-block;
    background-image: url('../../src/000_assets/icons/right-green-arrow.svg');
    position: absolute;
    right: 0;
    bottom: 6%;
    background-repeat: no-repeat;
    background-size: contain;
    @include mappy-bp(med) {
      right: 0;
      bottom: 18px;
      width: 10.3px;
      height: 16px;
    }
  }

  .previous-gallery {
    font-size: 0;
    width: 15.4px;
    height: 24px;
    display: inline-block;
    background-image: url('../../src/000_assets/icons/right-green-arrow.svg');
    position: absolute;
    left: 0;
    bottom: 6%;
    background-repeat: no-repeat;
    background-size: contain;
    @include mappy-bp(med) {
      left: 0;
      bottom: 18px;
      width: 10.3px;
      height: 16px;

    }
    transform: scaleX(-1);
  }
}
