.show {
  display: flex;
}

.hide {
  display: none;
}

.map-block {
  // display: none;
  position: relative;
  min-width: 315px;
  overflow: hidden;

  @include mappy-bp(large) {
    overflow: inherit;
    position: inherit;
  }
  // padding-bottom: 20px;
  z-index: 19;

  @include mappy-bp(large) {
    // padding-bottom: 150px;
  }

  &.show-map {
    display: block;
  }

  .map-block-heading {
    // background-color: $sja_green;
    background-color: #18573a;
    min-height: 120px;

    // min-width: 372px;
    // overflow-x: hidden;

    // padding: 1.75rem 1rem;
    padding: 1rem 1rem;
    align-items: center;

    @include mappy-bp(med) {
      display: flex;
      padding: 2.25rem 3.75rem;
      position: relative;
      min-height: 120px;
      overflow: hidden;
    }

    @include mappy-bp(large) {
      display: flex;
      padding: 0.25rem 3.75rem;
      // min-height: 247px;
    }
    @include mappy-bp($content-max-width + 32px) {
      padding: 0.25rem 3.75rem; // 83px
      display: flex;
      // min-height: 247px;
    }
    @include mappy-bp(widest) {
      padding: 0.25rem 1rem;
    }

    .map-block-heading-title {
      display: block;
      min-width: 300px;
      font-size: 1.875rem;
      font-weight: 900;
      line-height: 1.36;
      letter-spacing: 0.9px;
      color: #ffffff;
      padding-bottom: 0.75rem;
      margin: 0;

      @include mappy-bp(large) {
        display: inline-block;
        font-size: 2.25rem;
        font-weight: 900;
        line-height: 1.36;
        letter-spacing: 0.9px;
        color: #ffffff;
        padding: 0;
        position: absolute;
        top: 60%;
        transform: translate(-50%, -50%);
        left: 18%;
      }
      @include mappy-bp($content-max-width + 32px) {
        left: 23%;
      }
    }
  }
}
.map-block-course-title {
  display: block;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 900;
  margin: 0;
  line-height: 1.33;
  letter-spacing: 1.8px;
  color: #ffffff;

  @include mappy-bp(large) {
    display: inline-block;
    padding-left: 30px;
    font-size: 1.5rem;
    position: absolute;
    top: 75%;
    transform: translate(-50%, -50%);
    left: 52%;
    height: 120px;
  }
  @include mappy-bp(wide) {
    display: inline-block;
    padding-left: 30px;
    font-size: 1.5rem;
    position: absolute;
    top: 100%;
    transform: translate(-50%, -50%);
    left: 58%;
    padding-bottom: 18px;
    width: fit-content;
    z-index: 99;
  }
  @include mappy-bp(mega) {
    left: 50%;
    padding-left: 10;
  }
}
.searchPanel {
  width: 100%;
  background-color: #ffffff;
  padding-bottom: 2rem;
  margin: 0 auto 0;
  // transition: 0.5s all ease-in;

  @include mappy-bp(med) {
    margin: 1.75rem auto 0;
  }

  @include mappy-bp(large) {
    // max-width: 100%;
    background-color: #18573a;
    // position: inherit;
    // top: 7%;
    // transform: translate(0%, -50%);
    // width: auto;
    // overflow: inherit !important;
    // border: 0;
  }

  // @include mappy-bp(larger) {
  //   text-align: center;
  //   transform: translate(0%, -25%);
  // }

  // @include mappy-bp(wide) {
  //   width: 100%;
  // }

  // @include mappy-bp(mega) {
  //   margin-left: 8%;
  //   display: flex;
  //   transform: translate(-8%, -15%);
  //   z-index: 999;
  // }

  .search-panel-line {
    width: 100%;
    height: 8px;
    background-color: #006633;
    margin: 0;
  }
  .map-text-search {
    @include mappy-bp(mobile) {
      padding: 1rem 0.1rem 0.1rem;
    }
    @include mappy-bp(med) {
    }
  }
  .map-text-search,
  .map-search-date {
    width: 100%;
    @include mappy-bp(large) {
      // width: 30%;
      display: inline-flex;
      flex-direction: row;
      justify-content: space-around;
    }
    @include mappy-bp(wide) {
      display: inline-flex;
      width: fit-content;
    }
  }
  input#dateTbxFrom {
    @include mappy-bp(large) {
      width: 300px;
      margin: 28px;
    }
  }
  input#dateTbxTo {
    @include mappy-bp(large) {
      width: 300px;
      margin: 28px;
    }
  }

  input#searchTbx {
    width: 100%;
    padding: 0;
    height: 2.6rem;
    top: 0;
    padding-left: rem(38px);
    border-radius: 5px;
    background: url("/themes/custom/sja/src/000_assets/icons/theme-defaults/searchgreen.svg");
    background-repeat: no-repeat;
    background-position: left;
    background-position-x: 0.5rem;
    background-size: 1rem;
    font-size: 0.8rem;

    @include mappy-bp(375px) {
      font-size: 0.9rem;
    }

    @include mappy-bp(375px) {
      background-size: 20px;
    }

    @include mappy-bp(large) {
      width: 19rem;
    }

    &::placeholder {
      color: #000;
      text-align: center;
      @include mappy-bp(large) {
        color: #585858;
      }
    }

    &:focus,
    &:focus-visible {
      outline: none;

      &::placeholder {
        color: transparent;
      }
    }

    .searchBtn,
    .searchBtnReset {
      @extend %green-button-desktop;
    }

    .searchBtnReset {
      margin-left: 1rem;
    }
  }
  .map-switch-block {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 22px;

    @include mappy-bp(large) {
      position: inherit;
      border: 0;
      font-size: rem(19px);
      color: #585858;
    }

    @include mappy-bp(med) {
      font-size: 17px;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      border-radius: 0;
      color: #585858;
    }

    @include mappy-bp(wide) {
      width: 19rem;
      // padding-left: 2.5rem;
      padding: 0 1.5rem 0 2.5rem;
    }
  }

  .map-text-search {
    @include mappy-bp(land) {
      top: 9.5% !important;
      position: relative;
      width: auto;
      padding: 1.65rem 0.1px 0.1px;
    }
    @include mappy-bp(med) {
      top: 0% !important;
      position: relative;
      width: auto;
      padding: 1.65rem 0.1px 0.1px;
    }
    @include mappy-bp(large) {
      padding: 1.65rem 2rem 0;
      // width: 42%;
    }
    @include mappy-bp(widest) {
      top: 0% !important;
    }

    .autoComplete_list {
      // border: 1px solid $color__input-border--normal;
      border: 1px solid #5d5d5d;
      // border-top: 0;
      // border: 1px solid #BEC6C4;
      // border-top: 0;
      /* position: absolute; */
      // width: 100%;
      background: #fff;

      width: 100%;

      @include mappy-bp(large) {
        background: white;
        height: fit-content;
        position: absolute;
        z-index: 99;
        width: 304px;
        margin-top: 3rem;
      }
    }
    .no_result {
      list-style: none;
      padding: rem(10px);
    }
    .no_result_text {
      color: #666;
    }
    .map--autocomplete-result {
      > button {
        background: none;
        border: 0;
        cursor: pointer;
        line-height: 1.25rem;
        overflow: hidden;
        padding: 0.75rem 1rem;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;

        &:hover {
          outline: 1px solid $color__primary;
        }
      }
    }
  }

  .map-search-date {
    padding: 1rem 1.385rem;
    @include mappy-bp(large) {
      padding: 0.1px;
      margin: 1rem 0;
      justify-content: center;
    }
    @include mappy-bp(larger) {
      padding: inherit;
    }
    @include mappy-bp(wide) {
      padding: 1rem 7rem;
    }
    @include mappy-bp(mega) {
      padding: 1rem 13rem;
    }
    @include mappy-bp($content-max-width + 32px) {
      padding: inherit;
      display: flex;
      justify-content: center;
    }
    input {
      cursor: pointer;
    }
  }

  .map-text-search-label {
    font-size: 16px;
    font-weight: 900;
    line-height: 1.25;
    letter-spacing: 0.8px;
    margin-bottom: 0.375rem;
    @include mappy-bp(large) {
      color: white;
    }
  }

  .map-search-date .map-text-search-label {
    padding-top: 1.75rem;
  }

  .search-filters {
    display: block;
    // margin: 2.25rem auto 0;
    margin: 0 auto 0;

    text-align: center;
    width: 100%;

    @include mappy-bp(large) {
      // position: absolute;
      top: 45%;
      margin: auto 0;
      display: flex;
      margin-top: 1rem;
      // transform: translatex(32.5%);
    }
  }

  .searchBtn:hover {
    border: 2px solid white;
    @include mappy-bp(wide) {
      border: 2px solid white;
    }
  }

  .searchBtnReset:hover {
    border: 2px solid white;
    @include mappy-bp(wide) {
      border: 2px solid white;
    }
  }

  .searchBtn:focus {
  }

  .searchBtn,
  .searchBtnReset {
    @extend %green-button-desktop;
    border-radius: rem(5px);
    font-size: rem(18px);
    color: #18573a;
  }

  .searchBtnReset {
    // margin-left: 1rem;
    // margin-top: .5rem;
  }
}

.field-azure-map-block {
  position: relative;
  overflow: hidden;

  .map-renderer {
    position: relative;

    // height: 100vh;
    // width: 100vw;

    min-width: 320px;
    height: 28rem;

    @include mappy-bp(large) {
      max-height: 50;
      max-width: 2811px;

      border-radius: 5px;
    }

    // width: 100%;
    // height: 100%;
  }
  .map-renderer.hide-map {
    display: none;
    @include mappy-bp(large) {
      display: block;
    }
  }
}
.results-block {
  max-width: $content-max-width;
  margin: 0 auto;
  padding: 1rem 1rem 3.5rem;
  transition: 0.5s all ease-in;

  @include mappy-bp(med) {
    // padding: 1rem 3.75rem;
    // height: 100vh;
  }

  @include mappy-bp($content-max-width + 32px) {
    padding: 0;
  }

  .results-block-header {
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mappy-bp(large) {
      margin-top: 3.75rem;
    }

    .results-mobile {
      margin: 0;
      display: none;

      .breadcrumb__link {
        cursor: pointer;
        background-color: #ffffff;
        border-radius: 3px;
        border: solid 2px #bec6c4;
        color: #006633;
        display: inline-block;
        font-size: 14px;
        font-weight: 900;
        letter-spacing: 1.74px;
        line-height: 1.36;
        padding: 6px 14px 14px 30px;
        margin-right: 1rem;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        -webkit-transition: 0.2s all;
        height: 50px;
        @include mappy-bp(mobile) {
          padding: 14px 14px 14px 35px;
          // margin-right: 0;
        }
      }
      .breadcrumb__item a::before {
        padding-left: 24px;
        position: absolute;
        top: 24%;
        width: 12px;
        height: 0%;
        left: -6%;
        content: "\f060";
        font-family: "fontawesome";
        font-size: 18px;
        float: right;
        -webkit-transform: translateY(50%);
        transform: translateY(50%);
      }
      &.show {
        display: block;
      }
    }

    .results-desktop {
      font-size: 0.875rem;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 0.7px;
    }

    .locationSelect {
      padding-right: 2rem;
      border: solid 2px #bec6c4;
      text-transform: uppercase;
      border-radius: 3px;
      color: #006633;
      cursor: pointer;
      display: inline-block;
      font-size: 14px;
      font-weight: 900;
      letter-spacing: 1.74px;
      padding: 7px 34px 6px 21px;
      height: 50px;
      &:active,
      &:focus {
        border: 2px solid black !important;
      }
    }
    @include mappy-bp(large) {
      // border: solid 2px #bec6c4;
    }
  }
}
.selectedItem {
  display: none;
  height: 130px;
  @include mappy-bp(large) {
    padding-top: 32px;
    min-height: 210px;
    padding-left: 88.5px;
    padding-right: 85px;
  }
  .locationIcon-green {
    margin-left: 16px;
    margin-top: 20px;
    float: left;
    @include mappy-bp(large) {
      margin-left: 0;
      position: relative;
      left: 50px;
      top: 14px;
    }
  }
  .listItem {
    border-top-color: rgb(217, 224, 222);
    border-top-style: solid;
    border-top-width: 1px;
    padding: 1rem;
    background-color: #ffffff;
    list-style-type: none;
    font-size: 14px;
    @include mappy-bp(large) {
      font-size: 16px;
      list-style-type: none;
      width: 100%;
      height: 180px;
      border: solid 2px #d9e0de;
      background-color: #ffffff;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .listItem-title {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding-left: 8px;
    @include mappy-bp(large) {
      padding-left: 0;
    }
    height: 30px;
    color: white;
    font-weight: bold;
  }
  .popupSubTitle {
    font-size: 10px;
    line-height: 12px;
  }
  .popupContent {
    font-size: 11px;
    line-height: 18px;
    padding: 8px;
  }
}
.listPanel {
  .listItem {
    border: 1px solid rgb(217, 224, 222);
    background-color: #ffffff;
    list-style-type: none;
    font-size: 0.875rem;
    // padding: 1.25rem 0;
    display: flex;
    align-items: center;
    transition: padding 0.5s ease-in-out;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 10px;
    @extend %cf;

    &:last-of-type {
      border-bottom: 1px solid rgb(217, 224, 222);
    }

    &:active {
      background-color: #d8d4d4;
    }

    &.show-details {
      display: block;
      // border-top: none;

      &:hover,
      &:active,
      &:focus {
        background-color: inherit;
        outline: none;
      }

      @include mappy-bp(large) {
        padding-top: 3rem;
      }
    }

    @include mappy-bp(large) {
      font-size: 1rem;
      width: 100%;
      border: 2px solid #d9e0de;
      padding: 2.125rem 3.25rem;
      position: relative;

      &:last-of-type {
        border-bottom: 2px solid rgb(217, 224, 222);
      }

      &::after {
        // content: url('../../src/000_assets/icons/icon__search-arrow--green.svg');
        // width: 1rem;
        // height: 1rem;
        // position: absolute;
        // top: 50%;
        // right: 3.25rem;
        // transform: translateY(-50%);
      }
      &:hover {
        cursor: pointer;
      }
    }
    .locationIcon-green {
      margin-right: 1.5rem;
      @include mappy-bp(large) {
        left: 50px;
      }
    }

    .listItem-all {
      width: 100%;

      .listItem-title {
        position: relative;
        color: $sja_green;
        font-size: 1rem;
        font-weight: bold;
        padding-right: 1.5rem;
        margin: 0;
        width: 100%;

        @include mappy-bp(0 large) {
          &::after {
            content: url("../../src/000_assets/icons/icon__search-arrow--green.svg");
            width: 1rem;
            height: 1rem;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
          }
        }

        a {
          color: #006633;
        }
      }
      .listItem-details {
        display: none;

        @include mappy-bp(large) {
          display: block;
          padding-top: 0.5rem;
        }
      }
    }

    .listItemAddress {
      line-height: 1.71;
    }

    // List item selected.
    &.show-details {
      @include mappy-bp(large) {
        padding-left: 0;
        padding-right: 0;
        border: none;

        &::after {
          content: none;
        }

        &:hover,
        &:active,
        &:focus {
          background-color: #ffffff;
        }
      }

      .locationIcon-green {
        float: left;

        @include mappy-bp(large) {
          margin-left: 3.25rem;
        }
      }

      .listItem-title {
        margin-bottom: 0.5rem;
        &::after {
          content: none;
        }
      }

      .listItem-details {
        display: block;
        margin-left: 2.75rem; // 24px + 20px

        @include mappy-bp(large) {
          margin-left: 6rem; // 24px + 52px
        }
      }
    }
  }
}

//hack for greenpopup
.field-azure-map-block {
  .popup-content-container {
    background-color: none;
    box-shadow: none;
  }
}

// javascript generated html.
.map-block {
  .listPanel {
    .listItem.course-table-row {
      padding: 1rem;
      display: block;

      @include mappy-bp(large) {
        padding: 1.75rem 1.75rem;
        border: solid 2px #d9e0de;
        background-color: #ffffff;
        cursor: initial;

        &::after {
          content: none;
        }
      }
    }
  }
}

.course-table-first-segment,
.course-table-second-segment,
.course-table-third-segment {
  width: 100%;
  display: block;
}

.course-table-first-segment {
  @include mappy-bp(large) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0.75rem;
    // margin-top: 1rem;
  }
  &.address-string {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    justify-content: flex-start;

    img {
      margin: auto 0;
      padding-right: 0.5rem;
    }

    a {
      text-decoration: none;
    }
    // a:hover {
    //   text-decoration-color: green;
    // }
  }
}

.course-table-second-segment {
  @include mappy-bp(large) {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }
}

.course-start-date-time,
.course-end-date-time {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  max-width: 320px;

  @include mappy-bp(large) {
    margin-bottom: 0;
  }
}

.course-start-date-time,
.course-end-date-time,
.course-price,
.course-table-second-segment,
.course-table-third-segment {
  @include mappy-bp(0 large) {
    margin-bottom: 1.5rem;
  }
}

.course-date-time {
  // text-align: right;

  @include mappy-bp(large) {
    margin-left: 2rem;
    margin-right: 1rem;
  }

  .course-value {
    // text-align: right;
  }
}

.course-table-third-segment {
  display: flex;
  justify-content: space-between;
  align-self: center;
  flex-direction: column;
  margin-bottom: 0.25rem;

  @include mappy-bp(wide) {
    flex-direction: row;
  }
}

.course-value {
  // width: 121px;
  width: auto;
  // height: 22px;
  height: auto;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #006633;
  display: table-cell;
}

.course-price {
  @include mappy-bp(large) {
    width: 100%;
    text-align: right;
  }

  @include mappy-bp(wide) {
    width: 50%;
  }

  @include mappy-bp(mega) {
    width: 55%;
  }

  > span {
    display: inline-block;
    font-weight: bold;
    letter-spacing: 0.8px;
    font-size: 1rem;
    padding: 0.5rem 1.25rem;
    width: max-content;

    @include mappy-bp(large) {
      font-size: 1.25rem;
      padding: 0.5rem 0 0.5rem 1.25rem;
    }
  }
}

.course-available,
.course-full {
  display: block;

  .course-available-icon {
    content: url("../../src/000_assets/icons/available.svg");
    margin-right: 0.75rem;
  }

  .course-full-icon {
    content: url("../../src/000_assets/icons/full.svg");
    margin-right: 0.75rem;
  }
}

.course-text {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.875rem;
  letter-spacing: 0.7px;
}

.course-register {
  display: flex;
  align-items: center;
}

.seatsAvailableRegister .course-register a {
  @extend %green-button;
  padding: 1.2px 0.75rem !important;
}

.seatsAvailableRegister {
  clear: right;
  padding-top: 21px;

  @include mappy-bp(wide) {
    padding-top: 0;
  }
  color: $sja_green;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #bcbebe;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider::before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

// input:checked + .slider {
//   background-color: $sja_green;
//   // color: $light_light_grey;
// }

.map-switch-block .slider.round::before .map-switch-block {
  .switch-map-wrapper {
    .map-switch {
      .switch {
        .slider {
          background-color: $sja_green;
        }

        input:not(:checked) + .slider {
          background-color: #bcbebe;
          // color: $light_light_grey;
        }

        input:not(:checked) + .slider::before {
          -webkit-transform: translateX(26px);
          -ms-transform: translateX(26px);
          transform: translateX(26px);
        }
      }
    }
  }
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

// input:checked + .slider::before {
//   -webkit-transform: translateX(26px);
//   -ms-transform: translateX(26px);
//   transform: translateX(26px);
// }

.slider {
  background-color: #bcbebe;
}

input:not(:checked) + .slider {
  // background-color: $light_light_grey;
  // background-color: $sja_green;
  background-color: #18573a;
  // color: $light_light_grey;
}

input:not(:checked) + .slider::before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  display: block;
  border-radius: 34px;
  width: 95%;
  margin: auto 0;
}

.slider.round::before {
  border-radius: 50%;
}

.geolocation-button {
  position: absolute;
  margin: 0 65vw;
  @include mappy-bp(large) {
    margin: 21px 371px;
  }
  border-collapse: collapse;
  width: 60px;
  height: 35px;
  text-align: center;
  cursor: pointer;
  &:focus {
    outline: 2px dashed #17171d;
  }
  &:hover {
    svg {
      transform: scale(1.1);
    }
  }
  &::-moz-focus-inner {
    border: 0;
  }
  svg {
    outline: none;
    transition: transform 0.15s linear;
  }
}
.sr-only {
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
}

.eventLocationList {
  display: block;
  position: relative;
  padding-top: 1.5rem;
}

.right-green-arrow {
  position: relative;
  float: right;
  bottom: 18px;
  left: -5%;
  @include mappy-bp(large) {
    bottom: -12px;
  }
}

.map-search-error {
  display: none;
  color: $sja_red;
  font-size: 0.875rem;
  font-weight: bold;
  margin-top: 0.25rem;

  &.show {
    display: block;
  }
}

.map-no-results {
  display: none;
  margin: 1.5rem 0;

  &.show {
    display: block;
  }

  p {
    font-size: 1rem;
  }
}

#courseLeftPanelLanguageSwitcher {
  @include mappy-bp(med) {
    margin-left: auto;
  }
}

.language-select {
  margin: auto 0;

  .language-filter {
    padding: 0.5rem 2.3rem 0.5rem 1rem;
    font-size: 15px;
    height: fit-content;
    border-radius: 6px;
    background: #ffffff;
    background-image: url("/themes/custom/sja/src/000_assets/icons/arrow-down-green.svg");
    background-repeat: no-repeat;
    background-size: 0.8rem;
    background-position: 5.2rem center;
    border: 0.5px solid #c4c4c4;
    color: #000;

    @include mappy-bp(large) {
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.16);
      border: 0;
    }

    &:focus-visible {
      outline: none;
    }
  }
}

.map-container {
  font-family: "Lato", "Helvetica Neue", Arial;

  .map-block {
    background: #18573a;

    .map-form-container {
      @include mappy-bp(widest) {
        max-width: $content-max-width;
        margin: 0 auto;
        padding: 0;
        overflow-x: visible;
      }

      #searchAccordion {
        background-color: #fff;

        @include mappy-bp(large) {
          // margin-bottom: 2rem;
          // background-color: $sja_green;
          background-color: #18573a;
        }

        // .accordion-wrapper {
        // font-size: 16px;

        .accordion-title {
          text-align: center;
          font-size: revert;
          color: #000;
          margin-top: 0;
          background: #fff;
          // padding: .5em .5em .5em .7em;
          padding-top: 0.8125rem;
          padding-bottom: 0.8125rem;
          font-weight: 400;
          margin-bottom: 0;
          border-top: 0;
          border-left: 0;
          border-right: 0;
          border-radius: 0;
          display: flex;
          justify-content: space-between;

          @include mappy-bp(large) {
            display: none;
          }
          // background: url('../../src/000_assets/arrows/icon__arrow--down.svg');
          // background-position: right;
          // background-repeat: no-repeat;

          &.ui-state-active {
            // background: #C0D1C9;

            .accordion-image {
              display: flex;
              width: 1.5rem;
              background: url("/themes/custom/sja/src/000_assets/icons/arrow-up-green.svg");
              background-repeat: no-repeat;
              background-position: center;
              background-size: 100%;

              @include mappy-bp(med) {
                width: 1.5rem;
              }
            }
          }

          .ui-accordion-header-icon {
            display: none;
          }

          .ac-title {
            width: 90%;
            flex: 1;
            font-size: 20px;
          }

          .accordion-image {
            display: flex;
            width: 1.3rem;
            background: url("/themes/custom/sja/src/000_assets/icons/arrow-down-green.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100%;
            @include mappy-bp(med) {
              width: 1.5rem;
            }
          }
        }

        // }

        .find-classes {
          .accordion-image {
            width: 1.6rem;
          }
        }
      }

      .mobile-right-panel {
        display: block;

        @include mappy-bp(large) {
          display: none;
        }

        &.map-opacity {
          height: 0;
        }

        #courseAccordion {
          #course-1 {
            padding: 1rem 1rem;

            @include mappy-bp(med) {
              padding: 2rem 3.75rem;
            }
          }

          #course-2 {
            .course-page--should-know-section {
              // padding: 2rem 3.75rem;
              // padding: 2rem 1rem;
              // padding: 1rem 1rem;
              padding: 0 1rem;

              @include mappy-bp(med) {
                padding: 2rem 3.75rem;
              }
            }
          }

          .accordion-wrapper {
            // font-size: 16px;

            .accordion-content-wrapper {
              font-size: 16px;
              padding: 0;
              display: none;

              &.ui-accordion-content {
                display: block;
              }

              @include mappy-bp(med) {
                font-size: 18px;
              }

              .field--name-field-course-description {
                padding: 1em 1rem;
              }
            }

            .accordion-title {
              text-align: center;
              font-size: revert;
              color: #000;
              display: flex;
              justify-content: space-between;
              margin: 0;
              border: 0;
              border-radius: 0;
              padding-top: 1rem;
              padding-bottom: 1rem;

              &.course-introduction {
                background-color: #c0d1c9;
              }

              &.course-details {
                background-color: #dce3e0;
              }

              &.ui-state-active {
                .accordion-image {
                  display: flex;
                  width: 1.5rem;
                  background: url("/themes/custom/sja/src/000_assets/icons/arrow-up-green.svg");
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: 100%;
                }
              }

              .ui-accordion-header-icon {
                display: none;
              }

              .ac-title {
                width: 90%;
                font-size: 18px;
                font-weight: 400;
              }

              .accordion-image {
                display: flex;
                width: 1.5rem;
                background: url("/themes/custom/sja/src/000_assets/icons/arrow-down-green.svg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: 100%;
              }
            }
          }
        }

        #courses {
          .course-page--should-know-section {
            padding: 0;
          }

          .field-course-delivery-method {
            .availability-province {
              padding-top: 5px !important;
              display: inline-flex;
              border: 2px solid #f6f6f6;
              padding: 5px 5px 5px 5px;
              margin: 1%;
              border-radius: 3px;
              font-weight: 400;
            }
          }
        }
      }

      .map-block-heading {
        // justify-content: center;
        min-height: auto;
        justify-content: space-between;
        display: flex;
        flex-wrap: wrap;
        padding-top: 1rem;

        .mobile-course-link {
          display: block;

          @include mappy-bp(large) {
            display: none;
          }

          img {
            width: 1.5rem;

            @include mappy-bp(med) {
              width: 2rem;
            }
          }
        }

        .all-course-link {
          display: none;
          background-color: #fff;
          padding: 0.25rem 1rem 0.25rem 2rem;
          border-radius: 5px;
          font-size: 18px;
          font-weight: 500;
          color: #18573a;
          order: 0;
          background-image: url("/themes/custom/sja/src/000_assets/icons/arrow-left-green.svg");
          background-repeat: no-repeat;
          background-size: 0.9rem;
          background-position: 0.5rem center;
          position: absolute;
          top: rem(46px);
          left: 3.75rem;

          @include mappy-bp(large) {
            display: block;
            border: 2px solid #fff;
          }

          @include mappy-bp(widest) {
            left: 1rem;
          }

          &:hover {
            background-image: url("/themes/custom/sja/src/000_assets/icons/arrow-left-white.svg");
            background-color: #18573a;
            border: 2px solid #fff;
          }

          a {
            text-decoration: none;
          }

          &:hover a {
            color: #fff;
          }
        }

        .not-sure-link {
          color: #fff;
          border-bottom: 2px solid #fff;
          font-size: 18px;
          order: 1;
          display: none;

          @include mappy-bp(large) {
            order: 2;
            display: block;
          }

          a {
            text-decoration: none;
            color: #fff;
          }
        }

        .map-course-title {
          font-weight: 700;
          color: #fff;
          text-align: center;
          order: 2;
          flex: 1;

          @include mappy-bp(med) {
            order: 1;
            font-size: 2rem;
            line-height: 1.2;
          }

          @include mappy-bp(large) {
            font-size: 2.75rem;
            padding: 0 rem(184px);
          }
        }
      }

      .searchPanel {
        // padding: 2px;
        // height: auto !important;
        // border: 0;

        &[data-delivery="9891446"] {
          .map-text-search {
            background-color: #fff;
            // opacity: .5;
            @include mappy-bp(large) {
              // background-color: rgb(0, 102, 51);
            }
          }

          .search-box {
            // background-color: #fff;
            // opacity: .5;

            background-color: #d3d3d3 !important;
            color: #585858 !important;
            opacity: 1 !important;
            pointer-events: none;

            @include mappy-bp(large) {
              background-color: #d3d3d3 !important;
              color: #585858 !important;
              opacity: 1 !important;
            }
          }

          #searchTbx {
            // background-color: #fff;
            background-color: #d3d3d3;
            // opacity: .5;
            opacity: 1;
            pointer-events: none;

            @include mappy-bp(large) {
              background-color: #d3d3d3;
              color: #585858;
              opacity: 1;
            }

            &::placeholder {
              // color: #000;
              color: #585858;
            }
          }

          .date-range-box {
            // opacity: .5;

            background-color: #d3d3d3 !important;
            color: #585858 !important;
            opacity: 1 !important;

            @include mappy-bp(large) {
              background-color: #d3d3d3 !important;
              opacity: 1;
            }

            .date-range-box-icon {
              opacity: 1;

              @include mappy-bp(large) {
                background-color: #d3d3d3 !important;
                opacity: 1;
              }
            }
            .date-range-box-icon img {
              height: 25px !important;
            }

            .date-range-box-inner {
              opacity: 1;

              @include mappy-bp(large) {
                background-color: #d3d3d3 !important;
                opacity: 1;
              }
            }

            .online-tooltip {
              display: flex !important;
            }

            #litepicker {
              pointer-events: none;
              opacity: 1;
              background-color: #d3d3d3 !important;

              @include mappy-bp(large) {
                background-color: #d3d3d3 !important;
                opacity: 1;
              }
            }
          }
          #searchBtn {
            pointer-events: none;
            // opacity: .5;
          }
        }

        &[data-delivery="9892791"] {
          .virtual-tooltip {
            display: flex !important;
            width: 1.3rem;
            margin-right: 0.3rem;
            outline: none;

            @include mappy-bp(med) {
              width: 1.3rem;
            }

            @include mappy-bp(large) {
              margin-right: 0.5rem;
              position: absolute;
              right: 0;
              top: 30%;
              // width: 1.3rem;
            }
          }

          .search-box {
            // background-color: #fff;
            // opacity: .5;
            // pointer-events: none;

            background-color: #d3d3d3 !important;
            color: #585858 !important;
            opacity: 1 !important;

            @include mappy-bp(large) {
              background-color: #d3d3d3 !important;
              color: #585858 !important;
              opacity: 1 !important;
            }

            .search-box-inner {
              flex-direction: row !important;

              @include mappy-bp(large) {
                flex-direction: column !important;
              }
            }
          }

          #searchTbx {
            // background-color: #fff;
            // opacity: .5;
            // pointer-events: none;

            background-color: #d3d3d3;
            color: #585858;
            opacity: 1;

            @include mappy-bp(large) {
              background-color: #d3d3d3;
              color: #585858;
              opacity: 1;
            }

            &::placeholder {
              // color: #000;
              color: #585858;
            }
          }

          // .date-range-box {
          //   opacity: .5;
          //   #litepicker {
          //     pointer-events: none;
          //   }
          // }
          // #searchBtn {
          //   pointer-events: none;
          //   opacity: .5;
          // }
        }

        // &[data-delivery='9891436'],
        // &[data-delivery='9891441'] {

        // }
      }

      .searchPanel,
      .accordion-title {
        padding: 1.125rem 1rem 0;
        // padding: 1rem;

        @include mappy-bp(land) {
          padding: 0 3.75rem;
        }

        @include mappy-bp(large) {
          transform: none;
          margin: 0;
          padding-bottom: 2rem;
        }

        .map-date-wrapper {
          width: 100%;
          margin-left: 0;
          transform: none;
          padding: 0;
          display: flex;
          justify-content: center;
          margin-top: 0;
          flex-direction: column;

          @include mappy-bp(large) {
            width: 100%;
            display: flex;
            flex-grow: 1;
            flex-direction: row;
            // justify-content: space-between;
            justify-content: space-evenly;
          }

          @include mappy-bp(wide) {
            justify-content: center;
          }

          .map-search-date {
            display: flex;
            padding: 0;
            justify-content: space-between;

            @include mappy-bp(med) {
              height: 3.5rem;

              // margin: 1rem 0;
              // width: 100%;
              // width: max-content;
              width: auto;
              // justify-content: flex-start;
              justify-content: center;
            }

            @include mappy-bp(large) {
              margin: 1rem 0;
            }

            @include mappy-bp(wide) {
              width: fit-content;
            }

            .map-text-search {
              @include mappy-bp(large) {
                width: auto;
                margin-right: 0;
                border: 1px;
                border-right: 1px solid #5d5d5d;
              }

              .search-box {
                @include mappy-bp(large) {
                  border-top-left-radius: 4px;
                  border-top-right-radius: 0;
                  border-bottom-left-radius: 4px;
                  border-bottom-right-radius: 0;
                }
              }
            }

            .date-range-box {
              background: #fff;
              display: flex;
              flex-direction: row;
              border: 0.5px solid #5d5d5d;
              // border: 1px solid #5D5D5D;
              width: 49%;
              margin-left: 3.5px;
              // padding-left: 1.2rem;
              border-radius: 5px;
              // height: 3.5rem;
              height: 2.6rem;
              padding-left: 0.5rem;

              @include mappy-bp(med) {
                height: 3.5rem;
              }

              @include mappy-bp(large) {
                margin-left: 0;
                border-top-right-radius: 4px;
                border-top-left-radius: 0;
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 0;
                width: fit-content;
                height: 3.5rem;
                border: none;
              }

              // .online-tooltip {
              //   display: none;
              // }

              .date-range-box-icon {
                // padding-top: 1rem;
                // padding-top: .6rem;
                // height: 2.5rem;
                height: auto;
                cursor: pointer;
                margin: auto 0;

                @include mappy-bp(med) {
                  // padding-top: 1rem;
                }

                @include mappy-bp(large) {
                  // padding-top: 1rem;
                  border: 0;
                }
              }

              .date-range-box-inner {
                display: flex;
                flex-direction: column;
                justify-content: center;

                @include mappy-bp(med) {
                  flex-grow: 1;
                  font-size: 16px;
                }

                .date-range-title {
                  font-size: 0.8rem;
                  color: #585858;
                  text-align: center;
                  // line-height: normal;
                  line-height: 1rem;
                  cursor: pointer;

                  // border-top: 1px solid #5D5D5D;
                  // border-right: 1px solid #5D5D5D;
                  // border-left: 1px solid #5D5D5D;
                  // border-top-left-radius: 5px;
                  // border-top-right-radius: 5px;

                  @include mappy-bp(375px) {
                    font-size: 0.9rem;
                  }

                  @include mappy-bp(med) {
                    font-size: 1rem;
                    line-height: initial;
                  }

                  @include mappy-bp(large) {
                    border: 0;
                    font-size: 1rem;
                    line-height: 1.25rem;
                  }
                }

                #litepicker {
                  // width: auto;
                  width: 98%;
                  border: 0;
                  text-align: center;
                  height: auto;
                  padding: 0 0.5rem 0 0.5rem;
                  font-size: 0.8rem;
                  // border-bottom: 1px solid #5D5D5D;
                  // border-right: 1px solid #5D5D5D;
                  // border-left: 1px solid #5D5D5D;
                  // border-top: 0;
                  // border-bottom-left-radius: 5px;
                  // border-bottom-right-radius: 5px;

                  @include mappy-bp(375px) {
                    font-size: 0.9rem;
                  }

                  @include mappy-bp(med) {
                    font-size: 1rem;
                    padding: 0.1rem 1.5rem 0.1rem;
                  }

                  &:focus {
                    border: 0;
                    outline: 0;
                  }

                  @include mappy-bp(large) {
                    border: 0;
                    // font-size: 1rem;
                    // padding: .1rem 1.5rem .1rem;
                  }
                }
              }

              .online-tooltip,
              .virtual-tooltip {
                width: 1.3rem;
                // display: flex;
                margin-right: 0.5rem;
                display: none;

                &:focus,
                &:focus-visible {
                  outline: none;
                }
              }
            }
          }

          .search-filters {
            // width: fit-content;
            position: relative;
            // margin: 1rem auto 0;
            top: 0;
            height: fit-content;
            // margin: auto 0;
            // width: 100%;
            width: fit-content;
            margin-bottom: 1.125rem;

            @include mappy-bp(larger) {
              // margin-bottom: 0;
              margin: auto 0;
            }

            @include mappy-bp(larger) {
              // margin-bottom: 0;
              margin: auto 0;
              width: fit-content;
            }

            #searchBtn {
              // margin-left: 3rem;
              // padding: .8rem 2rem;
              padding: 0.375rem 3rem;
              border-radius: 5px;
              margin-top: 1rem;
              // margin-top: 0;
              text-transform: none;
              font-weight: 600;

              @include mappy-bp(large) {
                margin-top: 0;
              }

              &:hover {
                background-color: #18573a;
              }

              &:focus {
                background: #fff;
                color: #18573a;
              }

              &:disabled,
              &:disabled:hover,
              &:disabled:focus,
              &:disabled:focus-visible {
                background: #fff;
                border: solid 2px #006633;
                color: #18573a;
                opacity: 0.6;
                cursor: not-allowed;
              }

              @include mappy-bp(med) {
                padding: 0.5rem 3rem;
                font-size: 18px;
              }

              @include mappy-bp(large) {
                margin-left: 0;
                padding: 0.8rem 3rem;
              }

              @include mappy-bp(larger) {
                margin-top: 0;
              }

              @include mappy-bp(wide) {
                margin-left: 3rem;
                font-size: 19px;
              }

              @include mappy-bp(widest) {
                padding: 0.8rem 3rem;
              }
            }

            #searchBtnReset {
              margin-left: 1rem;
              border-radius: 5px;
              padding: 0.375rem 3rem;
              // padding: .8rem 2.5rem;
              margin-top: 1rem;
              text-transform: none;
              font-size: 1rem;
              font-weight: 600;

              &:hover {
                background-color: #18573a;
              }

              &:focus {
                background: #fff;
                color: #18573a;
              }

              @include mappy-bp(med) {
                font-size: 18px;
                padding: 0.5rem 3rem;
              }

              @include mappy-bp(large) {
                margin-top: 0;
                padding: 0.8rem 3rem;
              }

              @include mappy-bp(larger) {
                margin-top: 0;
              }

              @include mappy-bp(wide) {
                font-size: 19px;
              }

              @include mappy-bp(widest) {
                padding: 0.8rem 3rem;
              }
            }
          }

          // .searchTbx {
          //   border: 0;
          //   padding: 0 1rem;
          //   width: auto;
          //   color: #585858;

          //   &::focus-visible,
          //   &::focus {
          //     outline: none;
          //   }
          // }

          .map-text-search {
            padding: 0;
            // border-right: 1px solid #5D5D5D;
            border: 0.5px solid #5d5d5d;
            // border: 1px solid #5D5D5D;
            width: 51%;
            margin-right: 3.5px;
            border-radius: 5px;

            .search-box {
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              background: #fff;
              border-radius: 5px;

              .search-box-inner {
                display: flex;
                justify-content: space-between;
                flex-direction: column;

                .search-icon {
                  margin: auto 0;
                  height: fit-content;
                }

                .searchTbx {
                  border: 0;
                  padding: 0 1rem;
                  width: auto;
                  color: #585858;

                  &:focus-visible,
                  &:focus {
                    outline: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .course-panel {
    display: flex;
    justify-content: space-between;

    @include mappy-bp(large) {
      padding: 0 3.75rem;
    }

    @include mappy-bp(widest) {
      max-width: rem(1482px);
      margin: 0 auto;
      padding: 0;
      overflow-x: visible;
    }

    .course-left-panel {
      width: 100%;

      @include mappy-bp(larger) {
        width: 50%;
        margin-bottom: 5rem;
        padding-right: 1.5rem;
      }

      table {
        margin: 0;
      }

      #tabs {
        .field-azure-map-block {
          background: #f1f1f1;
          padding: 0 1rem 1rem 1rem;
          border-radius: 0px 0px 8px 8px;

          @include mappy-bp(med) {
            padding: 0 3.75rem;
          }

          @include mappy-bp(large) {
            padding: 0 1rem 1rem 1rem;
          }

          .block-load-more {
            display: flex;
            justify-content: center;
            flex-direction: column;

            .no-more-items {
              font-size: 16px;
              // margin-top: 1.6rem;

              @include mappy-bp(med) {
                font-size: 18px;
              }

              @include mappy-bp(large) {
                font-size: 20px;
              }
            }

            .button-load-more {
              padding: 0.6rem 2.5rem;
              border-radius: 9px;
              text-decoration: none;
              // background: #006633;
              background: #18573a;
              cursor: pointer;
              color: #fff;
              border: 2px solid #006633;
              margin: rem(32px) auto;

              // lastitem
              font-size: 1.125rem;

              @include mappy-bp(med) {
                font-size: 18px;
              }

              @include mappy-bp(wide) {
                font-size: 20px;
              }

              &:hover {
                color: #006633;
                background: #fff;
              }
            }
          }

          #listPanelBox {
            max-height: 45rem;
            overflow-y: scroll;
            margin: 0 rem(-6px);
            padding-top: rem(3px);
            padding-bottom: rem(6px);

            .listPanel {
              margin: 0 rem(6px);

              .listItem {
                box-shadow: rgba(60, 64, 67, 0.3) 0 1px 2px 0,
                  rgba(60, 64, 67, 0.15) 0 2px 6px 2px;
                border: 0 solid red;
                padding: 1rem 0.75rem;
                list-style-type: none;
                flex-direction: column;
                margin: 1.6rem 0 0 0;
                width: 100%;

                @include mappy-bp(375px) {
                  padding: 1rem;
                }

                &:first-child:not(.hidden) {
                  margin-top: 0;
                }

                .mobile-variation-link {
                  width: 100%;
                }

                &.seat-9891446 {
                  .address-string {
                    display: none;
                  }

                  .course-seat {
                    display: none !important;
                  }

                  .online {
                    display: block !important;
                  }

                  .not-online {
                    display: none !important;
                  }

                  .virtual-div {
                    .class-language {
                      .course-language {
                        margin-left: 1.5rem;

                        @include mappy-bp(widest) {
                          margin-left: 2.5rem;
                        }

                        .course-lang-image {
                          display: flex;
                          flex-direction: column-reverse;
                          margin: 0;
                        }

                        .course-lang-text {
                          display: flex;
                          flex-direction: column-reverse;
                          margin: 0 0 -2px 0.2rem;
                          height: auto;
                        }
                      }
                    }
                  }

                  .course-table-first-segment {
                    // justify-content: flex-end;
                    justify-content: space-between;

                    .course-start-date-time,
                    .course-end-date-time {
                      display: none !important;
                    }
                  }

                  .mobile-variation-link {
                    .mobile-online-card {
                      .mobile-row-wrapper {
                        justify-content: space-between;

                        .first-section {
                          width: fit-content;
                        }

                        .second-section {
                          flex-grow: 0;
                        }
                      }

                      &.mobile-second-row {
                        margin-top: 1.25rem;
                      }
                    }

                    .mobile-card-wrapper {
                      display: none !important;
                    }

                    // .mobile-card-date-wrapper,
                    // .mobile-card-time-wrapper {

                    // }

                    .mobile-card-duration-wrapper {
                      display: none !important;
                    }

                    .mobile-card-land-wrapper {
                      justify-content: space-between;
                      flex-grow: 1;

                      .mobile-card-available-wrapper {
                        display: none !important;
                      }
                      .mobile-card-price-wrapper {
                        display: block !important;
                        visibility: visible !important;
                      }
                    }
                  }
                }

                .mobile-card {
                  width: 100%;

                  @include mappy-bp(wide) {
                    display: none;
                  }

                  .mobile-row-wrapper {
                    width: 100%;
                    display: flex;

                    .first-section {
                      width: 31%;
                      // width: auto;
                      padding-right: 10px;
                      // word-break: break-word;

                      @include mappy-bp(375px) {
                        width: 30%;
                      }

                      @include mappy-bp(med) {
                        width: 40%;
                      }

                      @include mappy-bp(large) {
                        width: 30%;
                      }
                    }

                    .second-section {
                      flex-grow: 1;
                      display: flex;
                      justify-content: space-between;

                      .second-section-wrapper {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                      }
                    }
                  }

                  .mobile-card-wrapper {
                    display: flex;
                    justify-content: space-between;

                    &.mobile-second-row {
                      margin-top: 1.5rem;

                      // .first-section {
                      //   width: 31%;

                      //   .course-date {
                      //     padding-right: 5px;

                      //     @include mappy-bp(land) {
                      //       padding-right: 0;
                      //     }
                      //   }

                      // }

                      .second-section {
                        width: 50%;
                        .course-date {
                          // padding-right: 5px;
                          // padding-right: 1rem;
                          padding-right: rem(9px);

                          @include mappy-bp(375px) {
                            // max-width: 50%;
                            padding-right: 1rem;
                          }

                          // @include mappy-bp(375px) {

                          @include mappy-bp(land) {
                            padding-right: 0;
                          }

                          &.availability {
                            @include mappy-bp(375px) {
                              padding-right: 0;
                            }
                          }
                        }
                      }

                      .availability {
                        margin-right: 2rem;

                        @include mappy-bp(med) {
                          margin-right: 2rem;
                        }
                      }
                    }

                    @include mappy-bp(med) {
                      justify-content: space-between;
                    }

                    .mobile-card-date-wrapper {
                      width: fit-content;

                      @include mappy-bp(med) {
                        width: 30%;
                      }
                    }

                    .mobile-card-time-wrapper {
                      width: fit-content;
                      margin: 0 16px;

                      @include mappy-bp(med) {
                        width: 30%;
                      }
                    }

                    .mobile-card-price-wrapper {
                      width: fit-content;

                      @include mappy-bp(med) {
                        width: 30%;
                        text-align: right;
                      }
                    }
                  }

                  .mobile-card-second-wrapper {
                    display: flex;
                    margin-top: 1.5rem;

                    @include mappy-bp(med) {
                      justify-content: space-between;
                    }

                    .mobile-card-duration-wrapper {
                      // width:fit-content;
                      width: 50%;

                      @include mappy-bp(med) {
                        width: fit-content;
                      }
                    }

                    .mobile-card-land-wrapper {
                      // margin: 0 16px;
                      margin: 0;
                      display: flex;
                      width: 100%;
                      justify-content: space-between;

                      @include mappy-bp(med) {
                        width: 66%;
                        margin: 0;
                      }

                      .course-date {
                        padding-right: 10px;

                        @include mappy-bp(med) {
                          padding-right: 0;
                          // margin-left: .5rem;
                        }
                      }
                    }

                    .mobile-card-available-wrapper {
                      display: flex;
                      flex-direction: column;

                      @include mappy-bp(med) {
                        display: flex;
                        flex-direction: column;
                      }

                      @include mappy-bp(large) {
                        display: block;
                      }

                      .course-value {
                        display: flex;
                        justify-content: flex-end;

                        @include mappy-bp(med) {
                          text-align: right;
                          display: block;
                        }
                      }
                    }
                  }

                  .course-value {
                    padding: 0.75rem 0 0 0;
                    width: 100%;
                  }

                  .course-text {
                    width: 100%;
                  }

                  .course-price {
                    span {
                      padding: 0;
                    }
                  }
                }

                .desktop-card {
                  display: none;
                  width: 100%;

                  @include mappy-bp(wide) {
                    display: block;
                  }
                }

                .virtual-div {
                  margin: 0.5rem 0.5rem 0 0;
                  display: none;

                  @include mappy-bp(wide) {
                    display: block;
                  }

                  .class-language {
                    height: fit-content;
                    margin: auto 0;
                    display: flex;
                    justify-content: space-between;

                    .course-language {
                      display: flex;

                      .course-lang-image {
                        margin: auto 0;
                        padding-bottom: 3px;

                        img {
                          // width: 40px;
                          width: auto;

                          @include mappy-bp(wide) {
                            // width: 20px;
                          }
                        }
                      }

                      .course-lang-text {
                        // margin-left: 3px;
                        height: fit-content;
                        margin: auto 3px;
                      }
                    }

                    .course-seat {
                      margin-left: 1.5rem;
                      display: flex;

                      .course-seat-image {
                        height: fit-content;
                        margin: auto 0;

                        img {
                          // width: 40px;
                          width: auto;

                          @include mappy-bp(wide) {
                            // width: 20px;
                          }
                        }
                      }

                      .course-seat-text {
                        // margin-left: 3px;
                        height: fit-content;
                        margin: auto 3px;
                      }
                    }
                  }

                  .date-string {
                    padding-top: 1rem;
                  }
                }

                .seatsAvailableRegister {
                  display: flex;
                  // align-items: flex-end;
                  justify-content: center;
                  margin: auto 0 -5px;

                  .course-register {
                    .online {
                      display: none;
                    }

                    // a {
                    //   height: rem(36px);
                    //   border-radius: rem(18px);
                    //   border: 2px solid transparent;
                    //   background: #18573a;
                    //   color: #fff;
                    //   display: inline-flex;
                    //   align-items: center;
                    //   font-weight: 500;
                    //   font-size: 1rem;

                    //   &:hover {
                    //     background: #fff;
                    //     color: #18573a;
                    //     border-color: #18573a;
                    //   }
                    // }
                  }
                }

                .course-value {
                  color: #000;
                  font-weight: 600;
                  padding: 0.4rem 0 0 0;
                }

                .variation-addr {
                  text-decoration: none;
                  margin: 0.5rem 0.5rem 0 0;
                }

                .course-text {
                  color: #7b7b7b;
                  font-size: rem(13px);
                  font-weight: 600;
                }

                .course-price {
                  // width: 100%;
                  // min-width: 20%;
                  // width: 25%;

                  // span {
                  font-size: 20px;
                  color: #18573a;
                  background: none;
                  font-weight: bold;
                  margin-top: -0.5rem;
                  width: fit-content;
                  // padding: .5rem 0;

                  // }
                }
              }

              .load-more-li {
                list-style-type: none;
                // margin-top: 3rem;
                display: flex;
                justify-content: center;
                // margin-bottom: 2rem;

                @include mappy-bp(large) {
                  // margin-bottom: 0;
                }

                margin: 0;

                .no-more-items {
                  font-size: 16px;

                  @include mappy-bp(med) {
                    font-size: 18px;
                  }

                  @include mappy-bp(large) {
                    font-size: 20px;
                  }
                }

                .button-load-more {
                  padding: 0.6rem 2.5rem;
                  border-radius: 9px;
                  text-decoration: none;
                  // background: #006633;
                  background: #18573a;
                  cursor: pointer;
                  color: #fff;
                  border: 2px solid #006633;

                  // lastitem
                  font-size: 1rem;

                  @include mappy-bp(med) {
                    font-size: 18px;
                  }

                  @include mappy-bp(wide) {
                    font-size: 20px;
                  }

                  &:hover {
                    color: #006633;
                    background: #fff;
                  }
                }
              }
            }
          }

          .map-calendar {
            margin-bottom: rem(16px);
            width: 100%;

            &[data-delivery="9891446"] {
              display: none;
            }

            &[data-delivery="9892791"] {
              .atlas-map {
                display: none;
              }
              .courseCalendar {
                display: block;
              }
            }

            &[data-delivery="9891436"],
            &[data-delivery="9891441"] {
              .atlas-map {
                display: block;
              }
              .courseCalendar {
                display: none;
              }
            }
          }
        }

        .courseCalendar {
          visibility: visible;
          -webkit-box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
          box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
          margin-top: 1rem;
          border-radius: 10px;
          padding: 0.75rem 0.75rem 0.75rem 0.75rem;

          // @include mappy-bp(med) {
          //   padding: 2.75rem 3.75rem;
          // }

          table {
            width: 100%;
          }

          .fc-scrollgrid-section-body {
            td {
              border: 0;
              background: none;
            }
          }

          table.fc-col-header {
            margin-bottom: 0;
            width: 100% !important;
          }

          .fc-event {
            border-radius: 5px;
            opacity: 1;
          }

          .fc-theme-standard th {
            border: 0;
          }

          .fc-scrollgrid {
            margin-bottom: 0;
            margin-top: 0;
            border: 0;

            .fc-scrollgrid-section {
              tr {
                border: 0;
              }
              th {
                border: 0;
                background: none;

                a {
                  text-decoration: none;
                  font-size: 1rem;
                }
              }
            }
          }
        }

        .fc {
          .fc-daygrid-day-frame {
            height: auto;
            min-height: fit-content;
            background: #80b399;
            border-radius: 5px;
            padding: 5px 0;
          }

          .fc-daygrid-day-top {
            justify-content: center;
            margin: 0 auto;
            display: block;
            opacity: 2;
            text-align: center;
          }

          .fc-daygrid-day-number {
            text-decoration: none;
            font-size: 1rem;
            padding: 0.25rem;
            color: #fff;
            width: 30px;
            height: 30px;
            display: block;
            text-align: center;
            margin: 0 auto;
          }

          .fc-scrollgrid-sync-table {
            width: 100% !important;
            tr {
              .fc-day.fc-day-today {
                .fc-daygrid-day-frame {
                  background: #1a7547;
                  padding: 5px 0;
                  .fc-daygrid-day-top {
                    display: inherit;
                    .fc-daygrid-day-number {
                      font-size: 1rem;
                      display: inline-block;
                      width: 30px;
                      height: 30px;
                      padding: 2px;
                      background-color: #fff;
                      border-radius: 50%;
                      color: #1a7547;
                    }
                  }
                }
              }
            }
          }

          td.fc-day {
            padding: 0.25rem;

            // padding: .4375rem;
          }

          .fc-daygrid-body-natural {
            width: 100% !important;

            .fc-daygrid-day-events {
              display: none;
            }
          }

          .fc-toolbar-title {
            font-size: 1.25rem;
          }

          .fc-button {
            .fc-icon {
              font-size: 1.5rem;
              vertical-align: text-bottom;
            }
          }

          .fc-button-primary {
            background-color: transparent !important;
            border-color: transparent !important;
            color: #000 !important;
            padding: 0 !important;
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
          }

          .fc-toolbar.fc-header-toolbar {
            justify-content: center;
            width: fit-content;
            margin: 0 auto;

            .fc-toolbar-chunk {
              width: fit-content;
              display: inline-block;
              padding-left: 0.5rem;
            }
          }
        }
      }
    }

    .course-right-panel {
      margin-top: 2.75rem;
      display: none;

      @include mappy-bp(med) {
        margin-bottom: 5rem;
      }

      @include mappy-bp(larger) {
        display: block;
        width: 50%;
        padding-left: 1.5rem;
      }

      .no-variation-details {
        @include mappy-bp(med) {
          font-size: 18px;
        }
      }

      #courses {
        padding: 1rem 1.5rem;
        border-radius: 5px;
        box-shadow: rgba(149, 157, 165, 0.2) 0 -9px 15px;
        border: 0;

        .course-page--should-know-section {
          padding: 0;
        }

        .field-course-delivery-method {
          .availability-province {
            display: inline-flex;
            border: 2px solid #f6f6f6;
            padding: 5px 5px 5px 5px !important;
            margin: 1%;
            border-radius: 3px;
            font-weight: 400;
          }
        }
      }

      .course-private {
        background: #f5f5f7;
        padding: 0.5rem 0;
        text-align: center;
        /* border: 1px solid red; */
        border-radius: 0 0 10px 10px;
        font-size: 1rem;

        a {
          color: #18573a;
        }

        @include mappy-bp(larger) {
          font-size: 1.125rem;
        }
      }
    }
  }
}

.deliveryTitle {
  font-weight: 500;
  margin: auto 0;
  font-size: 1.2rem;

  @include mappy-bp(wide) {
    display: inline-block;
  }
}

div.courseLeftPanelDeliveryMethod {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 0 1rem 0;
  background-color: #f1f1f1;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
  border-top: 1px solid #d7d7d7;

  @include mappy-bp(large) {
    padding: 0 3.75rem;
    margin-bottom: 1rem;
  }

  @include mappy-bp(large) {
    padding: 1rem;
    margin-bottom: 0;
  }

  .deliveryTitle {
    text-align: center;
    flex: 0 0 auto;
    margin-right: 1rem;

    @include mappy-bp(large) {
      text-align: left;
      font-weight: 600;
    }
  }

  .delivery-method-centre-wrapper {
    display: flex;
    flex: 0 0 auto;
    justify-content: center;

    @include mappy-bp(large) {
      justify-content: flex-start;
    }

    .delivery-method-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      & > span {
        margin: 5px;
      }

      a.use-ajax.ajax-link.js.delivery-method-link {
        padding: 0 1.2rem;
        height: rem(38px);
        display: inline-flex;
        align-items: center;
        font-size: rem(16px);
        border-radius: rem(19px);
        text-decoration: none;
        color: #18573a;
        background: #fff;
        cursor: pointer;
        transition: 0.1s all ease-in;

        &:not(.del-0) {
          padding: 0 1.2rem 0 2.5rem;
          background-position: 17px 9px;
          background-repeat: no-repeat;
          background-size: 1.2rem;
          display: flex;
          border: 2px solid #e3e3e3;
        }

        &.del-9892791 {
          background-image: url("/themes/custom/sja/src/000_assets/icons/virtual-gray.svg");

          &.active {
            background-image: url("/themes/custom/sja/src/000_assets/icons/virtual-white.svg");
          }
        }

        &.del-9891441 {
          background-image: url("/themes/custom/sja/src/000_assets/icons/blended-gray.svg");

          &.active {
            background-image: url("/themes/custom/sja/src/000_assets/icons/blended-white.svg");
          }
        }

        &.del-9891436 {
          background-image: url("/themes/custom/sja/src/000_assets/icons/in-class-gray.svg");

          &.active {
            background-image: url("/themes/custom/sja/src/000_assets/icons/in-class-white.svg");
          }
        }

        &.del-9891446 {
          background-image: url("/themes/custom/sja/src/000_assets/icons/online-gray.svg");

          &.active {
            background-image: url("/themes/custom/sja/src/000_assets/icons/online-white.svg");
          }
        }
      }

      a.use-ajax.ajax-link.js.delivery-method-link.active {
        color: #fff;
        background-color: #18573a;
        font-weight: 600;
        border-color: #18573a;
      }
    }
  }
}

.map-switch-block {
  padding: 0 1rem 1rem 1rem;
  display: flex;
  justify-content: center;
  background-color: #f1f1f1;

  @include mappy-bp(med) {
    padding: 1rem 3.75rem;
  }

  @include mappy-bp(large) {
    justify-content: auto;
  }

  @include mappy-bp(large) {
    padding: 1rem;
    display: flex;
  }

  .deliveryTitle {
    display: none;

    @include mappy-bp(med) {
      display: inline-block;
    }
  }
}

.switch-map-wrapper {
  display: none;
  margin-left: 1rem;

  @include mappy-bp(large) {
    display: flex;
    align-items: center;
  }

  .map-switch-text-type {
    display: none;
  }

  &[data-delivery="9891446"] {
    visibility: hidden;
    display: none;
  }

  &[data-delivery="9891436"],
  &[data-delivery="9891441"] {
    .map-switch-text-type:not(.calendar) {
      display: block;
    }

    .map-switch-text-type.calendar {
      display: none;
    }
  }

  &[data-delivery="9892791"] {
    .map-switch-text-type:not(.calendar) {
      display: none;
    }

    .map-switch-text-type.calendar {
      display: block;
    }
  }

  .map-switch {
    display: flex;
    align-items: center;
  }

  .switch {
    height: 25px;
    width: 50px;

    .slider.round {
      height: 20px;
      width: 90%;

      &::before {
        height: 22px;
        width: 22px;
        left: -1px;
        bottom: -1px;
        border: 1px solid #bcbebe;
      }
    }
  }

  #mapSwitchText,
  .map-switch-text-type {
    margin: auto 0 auto 3px;
    font-size: 14px;
  }
}

// .ui-tabs .ui-tabs-nav .ui-tabs-anchor {
//   padding: 0;
//   color: #666666;
// }

.course-page--details {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-evenly;
  justify-content: flex-start;
  padding-bottom: rem(16px);

  & > div:nth-child(odd) {
    @include mappy-bp(med) {
      margin: 0 1rem 2rem 0;
    }
  }

  & > div:nth-child(even) {
    @include mappy-bp(med) {
      margin: 0 0 2rem 1rem;
    }
  }

  & > div {
    width: 100%;
    // font-size: 18px;
    font-size: 1rem;

    @include mappy-bp(med) {
      width: 45%;
      margin: 0 1rem 2rem 0;
    }

    & > h5 {
      font-size: 1rem;
      font-weight: bold;
      color: #18573a;
    }
  }
}

.desktop-courses {
  display: none;

  @include mappy-bp(larger) {
    display: block;
  }
}

.course-accordion {
  display: block;
  background-color: #ffffff;

  @include mappy-bp(larger) {
    display: none;
  }
}

.information-text-wrapper {
  background-color: #edf2f1;
  color: #18573a;
  text-align: left;
  font-size: 16px;
  padding: 1rem 1rem;

  @include mappy-bp(med) {
    padding: 1rem 3.75rem;
  }

  .information-text {
    width: fit-content;
    margin: 0 auto;
    padding: 0 1.5rem 0 rem(22px);
    background: url("/themes/custom/sja/src/000_assets/icons/information-green.svg");
    background-repeat: no-repeat;
    background-position: left 5px;
    background-size: rem(18px) auto;
  }
}

.page-node-type-course-page {
  #related-courses-wrapper {
    margin: 2.5rem auto;
    padding: 0 1rem;

    @include mappy-bp(med) {
      padding: 0 3.75rem;
    }

    @include mappy-bp(widest) {
      max-width: $content-max-width;
      padding: 0;
      overflow-x: visible;
    }

    .related-courses-title {
      h4 {
        font-weight: 600;
        margin-bottom: 1.5rem;
      }
    }

    .related-courses-content {
      display: flex;
      flex-direction: column;

      @include mappy-bp(large) {
        flex-direction: row;
      }

      .course-card-wrapper {
        // box-shadow: rgba(149, 157, 165, .2) 0 8px 24px;
        box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
        padding: 1.5rem 1.5rem;
        /* border: 1px solid red; */
        flex: 1;
        display: flex;
        flex-direction: column;

        &:not(:first-child) {
          margin-top: 2rem;

          @include mappy-bp(large) {
            margin-top: 0;
          }
        }

        @include mappy-bp(large) {
          margin: 0 0.5rem;
        }

        @include mappy-bp(wide) {
          margin: 0 1.5rem;
        }

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        .course-card-title {
          font-weight: 500;
          font-size: 20px;
          padding: 0.5rem 0;
          border-bottom: 2px solid #006633;

          @include mappy-bp(larger) {
            padding: 0.5rem 1rem;
          }
        }

        .course-card-delivery {
          display: flex;
          justify-content: flex-start;
          padding: 0.5rem 0;
          font-size: 16px;

          @include mappy-bp(larger) {
            justify-content: center;
          }

          .course-card-delivery-content {
            margin-left: 0.5rem;
          }
        }

        .course-card-content {
          font-size: 17px;
          color: #234135;
          margin-bottom: 1rem;
          padding: 0.5rem 0.5rem 0 0.5rem;
        }

        .course-card-link {
          border-radius: 9px;
          text-decoration: none;
          // background: #006633;
          background: #18573a;
          cursor: pointer;
          color: #fff;
          width: fit-content;
          margin: auto auto 0 auto;

          // &:hover {
          //   background: #fff;
          //   border: 2px solid #006633;
          // }

          a {
            color: #fff;
            text-decoration: none;
            padding: 0.6rem 2.5rem;
            border-radius: 9px;
            border: 2px solid #006633;
            display: flex;
            // lastitem
            font-size: 1.125rem;

            @include mappy-bp(med) {
              font-size: 18px;
            }

            @include mappy-bp(wide) {
              font-size: 20px;
            }

            &:hover {
              color: #006633;
              border: 2px solid #006633;
              background: #fff;
            }
          }
        }
      }
    }
  }
}

.online-tooltip,
.virtual-tooltip {
  width: 1.3rem;
  // display: flex;
  margin-right: 0.5rem;
  display: none;
  border: 0;
  outline: none;

  &:focus,
  &:focus-visible {
    outline: none;
  }
}

.tooltip {
  z-index: 999;
  max-width: 12rem;
  padding: 1.5rem;
  background: #f7f7f7;
  border-color: #17573a;
  font-size: 16px;

  @include mappy-bp(large) {
    max-width: 20;
  }

  .short-description-tooltip {
    max-width: 10;
  }
}

.ui-tabs .ui-tabs-panel {
  padding: 1em 0;
}

#parent-loader {
  background: #000;
  opacity: 0.5;
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  display: none;
}

#preloader {
  left: 43vw;
  top: 48%;
  position: fixed;

  @include mappy-bp(med) {
    left: 47 vw;
  }

  @include mappy-bp(large) {
    left: 48vw;
  }

  @include mappy-bp(widest) {
    left: 48vw;
  }
}

.top-right.subcontrol-container {
  z-index: 0;
}

.field-azure-map-block {
  #listPanelBox {
    .listPanel {
      .listItem {
        &:nth-last-child(-n + 1) {
          // margin-bottom: 3rem;

          margin-bottom: 0 !important;

          @include mappy-bp(med) {
            // margin-bottom: 4rem;
            margin-bottom: 0 !important;
          }
        }
      }
    }
  }
}

.litepicker {
  font-size: 0.875rem;
  font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
  z-index: 99 !important;
  margin-top: rem(5px);

  &.mobilefriendly[data-plugins*="mobilefriendly"] {
    top: auto !important;
    bottom: 1%;
    transform: translate(-50%, 0%) !important;
  }

  &::before {
    content: "";
    display: none;
    width: 0;
    height: 0;
    border-left: 16px solid #cfddd7;
    border-top: 8px solid transparent;
    border-bottom: 9px solid transparent;
    transform: rotate(270deg);
    align-self: center;
    /* position: absolute; */
    /* bottom: -17px; */
    /* top: 0; */
    /* border: 10px solid red; */
    /* left: 50%; */
    // left: 47%;
    position: relative;
    /* margin-top: 1%; */

    @include mappy-bp(med) {
      display: flex;
      left: 73%;
    }

    @include mappy-bp(large) {
      left: 47%;
    }
  }

  @include mappy-bp(med) {
  }

  .container__footer {
    display: none !important;
  }

  .container__days {
    .day-item {
      font-size: rem(16px);

      @include mappy-bp(med) {
        font-size: rem(14px);
      }

      @include mappy-bp(large) {
        font-size: rem(13px);
      }

      &.is-start-date {
        background-image: url("/themes/custom/sja/src/000_assets/bg/filled-green-circle.svg") !important;
        background-position: center !important;
        border-top-left-radius: 50% !important;
        border-bottom-left-radius: 50% !important;
        background-size: contain !important;
        background-repeat: no-repeat !important;
      }

      &.is-end-date {
        background-image: url("/themes/custom/sja/src/000_assets/bg/filled-green-circle.svg") !important;
        background-position: center !important;
        border-top-right-radius: 50% !important;
        border-bottom-right-radius: 50% !important;
        background-size: contain !important;
        background-repeat: no-repeat !important;
      }
    }
  }

  .litepicker-title {
    text-align: center;
    background-color: #cfddd7;
    // padding: .875rem 1rem;
    padding: 0.875rem 7px;
    // box-shadow: 0 1px 6px rgba(0,0,0, .35), 0 -1px 0 #fff;
    box-shadow: rgba(255, 255, 255, 0.1) 0 1px 1px 0 inset,
      rgba(50, 50, 93, 0.25) 0 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0 30px 60px -3px;
    // border-radius: 5px 5px 0 0;
    display: flex;
    justify-content: space-between;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-top: -1px;

    @include mappy-bp(375px) {
      padding: 0.875rem 0.75rem;
    }

    @include mappy-bp(med) {
      padding: 0.875rem 1rem;
    }

    .title-cancel {
      margin: auto 0;

      .button-cancel {
        margin: auto 0;
        width: 1.6rem;
        cursor: pointer;
      }
    }

    .date-title {
      font-weight: 700;
      font-size: 24px;
    }

    .button-apply {
      margin: auto 0;
      padding: 2px 7px;
      background: #fff;
      border-radius: 2px;
      box-shadow: rgba(0, 0, 0, 0.1) 0 4px 6px -1px,
        rgba(0, 0, 0, 0.06) 0 2px 4px -1px;
      font-weight: 600;
      cursor: pointer;
    }
  }

  .container__months {
    border-radius: 0 0 5px 5px !important;
    // box-shadow: 0 -6px 0 #fff, 0 1px 6px rgba(0,0,0, .35);
    // box-shadow: 0 -3px 0 #fff, 0 1px 6px rgba(0,0,0, .35);
    box-shadow: rgba(255, 255, 255, 0.1) 0 1px 1px 0 inset,
      rgba(50, 50, 93, 0.25) 0 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0 30px 60px -30px !important;

    .month-item-header {
      .month-item-name,
      .month-item-year {
        font-size: rem(15px);
        // color: #A0A0A0;
        color: #696969;
        font-weight: 600;
      }
    }

    .month-item-weekdays-row {
      color: #a0a0a0;

      & > div {
        font-size: 14px !important;
      }
    }
  }

  .container__days {
    & > div {
      padding: 7px 0 !important;
    }

    .day-item {
      margin: 0.25rem 0;
      cursor: pointer !important;

      &.is-start-date,
      &.is-end-date {
        // border-radius: 50% 0 0 50%;
        // background-color: cornsilk;
        // background-image: url(/themes/custom/sja/src/000_assets/icons/facebook-with-circle.svg);
        // background-position: -1px -1px;
      }

      &.is-in-range {
        background-color: cornsilk;
      }
    }
  }
}

.map-opacity {
  opacity: 0;
  position: absolute;
  left: -100%;
}

.online {
  display: none;
}

:root {
  --litepicker-button-next-month-color: #18573a !important;
  --litepicker-is-in-range-color: #cfddd7 !important;
  --litepicker-is-start-color-bg: #cfddd7 !important;
  --litepicker-is-end-color-bg: #cfddd7 !important;
  --litepicker-day-color-hover: #18573a !important;
  --litepicker-button-next-month-color-hover: #18573a !important;
  --litepicker-button-prev-month-color-hover: #18573a !important;
  --litepicker-button-prev-month-color: #18573a !important;
  --litepicker-month-weekday-color: #a0a0a0 !important;
  --litepicker-month-header-color: #a0a0a0 !important;
}

.add-to-cart-button {
  width: rem(38px);
  height: rem(38px);
  cursor: pointer;
  background-color: transparent;
  border: 0;
  background-image: url("../../src/000_assets/icons/cart/quick-add-to-cart-white.svg");
  background-size: rem(38px) rem(38px);
  background-repeat: no-repeat;
  margin-left: rem(8px);

  &::after {
    content: "";
    background-image: url("../../src/000_assets/icons/cart/quick-add-to-cart-white.svg");
  }

  &:hover,
  &:focus {
    background-image: url("../../src/000_assets/icons/cart/quick-add-to-cart-green.svg");
  }
}
