.page-node-type-page {

  .prefix {
    .region-prefix {
      margin: 0;
    }
  }

  .region-content > .block--page-title {
    margin-top: 0;
    margin-bottom: 0;
    .block-content {
      h1 {
        margin: 0 auto 40px;
      }
    }
  }

  .region-banner-image {
    margin-bottom: 0;
    position: relative;
    @include mappy-bp(large) {
      margin-bottom: 0;
    }

    picture {
      width: 100%;
      max-width: 100%;
      height: auto;

      img {
        width: 100%;
        max-width: 100%;
        height: auto;
      }
    }

    .field--name-field-banner-text {
      font-size: 24px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.42;
      letter-spacing: .6px;
      text-align: left;
      color: #171918;
      padding: rem(10px) rem(14px);
      box-shadow: 0 0 24px 0 rgba(0, 0, 0, .16);
      background-color: #ffffff;
      position: absolute;
      top: 90%;
      left: 50%;
      transform: translateX(-50%);
      width: 95%;
      margin: 0 auto;
      @include mappy-bp(land) {
        top: 85%;
        padding: rem(20px) rem(40px);
        font-size: 36px;
        font-weight: 900;
        line-height: 1.14;
        letter-spacing: .9px;
        text-align: left;
        transform: translateX(-50%);
        margin: 0 auto;
      }
      @include mappy-bp(med) {
        width: 664px;
        margin: 0 auto;
      }
      @include mappy-bp(large) {
        font-size: 36px;
        font-weight: 900;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.14;
        letter-spacing: .9px;
        text-align: left;
        color: #000000;
        box-shadow: 0 0 24px 0 rgba(0, 0, 0, .16);
        background-color: #ffffff;
        padding: rem(20px) rem(40px);
        top: 80%;
        position: absolute;
        // transform: translateX(0);
        transform: translateY(-80%);
        left: rem(80px);
        width: auto;
      }
      @include mappy-bp(2500px) {
        top: 87%;
      }
    }
  }

  .region-content {
    max-width: 100%;
    margin: 0 auto;
    // padding: 0 16px;
  }

  .block--page-title {
    margin: 0 0 26px;

    @include mappy-bp(med) {
      margin: 0 auto 40px;
    }

    span {
      @extend %h2;
    }
  }
  .nav--primary-local-tasks {
    padding: 0 1rem;

    @include mappy-bp(med) {
      max-width: $content-max-width;
      margin: 40px auto;
      padding: 0;
    }
  }

  .field_subtitle {
    width: 100%;
    font-size: 20px;
    line-height: 1.5;
    text-align: left;
    color: #000000;
  }
}
