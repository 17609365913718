.field--name-field-block-title {
  @extend %full-width-green-heading;
  overflow: hidden;
  padding: rem(20px) rem(16px);
  font-size: 14px;
  @include mappy-bp(med) {
    padding: rem(20px) 5.125rem;
  }
  @include mappy-bp(wide) {
    margin-bottom: 75px;
  }
  @include mappy-bp(widest) {
    width: 1540px;
    margin: 0 auto;
  }
}

.picture_right {
  .two_col_container {
    @extend %cf;
    max-width: 100%;
    margin-bottom: 50px;
    @include mappy-bp(larger) {
      padding: rem(20px) 5.125rem;

      .two_col_image {
        width: 100%;
        height: auto;
      }
    }
    @include mappy-bp(wide) {
      margin-bottom: 100px;

      .two_col_image {
        float: right;
        width: 50% !important;
        margin: 0 auto;
      }

      .two_col_text_container {
        float: left;
        width: 45%;
      }
    }
    @include mappy-bp(widest) {
      width: 1540px;
      margin: 0 auto 90px;
    }
  }
}

.picture_left {
  .two_col_container {
    @extend %cf;
    max-width: 100%;
    margin-bottom: 50px;
    @include mappy-bp(larger) {
      padding: rem(20px) 5.125rem;

      .two_col_image {
        width: 100%;
        height: auto;
      }
    }
    @include mappy-bp(wide) {
      margin-bottom: 100px;

      .two_col_image {
        float: left;
        width: 50% !important;
        margin: 0 auto;
      }

      .two_col_text_container {
        float: right;
        width: 45%;
      }
    }
    @include mappy-bp(widest) {
      width: 1540px;
      margin: 0 auto 90px;
    }
  }
}

.two_col_title {
  @extend %h5;
  padding: 32px 12px 10px 16px;
  font-size: 20px;
  text-transform: capitalize;
  @include mappy-bp(med) {
    padding: rem(20px) 5.125rem;
  }
  @include mappy-bp(larger) {
    padding: rem(20px) 0;
  }
  @include mappy-bp(wide) {
    font-size: 32px;
  }
  @include mappy-bp(mega) {
    margin-top: 25px;
  }

}

.two_col_summary {
  padding: 2px 16px 22px;
  @include mappy-bp(med) {
    padding: rem(20px) 5.125rem;
  }
  @include mappy-bp(larger) {
    padding: rem(20px) 0;
  }
}

.two_col_link {
  padding: 2px 16px 40px 16px;
@include mappy-bp(med) {
  padding: rem(20px) 5.125rem;
}
@include mappy-bp(larger) {
  padding: rem(20px) 0;
}
  a {
    @extend %slide-in-green;
  }
}

img {
  width: 100%;
  @include mappy-bp(larger) {
  width: auto;
}
}
