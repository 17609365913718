.node__content {
  .field--name-field-reference {
    .field__item {
      .container--quartered-stats {
        @extend %block-container;
        @extend %cf;
        @include mappy-bp (widest) {
          max-width: 1540px;
          margin: 0 auto;
        }

        .field--name-field-title {
          display: block;
          font-size: 14px;
          font-weight: 900;
          line-height: 1.36;
          letter-spacing: rem(1.74px);
          color: $color__primary;
          position: relative;
          text-transform: uppercase;
          overflow: hidden;
          @include mappy-bp (large) {
            font-size: 16px;
            letter-spacing: rem(1.98px);
          }

          &::after {
            content: ' ';
            display: inline-block;
            width: 100%;
            margin-left: 12px;
            transform: translateY(-50%);
            top: 50%;
            position: absolute;
            background-color: #787e7c;
            height: 2px;
            @include mappy-bp(large) {
              display: inline-block;
            }
          }
        }

        .field--name-field-stat-content {
          padding: 35px 0;
          float: left;
          width: 100%;
          @include mappy-bp (med) {
            padding: 95px 0;
          }
          @include mappy-bp (large) {
            padding: 120px 0;
            padding-left: rem(70px);
          }

          .paragraph--type-stats-with-textarea:nth-child(1),
          .paragraph--type-stats-with-textarea:nth-child(3) {
            margin-left: 0;
          }

          .paragraph--type-stats-with-textarea:nth-child(1) {
            padding-top: 0;
          }

          .paragraph--type-stats-with-textarea:nth-child(2) {
            @include mappy-bp (med) {
              padding-top: 0;
            }
          }

          .paragraph--type-stats-with-textarea:nth-child(3),
          .paragraph--type-stats-with-textarea:nth-child(4) {
            @include mappy-bp (med) {
              padding-top: 120px;
            }
          }

          .paragraph--type-stats-with-textarea {
            width: 100%;
            padding-top: 56px;
            @include mappy-bp(med) {
              @include gallery (6 of 12 .6);
              max-width: 465px;
            }
            @include mappy-bp(wide) {
              @include gallery (6 of 12 .1);
            }
            @include mappy-bp(mega) {
              max-width: 500px;
            }

            .textarea {
              padding-top: 30px;
              font-size: 14px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: 1.36;
              // letter-spacing: 1.05px;
              text-align: left;
              color: #000000;
              @include mappy-bp (land) {
                max-width: rem(440px);
                margin: 0 auto;
              }
              @include mappy-bp (med) {
                max-width: none;
              }

              @include mappy-bp (large) {
                padding-top: 61px;
                font-size: 20px;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: left;
                color: #000000;
              }
            }
          }
        }
      }
    }
  }
}
