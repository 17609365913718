.menu--region-menu {
  position: relative;

  &.contextual-region {
    position: initial !important;
  }

  @include mappy-bp(med) {

    &.contextual-region {
      position: relative !important;
    }
  }

  h2 {
    font-size: 12px;
    text-transform: uppercase;
    text-decoration: none;
    color: black;
    letter-spacing: 1.74px;
    line-height: 1.2;
    margin: 0;
    cursor: pointer;
    background-color: #F5F5F5;
    padding: 0 .5rem;
    height: rem(28px);
    display: inline-flex;
    align-items: center;
    border-radius: 2px;
    font-weight: 600;
    font-family: lato;
    transition: box-shadow .3s ease; /* Smooth transition for the shadow */
    width: max-content;

    @include mappy-bp(wide) {
      font-size: 14px;
      position: relative;
    }

    &::before {
      display: inline-block;
      width: 12px;
      height: 16px;
      padding-left: 0;
      margin-right: 7px;
      content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="11.278" height="16.563" viewBox="0 0 11.278 16.563"%3E%3Cpath id="maps-location-pin-3" d="M23.778,10.012a5.745,5.745,0,0,0-5.639-5.846A5.745,5.745,0,0,0,12.5,10.012c0,3.9,5.639,10.717,5.639,10.717S23.778,13.91,23.778,10.012Zm-7.989,0a2.351,2.351,0,1,1,2.35,2.436A2.394,2.394,0,0,1,15.789,10.012Z" transform="translate(-12.5 -4.167)" fill="%23063" /%3E%3C/svg%3E');
    }

    &::after {
      display: inline-block;
      width: 12px;
      height: 16px;
      padding-left: 8px;
      padding-right: 12px;
      content: url('../../src/000_assets/icons/more-n.svg');
      position: relative;
    }

    &:hover,
    &:focus  {
      box-shadow: 0 0 1.5px rgba(0, 0, 0, .2); /* Slightly darker shadow on hover */
    }


  }

  &.open {

    h2::after {
      display: inline-block;
      width: 28px;
      height: 15px;
      padding-right: 8px;
      padding-left: 0;
      float: right;
      transform: rotate(180deg);

      @include mappy-bp(wide) {
        width: 28px;
        height: 17px;
      }
    }
  }
}

ul.nav--region-menu {
  text-align: left;
  margin: 0;
  list-style: none;
  position: absolute;
  z-index: 9;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, .16);
  background-color: #ffffff;
  overflow-y: scroll;
  height: 0;
  transition: .3s all;
  top: 45px;
  min-width: 260px;
  left: 0;
  padding: 0 20px;

  li {
    padding: .5rem 0 .5rem .5rem;
    @extend %cf;
    float: left;
    width: 100%;
    @include mappy-bp(med) {
      padding: .5rem .25rem;
    }
    a {
      font-size: 14px;
      float: left;
      width: 100%;
      font-weight: normal;
      color: #000000;
      text-decoration: none;
      &:hover, &:focus {
        color: $color__primary;
      }
    }
    &.geo-active {
      a {
        font-weight: bold;
        color: $color__primary;
        pointer-events: none;
        cursor: default;
      }
    }
  }
}

.open ul.nav--region-menu {
  display: block;
  height: auto;
  padding: 20px;
}
