
.page-header {
  background-color: white;
  max-width: rem(1600px);
  padding: rem(10px) 1.75rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;


  @include mappy-bp(med) {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  @include mappy-bp(wide) {
    flex-direction: row;
    min-height: 6.6rem;
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }
}

.page-header__second {
  flex: 1 1 rem(50px);
  order: 1;
  display: flex;
  justify-content: space-between;

  @include mappy-bp(wide) {
    flex: 1 1 100%;
    order: 2;
    display: block;
  }
}

.region-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;
  // transition: .5s all ease-in;
  order: 2;

  @include mappy-bp(wide) {
    margin-top: 0;
    flex-direction: row;
    order: 1;
    flex: 0 0 auto;
    align-items: flex-start
  }

  .l-constrain {
    position: relative;
  }
}

.region-header-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: rem(12px);
}

.region-header-bottom {
  display: flex;
}



body.gin--vertical-toolbar header[role='banner'],
body.gin--horizontal-toolbar header[role='banner'],
body.gin--classic-toolbar header[role='banner'] {
  top: var(--gin-toolbar-y-offset) !important;
}

body.gin--horizontal-toolbar header[role='banner'] {
  left: var(--drupal-displace-offset-left, 0);

  @include mappy-bp(wide) {
    top: var(--drupal-displace-offset-top, 113px) !important;
  }
}


header[role='banner'] {
  position: fixed;
  z-index: 200;
  top: 0;
  right: 0;
  left: 0;
  background: #FFF;

  * {
    .contextual-region {
      position: inherit;
    }
  }

  .main-nav > .navigation {
    position: relative;
    background-color: white;

    @include mappy-bp(wide) {
      padding: 68px 0;
    }

    .region-navigation {
      max-width: $content-max-width;
      margin: 0 auto;
      padding: 0 1rem;

      @include mappy-bp(med) {
        padding: 0 3.75rem;
      }

      .l-constrain {
        position: relative;
      }

      .l-constrain::after {
        content: none;
      }
    }

    @include mappy-bp(wide) {
      padding-top: 1rem;
      padding-bottom: 0;
      border-top: 1px solid rgba(112, 112, 122, .3);
      border-bottom: 1px solid rgba(112, 112, 122, .3);
  }
  }

  // &.fixed-header {
  //   .main-nav > .navigation {
  //     padding: 25px 0;
  //     min-height: 100px;
  //     @include mappy-bp(large) {
  //       padding: 25px 0;
  //     }
  //     @include mappy-bp(wide) {
  //       padding: 50px 0;
  //     }
  //   }
  // }
}

// With administration toolbar

.adminimal-admin-toolbar {
  header[role='banner'] {
    top: 39px;
  }

  &.toolbar-tray-open {
    header[role='banner'] {
      top: 79px;
    }
  }
}

#nav-icon3 {
  margin-top: 3px;
  width: rem(26px);
  height: rem(20px);
  transform: rotate(0deg);
  transition: .3s;
  cursor: pointer;

  @include mappy-bp(wide) {
    display: none;
  }
}

#nav-icon3 span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #000;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
  cursor: pointer;
}
/* Icon 3 */
#nav-icon3 span:nth-child(1) {
  top: 0;
}

#nav-icon3 span:nth-child(2),
#nav-icon3 span:nth-child(3) {
  top: 7px;
}

#nav-icon3 span:nth-child(4) {
  top: 14px;
}

#nav-icon3.open span:nth-child(1) {
  top: 18px;
  width: 0;
  left: 50%;
}

#nav-icon3.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
  top: 18px;
  width: 0;
  left: 50%;
}

//------Phone number block styling------//


#block-sja-call {


  .block__content {
    background-color: #F5F5F5;
    color: black;
    padding-left: .4rem;
    padding-right: .5rem;
    padding-top: .3rem;
    border-radius: 2px;
    padding-bottom: .3rem;
    display: inline-flex;
    position: relative;
    text-align: right;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: initial;
    transition: box-shadow .3s ease; /* Smooth transition for the shadow */

    &:hover {
      box-shadow: 0 0 1.5px rgba(0, 0, 0, .2); /* Slightly darker shadow on hover */
    }

    &::before {
      display: inline-block;
      width: 12px;
      height: 12px;
      padding-left: 0;
      float: left;
      margin-right: 7px;
      content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="15.25" height="15.23"%3E%3Cpath fill="%23063" d="m14.693 10.933-1.624-1.624a1.856 1.856 0 0 0-2.652 0l-.325.325a35.8 35.8 0 0 1-4.514-4.508l.331-.331a1.876 1.876 0 0 0 0-2.652l-1.6-1.6a1.9 1.9 0 0 0-2.652 0l-.888.888a2.651 2.651 0 0 0-.336 3.31A35.957 35.957 0 0 0 10.49 14.797a2.652 2.652 0 0 0 3.315-.331l.888-.882a1.856 1.856 0 0 0 0-2.652Z"/%3E%3C/svg%3E');
      padding-top: 2px;
    }
  }
}

a.sja-call-number {
  text-decoration: none;
  color: black;
}
@media (max-width: 1200px) {
  #block-sja-call {
    display: none;
  }
}
  @media (max-width: 1420px) {
  .user-logged-in #block-sja-call {
    top: -76%;
  }
}


html[lang='fr'] {
  .user-logged-in {
    @media (max-width: 1644px) {
    #block-sja-call {
      top: -54%;
    }
  }
}
}

html[lang='fr'] {
  .user-logged-in {
    @media (min-width: 1664px) and (max-width: 1778px) {
    #block-sja-call {
      top: -54%;
    }
  }
}
}







// @media (max-width: 1420px) {
//   .user-logged-in #block-sja-call {
//     top: -54%;
//   }
// }
//------Phone number block styling------//

