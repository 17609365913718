// Overrides layout region margin top and bottom
// padding between elements is 60px (mobile) and 190px (desktop)
// So we don't have to account for too many scenarios, just halfing both and adding to the top and bottom of each element.
.layout__region-container > .layout__region {
  > .block-views-blockfeatured-courses-block-1,
  > .block-views-blockfeatured-courses-block-1:first-of-type,
  > .block-views-blockfeatured-courses-block-1:last-of-type {
    padding-top: rem(30px);
    padding-bottom: rem(30px);
    margin: 0 auto;

    @include mappy-bp(med) {
      padding-top: rem(63px);
      padding-bottom: rem(63px);
    }
  }
}

.block-views-blockfeatured-courses-block-1 {
  @extend %cf;
  @include mappy-bp(widest) {
    max-width: $content-max-width;
    margin: 0 auto;
  }
  .block__title {
    font-size: rem(24px);
    font-weight: 900;
    line-height: 1.33;
    letter-spacing: rem(1.2px);
    text-align: left;
    padding: .75rem 1rem;
    margin-right: rem(16px);
    width: auto;
    color: $color__black;
    background-color: $color__grey--lighter;

    @include mappy-bp(med) {
      max-width: 1095px;
      padding: 2.375rem 1rem 2.375rem 2rem;
      font-size: 36px;
    }
  }
  .view--featured-courses {
    .view__content {
      width: 100%;

      .view__row {
        margin-top: rem(36px);
        width: 100%;
        &.odd {
          @include mappy-bp(med) {
            flex-direction: row;
          }
          .views-field-custom--featured-course-details {
           padding-right: rem(16px);
            .field-content {
              flex-direction: row-reverse;
              .featured-courses--view-details {
                @include mappy-bp(med) {
                  padding-left: rem(20px);
                  padding-right: rem(50px);
                }
                @include mappy-bp(larger) {
                  padding-left: rem(30px);
                }
              }
              .featured-courses--hr {
                padding-right: rem(23px);
                padding-left: 0;
              }
            }
          }
        }
        &.even {
          @include mappy-bp(med) {
            flex-direction: row-reverse;
          }
          .views-field-custom--featured-course-details {
            padding-left: rem(16px);
            .field-content {
              flex-direction: row;
              .featured-courses--view-details {
                @include mappy-bp(med) {
                  padding: 0 rem(50px);
                }
                @include mappy-bp(larger) {
                  padding: 0 rem(70px);
                }
              }
              .featured-courses--hr {
                padding-left: rem(23px);
                padding-right: 0;
              }
            }
          }
        }
        @include mappy-bp(med) {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          align-content: center;
          justify-content: space-between;
        }

        .views-field-custom--featured-course-details {
          font-size: rem(14px);
          width: 100%;
          @include mappy-bp(med) {
            margin: auto 0;
            font-size: rem(16px);
          }

          .field-content {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            align-content: center;
            justify-content: space-between;

            .featured-courses--view-details {
              width: 80%;
              .featured-courses--title {
                margin-bottom: rem(16px);
                @include mappy-bp(med) {
                  margin-bottom: rem(24px);
                }
                h2 {
                  font-size: rem(20px);
                  font-weight: 900;
                  margin-top: rem(12px);
                  margin-bottom: rem(9px);
                  color: $color__primary;
                  @include mappy-bp(med) {
                    font-size: rem(28px);
                  }
                }
              }
              .featured-courses--book-now {
                margin: rem(42px) 0;
                a {
                  @extend %green-button-mobile;
                  @include mappy-bp(med) {
                    @include green-button-desktop;
                  }
                }
              }
            }

            .featured-courses--hr {
              width: 20%;
              margin: auto 0;
            }
          }
        }

        .views-field-field-banner-image {
          width: 100%;
          @include mappy-bp(med) {
            margin: auto 0;
            padding: rem(16px);
          }

          img {
            height: auto;
            width: 100%;
          }
        }

        .views-field-field-banner-image, .views-field-custom--featured-course-details {
          @include mappy-bp(med) {
            width: 49%;
          }
          @include mappy-bp(larger) {
            width: 49%;
          }
        }
      }
    }
  }
}
