// Overrides layout region margin top and bottom
// padding between elements is 60px (mobile) and 190px (desktop)
// So we don't have to account for too many scenarios, just halfing both and adding to the top and bottom of each element.
.layout__region-container > .layout__region {
  > .block--logo-carousel,
  > .block--logo-carousel:first-of-type,
  > .block--logo-carousel:last-of-type {
    padding-top: rem(32px);
    padding-bottom: rem(32px);
    margin: 0 auto;

    @include mappy-bp(med) {
      padding-top: rem(63px);
      padding-bottom: rem(32px);
    }
  }
}

.block--logo-carousel {

  text-align: left;
  // padding: rem(32px) 1rem;
  // @include mappy-bp(med) {
  //   padding: rem(53px) rem(60px);

  //   .blockquote_wrapper {
  //     margin: 0;
  //   }
  // }

  // @include mappy-bp(wide) {
  //   padding: 0 rem(83px);
  //   padding-top: rem(70px);
  //   padding-bottom: rem(40px);

  //   .blockquote_wrapper {
  //     margin: 0;
  //   }
  // }

  .logo--carousel--header,
  >.block__content {
    @include mappy-bp(widest) {
      max-width: rem(1540px);
      margin: 0 auto;
    }
  }

  .logo--carousel--header {
    .logo--carousel--title {
      display: inline-block;
      font-size: rem(14px);
      font-weight: 900;
      line-height: 1.36;
      letter-spacing: rem(1.74px);
      position: relative;
      text-transform: uppercase;
      overflow: hidden;
      color: #006633;
      width: 100%;
      padding-top: rem(13px);
      padding-bottom: rem(13px);
      margin: 0 auto;

      @include mappy-bp(med) {
        padding-top: rem(62px);
        padding-bottom: rem(62px);
      }
      @include mappy-bp(widest) {
        max-width: rem(1540px);
      }

      @include mappy-bp(large) {
        font-size: rem(16px);
        letter-spacing: rem(1.98px);
        // max-width: 30%;
      }

      &::after {
        content: ' ';
        width: 100%;
        margin-left: rem(12px);
        transform: translateY(-50%);
        top: 50%;
        position: absolute;
        height: rem(2px);
        display: inline-block;
        background-color: #787e7c;

      }

      .field--name-field-logo-carousel-title {
        max-width: 50%;
        display: inline-block;

        @include mappy-bp(large) {
          max-width: 30%;
          // max-width: 30%;
        }
      }
    }

    .two-lines {
      &::after {
        display: none;
      }
    }
  }

  >.block__content {
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: rem(32px) 1rem;
    @include mappy-bp(med) {
      padding: 0 rem(60px);

      .blockquote_wrapper {
        margin: 0;
      }
    }

    @include mappy-bp(wide) {
      padding: 0 rem(83px);
      // padding-top: rem(70px);
      // padding-bottom: rem(40px);

      .blockquote_wrapper {
        margin: 0;
      }
    }
  }


  .field--name-field-partner-logos {
    .slick-slider {

      .slick-prev,
      .slick-next {

        // background-image: url('../../src/000_assets/icons/right_green_arrow.svg');
        &::before {
          content: url('../../src/000_assets/icons/right_green_arrow.svg');
        }

        &:focus {
          outline: lightgreen solid 2px;
        }
      }

      .slick-prev,
      .slick-next {
        transform: scale(0);

        &::before {
          display: inline-block;
        }

        @include mappy-bp(wide) {
          transform: scale(1.5);
        }
      }

      .slick-prev {
        left: 0;
        right: rem(10px);
        // padding-left: 3rem;

        &::before {
          // display: inline-block;
          // transform: scaleX(-1);
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -o-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          transform: rotate(180deg);
        }

        @include mappy-bp(max-width widest) {
          left: 0 !important;
        }
      }

      .slick-next {
        @include mappy-bp(max-width widest) {
          right: 0 !important;
        }
      }

      .slick-next {
        right: 0;
      }

      .slick-dots {
        bottom: -50px !important;

        @include mappy-bp(wide) {
          display: none !important;
          bottom: rem(-15px);
        }

        li {
          margin: 0 rem(2px);

          button {
            &::before {
              font-size: rem(16px);
              color: $color__primary;
              opacity: .25;
            }
          }

          &.slick-active {
            button {
              &::before {
                font-size: rem(16px);
                color: $color__primary;
                opacity: 1;
              }
            }
          }
        }
      }

      .slick-list {
        .slick-slide {
          display: inline-block;
          vertical-align: middle;
          float: none;

          img {
            max-width: 200px;
            margin: auto;
          }
        }
      }

    }
  }
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {

  /* IE10+ CSS styles go here */
  .block--logo-carousel {
    .field--name-field-partner-logos {
      .slick-slider {

        .slick-prev,
        .slick-next {
          @include mappy-bp(wide) {
            transform: scale(.8);
            height: auto;
          }

          &::before {
            display: block;
            width: 20px;
            height: auto;

          }
        }
      }
    }
  }
}
