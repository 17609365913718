form#class-registration-assign-form {
  background-image: url('/themes/custom/sja/src/000_assets/default/SJA_BrandBackground_Green.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  // height: 72vh;
  padding: 4rem;
  // background-position: 99% 123%;
}

.assign_class_title {
  font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, sans-serif;
  font-size: 1.5em;
  font-weight: 700;
  color: white;
}

.assign_class_description {
  font-weight: 700;
  font-family: inherit;
  color: white;
  margin-top: 6%;
  display: block;
  margin-bottom: 6%;
}

.custom_textfield {
  border-radius: 7px;
  width: 50%;
}
.assign_class_submit:hover {
border: 2px solid white;
}
@media screen and (max-width: 768px) {
.custom_textfield {
  border-radius: 7px;
  width: 100%;
}
}

