.cc_banner-wrapper {
  z-index: 9001;
  position: relative;
}

@media screen and (max-width: 1200px) {
  .cc_banner-wrapper {
    z-index: 100;
  }
}

.cc_container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  padding: 20px 8%;
  box-shadow: 0 -4px 16px 0 rgba(0, 0, 0, .04);
  background: #fff;
  color: #000000;
  font-size: 14px;
  box-sizing: border-box;

  .cc_btn {
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    transition: font-size 200ms;
    line-height: 24px;
  }

  .cc_message {
    font-size: 14px;
    transition: font-size 200ms;
    margin-bottom: 60px;
    padding: 0;
    line-height: 24px;
  }

  .cc_logo {
    display: none;
    text-indent: -1000px;
    overflow: hidden;
    width: 100px;
    height: 22px;
    background-size: cover;
    opacity: .9;
    transition: opacity 200ms;

    &:hover,
    &:active {
      opacity: 1;
    }
  }

  .cc_btn {
    padding: 9px 38px;
    border-radius: 22px;
    -webkit-border-radius: 22px;
    border: solid 2px #006633;
    color: #006633;
    cursor: pointer;
    transition: font-size 200ms;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 900;
    letter-spacing: .05em;
    display: block;
    position: absolute;
    bottom: 20px;
  }

  .cc_message {
    transition: font-size 200ms;
    font-size: 14px;
    display: block;
  }

  ::-moz-selection {
    background: #ff5e99;
    color: #fff;
    text-shadow: none;
  }

  .cc_btn {
    &:hover,
    &:active {
      background-color: #006633;
      color: #ffffff;
    }
  }

  a {
    text-decoration: underline;
    color: #006633;
    font-weight: bold;
    transition: 200ms color;
    animation-duration: .8s;
    -webkit-animation-name: slideUp;
    animation-name: slideUp;

    &:visited {
      text-decoration: underline;
      color: #006633;
      font-weight: bold;
      transition: 200ms color;
    }

    &:hover,
    &:active {
      color: #000000;
    }
  }
}

@media print {
  .cc_banner-wrapper,
  .cc_container {
    display: none;
  }
}

@media screen and (min-width: 1200px) {
  .cc_container {
    padding: 20px 4%;
    margin: auto;

    .cc_message {
      margin: 10px 0;
    }

    .cc_btn {
      position: static;
      float: right;
      font-size: 14px;
    }
  }
}

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(66px);
    transform: translateY(66px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(66px);
    -ms-transform: translateY(66px);
    transform: translateY(66px);
  }

  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.cc_message,
.cc_btn {
  animation-duration: .8s;
  -webkit-animation-name: slideUp;
  animation-name: slideUp;
}
