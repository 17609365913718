.view--commerce-cart-form {
  .views-form {
    form {
      .views-field-edit-quantity {
        .form-item--error-message {
          color: #e50020;
          font-size: 1rem;
        }
      }
    }
  }
}
