.block-views-blockcompany-online-registration-orders-block-1 {
  .views-exposed-form {
    .form-item--id-combine {
      margin-bottom: 1rem;
    }
    #edit-actions,
    .form-actions {
      input {
        margin: .5rem 0;
        @include mappy-bp(mobile) {
          margin: 1.5rem 0;
        }
      }
    }
  }
  .view__content {
    overflow-x: auto;
    .tablesaw-bar.tablesaw-mode-swipe {
      margin-top: 0 !important;
    }
    table {
      thead {
        background-color: $light_light_grey;
        th {
          padding: 1.25rem 1rem;
          font-size: 1rem;
        }
      }
    }
  }
  .view__empty {
    margin-bottom: 2rem;
  }
  .feed-icons {
    margin: 1rem 0 3rem;

    .csv-feed {
      a {
        display: inline-block;
        width: auto;
        background: none;
        text-indent: 0;
        font-size: .875rem;
        font-weight: 800;
        color: $color__black;
        text-decoration: none;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }
}
