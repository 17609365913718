// Overrides layout region margin top and bottom and adds
// padding between elements is 60px (mobile) and 190px (desktop)
// So we don't have to account for too many scenarios, just halfing both and adding to the top and bottom of each element.
.layout__region-container > .layout__region {
  > .block--two-column-card-and-image-layout,
  > .block--two-column-card-and-image-layout:first-of-type,
  > .block--two-column-card-and-image-layout:last-of-type {
    padding-top: rem(30px);
    padding-bottom: rem(30px);
    margin: 0 auto;

    @include mappy-bp(med) {
      padding-top: rem(60px);
      padding-bottom: rem(60px);
    }
  }
}

.block--two-column-card-and-image-layout {
  .logo--carousel--header,
  >.block__content {
    margin-top: 2rem;
    @include mappy-bp(widest) {
      max-width: rem(1540px);
      margin: 0 auto;
      margin-top: 2rem;
    }
  }

  .two-column-card-and-image-layout-header {
    .two-column-card-and-image-layout-title {
      display: inline-block;
      font-size: rem(14px);
      font-weight: 900;
      line-height: 1.36;
      letter-spacing: rem(1.74px);
      position: relative;
      text-transform: uppercase;
      overflow: hidden;
      color: $sja_green;
      width: 100%;
      margin-bottom: 2rem;

      @include mappy-bp(large) {
        font-size: rem(16px);
        letter-spacing: rem(1.98px);
        margin-bottom: rem(94px);
      }

      &::after {
        content: ' ';
        width: 100%;
        margin-left: rem(12px);
        transform: translateY(-50%);
        top: 50%;
        position: absolute;
        height: rem(2px);
        display: inline-block;
        background-color: #787e7c;

      }

      >h2 {
        font-size: .875rem;
        max-width: 50%;
        display: inline-block;
        margin-top: 0;
        margin-bottom: 0;

        @include mappy-bp(large) {
          max-width: 30%;
          font-size: 1rem;
        }
      }
    }
  }

  > .block__content {

    .block_wrapper {
      @include mappy-bp(large) {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        // min-height: 440px;
        // min-height: 440px;
        &.image-left {
          flex-direction: row;
        }
      }

      .two-col-left {
        margin-top: 2rem;

        @include mappy-bp(large) {
          flex-basis: 49%;
          margin-top: 0;
        }

        .field--name-field-category {
          margin-bottom: 1rem;
          font-size: 1rem;
          text-transform: uppercase;
          line-height: 1.38;
          letter-spacing: 1.2px;
          @include mappy-bp(large) {
            margin-bottom: 2rem;
          }
        }

        .field--name-field-heading {
          font-size: 1.5rem;
          margin-bottom: 1rem;

          @include mappy-bp(large) {
            font-size: 2rem;
            margin-bottom: 2rem;
          }

          font-weight: 900;
          color: $sja_green;
          line-height: 1.34;
          letter-spacing: .8px;

          word-break: break-word;

          @media all and (-ms-high-contrast: none),
          (-ms-high-contrast: active) {
            word-break: break-all;
          }
        }

        .field--name-field-search-results-description {
          font-size: 1rem;
          margin-bottom: 1rem;

          @include mappy-bp(large) {
            font-size: 1.25rem;
            margin-bottom: 2rem;
          }
        }

        .link_field {
          margin-top: 2.5rem;
        }

      }

      .two-col-right {
        width: calc(100% + 32px);
        margin-left: -16px;
        @include mappy-bp(large) {
          width: 100%;
          margin-left: 0;
          flex-basis: 49%;
          // max-height: 500px;
        }

        .field--name-field-2-col-image {
          @include mappy-bp(large) {
            height: 0;
            overflow: hidden;
            position: relative;
            padding-top: 90%;

            .field__item {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }

            .video-embed-field-responsive-video {
              height: 500px;
            }
          }

          @include mappy-bp(wide) {
            padding-top: 65%;
          }

          img {
            @include mappy-bp(large) {
              // height: 440px;
              width: 100%;
              object-fit: cover;
              // max-height: 500px;
            }
          }
        }
      }
    }

    // .block_wrapper.image-left {
    //   @include mappy-bp(large) {
    //     flex-direction: initial;
    //   }
    // }

    .look-1-float {
      .two-col-left {
        .content-wrapper > .link_field > a,
        .content-wrapper > a {
          text-decoration: none;
          color: $sja_green;
          background-repeat: no-repeat;
          background-size: 0;
          background-position: 0 1.25em;
          font-size: 1rem;
          font-weight: 900;
          // text-transform: capitalize;
          @extend %slide-in-green;

          @media all and (-ms-high-contrast: none),
          (-ms-high-contrast: active) {
            background-position: 0 1.35em;
          }

          // padding-bottom: .35rem;
          overflow: hidden;
          // transition: background-position .2s linear .2s,
          // box-shadow .2s linear 0s;
        }
      }
    }

    .look-2-boarded {
      .two-col-left {
        box-shadow: 0 0 24px 0 rgba(0, 0, 0, .16);
        background-color: #ffffff;

        @include mappy-bp(large) {
          align-self: stretch;
          position: relative;
        }

        .content-wrapper {
          padding: 2rem;

          @include mappy-bp(large) {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            @media all and (-ms-high-contrast: none),
            (-ms-high-contrast: active) {
              max-width: 100%;
            }
          }

          @include mappy-bp(wide) {
            padding: 3rem;
          }

          >.link_field > a,
          > a {
            @extend %green-button-mobile;

            @include mappy-bp(med) {
              margin: 0;
              @include green-button-desktop;
            }
          }
        }
      }

      // .two-col-right{
      //   img{
      //     width: 100%;
      //   }
      // }
    }
  }

  .referenced_content_wrapper {
    .field--name-field-content {
      >.field__item {
        @include mappy-bp(large) {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          flex-wrap: wrap;
          justify-content: space-between;
          // max-height: 440px;
        }

        .two-col-left {
          margin-top: 2rem;

          @include mappy-bp(large) {
            flex-basis: 49%;
            margin-top: 0;
            // height: 440px;
          }

          .referenced_entity_title {
            font-size: 1.5rem;
            margin-bottom: 1rem;

            @include mappy-bp(large) {
              font-size: 2rem;
              margin-bottom: 2rem;
            }

            font-weight: 900;
            color: $sja_green;
            line-height: 1.34;
            letter-spacing: .8px;

            word-break: break-word;

            @media all and (-ms-high-contrast: none),
            (-ms-high-contrast: active) {
              word-break: break-all;
            }
          }

          .field--name-field-search-results-description {
            font-size: 1rem;
            margin-bottom: 1rem;

            @include mappy-bp(large) {
              margin-bottom: 2rem;
              font-size: 1.25rem;
            }

          }

        }

        .two-col-right {
          @include mappy-bp(large) {
            flex-basis: 49%;
            // max-height: 500px;
          }

          >.field--name-field-banner-image,
          >.field--name-field-small-banner,
          >.field--name-field-banner {
            @include mappy-bp(large) {
              height: 0;
              overflow: hidden;
              position: relative;
              padding-top: 90%;

              .field__item {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              }
            }

            @include mappy-bp(wide) {
              padding-top: 65%;
            }

            img {
              @include mappy-bp(large) {
                // height: 440px;
                width: 100%;
                object-fit: cover;
                // max-height: 500px;
              }
            }
          }
        }
      }
    }

  }

  .referenced_content_wrapper.image-left {
    .field--name-field-content {
      >.field__item {
        @include mappy-bp(large) {
          flex-direction: row;
        }
      }
    }
  }
}

.layout__region {
  > .block--two-column-card-and-image-layout {
    .field--name-field-banner-image {
      .media--display-mode-two-col-layout-image {
        width: calc(100% + 32px);
        margin-left: -16px;
      }
    }
  }
}