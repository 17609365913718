.path-checkout {

  .layout-container > .wrapper {

    // layout styles
    .commerce-checkout-flow,
    .block--basic {
      max-width: $content-max-width;
      margin: 0 auto;
      transition: all .5s ease-in;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-bottom: rem(82px);

      // padding will be added to the breadcrumb link
      @include mappy-bp(med) {
        padding-left: 3.75rem;
        padding-right: 3.75rem;
      }
      @include mappy-bp(wide) {
        padding: 0 3.75rem rem(206px);
      }

      @include mappy-bp($content-max-width + 32px) {
        padding: 0 0 68px;
      }
    }

    .layout-checkout-form {
      @include mappy-bp(larger) {
        display: flex;
        justify-content: space-between;
      }
    }

    .layout-region-checkout-main {
      padding-right: 0;
      float: none ;
      width: auto;

      @include mappy-bp(larger) {
        padding-right: 3rem;
        flex: 0 0 66.66%;
        width: 100%;
      }
    }

    .layout-region-checkout-secondary {
      float: none ;
      width: auto;

      @include mappy-bp(larger) {
        padding-right: 0;
        flex: 0 0 33.33%;
        width: 100%;
        position: relative;
      }

      > .checkout-pane {

        @include mappy-bp(larger) {
          position: sticky;
          top: rem(290px);
        }
      }
    }

    .layout-region-checkout-footer {
      flex-basis: 100%;
      width: 100%;
      float: none;

      #edit-actions,
      .form-actions {
        input,
        a.link--previous {
          font-size: 1.25rem;
          height: auto;
          white-space: normal;
        }
      }
    }

    .purchase-information__header {

      @include mappy-bp(med) {
        text-align: center;
      }
    }

    // Payment Information title
    .checkout-pane > .fieldset__legend {
      width: 100%;
      position: relative;
      overflow: hidden;

      .legend-wrapper {
        display: inline-block;
        font-size: rem(14px);
        font-weight: 900;
        line-height: 1.36;
        letter-spacing: rem(1.74px);
        position: relative;
        text-transform: uppercase;
        overflow: hidden;
        color: #006633;
        width: 100%;
        // &::after {
        //   content: ' ';
        //   width: 100%;
        //   margin-left: rem(12px);
        //   transform: translateY(-50%);
        //   top: 50%;
        //   position: absolute;
        //   height: rem(2px);
        //   display: inline-block;
        //   background-color: #787e7c;

        // }
        .fieldset__legend-text {
          max-width: 75%;
          display: inline-block;

          // @include mappy-bp(large) {
          //   max-width: 30%;
          //   // max-width: 30%;
          // }
        }

        @include mappy-bp(large) {
          font-size: rem(16px);
          letter-spacing: rem(1.98px);
          // max-width: 30%;
        }
      }
    }

    // Stripe
    // .stripe-form {
    //   // background: #E5EBE9;
    //   padding: 1rem 0;
    //   margin: 1rem 0;
    //   .form-item {
    //     margin-top: 1rem;
    //     margin-bottom: 1rem;
    //   }

    //   @include mappy-bp(land) {
    //     .form-item {
    //       display: inline-block;
    //       margin: 0 1rem 0 0;
    //     }
    //   }
    // }

    // Form Margin Adjustments.
    .form-item--id-payment-information-add-payment-method-payment-details-number,
    .form-item--id-payment-information-add-payment-method-billing-information-select-address {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    @include mappy-bp(0 land) {

      .address-container-inline {
        margin-bottom: 1rem;
      }

      .field--type-address .form-item {
        width: 100%;
        display: block;

        select {
          width: 100%;
        }
      }
    }

    .field--type-address .form-item {
      margin-bottom: 1rem;
    }

    .address-container-inline > .form-item {
      &:last-of-type {
        margin-bottom: 0;
        margin-right: 0;
      }
    }

    .form-item--id-payment-information-add-payment-method-billing-information-copy-to-address-book {
      margin-top: 1rem;
    }

    // form buttons

    .link--previous {
      @extend %green-button-desktop;
      width: auto;
      height: 43px;
      margin-top: 3rem;
      margin-right: 1rem;
      padding: .5rem 2.75rem;
      cursor: pointer;

      @include mappy-bp(land) {
        float: left;
      }
    }

    // .coupon-redemption-form__coupons {
    //   >h3 {
    //     display: block;
    //     letter-spacing: 1.2px;
    //     text-transform: uppercase;
    //     color: #000000;
    //     font-size: 1rem;
    //     font-weight: 800;
    //   }
    //   table {
    //     tr {
    //       .button {
    //         margin-top: 0;
    //       }
    //     }
    //   }
    // }
    .checkout-pane {

      .form-item--id-shipping-information-shipments-0-title-0-value,
      .form-item--id-shipping-information-shipments-0-tracking-code-0-value {
        display: none;
      }
      .address,
      .locality,
      .administrative-area {
        margin-right: .5rem;
      }
      #edit-review-shipping-information-summary-0 {
        margin-top: 1rem;
      }
    }
    .field--name-expires {
      margin-bottom: 1rem;
    }
  }
}


// checkout-form header
.checkout-form__form-header {
  background-color: #18573A;
  padding: rem(14px);
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mappy-bp(med) {
    justify-content: flex-start;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    background: #18573A;
    z-index: -1;
    background-size: cover;
  }

  img {
    width: rem(30px);
    height: auto;
    margin-right: rem(10px);
  }

  .page-title {
    margin: 0;
    color: #ffffff;
    font-size: rem(20px);
    font-weight: 800;
    letter-spacing: 0;
  }
}

.layout-container > .wrapper {

  .menu--continue-shopping {

  @include mappy-bp(med) {
      padding-left: 3.75rem;
      padding-right: 3.75rem;
    }
    @include mappy-bp(wide) {
      padding: 0 3.75rem 0;
    }

    @include mappy-bp($content-max-width + 32px) {
      padding: 0 0 0;
    }

  }

  .nav--continue-shopping {

    list-style-type: none;
    max-width: 1540px;
    margin-left: 15px;
    padding-top: 1.5rem;

    @include mappy-bp(med) {
      margin: 0 auto;
    }

    @include mappy-bp(wide) {
      padding-top: 2rem;
    }

    .nav__link {
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: #006633;
      position: relative;
      text-decoration: none;
      padding-left: 18px;

      &:hover {
          color: black;
      }

      &::before {
          content: ' ';
          display: inline-block;
          background-image: url('../../src/000_assets/icons/double_arrow_n.svg');
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 12px;
          height: 12px;
          left: 0;
      }

      &:hover {
          &::before {
          background-image: url('../../src/000_assets/icons/double_arrow_h.svg');
          }
      }

    }
  }
}
