// Overrides layout region margin top and bottom
// padding between elements is 60px (mobile) and 190px (desktop)
// So we don't have to account for too many scenarios, just halfing both and adding to the top and bottom of each element.
.layout__region-container > .layout__region {
  > .block--quartered-text-and-stats-layout,
  > .block--quartered-text-and-stats-layout:first-of-type,
  > .block--quartered-text-and-stats-layout:last-of-type {
    padding-top: rem(30px);
    padding-bottom: rem(30px);
    margin: 0 auto;

    @include mappy-bp(med) {
      padding-top: rem(63px);
      padding-bottom: rem(63px);
    }
  }
}

.block--quartered-text-and-stats-layout {
  .block_stats {
    .field--name-field-stat-content {
      @include mappy-bp(med) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      .paragraph--type-stats-with-textarea {
        margin-left: 0 !important;
        margin-right: 0 !important;
        max-width: none !important;

        @include mappy-bp(med) {
          width: 45% !important;
        }

        .l-constrain {
          width: 80%;
          max-width: 465px;
        }
      }
    }
  }
}
