// Overrides layout region margin top and bottom
// padding between elements is 60px (mobile) and 190px (desktop)
// So we don't have to account for too many scenarios, just halfing both and adding to the top and bottom of each element.
.layout__region-container > .layout__region {
  > .block--quick-links,
  > .block--quick-links:first-of-type,
  > .block--quick-links:last-of-type {
    padding-top: rem(30px);
    padding-bottom: rem(30px);
    margin: 0 auto;

    @include mappy-bp(med) {
      padding-top: rem(60px);
      padding-bottom: rem(60px);
    }
  }
}

.block--quick-links {
  .quick_links {
    max-width: 100%;

    .quick_links_heading {
      @extend %full-width-green-heading;
      width: 100%;
      margin: 0 auto;
      font-size: 16px;

      @include mappy-bp(med) {
        max-width: 1540px;
      }
    }

    .quick_link_container {
      max-width: 100%;
      max-height: 100%;
      padding: 0;
      margin: 0;
      margin-top: 2rem;

      @include mappy-bp(med) {
        margin: 2rem auto 0 auto;
        height: auto;
        display: flex;
        justify-content: space-between;
      }
    }

    .node__content {
      width: auto;
      padding-bottom: 0;
      margin-bottom: 0;

      @include mappy-bp(med) {
        min-height: 380px;
        flex: 0 1 33%;
        margin: 0 .625rem;

        &:first-of-type {
          margin-left: 0;
          margin-right: .75rem;
        }

        &:last-of-type {
          margin-left: .75rem;
          margin-right: 0;
        }
      }
    }
  }
}
