.address-book__container {
  .address-book__add-link {
    @extend %green-button-desktop;
  }

  .address-book__profiles {
    display: block;

    @include mappy-bp(land) {
      display: flex;
    }

    .address-book__profile--default {
      flex-basis: 100%;
      margin: 2rem .75em;
    }

    .address-book__operations {
      a {
        @extend %green-link;
        display: inline-block;
        margin-right: 1.5rem;
      }
    }
  }

  // Individual address.
  .address {
    .given-name,
    .locality,
    .administrative-area {
      margin-right: .25rem;
    }
  }
}

// Add Form.
.profile-customer-address-book-add-form {
  max-width: 769px;
  > .field--name-address {
    div[id^='edit-address-0-address'] {
      > .form-item,
      > .form-wrapper {
        margin-bottom: 1rem;
      }

      > .address-container-inline {

        .form-item {
          display: block;
          margin-bottom: 1rem;
          margin-right: 0;

          &:last-of-type {
            margin-bottom: 0;
          }

          @include mappy-bp(wide) {
            display: inline-block;
            margin-right: 1rem;
            margin-bottom: 0;

            &:nth-of-type(3) {
                margin-top: 1rem;
            }
          }
        }
      }
    }
  }
}
