//sja

$sja_green: #006633;
$sja_green_dark: #18573A;
$sja_red: #e50020;
$light_light_grey: #e5ebe9;
$light_grey: #d9e0de;
$light_medium_grey: #bec6c4;
$medium_grey: #787e7c;
$medium_dark_grey: #474b4a;
$black: #000000;

// colors
$color__white: #FFFFFF;
$color__grey--lighter: #e5ebe9;
$color__grey--light: #d9e0de;
$color__grey--light-medium: #bec6c4;
$color__grey--medium: #787e7c;
$color__grey--dark: #474b4a;

$color__black: #000;

$color__red: #e50020;

$color__blue--darker: #003767;
$color__blue--dark: #114676;
$color__blue: #216088;

$color__teal: #009FBD;
$color__aqua: #e8f4f8;

// Default Colors

// PRIMARY
$color__primary:                 #006633;
$color__secondary:               #000;

// bkg
$color__bkg--grey:               $color__grey--lighter;

//TEXT
$color__text--dark:              $color__black;
$color__text--light:             #4D4D4D;
$color__text--white:             $color__white;

// FORMS
$color__input-border--normal:    #BEC6C4;
$color__input-border--active:    #CDCDCD;
$color__input-border--focus:     #CDCDCD;
$color__input-bkg--normal:       #CDCDCD;
$color__input--placeholder:      rgba(65, 74, 81, .7);
$color__filter-tag:              #EDEFEE;

$color__fieldset-border--normal: #CDCDCD;
$color__fieldset-bkg--normal:    rgb(126, 94, 94);

$color__submit-border--normal:   #007F61;
$color__submit-bkg--normal:      #007F61;

// TABLES
$color__table--border:           #CCCCCC;

// Geo Location
$color__geo-location-text:       #171918;
