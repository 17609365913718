.block--two-column-text-and-stats-block {
  @extend %cf;
  @include mappy-bp(larger) {
    max-width: $content-max-width;
    margin: 0 auto;
    padding: 0;
  }
  .field--name-field-title {
    margin: 41px 0;
    @include mappy-bp (larger) {
      margin-bottom: 132px;
    }
  }
  .field--name-body {
    padding-bottom: 44px;
    @include mappy-bp (larger) {
      width: 50%;
      float: left;
      font-size: 20px;
    }
  }
  .field--name-field-stats {
    .paragraph--type-individual-stats {
      padding-bottom: 44px;
      .stat-container {
        @include mappy-bp (larger) {
          margin-left: 25%;
        }
      }
      @include mappy-bp (land) {
        @include span (6 of 12);
        margin-left: 0;
      }
      @include mappy-bp (larger) {
        @include span (12 of 12 );
      }
    }
    @include mappy-bp (larger) {
      display: block;
      width: 50%;
      float: right;
    }
  }
}