/*
* User Registrations List
*/
.registration {

  &__list-item {
    background: #ffffff;
    border: solid 1px #eaeaeb;
    border-radius: 10px;
    color: #494d55;
    list-style: none;
    margin: rem(16px) 0;
    padding: rem(36px) rem(24px);
  }

  &__info-primary,
  &__info-secondary {
    overflow: hidden;

    @include mappy-bp(wide) {
      display: flex;
      margin: 0 rem(-40px);
    }
  }

  &__info-secondary {
    flex-wrap: wrap;
    margin-top: rem(24px);
  }

  &__field-item {
    font-weight: 600;
    font-family: 'Lato' sans-serif;
    font-size: rem(20px);
    -webkit-font-smoothing: antialiased;
    margin: rem(16px) 0;

    @include mappy-bp(wide) {
      padding: 0 rem(40px);
      margin: 0;
    }
  }

  &__field-item label {
    letter-spacing: normal;
    color: #9a9a9a;
    font-size: rem(18px);
    font-weight: 600;
  }

  &__class a {
    color: #626363;
    text-decoration: underline;
    font-weight: 500;

    &:hover {
      text-decoration: none;
    }
  }

  &__button {
    display: flex;
    align-items: flex-end;
    margin-left: auto;
    margin-top: rem(16px);
  }

  &__button a,
  &__load-more button {
    border-radius: 6px;
    font-family: 'Lato' sans-serif;
    font-size: rem(18px);
    font-weight: 500;
    background-color: #006633;
    letter-spacing: 0;
    line-height: 1.2;
    text-transform: none;
    color: #FFF;
    margin-right: rem(8px);
    border: 0;
    cursor: pointer;
    display: inline-block;
    padding: rem(10px) rem(26px);
    text-align: center;
    text-decoration: none;
    transition: .2s all;
    -webkit-font-smoothing: antialiased;

    &:hover,
    &:focus {
      background-color: #17573a;
      color: #FFF;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__load-more {
    text-align: center;

    button {
      margin: 0 auto;
    }
  }
}