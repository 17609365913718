.table-container {
  overflow-x: auto;
  white-space: nowrap;
}

.tablesaw {
  margin: 1rem auto;
  max-width: 1200px;
  border-spacing: 0;
  border-collapse: collapse;
  border: unset !important;
  width: 100%;
  // border: 0;
  td {
    @include mappy-bp(0 640px) {
      width: 100% !important;
    }
  }
}

table {
  margin: 1em auto;
  max-width: 1200px;
  border-spacing: 0;
  border-collapse: collapse;
  border: unset;
  width: 100%;
  // border: 0;
  caption,
  th {
    padding: 30px;
    text-align: left;
  }

  .text-align-center {
    text-align: center;
  }

  .text-align-right {
    text-align: right;
  }

  .text-align-left {
    text-align: left;
  }

  td {
    padding: rem(30px);
  }

  tbody {
    th {
      background-color: white;
      // font-size: 16px;
      padding: 30px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.38;
      letter-spacing: normal;
      text-align: left;
      color: #006633;
    }
  }

  thead {
    th {
      background-color: #e5ebe9;
      padding: 30px;
      // font-size: 16px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.38;
      letter-spacing: 1.2px;
      text-align: left;
      color: #000000;
      text-transform: uppercase;
    }
  }

  tr {
    color: #000000;
    border-bottom: 2px solid #e5ebe9;
  }
  @include mappy-bp(0 640px) {
    td {
      color: #000000;
      border-bottom: 2px solid #e5ebe9;
    }

    tbody > tr > th {
      background-color: #e5ebe9;
      border-color: #e5ebe9 !important;
    }

    tbody > tr > td:nth-child(1) {
      background-color: #e5ebe9;
      border-color: #e5ebe9 !important;
    }

    b.tablesaw-cell-label {
      // display: inline !important;
      // font-size: 14px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.36;
      letter-spacing: 1.05px;
      text-align: left;
      color: #000000;
      text-transform: uppercase;
    }

    span.tablesaw-cell-content {
      padding-left: rem(43px);
    }
  }
}

.views-field-remove-button {
  input {
    background-color: transparent;
    color: #FFF;

  }
}

.tableresponsive-toggle-columns {
  display: none;
}

.tablesaw-bar.tablesaw-mode-swipe {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;

  &.tablesaw-all-cols-visible {
    display: none;
  }

  .tablesaw-advance {
    float: none;

    &.minimap {
      margin: 0 0 0 .5rem;
    }

    .tablesaw-nav-btn {
      margin-top: 0;
      margin-bottom: 0;
    }

    .tablesaw-advance-dots li i {
      display: block;
      margin-top: .5rem;
    }
  }
}
.tablesaw {
  caption {
    margin: 0;
    padding: .5rem 0;
  }
}
