.file--general,
.file--application-octet-stream {
  background-image: url('../../assets/icons/theme-defaults/file-exe.svg');
}
.file--package-x-generic {
  background-image: url('../../assets/icons/theme-defaults/file-generic.svg');
}
.file--x-office-spreadsheet {
  background-image: url('../../assets/icons/theme-defaults/file-xls.svg');
}
.file--x-office-document {
  background-image: url('../../assets/icons/theme-defaults/file-doc.svg');
}
.file--x-office-presentation {
  background-image: url('../../assets/icons/theme-defaults/file-ppt.svg');
}
.file--text-x-script {
  background-image: url('../../assets/icons/theme-defaults/file-js.svg');
}
.file--text-html {
  background-image: url('../../assets/icons/theme-defaults/file-html.svg');
}
.file--text-plain {
  background-image: url('../../assets/icons/theme-defaults/text-plain.png');
}
.file--application-pdf {
  background-image: url('../../assets/icons/theme-defaults/file-pdf.svg');

}
.file--application-x-executable {
  background-image: url('../../assets/icons/theme-defaults/file-exe.svg');
}
.file--audio {
  background-image: url('../../assets/icons/theme-defaults/file-mp3.svg');

}
.file--video {
  background-image: url('../../assets/icons/theme-defaults/file-mp4.svg');

}
.file--text {
  background-image: url('../../assets/icons/theme-defaults/file-txt.svg');

}
.file--image {
  background-image: url('../../assets/icons/theme-defaults/file-jpg.svg');
}

.file--mime-application-postscript {
  background-image: url('../../assets/icons/theme-defaults/file-eps.svg');
}

.file {
  background-size: 16px auto;
  background-position: left top;
}

a[data-entity-type='file'] {
  position: relative;
  margin-left: 1rem;
  font-weight: 500;
  // border-bottom: 1px dotted;

  &::before {
    position: absolute;
    content: ' ';
    width: .75rem;
    height: 1rem;
    left: -.9rem;
    top: .25rem;
    background-image: url('/themes/custom/sja/src/000_assets/icons/theme-defaults/attach-2.svg');
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
  }

}
