.embedded-entity {
  .file--application-pdf {

      position: relative;
      margin-left: 1rem;
      font-weight: 500;
      &::before {
        position: absolute;
        content: ' ';
        width: 1.25rem;
        height: 1.25rem;
        left: -.9rem;
        top: .25rem;
        background-image: url('/themes/custom/sja/src/000_assets/icons/theme-defaults/file-pdf.svg');
        background-repeat: no-repeat;
        background-size: 1.25rem 1.25rem;
      }
      a {
       margin-left: .5rem;
       color: $sja_green;
       text-decoration: none;
       font-weight: 500;
       border-bottom: 1px solid $sja_green;
       &:hover, &:focus  {
         cursor: pointer;
         color: $black;
         border-bottom-color: $black;
       }
      }

  }
}
.js-off-canvas-dialog-open {
  .ui-widget-content.editor-link-dialog {
   #drupal-modal {
     overflow: initial;
   }
 }
}
.block--wysiwyg-text {
  a[data-entity-type= 'file'] {
    margin-left: .75rem;
    color: $sja_green;
    text-decoration: none;
    font-weight: 500;
    border-bottom: 1px solid $sja_green;
    &:hover, &:focus  {
      cursor: pointer;
      color: $black;
      border-bottom-color: $black;
    }
  }
}