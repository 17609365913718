// @file
// Styles for messages.

.messages {
  max-width: 100%;
  margin: auto;
  transition: .5s all ease-in;
  background-color: #ecf6fa;
  color: #424242;
  overflow: hidden;
  position: relative;
  word-wrap: break-word;
  background-repeat: no-repeat;
  background-size: 45px 45px;
  background-position: right;
  border-radius: 3px;
  font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, sans-serif;
  font-weight: 500;

  a {
    color: #bcdeee;
    font-weight: 600;
    text-decoration: none;
    font-family: $system-font-family !important;
  }
}

.messages__inner {
  max-width: rem(1600px);
  padding: 0 1.25rem;
  margin: 0 auto;

  @include mappy-bp(med) {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  @include mappy-bp(wide) {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }
}

.messages__content {
  background-repeat: no-repeat;
  background-size: 45px 45px;
  background-position: 0 center;
  padding: 1.25rem 0 1.25rem rem(60px);

  > *:last-child {
    margin-bottom: 0;
  }
}

.messages--error {
  background-color: #ffe6e5;
  border-color: #ffc5c2;
  .messages__content {
    background-image: url('../../src/000_assets/bg/messages-error.svg');
  }

  a {
    color: #ff645c;
  }

  .messages__item {
    em:first-child {
      font-style: normal;
      font-weight: bold;
    }
  }
}

.messages--status {
  background-color: #e5ebe9;
  border-color: #c9e8a9;
  .messages__content {
    background-image: url('../../src/000_assets/bg/checkmark.svg');
  }


  a {
    color: #71b02f;
    font-family: $system-font-family !important;
  }
}

.messages--warning {
  background-color: #fcf8e3;
  border-color: #faf2cc;
  .messages__content {
    background-image: url('../../src/000_assets/bg/messages-warning.svg');
  }


  a {
    color: #8a6d3b;
    font-family: $system-font-family !important;
  }
}
