#courses {
  font-family: 'Lato', 'Helvetica Neue', Arial;
  padding: 0;
  border-radius: 5px;
  box-shadow: rgba(149, 157, 165, .2) 0 8px 24px;
  border: 0;

  #course-1 a {
    color: #17573a;
  }

  /*
  * Tabs Navigation
  */
  ul.ui-tabs-nav {

    @include mappy-bp(med) {
      background-color: transparent;
      border-bottom: solid 1px #ccc;
      padding: 0;
      display: flex;
      margin: 0;
      justify-content: space-between;
    }

    // List item
    li {

      @include mappy-bp(med) {
        margin: 0;
        padding: 0;
        background-color: transparent;
        text-align: center;
        font-weight: 400;

        border: 0;
        color: $color__black;
        float: none;
      }
    }

    // List item anchor
    a {
      display: block;
      float: none;

      @include mappy-bp(med) {
        font-size: rem(18px);
        color: #666;
        border: 0;
        border-bottom: solid 3px transparent;
        background-color: transparent;
        border-radius: 0;
        padding: rem(12px);
      }

      @include mappy-bp(wide) {
        font-size: rem(20px);
      }

      &:hover {
        color: #000;
      }

      &:focus {
        outline: 0;
      }
    }

    // Active list item anchor
    .ui-state-active a {
      @include mappy-bp(med) {
        background-color: transparent;
        color: $color__black;
        border-bottom-color: $color__black;
        font-weight: 600;
      }
    }

    /*
    * Tab Panel
    */
    .ui-tabs-panel {
      padding: 1rem;
    }

    .ui-tabs-panel.ui-widget-content {
      font-size: 1rem;

      @include mappy-bp(larger) {
        font-size: 1.125rem;
      }
    }
  }
}