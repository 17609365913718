.view--corporate-user-registered-classes.view--display-block_1 {
    .view__content {
    > .tablesaw {
      thead {
        th {
         padding: 1.25rem 1rem;
         font-size: 1rem;

         a {
          color: $color__black;
          text-decoration: none;

          &:hover,
          &:focus {
           text-decoration: underline;
           color: $sja_green;
          }
         }
        }
       }

       tbody {
        td {
         padding: 2rem 1rem;
         font-size: 1rem;
         font-weight: bold;

         a {
          color: $sja_green;
          text-decoration: none;

          &:hover,
          &:focus {
           text-decoration: underline;
          }
         }
        }
       }

      .views-field-title {
        width: 20%;
      }
      .views-field-field-class-start-date {
        width: 15%;
      }
      .views-field-quantity-1 {
        width: 5%;
      }
      .views-field-field-class-location-entity-refe {
        width: 20%;

      }
      .views-field-field-attendee.views-field-order-item-id.views-field-quantity {
        .employees {
          margin-top: .5rem;
          > p {
            margin-bottom: .5rem;
          }
          > ol li {
            margin: 1rem 0;
            @include mappy-bp(widest) {
              margin: 2rem 0;
            }
          }
          a.webform-dialog.button {
          background: url('/themes/custom/sja/src/000_assets/icons/icon__edit.svg') center no-repeat;
          background-size: auto;
          border-radius: 50%;
          overflow: hidden;
          width: 30px;
          height: 30px;
          margin-left: .75rem;
          display: inline-block;
          vertical-align: bottom;
            &:hover {
              filter: sepia(100);
            }
          }
        }
        > .webform-dialog.webform-dialog-large.button.assign {
          background: url('/themes/custom/sja/src/000_assets/icons/icon__add.svg') center no-repeat;
          background-size: auto;
          border-radius: 50%;
          display: block;
          overflow: hidden;
          width: 30px;
          height: 30px;
          margin-left: 2rem;
          &:hover,
          &:focus,
          &:active {
            background-image: url('/themes/custom/sja/src/000_assets/icons/icon__add-hover.svg');
          }
        }
      }
    }
  }
  .feed-icons {
    margin: 1rem 0 3rem;

    .csv-feed {
     a {
      display: inline-block;
      width: auto;
      background: none;
      text-indent: 0;
      font-size: .875rem;
      font-weight: 800;
      color: $color__black;
      text-decoration: none;

      &:hover,
      &:focus {
       text-decoration: underline;
      }
     }
    }
  }
}

.ui-front.ui-dialog-content.ui-widget-content {
  .webform-submission-register-employees-contacts-form,
  .webform-submission-edit-employee-form,
  .webform-submission-assign-edit-email-form,
  .webform-submission-add-new-employee-form {
    margin: 3rem auto;
    .form-item--checkbox {
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .form-checkbox {
            display: none;
        }
        // display: none;
      }
    }
    .form-item {
      >.form-required,
      >.is-required {
        &::after {
          content: '*';
          display: inline;
          color: $sja_red;
          font-size: 1rem;
          line-height: 0;
        }
      }

      label {
        word-break: break-all;
      }
    }
  }
}

