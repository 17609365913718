html {
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

body {
  // Basic type set
  font-family: $base-font-family;
  color: $color__text--dark;
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: left;
  overscroll-behavior: none;

  // color: #000000;
  @include mappy-bp(med) {
    font-size: 16px;
    line-height: 1.63;
  }
  @include mappy-bp(wide) {
    font-size: 20px;
  }
}


//styles for image captions
figcaption {
  font-size: 12px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  @include mappy-bp(med) {
    font-size: rem(16px);
  }
}
//styles for blockquote
blockquote {
  display: inline-block;
  position: relative;
  margin: rem(25px 0);
  width: 100%;
}

.blockquote_wrapper::before {
  content: url('../../src/000_assets/icons/quote-1.svg');
  position: absolute;
  top: rem(-15px);
  left: 0;
  @include mappy-bp(768px) {
    left: rem(-60px);
  }
  @include mappy-bp(large) {
    left: rem(-90px);
  }
  // transform: translateY(-30%);
}

.blockquote_wrapper::after {
  content: url('../../src/000_assets/icons/quote-2.svg');
  position: absolute;
  right: 0;
  bottom: rem(-21px);
  @include mappy-bp(768px) {
    right: rem(-60px);
  }
  @include mappy-bp(large) {
    right: rem(-90px);
  }
}

blockquote::after {
  content: ' ';
  position: absolute;
  width: rem(60px);
  height: 2px;
  background: black;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #bec6c4;
}

blockquote::before {
  content: ' ';
  position: absolute;
  width: rem(60px);
  height: 2px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #bec6c4;
}

.blockquote_wrapper {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  position: relative;
  padding: rem(67px 20px);
  display: inline-block;
  width: 100%;
  @include mappy-bp(768px) {
    margin: rem(67px 0);
    padding: 0;
    font-size: 20px;
  }

  .small--quote {
    text-align: center;
  }
}
// IE hack to remove border from linked img
a img {
  border: 0;
}

a,
p a {
  color: $sja_green;
  text-decoration: underline;

  &.gbutton {
    @include green-button-desktop();
  }
}

.more-link {
  margin-top: vr(.25);
  text-transform: uppercase;
  font-size: .8rem;

  a {
    padding-right: vr(.5);
    display: inline-block;
    color: $color__primary;
    font-weight: bold;

    &::after {
      content: ' ';
      width: 10px;
      height: 10px;
      display: inline-block;
      background-image: url('../../assets/arrows/icons__arrow—link.svg');
      background-repeat: no-repeat;
      background-size: 10px 10px;
      margin-left: .25rem;
    }
  }
}
// Style for Italics
p + em {}
// Content link styles
.l-main a {
  text-decoration: none;
  color: $color__primary;
  border-bottom: 1px solid transparent;
  @extend %animate;

  &:hover {
    border-bottom: 1px solid $color__primary;
  }
}
// Removing link styles from contextual links
.contextual-links a {
  border-bottom-color: transparent !important;

  &:hover {
    border-bottom-color: transparent !important;
  }
}
// Making all images responsive
img {
  display: block;
  height: auto;
  max-width: 100%;
}

hr {
  display: block;
  margin: .5rem auto;
  border-style: inset;
  border-width: 1px;
  border-color: $color__primary;
}
// Setting bold based on custom font
b,
strong {
  font-weight: 500;
}
// NEW marker styles
.marker {
  font-weight: 500;
}

li {
  list-style-position: inside;
}
// Use Apex Rounded for all the headings and margin correctins
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: vr(1); // 1 baseline
  margin-bottom: vr(.25); // .5 baselines
}
///Resetting paragraph margins
p + p {
  margin-top: vr(.75);
}

h2 + p,
h3 + p,
h4 + p,
h5 + p,
h6 + p {
  margin-top: 0;
}
// H1 Styles
%h1,
.h1,
h1 {
  font-size: rem(24px);
  // line-height: 1.33;
  @include mappy-bp(large) {
    font-size: rem(44px);
    // line-height: 0.93;
  }
  // font-family: $base-font-family;
  font-style: normal;
  font-weight: 900;
  letter-spacing: rem(1.1px);
}
// H2 Styles
%h2,
.h2,
h2 {
  font-size: rem(24px);
  // line-height: 1.33;
  @include mappy-bp(large) {
    font-size: rem(36px);
    letter-spacing: rem(.9px);
    // line-height: 0.93;
  }
  // font-family: $base-font-family;
  font-style: normal;
  font-weight: 900;
  letter-spacing: rem(.6px);
}
// H3 Styles
%h3,
.h3,
h3 {
  font-size: rem(20px);
  // line-height: 1.33;
  @include mappy-bp(large) {
    font-size: rem(32px);
    letter-spacing: rem(.8px);
    // line-height: 0.93;
  }
  // font-family: $base-font-family;
  font-style: normal;
  font-weight: 900;
  letter-spacing: normal;
}
// H4 Styles
%h4,
.h4,
h4 {
  font-size: rem(20px);
  // line-height: 1.33;
  @include mappy-bp(large) {
    font-size: rem(28px);
    letter-spacing: rem(.7px);
    // line-height: 0.93;
  }
  // font-family: $base-font-family;
  font-style: normal;
  font-weight: 900;
  letter-spacing: rem(.5px);
}
// H5 Styles
%h5,
.h5,
h5 {
  font-size: rem(14px);
  // line-height: 1.33;
  @include mappy-bp(large) {
    font-size: rem(20px);
    letter-spacing: rem(1.05px);
    // line-height: 0.93;
  }
  // font-family: $base-font-family;
  font-style: normal;
  font-weight: 900;
  letter-spacing: rem(.5px);
  color: $color__primary;
  text-transform: uppercase;
}
// H6 Styles
%h6,
.h6,
h6 {
  font-size: rem(14px);
  // line-height: 1.33;
  // font-family: $base-font-family;
  font-style: normal;
  font-weight: 900;
  letter-spacing: rem(1.05px);
}

.block--page-title h1 {
  @include mappy-bp(768px) {
    margin-top: 0;
  }
}

.addthis {
  padding-top: vr(.5);

  a {
    &:hover {
      border-bottom: none !important;
      opacity: .8;
    }
  }

  .at-share-btn {
    &:hover {
      transform: translateY(0) !important;
    }
  }
}

.small {
  font-size: rem(10px);
}