$color__tab--normal:             #2a414f;
$color__tab--bkg:                #2e323d;
$color__tab--active:             #2a414f;
$color__tab--hover:              #45405e;

.block--tabs {
  text-align: right;
  max-width: $content-max-width;
  margin: 0 auto;
  padding: 0 1rem;
  transition: .5s all ease-in;

  .block__content ul {
    @extend %cf;

    // margin-top: 12rem;
    // margin-bottom: .5rem;

    li {
      display: inline-flex;

      margin: 0;
      padding: 0;

      list-style: none;

      a {
        display: block;

        padding: .2rem .6em;

        letter-spacing: 1px;
        text-transform: uppercase;

        color: $color__tab--active;
        border: 2px solid $color__tab--bkg;
        background-color: white;

        font-family: $system-font-family !important;
        font-size: .7rem;
        font-weight: 500;

        margin-bottom: 0 !important;

        &:hover {
          color: white;
          background-color: $color__tab--bkg;

          margin-bottom: 0 !important;
        }
      }
    }

    li {
      &.is-active a {
        color: white;
        background-color: $color__tab--bkg;
      }
    }

    ul.tabs.primary {
      @include mappy-bp(med) {
        float: right;
      }

      float: left;

      margin-bottom: 0;
      padding-left: 1rem;
    }

    ul.tabs.secondary {
      @include mappy-bp(med) {
        float: right;
      }

      float: left;
      clear: both;

      margin-top: .5rem;
      padding-left: 1rem;

      a {
        padding: .1rem .4;

        font-size: .6rem;
        font-weight: 500;
      }
    }
  }
}
