/// Clearfix hack for wrapping elements around their floated children
///
/// @group placeholders
///
/// @example
/// 	@extend %cf;
///

%cf {
  @include cf;
}

%cf-importnat {
  @include cf-important;
}

%lsn {
  list-style: none;
}

%animate {
  transition: all .2s;
}
// Accessibility utility classes
%visually-hidden {
  @include visually-hidden;
}

%visually-hidden-off {
  @include visually-hidden-off;
}

%focusable {
  @extend %visually-hidden;

  &:active,
  &:focus {
    @extend %visually-hidden-off;
  }
}

%hidden {
  @include hidden;
}

%invisible {
  @include invisible;
}

%block-container {
  padding: 0 1rem;
  margin: 0 auto;

  @include mappy-bp(med) {
    padding: rem(20px) 5.125rem;
  }
}
%black-button {
  display: inline-block;
  padding: 9px 38px;
  border-radius: 20px;
  border: solid 2px #000000;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: .05em;
  color: #000000;
  transition: .2s all;
  &:hover, &:focus {
    background-color: #000;
    color: white;
  }
}

%green-button-desktop {
  @include green-button-desktop;
}

%green-button-mobile {
  padding: 10px 40px;
  border-radius: 20px;
  border: solid 2px #006633;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1.33;
  font-weight: 900;
  letter-spacing: 1.74px;
  color: $color__primary;
  transition: .2s all;
  display: inline-block;

  &:hover, &:focus {
    background-color: $color__primary;
    color: $color__white;
  }
}

%cta-white-button {
  padding: 10px 36px;
  border-radius: 20px;
  border: solid 2px #006633;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1.36;
  font-weight: 900;
  letter-spacing: 1.05px;
  color: $color__white;
  transition: .2s all;
  display: inline-block;

  &:hover, &:focus {
    background-color: $color__white;
    color: $color__primary;
  }
}

%breadcrumbs {
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #006633;
  position: relative;
  display: inline-block;
  text-decoration: none;

  &::before {
    content: ' ';
    display: inline-block;
    border: 0;
    font-size: 0;
    margin: 0;
    width: 30px;
    height: 30px;
    // color: #fff;
    background-image: url('../../src/000_assets/icons/double_arrow_n.svg');
    background-repeat: no-repeat;
    background-position: center;
    // background-size: 30px 30px;
    position: absolute;
    right: 3.125rem;
    top: 50%;
    transform: translateY(-50%);
  }

  &:hover, &:focus {
    color: black;
  }

  &:hover, &:focus {
    &::before {
      background-image: url('../../src/000_assets/icons/double_arrow_h.svg');
    }
  }
}

%full-width-black-heading,
.full-width-black-heading {
  font-size: 20px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  position: relative;
  overflow: hidden;
  margin: 0;
  @include mappy-bp (large) {
    font-size: 36px;
    letter-spacing: rem(.9px);
  }

  &::after {
    content: ' ';
    // display: none;
    width: 100%;
    margin-left: 16px;
    transform: translateY(-50%);
    top: 50%;
    position: absolute;
    background-color: #006633;
    height: 2px;
    @include mappy-bp(land) {
      display: inline-block;
    }
  }
}

.full-width-black-heading.two-lines,
.full-width-green-heading.two-lines {
  &::after {
    display: none;
  }
}



%full-width-green-heading,
.full-width-green-heading {
  font-size: rem(14px);
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: rem(1.74px);
  text-align: left;
  color: $color__primary;
  position: relative;
  text-transform: uppercase;
  overflow: hidden;
  margin: 0;
  @include mappy-bp (med) {
    font-size: rem(16px);
    letter-spacing: rem(1.98px);
  }

  &::after {
    content: ' ';
    // display: none;
    width: 100%;
    margin-left: 12px;
    transform: translateY(-50%);
    top: 50%;
    position: absolute;
    background-color: #787e7c;
    height: 2px;
    @include mappy-bp(land) {
      display: inline-block;
    }
  }
}

%heading_box-shadow,
.heading_box-shadow {
  font-size: 24px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: rem(.6px);
  text-align: left;
  color: #171918;
  padding: rem(5px) rem(11px);
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, .16);
  background-color: #ffffff;
  display: inline-block;
  @include mappy-bp(med) {
    font-size: rem(36px);
    padding: rem(20px) rem(35px);
  }
}

%slide-in-green,
.slide-in-green {
  font-size: 16px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: .4px;
  color: #006633;
  position: relative;
  text-decoration: none;
  border-bottom: 4px solid #006633;

  &::after {
    content: ' ';
    // display: inline-block;
    // color: #fff;
    background-image: url('../../src/000_assets/icons/right_green_arrow.svg');
    background-repeat: no-repeat;
    background-position: center;
    // background-size: 30px 30px;
    position: absolute;
    top: 80%;
    transform: translateY(-80%);
    // margin-left: 13px;
    width: 10px;
    height: 16px;
    opacity: 0;
    -webkit-transition: all .25s linear;
    transition: all .25s linear;
  }
  // &:hover {
  //   border-bottom: 4px solid #006633;
  // }
  &:hover, &:focus {
    &::after {
      opacity: 1;
      -webkit-transition: all .5s linear;
      transition: all .5s linear;
      margin-left: 13px;
      // transform: translateY(-50%);
    }
  }
}

%slide-in-black,
.slide-in-black {
  font-size: 16px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: .4px;
  color: #000000;
  position: relative;
  text-decoration: none;
  border-bottom: 4px solid #000000;

  &::after {
    content: ' ';
    // display: inline-block;
    // color: #fff;
    background-image: url('../../src/000_assets/icons/right_black_arrow.svg');
    background-repeat: no-repeat;
    background-position: center;
    // background-size: 30px 30px;
    position: absolute;
    top: 80%;
    transform: translateY(-80%);
    // margin-left: 13px;
    width: 10px;
    height: 16px;
    opacity: 0;
    -webkit-transition: all .25s linear;
    transition: all .25s linear;
  }
  // &:hover {
  //   border-bottom: 4px solid #000000;
  // }
  &:hover, &:focus {
    &::after {
      opacity: 1;
      -webkit-transition: all .5s linear;
      transition: all .5s linear;
      margin-left: 13px;
      // transform: translateY(-50%);
    }
  }
}

%green-link,
.green-link {
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: .4px;
  text-align: left;
  color: #006633;
  border-bottom: 2px solid #006633;
  text-decoration: none;

  &:hover, &:focus {
    color: #000000;
    border-bottom: 2px solid #000000;
  }
}

%black-link,
.black-link {
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: .4px;
  text-align: left;
  color: #000000;
  border-bottom: 2px solid #000000;
  text-decoration: none;

  &:hover, &:focus {
    color: #006633;
    border-bottom: 2px solid #006633;
  }
}

%cards {
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, .16);
  background-color: #ffffff;
}


%green-button {
  height: rem(34px);
  border-radius: rem(17px);
  border: 2px solid transparent;
  background: #18573a;
  color: #fff;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  font-size: 1rem;
  text-decoration: none;
  transition: all .2s ease-in-out;
  padding: 0 rem(12px);

  &:hover {
    background: #fff;
    color: #18573a;
    border-color: #18573a;
  }
}