.view--commerce-user-invoices {
  position: static;
  margin-bottom: rem(80px);

  @include mappy-bp(large) {
    margin-bottom: rem(188px);
  }

  .view__header {
    margin: 3rem 0;

      @include mappy-bp(large) {
        margin: -.75rem 0 3rem;
      }

    h1 {
      font-size: 1.5rem;
      margin: 0;

      @include mappy-bp(large) {
        font-size: 2.25rem;
      }
    }
  }

  .view__filters {


    .form-actions {

      input {
        margin-top: 1rem;
      }


      input[id^='edit-reset--'] {
        color: #787e7c;
        margin: 1.3125rem;
        padding: 0;
        height: 2rem;
        border: none;
        border-bottom: 2px solid transparent;
        border-radius: 0;
        font-weight: bold;

        &:hover,
        &:focus {
          background-color: transparent;
          color: $sja_green;
          border-bottom-color: $sja_green;
        }
      }
    }
  }

  .view__empty,
  .view__content {
    margin: 2rem 0 0;

    @include mappy-bp(large) {
      margin: 3rem 0 0;
    }

    table {

    }

    thead {
      th {
        padding: 1.25rem 1rem;
        font-size: 1rem;

        a {
          color: $color__black;
          text-decoration: none;

          &:hover,
          &:focus {
            text-decoration: underline;
            color: $sja_green;
          }
        }
      }
    }
    tbody {
      td {
        padding: 2rem 1rem;
        font-size: 1rem;
        font-weight: bold;

        a {
          color: $sja_green;
          text-decoration: none;

          &:hover,
          &:focus {
            text-decoration: underline;
          }
        }
      }
    }

    .views-field-total-price__number,
    .views-field-total-paid__number {
      color: $color__black;
    }
  }

  .feed-icons {
    margin: 1rem 0 3rem;

    .csv-feed {
      a {
        display: inline-block;
        width: auto;
        background: none;
        text-indent: 0;
        font-size: .875rem;
        font-weight: 800;
        color: $color__black;
        text-decoration: none;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }
}
