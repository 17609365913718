form.user-login-form {

  max-width: $content-max-width;
  margin-left: auto;
  margin-right: auto;
  padding: 1.5rem 8%;
  .form-actions {
    @include mappy-bp(med) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  @include mappy-bp(wide) {
    padding: 2rem 3.75rem;
  }
}

//, .user-logged-out.path-first-time-login
.no-sidebars.user-logged-out.path-user,
.no-sidebars.user-logged-out.path-first-time-login {

  .path-user .layout-content .region-content,
  .path-user.sidebar-first .layout-content .region-content {
    border: 1px solid green;
  }

  .layout-content .region-content .path-user.sidebar-first .layout-content .region-content {
    flex: 0 1 100%;
    flex-basis: 100% !important;
    margin: 0 auto;

    @include mappy-bp(mobile) {
      flex: 0 1 100%;
      flex-basis: 100% !important;
      margin: 0 auto;
    }

    @include mappy-bp(med) {
      flex: 0 1 100%;
      flex-basis: 100% !important;
      margin: 0 auto;
    }

  }

  #edit-actions {

    text-align: center;
    @include mappy-bp(med) {
      text-align: left;
    }

    #edit-submit {
      margin-bottom: 1rem;
      width: 100%;


      @include mappy-bp(med) {
        width: auto;
        text-align: left;
      }
    }
    a {
      text-align: left;
      font-size: .8rem;
      font-weight: 800;
      letter-spacing: 1.05px;
      color: $color__primary;
      text-transform: uppercase;
      text-decoration: none;
      margin-left: 0;

      @include mappy-bp(med) {
        margin-left: 2rem;
      }
    }
  }

  .user-register--title {
    margin-left: 14%;
    margin-bottom: 4rem;
  }

  .user-login--title {
    margin-left: 14%;
    margin-bottom: 4rem;
  }

  .layout-content {
    padding: 0;
    flex: 0 1 auto;
    justify-content: center;
  }

  .region-content {
    flex-basis: 100%;
    padding: 0;
    margin: 0 auto;

  .block-userloginregistration-menu {
    margin-bottom: 4rem;
  }

  .user-pass {
    font-size: .8rem;
    color: $color__text--light;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    margin-bottom: 4rem;
    padding: 1rem;
    box-shadow: 0 0 24px rgba(0, 0, 0, .24);
    width: 90%;

    @include mappy-bp(land) {
      width: $content-max-width-user-block;
    }

    .form-item--id-persistent-login {
      label {
        text-transform: capitalize;
      }

    }

    .form-item {
      margin-bottom: 0;
    }

    .form-item__description {
      display: none;
    }

    #edit-email--description {
      display: block;
    }

    .form-item__label {
      font-weight: 600;
    }

    @include mappy-bp(mobile) {
      padding: 2rem;
    }

    @include mappy-bp(med) {
      padding: 3rem;
    }

  }

  form.user-login-form,
  .user-register-form,
  .dynamics-users-migration-form,
  .user-pass-reset {
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    margin-bottom: 4rem;
    padding: 1rem;
    box-shadow: 0 0 24px rgba(0, 0, 0, .24);
    width: 90%;

    .field--name-field-account-type {

      legend {
        margin-bottom: 0;
      }

      .fieldset__legend-text {
        display: block;
        letter-spacing: 1.2px;
        text-transform: uppercase;
        color: #000000;
        font-size: 1rem;
        font-weight: 600;
      }

      input[type='radio']:focus + label {
        outline: none;
      }

      .form-item {
        margin-bottom: .5rem;
      }

      label {
        font-weight: 400;
      }

      @include mappy-bp(0 640px) {
        label {
          font-size: .875rem;
        }
      }
    }

    @include mappy-bp(land) {
      width: $content-max-width-user-block;
    }

    .form-item--id-persistent-login {
      label {
        text-transform: capitalize;
      }

    }

    .form-submit {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    .form-item {
      margin-bottom: 2rem;
    }

    .form-item__description {
      display: none;
    }

    #edit-email--description {
      display: block;
    }

    .form-item__label {
      font-weight: 600;
    }

    @include mappy-bp(mobile) {
      padding: 2rem;
    }

    @include mappy-bp(med) {
      padding: 3rem;
    }

  }
  }

  .menu--user-login-registration {

    #block-userloginregistration-menu {
      max-width: 1540px;
      margin: 0 auto;
    }

    .nav--user-login-registration {
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      align-content: center;
      list-style-type: none;

      @include mappy-bp(land) {
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: flex-start;
        align-content: center;
      }

      font: $color__secondary 16px/22px Nunito Sans;
      font-weight: 600;

      letter-spacing: 1.2px;
      @include mappy-bp(land) {
        border-bottom: 2px solid $color__grey--light-medium;
      }

      .is-active-trail {
        color: $color__primary;
        display: inline-block;
      }

      .nav__item {
        padding-right: 0;
        text-transform: uppercase;
        margin: 0;
        margin-bottom: 1.5rem;
        flex: 0 1 auto;
        align-self: auto;


        @include mappy-bp(land) {
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;
          flex: 0 1 auto;
          align-self: auto;
        }

        .is-active-trail {
          color: $color__primary;
        }

        .is-active-trail::after {
          content: '';
          display: block;
          position: relative;
          width: 100%;
          height: 2px;
          bottom: -2px;
          left: 45%;
          margin-left: -45%;
          background: $color__primary;
        }

      }

      .nav__link {
        font-size: 1rem;
        color: #474B4A;
        text-decoration: none;
        border-bottom: 2px solid #BEC6C4;
        @include mappy-bp(land) {
          border-bottom: 0;
        }
      }
    }
  }

}
