.path-first-time-login {
  .region-content {
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }

  .region-content {
    max-width: $content-max-width;
    margin: 0 auto;
    padding: 0 1rem 1rem 1rem;
    transition: .5s all ease-in;

    @include mappy-bp(med) {
      padding: 0 3.75rem;
    }

    @include mappy-bp($content-max-width + 32px) {
      padding: 0;
    }
  }
}