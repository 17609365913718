
.form-item-search-block-form {
  display: inline-block;
}

select + button,
select + .btn,
select + button:hover,
select + .btn:hover,
select + button:focus,
select + .btn:focus,
input + button,
input + .btn,
input + button:hover,
input + .btn:hover,
input + button:focus,
input + .btn:focus {
  border-bottom: 0;
  border-radius: 0;
}

main input[type='submit'],
#edit-cancel {
  @extend %green-button-desktop;
  width: auto;
  margin-top: 3rem;
  padding: .5rem 2.75rem;
  cursor: pointer;
}

input[type='submit']:disabled {
  background-color: $light_light_grey;
  cursor: not-allowed;
  transition: none;

  &:hover {
    color: $color__primary;
  }
}

#edit-cancel {
  color: $color__text--dark;
  background-color: white;
  border: 3px solid $color__text--dark;

  &:hover {
    color: $color__white;
    background-color: $color__text--dark;
    border: 3px solid $color__text--dark;
  }
}
