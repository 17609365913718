.node__content {
  .quick_link {
    position: relative;
    width: 100%;
    height: 220px;
    background-color: #d9e0de;
    overflow: hidden;
    display: block;
    margin: 0 auto 16px;

    @include mappy-bp(med) {
      height: 300px;
      padding: 1rem;
      margin: 0;
    }

    @include mappy-bp(large) {
      min-height: 380px;
      padding: 3rem;
    }

    .quick_link_title {
      width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 20px;
      font-weight: 900;
      line-height: 1.35;
      letter-spacing: .5px;
      text-align: left;
      color: #000000;
      margin: 16px 15px 11px 17px;

      @include mappy-bp(med) {
        width: 100%;
        margin: 0;
        padding-bottom: 1rem;
      }
    }

    .quick_link_body {
      width: 90%;
      line-height: 1.57;
      overflow: hidden;
      font-size: 14px;
      text-align: left;
      color: #000000;
      margin: 10px 15px 15px 17px;

      @include mappy-bp(med) {
        width: 100%;
        margin: 0;
        padding-bottom: 1rem;
      }
    }

    .quick_link_url {
      display: block;
      position: absolute;
      bottom: 2rem;
      right: 1rem;

      a {
        @extend %slide-in-black;
        padding-bottom: 3px;
        font-size: 16px;
      }
      @include mappy-bp(large) {
        position: absolute;
        bottom: 3rem;
        right: 3rem;
      }
    }
  }
}
