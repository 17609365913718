// Overrides layout region margin top and bottom
// No padding since it's a full width element.
.layout__region-container > .layout__region > .full-width-image--container {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

.full-width-image--container {
  @extend %cf;
  margin: 0;

  @include mappy-bp(med) {
    position: relative;
  }

  .full-width-image--image {
    img {
      width: 100%;

      @include mappy-bp(med) {
        max-height: rem(700px);
      }
    }
  }

  .full-width-image--content {
    margin: rem(16px);
    padding: rem(16px);
    box-shadow: 0 0 rem(24px) 0 rgba(0, 0, 0, .16);
    background-color: $color__white;

    .full-width-image--featured {
      font-size: rem(14px);
      color: $color__grey--dark;
      text-transform: uppercase;
      line-height: 1.71;
      letter-spacing: rem(.7px);
      text-align: left;
    }

    .full-width-image--title,
    .full-width-image--link {
      padding: 0 rem(10px);

      @include mappy-bp(med) {
        // padding: 0 rem(60px);
        padding: 0 2.5rem;
      }
    }

    .full-width-image--title h2 {
      font-size: rem(20px);
      font-weight: 900;
      color: $color__primary;
      line-height: 1.35;
      text-align: left;
      margin-top: rem(22px);
      word-break: break-all;

      &::after {
        content: '”';
      }

      &::before {
        content: '“';
      }

      @include mappy-bp(med) {
        font-size: rem(32px);
        margin-top: rem(47px);
      }
    }

    .full-width-image--link {
      margin: rem(35px) 0;

      a {
        @extend %green-button-mobile;
        display: inline-block;
      }

      @include mappy-bp(med) {
        margin: rem(44px) 0;

        a {
          @include green-button-desktop;
        }
      }
    }

    @include mappy-bp(med) {
      // margin: 0 rem(67px);
      padding: rem(26px), rem(18px);
      width: rem(584px);
      // height: rem(311px);
      height: auto;
      // bottom: rem(384px);
      position: absolute;
      max-width: 70%;
      bottom: 3rem;
      left: 0;
      right: 0;
      margin: 0 auto;
    }

    @include mappy-bp(larger) {
      margin: inherit;
      left: 3rem;
      max-width: 50%;
    }

    @include mappy-bp(2000px) {
      bottom: 1rem;
    }
  }
}
