.page-node-type-article {
  .region-banner-image {
    margin-bottom: 40px;

    picture {
      width: 100%;
      max-width: 100%;
      height: auto;

      img {
        width: 100%;
        max-width: 100%;
        height: auto;
      }
    }
  }

  .banner_image_article_content {
    margin-bottom: 40px;

    picture {
      width: 100%;
      max-width: 100%;
      height: auto;

      img {
        width: 100%;
        max-width: 100%;
        height: auto;
      }
    }
  }

  .title_article_content {
    max-width: $content-max-width-sm;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    padding: 0 16px;
    font-size: 24px;
    line-height: 1.33;
    letter-spacing: .6px;
    margin: 26px auto;

    @include mappy-bp(med) {
      font-size: 36px;
      font-weight: 900;
      line-height: 1.36;
      letter-spacing: .9px;
      text-align: left;
      margin: 40px auto;
    }
  }

  .region-content {
    margin: 0 auto;
    padding: 0;
  }

  // setting max-widths for each element.
  .block--page-title,
  .publish_info,
  .updated_date,
  .region-content .node__content {
    max-width: $content-max-width-sm;
    margin-left: auto;
    margin-right: auto;
  }

  .block--page-title {
    height: auto;
    padding: 0 16px;
    font-size: 24px;
    line-height: 1.33;
    letter-spacing: .6px;
    margin: 26px auto;

    @include mappy-bp(med) {
      font-size: 36px;
      font-weight: 900;
      line-height: 1.36;
      letter-spacing: .9px;
      text-align: left;
      margin: 40px auto;
    }
  }

  .field_subtitle {
    width: 100%;
    font-size: 20px;
    line-height: 1.5;
    text-align: left;
    color: #000000;
  }

  .publish_info {
    padding: 0 16px;
    height: 40px;
    position: relative;
    margin: 0 auto 26px;

    .publish_date {
      padding: auto;
      margin: auto 12px auto auto;
      font-size: 16px;
      line-height: 1.5;
      color: #006633;
    }

    .seperator {
      border-left: 1px solid;
      border-right: 1px solid;
      position: absolute;
      color: #bec6c4;
      height: 40px;
      top: -5px;
    }

    .author_name {
      padding: auto;
      margin: auto auto auto 22px;
      font-size: 1rem;
      line-height: 1.5;
      color: #006633;
    }
    @include mappy-bp(med) {
      .author_name {
        font-size: 1.25rem;
      }

      .publish_date {
        font-size: 1.25rem;
      }
    }
  }

  .updated_date {
    width: auto;
    padding: 0 16px;
    height: 23px;
    font-size: 14px;
    font-style: italic;
    line-height: 1.4;
    color: #000000;
    margin-bottom: 40px;

    @include mappy-bp(med) {
      line-height: 2.14;
      margin: 0 auto 40px auto;
    }
  }

  .region-content .node__content {
    height: auto;
    padding: 0 16px;
    line-height: 1.63;
    text-align: left;
    padding-bottom: 20px;

    @include mappy-bp(med) {
      margin: 0 auto;
      line-height: 1.5;
      letter-spacing: normal;
    }
  }

  .block-div-contact {
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, .16);
    background-color: #ffffff;
    padding: 28px;
    margin: 65px 0;

    @include mappy-bp(med) {
      padding: 60px;
    }

    h3 {
      margin: 0;
      font-weight: 900;
      font-size: 20px;
      line-height: 1.28;
      letter-spacing: .8px;
      color: #000000;

      @include mappy-bp(med) {
        font-size: 32px;
      }
    }

    .field--name-field-name {
      margin-top: 38px;
      margin-bottom: 20px;
      display: inline-block;
      font-size: .875rem;
      font-weight: 900;
      line-height: 1;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      color: #006633;

      @include mappy-bp(med) {
        font-size: 1.25rem;
      }
    }

    .address {
      span {
        font-size: 14px;
        line-height: 1.5;
        text-align: left;
        color: #000000;
        text-decoration: none;
        display: inline-block;
        margin-right: 5px;
        @include mappy-bp(med) {
          font-size: 16px;
        }
      }

      br {
        display: none;
      }
    }

    a {
      font-size: 14px;
      line-height: 1.5;
      text-align: left;
      color: #000000;
      text-decoration: none;
      display: block;
      margin-top: 12px;
      @include mappy-bp(med) {
        font-size: 16px;
      }
    }

    .field--name-field-email {
      margin: 12px 0;
      font-size: 16px;
      line-height: 1.5;
      text-align: left;
      color: #000000;
      text-decoration: none;
      display: block;
    }
  }

  .nav--primary-local-tasks {
    text-align: left;
    padding: 16px;
    @include mappy-bp(med) {
      width: 648px;
      margin: 0 auto;
    }
    @include mappy-bp(larger) {
      width: 690px;
    }
    @include mappy-bp(widest) {
      width: 1000px;
    }
  }
  .node--type-article {
    >.block--social-media {
      overflow-y: hidden !important;
      max-width: $content-max-width-sm;
      margin-left: auto;
      margin-right: auto;
      @include mappy-bp(med) {
        padding: 0;
      }
    }
  }
}
