.class-registration-manually-sync-form,
.class-registration-manually-sync-form-individual,
.certificate-sync-form-manual-corporate,
.company-registration-orders-manual-sync-form,
.certificate-sync-form-manual-individual，
.invoice-sync-form-individual {
  > input {
    word-wrap: break-word;
    height: auto;
    white-space: normal;
  }
}
.company-registration-orders-manual-sync-form {
  margin-bottom: 2rem;
}

.invoice-sync-form-individual,
.invoice-sync-form-corporate {
  margin-bottom: 2rem;
  form {
    input {
      margin-top: 0;;
    }
  }
}