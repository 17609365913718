.class__layout {
  max-width: $content-max-width;
  margin: 0 auto;
  padding: 2rem 1rem 1rem;
  transition: .5s all ease-in;

  @include mappy-bp(med) {
    padding: 2rem 3.75rem 6.5rem;
  }

  @include mappy-bp($content-max-width + 32px) {
    padding: 2rem 0 6.5rem;
  }

  .back_to_courses_menu {
    margin-bottom: 2rem;

    .breadcrumb__btn {
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: #006633;
      position: relative;
      text-decoration: none;
      padding-left: 18px;

      &::before {
        content: ' ';
        display: inline-block;
        background-image: url('../../src/000_assets/icons/double_arrow_n.svg');
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 12px;
        height: 12px;
        left: 0;
      }

      &:hover {
        color: black;
      }

      &:hover {
        &::before {
          background-image: url('../../src/000_assets/icons/double_arrow_h.svg');
        }
      }
    }
  }

  .class-title-course-link {
    @include mappy-bp(large) {
      width: 50%;
      hr {
        margin-left: 0;
        margin-bottom: 0;
      }
    }
  }

  .class__product-details {
    @include mappy-bp(large) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .field--label-above {
      .field__label {
        float: none;
      }
    }
    .commerce-order-item-add-to-cart-form {
      .field--widget-commerce-quantity {
        .form-item--error-message {
          color: $sja_red;
          font-size: 1rem;
        }
      }
      > .form-actions {
        > .is-disabled {
          opacity: .7;
        }
      }
      &.contact-required {
        .form-item--id-contact {
          font-style: italic;
          font-size: 1rem;
          margin-top: 15px;
          max-width: 70%;
        }
        .form-submit.is-disabled {
          background: $color__grey--medium;
          opacity: 1;
          color: white;
          border: none;
          &:hover, &:focus {
            color: white;
          }
        }
      }
    }
  }

  .class__product-details-information {
    @include mappy-bp(large) {
      margin-top: 1rem;
      width: 50%;
      margin-right: rem(50px);
    }
  }

  .product-details-regitration {
    width: 100%;
    float: none;
    .field--name-variations {
      .commerce-order-item-add-to-cart-form {
        .field--name-purchased-entity {
          display: none;
        }
      }
    }
    @include mappy-bp(large) {
      width: 35%;
    }

    .field--widget-commerce-quantity,
    .classVariationPrice,
    input[type='submit'] {
      max-width: 380px;
      width: 100%;
    }
    .call-to-register-wrapper {
      .call-to-register-link {
        @include green-button-desktop;
        background-color: $sja_green;
        color: $color__white;
        border-radius: 10px;
        max-width: 380px;
        width: 100%;
        height: 70px;
        cursor: pointer;
        text-transform: uppercase;
        padding: 1rem;
      }
    }
    .field--name-price {
      display: flex;
      flex-wrap: nowrap;
      height: 100%;
      justify-content: center;
      align-items: center;
      > div:first-of-type {
        margin-right: 10px;
      }
    }
  }

  hr + .class__title {
    margin-bottom: 1rem;

    @include mappy-bp(large) {
      margin-bottom: 0 0 2rem;
    }
  }

  .class__title {
    margin-top: 0;
  }

  .field-details,
  .field--name-field-address,
  .field--name-field-class-directions {
    margin-bottom: 1rem;
  }

  .field--name-field-address {
    margin-top: 1rem;
  }

  .class__label {
    font-weight: bold;
  }

  .class__location {
    .field__label {
      display: block;
      float: none;
    }
    .field.field--label-inline {
      > .field__label,
      > .field__item {
        display: inline-block;
        margin-right: 0;
      }
    }
    .vocabulary-branch {
      > .field {
        margin-top: 1rem;
      }
    }
    .field--name-field-class-location-entity-refe {
      .field__item {
        .vocabulary-class-location {
          .field--name-field-branch {
            display: none;
          }
        }
      }
    }
  }

  .class__location_branch {
    .field__label {
      display: block;
      float: none;
    }
    .field.field--label-inline {
      > .field__label,
      > .field__item {
        display: inline-block;
        margin-right: 0;
      }
    }
    .vocabulary-branch {
      > .field {
        margin-top: 1rem;
      }
    }
    .field--name-field-class-location-entity-refe {
      >.field__label {
        display: none;
      }
      .field__item {
        .vocabulary-class-location {
          >span,
          .field--name-field-address,
          .field--name-field-class-directions {
            display: none;
          }
        }
      }
    }
  }

  .field--name-field-cancellation,
  .field--name-field-session {
    .field__label {
      float: unset;
    }
  }
  .field--name-field-pricingtable,
  .field--name-field-session {
    .field--label-above .field__label {
      float: left;
    }
    .field--label-above .field__label {
        float: left !important;
    }
  }
  .class__pricingtable.field-details {
    margin: auto;
    width: 22vh;
    float: left;
    display: table;
  }

  .class__pricingtable {
    table td {
    padding: 1rem;
    padding-left: 0;
    text-align: center;
  }
  table tr {
    border-bottom: 1px solid #e5ebe9;
  }
}



  .field--name-field-session {
    table {
      thead {
        background-color: $light_light_grey;
        th {
          font-size: inherit;
          text-transform: none;
          font-weight: 600;
        }
      }
      tbody > tr {
        > td {
          width: 100% !important;
        }
        @include mappy-bp(land) {
          > td {
            width: 35% !important;
          }
          > td:nth-child(1) {
            width: 30% !important;
          }
        }
      }
    }
    @media screen and (max-width: 568px) {
      table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      table tr {
        display: block;
        margin-bottom: .625em;
      }

      table td {
        border-bottom: 1px solid #ddd;
        display: block;
        font-size: .8em;
        text-align: right;
      }

      table td::before {
        /*
        * aria-label has no advantage, it won't be read inside a table
        content: attr(aria-label);
        */
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
      }

      table td:last-child {
        border-bottom: 0;
      }
    }
  }

  .classVariationPrice {
    margin-top: 1.5rem;
    .field__label {
      float: none;
      margin-left: unset !important;
      margin-top: unset;
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        margin-top: 0;
      }
    }
    .field__item {
      padding: unset;
    }
  }
  .you-may-also-like {
    max-width: 100%;
    margin-bottom: 0;
    .field__items {
      > .field__item {
        margin-left: 0;
        margin-bottom: 3rem;
        .field--name-field-variation-image {
          border: 1px solid #e5ebe9;
        }
        .field-prices {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-wrap: wrap;
          margin: 2rem 0 1rem 0;
          > .field--name-list-price {
            margin-right: 1rem;
            text-decoration: line-through;
            color: $medium_dark_grey;
          }
        }
        .field--name-title {
          margin-bottom: .75rem;
          a {
            text-decoration: none;
            letter-spacing: .4px;
            color: $sja_green;
            font-weight: bold;
            border-bottom: 2px solid white;
            transition: .5s;
            display: inline;
            cursor: pointer;
            &:hover,
            &:focus {
              border-bottom: 2px solid $sja_green;
            }
            &:visited {
              color: $color__primary;
            }
          }
        }
        .search-result-description {
          margin-bottom: 1.5rem;
        }
        .commerce-order-item-variation-cart-form-form {
          margin-top: 2rem;
          > div,
          > input {
            display: none;
          }
          > .form-actions {
            display: block;
            .button--add-to-cart {
              margin-top: 0;
              @include mappy-bp(large mega) {
                padding: 9px 12px;
              }
            }
          }
        }
      }
      @include mappy-bp(land) {
        display: grid;
        overflow: hidden;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 1fr;
        grid-column-gap: 3rem;
        grid-row-gap: 4rem;
        > .field__item {
          width: 100%;
          height: 100%;
          .product-card-product {
            position: relative;
            height: 100%;
          }
          .variatons_add_to_cart_button {
            position: absolute;
            bottom: 0;
          }
        }
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          display: flex;
          align-items: flex-start;
          flex-wrap: wrap;
          >.field__item {
            width: 23%;
            .variatons_add_to_cart_button {
              position: relative;
            }
          }
          > div:nth-of-type(3n + 2) {
            margin: 0 5%;
          }
        }
      }
      @include mappy-bp(mega) {
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 4rem;
      }
    }
  }
}
