.visually-hidden {
  @include visually-hidden-important;
}

.focusable {
  &:active,
  &:focus {
    @include visually-hidden-off-important;
  }
}

.hidden {
  @include hidden-important;
}

.invisible {
  @include invisible-important;
}

@include selection($color__primary, white);
