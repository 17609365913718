/*
* Branding Block
*/
.block--site-branding {
  margin: rem(16px) 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @include mappy-bp(wide) {
    margin: 0 rem(64px) 0 0;
    display: block;
    width: auto;
  }

  // Logo
  .sja-logo img {
    width: rem(138px);
    height: auto;

    @include mappy-bp(mobile) {
      width: rem(158px);
    }
  }
}