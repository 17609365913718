.block--social-media {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border: none;
  display: block;
  left: 0;
  margin: 0 0 36px;
  overflow: hidden;
  position: relative;
  top: 0;
  width: auto;
  height: auto;
  padding: 0 1rem;
  @include mappy-bp(med) {
    width: 648px;
    margin: 0 auto 36px;
  }
  @include mappy-bp(larger) {
    width: 690px;
    margin: 0 auto 36px;
  }
  @include mappy-bp(wide) {
    position: sticky;
    overflow-y: scroll;
    float: left;
    top: 40%;
    height: 200px;
    width: 150px;
    padding: 20;
    word-break: break-all;
    z-index: 0;
    opacity: 1;
  }
  @include mappy-bp(widest) {
    margin-left: 40px;
    top: 50%;
  }

  .addtoany_list {
    > a {
      border-radius: 4px;
      height: 44px;
      line-height: 44px;
      opacity: 1;
      width: 44px;
      @include mappy-bp(wide) {
        margin-bottom: 16px;
      }
      background-repeat: no-repeat;
      &:focus {
        outline: none;
      }
      > span {
        background-color: transparent !important;
        > svg {
          display: none;
        }
      }
    }

    .a2a_button_twitter {
      background-image: url('../../src/000_assets/icons/s_twitter.svg');
      margin-left: 12px;
      &:hover {
        background-image: url('../../src/000_assets/icons/s_twitter_hover.svg');
      }
      @include mappy-bp(wide) {
        margin-left: 80px ;
      }
    }

    .a2a_button_facebook {
      background-image: url('../../src/000_assets/icons/s_facebook.svg');
      margin-left: 0;
      &:hover {
        background-image: url('../../src/000_assets/icons/s_facebook_hover.svg');
      }
      @include mappy-bp(wide) {
        margin-left: 80px;
      }
    }

    .a2a_button_linkedin {
      background-image: url('../../src/000_assets/icons/s_linkedin.svg');
      margin-left: 12px;
      &:hover {
        background-image: url('../../src/000_assets/icons/s_linkedin_hover.svg');
      }
      @include mappy-bp(wide) {
        margin-left: 80px;
      }
    }
  }

}
