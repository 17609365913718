.block--reference-to-content {
  .block__content {
    .content-wrapper {
      margin: 0 1rem;
      @include mappy-bp(land) {
        margin: 0;
      }
    }
    .field--name-field-image {
      img {
        margin: auto;
        @include mappy-bp(land large) {
          margin: 0;
        }
      }
    }
    .media--display-mode-three-column-layout-image {
      overflow: hidden;
      text-align: center;
      height: auto;
      @include mappy-bp(land) {
        height: 220px;
      }
      @include mappy-bp(med) {
        height: 290px;
      }
      @include mappy-bp(large) {
        height: 192px;
      }
      @include mappy-bp(larger) {
        height: 202px;
      }
      @include mappy-bp(wide) {
        height: 240px;
      }
      @include mappy-bp(mega) {
        height: 290px;
      }
      @include mappy-bp(widest) {
        height: 330px;
      }
      img {
        margin: auto;
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
      }
    }
    .field--name-field-category {
      margin-top: 2rem;
      margin-bottom: 1rem;
      text-transform: uppercase;
      p {
        font-size: 1.26rem;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: 1.2px;
        color: white;
        font-weight: 900;
        text-shadow: 2px 2px 4px #000000;
        position: relative;
        bottom: 5rem;
      }
    }
    .field--name-field-title {
      @extend %h4;
      padding: 15px;
      position: relative;
      .green {
        color: $sja_green;
      }
    }
    .field--name-body {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
      position: relative;
      bottom: .5rem;
      padding: 15px;
      @include mappy-bp(large) {
        padding-top: rem(24px);
        font-size: 16px;
        line-height: 1.63;
        letter-spacing: normal;
        padding-left: 22px;
        padding-right: 22px;
        text-align: center;
      }
    }
  }
}
.field--name-field-link {
  background-color: #FFFFFF;
  border-radius: 22px;
  color: #006633;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 1.74px;
  line-height: 1.36;
  padding: 9px 27px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-transition: .2s all;
  transition: .2s all;
  @include mappy-bp(large) {
    margin: 30px auto;
    padding: .625rem 1.75rem;
  }
  @include mappy-bp(small) {
    margin-bottom: 20px;
  }
  a, .cta_button {
    @extend %green-button-mobile;
    display: inline-block;
    padding: rem(10px) rem(36px);
    border-radius: rem(20px);
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    font-size: rem(12px);
    line-height: 1.36;
    font-weight: 900;
    letter-spacing: rem(1.05px);
    color: $sja_green;
    transition: .2s all;
    @include mappy-bp (land) {
      @include green-button-desktop;
      font-size: rem(14px);
      padding: rem(10px) rem(44px);
    }
    &:hover {
      background-color: $sja_green;
      color: $color__white;
    }
  }
}
.no_under_line {
  text-decoration: none;
}

.helper-content-container {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  @include mappy-bp(small) {
  -webkit-box-shadow: 5px 5px 15px 5px #D4D4D4;
  box-shadow: 5px 5px 15px 5px #D4D4D4;
  flex: 0 1 30%;
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
  padding: inherit;
  margin: 50px;
}
@include mappy-bp(land) {
  flex: 0 1 30%;
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
  padding: inherit;
  box-shadow: 5px 5px 15px 5px #d4d4d4;
  margin: 26px;
}
}
a.helper_link {
  text-decoration: none;
}
.layout__region--third {
  .block--reference-to-content {
    @include mappy-bp(land) {
      margin-left: auto;
      margin-right: 0;
    }

    @include mappy-bp(land large) {
      margin: 0;
    }
  }
}

.layout__region--second {
  .block--reference-to-content {
    @include mappy-bp(land) {
      margin: auto;
    }
    @include mappy-bp(1572px) {
      width: 100%;
    }
  }
}

