/*
* Course Class Tabs
*
* Appears on course nodes to display a list of available classes
* Uses jQuery UI tabs to display tabs
*/

.course-left-panel #tabs {
  background: transparent;
  border: 0;
  border-radius: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  position: relative;

  @include mappy-bp(med) {
    padding-top: rem(32px);
  }

  .tabs-nav-container {
    overflow: hidden;
    position: relative;
  }

  /*
  * Tabs Navigation
  */
  ul.ui-tabs-nav {
    background-color: transparent;
    border: 0;
    margin: rem(16px);
    padding: 0;
    display: flex;
    transition: transform 0.2s ease-out;

    @include mappy-bp(med) {
      margin: 0;
      justify-content: center;
    }

    &.no-overflow {
      justify-content: center;
    }

    // List item
    li {
      background-color: transparent;
      border: 0;
      color: $color__black;
      float: none;
      padding: 0;
      margin: 0 rem(8px) 0 0;
      list-style: none;

      @include mappy-bp(med) {
        margin: 0;
        padding: 0;
      }
    }

    li:last-child {
      margin: 0;
    }

    // List item anchor
    a {
      background-color: $color__white;
      border: solid 2px $color__black;
      display: block;
      float: none;
      padding: rem(8px) rem(18px);
      font-weight: 700;
      border-radius: 2px;

      @include mappy-bp(med) {
        font-size: rem(18px);
        color: #666;
        border: 0;
        border-bottom: solid 3px transparent;
        background-color: transparent;
        border-radius: 0;
        padding: rem(8px) rem(24px);
      }

      &:hover {
        color: #000;
      }

      &:focus {
        outline: 0;
      }
    }

    // Active list item anchor
    .ui-state-active a {
      background-color: $color__black;
      color: $color__white;

      @include mappy-bp(med) {
        background-color: transparent;
        color: $color__black;
        border-bottom-color: $color__black;
        font-weight: 600;
      }
    }
  }

  .courseTabs {
    padding-top: 0;

    @include mappy-bp(med) {
      margin-top: rem(-3px);
    }
  }
}

/*
* Tabs Overflow
*/
.tabs-nav-overflow-controller {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  background: #fff;
  display: flex;
  align-items: center;

  &--prev {
    left: 0;
  }

  &--next {
    right: 0;
  }

  &::before {
    content: "";
    position: absolute;
    width: rem(50px);
    top: 0;
    bottom: 0;
  }

  &--next::before {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 20%,
      rgba(255, 255, 255, 1) 80%
    );
    left: rem(-50px);
  }

  &--prev::before {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 20%,
      rgba(255, 255, 255, 0) 80%
    );
    right: rem(-50px);
  }

  button {
    background: transparent;
    border: 0;
    height: 100%;
    padding: 0 rem(16px);
  }

  img {
    width: rem(10px);
    height: auto;
  }

  &--prev img {
    transform: rotate(180deg);
  }
}

/*
* Classes ToolTip
*/
.course-tooltip {
  position: absolute;
  right: rem(16px);
  top: 0;
  border: 0;
  cursor: pointer;

  display: none;

  @include mappy-bp(large) {
    top: 2rem;
    display: block;
  }

  img {
    width: 1.3rem;
  }
}
