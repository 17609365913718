.form-item--checkbox {
  position: relative;
  margin-bottom: 1rem;
}

main input[type='checkbox'],
#drupal-off-canvas input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
  line-height: normal;
  margin: 1px \9 0 0;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;

  &:focus {
    border: 1px solid $color__grey--lighter;
    min-height: 100%;
    height: auto;
  }
}

main input[type='checkbox']:checked + label,
#drupal-off-canvas input[type='checkbox']:checked + label {
  background-image: url('../../src/000_assets/checkboxes/icon__checkbox--checked.svg');
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position-y: center;

  ///IE10 hack
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    background-image: url('../../src/000_assets/checkboxes/icon__checkbox--checked.png');
    background-size: 20px 20px;
  }
}

main input[type='checkbox'] + label,
#drupal-off-canvas input[type='checkbox'] + label {
  background-image: url('../../src/000_assets/checkboxes/icon__checkbox.svg');
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position-y: center;
  font-size: 1rem;

  ///IE10 hack
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    background-image: url('../../src/000_assets/checkboxes/icon__checkbox.png');
    background-size: 20px 20px;
  }
}

main input[type='checkbox'] + label,
#drupal-off-canvas input[type='checkbox'] + label {
  display: inline;
  font-weight: 400;
  padding: 1px 0 1px 26px;
}
