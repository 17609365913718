.moderation-dashboard-block {
  .moderation-dashboard {
    max-width: $content-max-width;
    margin-left: auto;
    margin-right: auto;
    padding: 1.5rem 8%;

    @include mappy-bp(wide) {
      padding: 2rem 3.75rem;
    }
  }
}