
.path-cart {

  // layout
  .layout-container > .wrapper {
    .cart-form,
    .cart-empty-page {
      max-width: $content-max-width;
      margin: 0 auto;
      transition: all .5s ease-in;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-bottom: rem(82px);

      // padding will be added to the breadcrumb link
      @include mappy-bp(med) {
        padding-left: 3.75rem;
        padding-right: 3.75rem;
      }
      @include mappy-bp(wide) {
        padding: 0 3.75rem rem(206px);
      }

      @include mappy-bp($content-max-width + 32px) {
        padding: 0 0 68px;
      }

      // Table styles
      table {
        max-width: none;
        overflow: scroll;

        tr {
          @include mappy-bp(med) {
            border: 1px solid #E5EBE9;
          }
        }

        th {
          border: 1px solid #FFF;
        }

        td {
          border: 1px solid #E5EBE9;
        }

        td,
        th {
          padding: 1rem;
          font-size: 1rem;
          text-align: center;

          &:first-of-type {
            text-align: left;
          }

          @include mappy-bp(med) {
            padding: 1.5rem 2rem;
            border: none;
          }
        }
      }

      .views-field-total-price__number {
        color: $color__black;
      }

      .views-field-edit-quantity .form-item--number {
        max-width: 100px;
        margin: 0 auto;

        input {
          padding-right: 1rem;
        }
      }

      .views-field-purchased-entity {
        @include mappy-bp(med) {
          min-width: 40%;
        }
      }

      .views-field-remove-button {
        input {
          display: block;
          width: 40px;
          height: 40px;
          margin: 0 auto;
          padding: 0;
          border-radius: 0;
          border: 1px solid transparent;
          background-color: $sja_green;
          background-image: url('/themes/custom/sja/src/000_assets/icons/close_white.svg');
          background-position: center;
          background-repeat: no-repeat;
          font-size: 0;

          &:hover,
          &:focus {
            background-color: #FFF;
            border-color: $sja_green;
            background-image: url('/themes/custom/sja/src/000_assets/icons/close_green.svg');
          }
        }
      }

      // Sub Totals
      .order-total-line {
        padding: 0;
        font-size: 1rem;
        text-align: left;

          @include mappy-bp(land) {
            text-align: right;
          }

        .order-total-line-label {
          text-transform: uppercase;
          min-width: 80px;

          @include mappy-bp(land) {
            min-width: 0;
          }
        }

        .order-total-line-value {
          width: auto;
          margin: .5rem 0 0 4rem;
        }
      }

      // Submit buttons
      .form-actions {
        input {
          display: block;
          margin-top: .5rem;

          @include mappy-bp(land) {
            display: inline-block;
          }
        }

        #edit-submit {
          margin-top: 2rem;
          margin-right: 1rem;

          @include mappy-bp(land) {
            margin-top: 4rem;
          }
        }
      }
    }
  }
}

// on french pages,
.lang-fr .layout-container > .wrapper .cart-form .order-total-line .order-total-line-label {
  min-width: 100px;
}

.view--commerce-cart-form {
  .view__header {
    padding-top: 1.5rem;

    @include mappy-bp(wide) {
      padding-top: 2rem;
    }

    .breadcrumb__btn {
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: #006633;
      position: relative;
      text-decoration: none;
      padding-left: 18px;

      &::before {
        content: ' ';
        display: inline-block;
        background-image: url('../../src/000_assets/icons/double_arrow_n.svg');
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 12px;
        height: 12px;
        left: 0;
      }

      &:hover {
        color: black;
      }

      &:hover {
        &::before {
        background-image: url('../../src/000_assets/icons/double_arrow_h.svg');
        }
      }
    }

    h1.page-title {
      font-size: 1.5rem;
      margin: 2rem 0;

      @include mappy-bp(med) {
        font-size: 2.25rem;
        margin-bottom: 4rem;
        margin: 3rem 0;
      }
    }
  }
}
