.paragraph--type-individual-stats {
  .stat-container {
    text-align: center;
    @include mappy-bp(1024px) {
      text-align: left;
    }

    .stat_icon {
      display: block;
      @include mappy-bp (1024px) {
        display: inline-block;
        vertical-align: top;
      }

      img {
        height: 65px;
        width: 65px;
        display: inline-block;
      }
    }

    .stat_content {
      display: inline-block;
      word-break: break-word;
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        word-break: break-all;
   }
      @include mappy-bp (1024px) {
        padding-left: 1rem;
        width: calc(100% - 80px);
      }

      .number {
        .field--name-field-number-callout {
          font-size: 34px;
          font-weight: 900;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.21;
          letter-spacing: .85px;
          text-align: left;
          color: #006633;
          @include mappy-bp (large) {
            font-size: 44px;
            line-height: .93;
            letter-spacing: 1.1px;
            text-align: left;
            color: #006633;
          }
        }
      }

      .text {
        @include mappy-bp (larger) {
          // padding-top: .625rem;
          display: flex;
          max-width: rem(240px);
        }
        @include mappy-bp (wide) {
          max-width: none;
        }

        .field--name-field-text-callout {
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.57;
          letter-spacing: 1.05px;
          text-align: center;
          color: #000000;
          text-transform: uppercase;
          @include mappy-bp (med) {
            text-align: left;
          }
          @include mappy-bp (large) {
            font-size: 16px;
            line-height: 1.38;
            letter-spacing: 1.2px;
            text-align: left;
          }
        }
      }
    }
  }
}

.masquerade-block-form {
  position: relative;
  margin-left: 15%;
  margin-right: 15%;
}
