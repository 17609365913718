ul.pager,
ul.pager__items,
ul.pagination {
  padding: 1rem 1rem 1rem 0;
  margin: 0;

  li {
    display: inline-block;
    padding: 0;
    margin: 0;
    font-size: .8rem;
    list-style: none;
    padding-left: rem(9px);
    line-height: 3rem;
    margin-left: 0 !important;

    a {
      font-size: rem(14px);
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: .7px;
      text-align: left;
      color: #000000;
      border-bottom: 4px solid #bec6c4;
      text-decoration: none;
      padding: rem(12px) rem(18px);

      &:active,
      &:hover {
        border-bottom: 4px solid transparent;
        background-color: $color__primary;
        color: #fff;
      }
    }
  }

  li.pager-current {
    padding: .5rem 1rem;
    color: #fff;
    background-color: #0e86c1;
  }

  li.is-active {
    a {
      border-bottom: 4px solid transparent;
      background-color: $color__primary;
      color: #fff;
    }
  }

  li.pager__item--previous {
    a {
      position: relative;
      border-bottom: none;
      &:active,
      &:hover {
        border-bottom: none !important;
        background-color: transparent;
      }

      &::after {
        content: ' ';
        display: inline-block;
        background-image: $right-green-arrow;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(180deg);
        width: 10px;
        height: 16px;
      }
    }
  }

  li.pager__item--next {
    a {
      position: relative;
      border-bottom: none;

      &:active,
      &:hover {
        border-bottom: none !important;
        background-color: transparent;
      }

      &::after {
        content: ' ';
        display: inline-block;
        background-image: $right-green-arrow;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 10px;
        height: 16px;
      }
    }
  }

  li:first-child.is-active {
    position: relative;

    &::before {
      content: ' ';
      display: inline-block;
      background-image: $inactive_arrow_left;
      background-repeat: no-repeat;
      background-position: center;
      transform: translateY(25%) translateX(-1.3rem);
      width: 10px;
      height: 16px;
    }
  }

  li:last-child.is-active {
    position: relative;

    &::after {
      content: ' ';
      display: inline-block;
      background-image: $inactive_arrow_left;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      top: 50%;
      transform: translateY(-50%) rotate(180deg);
      width: 10px;
      height: 16px;
      right: rem(-50px);
    }
  }
  li.pager__item--ellipsis {
    display: none;
  }
}

nav.pager {
  text-align: center;
  display: flex;
  justify-content: center;
}
