.commerce-checkout-flow-sja-checkout-flow-donation * {
  font-family: $lato;
}

.heading-3 {
  font-size: rem(15px);
  font-family: $lato;
  font-weight: $font-bold;
  color: #18573A;
  margin: 0 0 1rem 0;

  @include mappy-bp(med) {
    font-size: rem(17px);
  }
}

.shadow-box {
  background-color: $color__white;
  border-radius: 6px;
  border: solid 1px #e0e0e0;
  padding: 1rem 2rem;
  margin: 1rem 0;
}

.checkout-pane {

  h3,
  > .fieldset__container > legend {
    @extend .heading-3;
    text-transform: uppercase;
    letter-spacing: rem(.48px);
    text-align: center;
    line-height: 1;

    @include mappy-bp(med) {
      letter-spacing: 0;
      text-align: left;
    }
  }

  p {
    font-size: rem(14px);
    font-family: $lato;
    font-weight: $font-semi-bold;
    color: rgba(102, 102, 102, 1);

    @include mappy-bp(med) {
      font-size: rem(16px);
    }
  }
}

.order-information .checkout-pane {
  @extend .shadow-box;
}

.path-checkout {
  background-color: #F7F7F7;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  fieldset {
    border: none;
    margin: 2rem 0;
    padding: 0;

    &:first-child,
    &:last-child {
      margin: 0;
    }

    legend {
      text-transform: none;
    }
  }

  label {
    text-transform: none;
    letter-spacing: 0;
    font-size: rem(13px);
    font-weight: bold;
    color: #4d4d4d;
  }

  .stripe-form {
    display: flex;
    flex-wrap: wrap;
  }

  #payment-errors {
    flex: 0 0 100%;

    .messages {
      margin-bottom: rem(16px);
      padding: rem(12px);
    }
  }

  .__PrivateStripeElement {
    width: rem(140px);
  }

  .payment-method-icon-list {
    flex: 0 0 100%;
    justify-content: flex-end;
  }

  .payment-method-icon-list .payment-method-icon {
    flex: 0 0 rem(25px);
    width: 25px;
    height: 16px;
    background-size: 25px 16px;
    margin-right: rem(4px);

    &:last-child {
      margin-right: 0;
    }
  }

  .form-item--id-payment-information-add-payment-method-payment-details-card-number {
    flex: 1 0 rem(275px);
    margin-right: rem(32px);
    margin-bottom: rem(16px);
  }

  .form-item--id-payment-information-add-payment-method-payment-details-expiration {
    margin-right: rem(32px);
  }

  .form-item--id-payment-information-add-payment-method-payment-details-expiration,
  .form-item--id-payment-information-add-payment-method-payment-details-security-code {
    flex: 0 0 rem(108px);
  }

  .stripe-form .form-text,
  input[type='text'],
  input[type='number'],
  input[type='tel'],
  select {
    border-color: #e4e4e4;
    border-radius: 4px;
    font-weight: 400;
    padding: .75rem 1rem;
    width: 100%;
  }

  select {
    padding: .75rem 3rem .75rem 1rem;
  }

  .stripe-form .form-text {
    height: 43px;
    font-size: .875rem;
    padding: .75rem 1rem.75rem 1rem;
    margin: 0;
  }

  .stripe-form #card-number-element,
  .stripe-form #expiration-element,
  .stripe-form #security-code-element {
    width: 100%;
  }

  .stripe-form #card-number-element {
    position: relative;
    padding-left: rem(40px);
  }

  .stripe-form #card-number-element::before {
    content: '';
    background-image: url('../../src/000_assets/checkout/credit-card-lock.svg');
    background-repeat: no-repeat;
    background-position: center center;
    width: rem(18px);
    height: rem(18px);
    display: inline-block;
    position: absolute;
    left: rem(12px);
    top: 50%;
    transform: translateY(-50%);
  }

  input[type='radio'] + label {
    background: none;
    position: relative;
    font-size: rem(13px);
    cursor: pointer;
    padding: 0 0 0 rem(22px);

    @include mappy-bp(med) {
      font-size: rem(14px);
    }

    &::before {
      content: '';
      width: rem(16px);
      height: rem(16px);
      display: inline-block;
      border: 1px solid #dadadb;
      border-radius: 100%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &::after {
      background-color: $color__black;
      content: '';
      width: rem(8px);
      height: rem(8px);
      display: inline-block;
      border-radius: 100%;
      position: absolute;
      left: rem(4px);
      top: 50%;
      transform: translateY(-50%);
      transition: opacity .2s ease-in-out;
      opacity: 0;
    }

    &:hover::after {
      opacity: .25;
    }
  }

  input[type='radio']:checked + label {

    &::after {
      opacity: 1;
    }
  }

  input[type='checkbox'] + label {
    background: none;
    position: relative;
    font-size: rem(13px);
    cursor: pointer;

    @include mappy-bp(med) {
      font-size: rem(14px);
    }

    &::before {
      content: '';
      width: rem(17px);
      height: rem(17px);
      display: inline-block;
      border: 1px solid #789C8B;
      border-radius: 4px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &::after {
      background-image: url('../../src/000_assets/checkout/checkmark.svg');
      background-repeat: no-repeat;
      background-position: center center;
      content: '';
      width: rem(12px);
      height: rem(12px);
      display: inline-block;
      position: absolute;
      left: rem(3px);
      top: 50%;
      transform: translateY(-50%);
      transition: opacity .2s ease-in-out;
      opacity: 0;
    }
  }

  input[type='checkbox']:checked + label {
    background-image: none;

    &::before {
      border-color: #0c0c0c;
    }

    &::after {
      opacity: 1;
    }
  }

  input[type='submit'] {
    background-color: $sja_green_dark;
    border-radius: 4px;
    text-transform: none;
    letter-spacing: 0;
    border: 0;
    color: $color__white;
    width: 100%;
    font-size: 1.125rem;
    font-weight: $font-bold;
    transition: background-color .2s ease-in-out;

    &:hover {
      background-color: $sja_green;
      color: $color__white;
    }

    &:disabled {
      opacity: .5;
    }

    &:disabled:hover {
      background-color: $sja_green_dark;
    }
  }
}


/*
* Donate Other Amount wrapper
*/
.sja-donate-other-wrapper {
  margin-right: 0;
}

.sja-donate-other {
  flex: 1;
}


/*
* Donate Disclaimer Text
*/
.sja-donate-disclaimer {
  padding: rem(12px);
  padding-left: rem(48px);
  background-color: #fcfcfc;
  margin: rem(16px) 0;
  position: relative;
  border-radius: 4px;

  p {
    font-size: rem(12px);
    color: #707070;

    @include mappy-bp(med) {
      font-size: rem(14px);
    }
  }

  &__icon {
    width: rem(15px);
    height: rem(15px);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: rem(16px);
    background-image: url('../../src/000_assets/checkout/info.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    z-index: 1;
  }
}

/*
* Donate Amount Fieldset
*/
.sja-donate-amount {

  &.fieldset {
    margin-bottom: 0;
  }

  .fieldset__container {

    @include mappy-bp(med) {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      padding: 0 rem(32px);
    }
  }

  .fieldset__legend {
    margin: 0;
    text-transform: none;
    color: rgba(102, 102, 102, 1);
    font-weight: 400;
    padding-right: rem(32px);
  }

  .fieldset__legend-text::after {
    content: ':';
  }

  .fieldset__content {
    flex: 1 1 100%;
  }

  .form-item--radios {
    display: flex;
    flex-wrap: wrap;
    margin: 0 rem(-4px);

    @include mappy-bp(med) {
      justify-content: space-between;
      margin: 0 rem(-12px);
    }
  }

  .form-item--radio {
    margin: 0;
    padding: rem(4px);

    @include mappy-bp(med) {
      padding: 0 rem(12px);
    }
  }
}

.donate-other-input-container {
  display: flex;
  align-items: center;
}

.donate-other-input {
  flex: 0 1 rem(300px);
}

.donate-other-suffix {
  padding-left: rem(16px);

  .form-item__description {
    margin-top: 0;
  }
}

.form-item--id-sja-donate-form-donate-other-number {
  margin-top: rem(16px);
  padding: 0 rem(32px);
}

/*
* Customer Information Fieldset
*/
.sja-customer-information {

  h3 {
    text-transform: uppercase;
  }

  .fieldset__content {

    @include mappy-bp(med) {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -1rem;
    }
  }

  .fieldset__content > .form-item {
    font-size: rem(15px);
    color: rgba(102, 102, 102, 1);
    font-weight: 600;
    padding: rem(7px) 0;

    @include mappy-bp(med) {
      flex: 1 1 33.33%;
      padding: 0 1rem rem(5px) 1rem;
    }
  }

  .form-item__label {
    @include visually-hidden;
  }
}

.company-code-container {

  @include mappy-bp(med) {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    order: 2;
    margin-left: auto;
  }

  // Error messages
  .messages {
    display: none;
  }

  .coupon-code {

    @include mappy-bp(med) {
      margin: 0 0 rem(14px) 0;
    }
  }
}


.coupon-applied .coupon-code {
  display: none;
}

.company-code__extra-fields-container {
  flex: 0 0 100%;

  @include mappy-bp(med) {
    display: flex;
    justify-content: flex-end;
  }
}

.company-code__extra-fields {
  padding: rem(32px) rem(16px);
  background-color: #F8F8F8;

  @include mappy-bp(med) {
    max-width: rem(500px);
  }

  .form-item {
    margin: rem(16px) 0;
  }

  input[type='submit'] {
    margin-top: rem(16px);
  }
}

// Behalf of form container
.toggle-behalf-of-wrapper {

  @include mappy-bp(med) {
    order: 1;
    padding-right: rem(16px);
  }

  input[type='text'] {
    padding: .75rem 1rem;
  }
}

.toggle-behalf-of-button-wrapper {
  margin-bottom: rem(16px);

  p {
    font-size: rem(14px);
    color: #434343;
    display: inline-block;
  }
}

.toggle-behalf-of-button {

  &__button {
    background-color: transparent;
    padding: 0;
    margin: 0 0 0 rem(4px);
    font-size: rem(14px);
    color: #434343;
    display: inline-flex;
    align-items: center;
    appearance: none;
    width: auto;
    font-weight: 500;
    border-radius: 0;
    text-decoration: underline;
    border: 0;
    cursor: pointer;

    &:hover {
      background-color: transparent;
      color: #434343;
      text-decoration: none;
    }

    &:focus {
      outline: 0;
      background-color: transparent;
      color: #434343;
    }
  }

  &__icon {
    background-image: url('../../src/000_assets/icons/more-n.svg');
    background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;
    width: rem(8px);
    height: rem(8px);
    margin-left: rem(2px);
    background-size: 100% auto;
  }

  &__button.js-open .toggle-behalf-of-button__icon {
    transform: rotate(180deg);
  }
}

.user-info-container {
  width: 100%;
  max-width: rem(505px);
  padding: rem(16px) rem(32px);
  background: #F8F8F8;
  border-radius: 4px;

  @include mappy-bp(med) {
    display: flex;
    margin: 0 rem(-10px);
    flex-wrap: wrap;
  }

  .form-item {
    margin: 0 0 rem(16px) 0;

    @include mappy-bp(med) {
      flex: 1 0 50%;
      padding: 0 rem(10px);
    }
  }

  .user-info-label {
    font-size: rem(13px);
    margin-bottom: rem(24px);
    color: #393E46;

    @include mappy-bp(med) {
      padding: 0 rem(10px);
    }

    strong {
      font-weight: 800;
    }
  }

  .user-info-label,
  .form-item--select,
  .form-item--id-sja-checkout-info-pane-customer-info-pane-behalf-of-form-user-info-container-help {
    flex: 0 0 100%;
  }

  input[type="submit"] {
    margin: rem(16px) 0;
  }
}

.checkout-pane-shipping-information,
.checkout-pane-payment-information, {

  .form-item {
    font-size: rem(15px);
    color: rgba(102, 102, 102, 1);
    font-weight: 600;
  }

  .form-item--id-shipping-information-same-as-default-address,
  .form-item--id-shipping-information-shipping-profile-copy-to-address-book {
    margin-top: rem(16px);
  }

  .form-item--id-shipping-information-shipping-profile-select-address {
    margin-bottom: rem(16px);
  }

  .shipping-information__form {
    background-color: #F8F8F8;
    padding: rem(16px) rem(32px);
    max-width: rem(600px);
    margin-bottom: rem(16px);
  }

  .form-item--id-shipping-information-selected-shipping-address {
    display: inline-block;
    position: relative;
    padding-left: rem(26px);

    &::before {
      content: '';
      background-image: url('../../src/000_assets/checkout/maps-location-pin.svg');
      background-repeat: no-repeat;
      background-position: center center;
      display: inline-block;
      width: rem(18px);
      height: rem(18px);
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  #edit-payment-information-add-payment-method-billing-information {
    max-width: rem(600px);
    display: flex;
    flex-direction: column;

    .form-item--id-payment-information-add-payment-method-billing-information-address-0-address-locality {
      flex: 0 0 100%;
    }
  }

  .form-item--id-payment-information-add-payment-method-billing-information-copy-to-address-book {
    padding-bottom: rem(16px);
  }

  .form-item--id-shipping-information-shipping-profile-field-user-created-by-0-value,
  .field--name-field-user-created-by,
  .profile--type--customer .field--name-field-phone-number {
    display: none;
  }

  .address-container-inline {
    display: flex;
    flex-wrap: wrap;

    &:last-child {
      margin-right: 0;
    }
  }

  .address-container-inline .form-item {
    flex: 1;
  }

  .address-container-inline .js-form-item-shipping-information-shipping-profile-address-0-address-locality {
    flex: 0 0 100%;
  }

  .address-book-edit-button {
    margin: rem(16px) 0;
  }
}

/*
* Coupon Code Pane
*/
.checkout-pane-coupon-redemption {

  .messages {
    display: none;
  }
}

/*
* Coupon Code
*/
.coupon-code {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: rem(20px) auto;
  // max-width: rem(210px);
  width: 100%;

  @include mappy-bp(med) {
    max-width: rem(270px);
  }

  .form-item__label {
    @include visually-hidden;
  }

  .form-item__error-message {
    color: #ED1C2D;
  }

  .form-item--textfield {
    flex: 1;
  }

  input[type='text'] {
    font-weight: normal;
    letter-spacing: 0;
    border: 1px solid #DEDEDF;
    border-right: 0;
    border-radius: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    font-size: rem(14px);
    padding: .75rem;

    &::placeholder {
      color: #B5B5B5;
      opacity: 1;
    }

    &:-ms-input-placeholder,
    &::-ms-input-placeholder,
    &::-webkit-input-placeholder {
      color: #B5B5B5;
      opacity: 1;
      font-weight: normal;
    }

    &.error {
      border-color: #ED1C2D;
      outline: #ED1C2D solid 1px;
      background-position-x: rem(8px);
      padding-left: rem(35px);
      background-image: url('../../src/000_assets/checkout/info--red.svg');
      background-size: rem(20px);
    }
  }

  input[type='submit'] {
    margin: 0;
    font-size: rem(15px);
    padding: .5rem 1.2rem;
    letter-spacing: 0;
    font-weight: bold;
    width: auto;
    border-radius: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .ajax-progress {
    flex: 0 0 100%;
  }
}

.coupon-redemption-form__coupons {
  text-align: center;

  input[type='submit'] {
    margin-top: 0;
    font-size: rem(12px);
    padding: rem(8px) rem(12px);
    height: auto;
  }

  p {
    color: #393E46;
    letter-spacing: 0;
    font-size: rem(17px);
    font-weight: 600;
    word-break: break-all;
    word-wrap: break-word;
    hyphens: auto;
  }
}

.coupon-redemption-form__coupon {
  display: flex;
  align-items: center;
  padding: rem(8px) 0;
  border-top: solid 2px #ededed;
  border-bottom: solid 2px #ededed;
}

.coupon-redemption-form__coupon-code {
  padding-right: rem(12px);
}

.coupon-redemption-form__coupon-remove {
  margin-left: auto;
}

.payment-method-icon-list {
  display: flex;

  .payment-method-icon {
    margin: 0 0 0 4px;
    width: 25px;
    height: 16px;
    background-size: 25px 16px;
  }
}

.stripe-form .form-item.has-error {


  .form-item__error-message {
    display: none;
  }

  .form-text {
    background-color: hsl(15, 75%, 97%);
    border-color: #e62600;
    box-shadow: 0 5px 5px -5px #b8b8b8 inset;
  }
}

.checkout-complete {

  // Checkout complete intro
  &__intro h1,
  &__behalf-wrapper h2 {
    color: #18573A;
    font-size: rem(22px);
    margin: 0;
  }

  &__intro p {
    font-size: rem(14px);
    color: #393E46;
  }

  &__success-icon {
    width: rem(30px);
    height: auto;
    display: inline-block;
  }

  // Checkout complete customer information
  &__info,
  &__behalf {
    background-color: #F8F8F8;
    border-radius: 5px;
    padding: rem(6px) rem(20px);
    margin: rem(24px) 0;

    @include mappy-bp(mobile) {
      display: flex;
      flex-wrap: wrap;
      margin: rem(24px) rem(-10px);
    }
  }

  // Checkout complete customer information item
  &__info-item {
    padding: 0 0 rem(32px) 0;

    @include mappy-bp(mobile) {
      flex: 0 0 50%;
      padding: rem(10px);
    }

    @include mappy-bp(med) {
      flex: 0 0 33.33%;
      padding: rem(10px);
    }
  }

  &__info-item h2,
  &__info-item label {
    font-size: rem(14px);
    text-transform: none;
    color: #212121;
    font-weight: bold;
    letter-spacing: 0;
    margin: 0 0 rem(10px) 0;
  }

  &__info-item p {
    font-size: rem(15px);
    color: #212121;
    margin: 0;
    font-weight: 600;
  }

  // Support text
  &__support {
    display: flex;
    align-items: center;
    margin-top: rem(70px);
  }

  &__support p {
    font-size: rem(15px);
    color: #212121;
    letter-spacing: 0;
  }

  &__support img {
    width: rem(70px);
    height: auto;
    margin-right: rem(20px);
  }

  &__behalf-wrapper {

    @include mappy-bp(med) {
      width: calc(66.66% - 3rem);
    }
  }
}


.form-item--id-sja-checkout-info-pane-customer-info-pane-customer-info-name,
.form-item--id-sja-checkout-info-pane-customer-info-pane-customer-info-address,
.form-item--id-sja-checkout-info-pane-customer-info-pane-customer-info-phone-number,
.form-item--id-sja-checkout-info-pane-customer-info-pane-customer-info-email  {

  .form-item__content {
    display: flex;
  }

  .form-item__icon {
    content: '';
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center center;
    width: rem(18px);
    height: rem(18px);
    margin-right: rem(12px);
    margin-top: rem(3px);
  }
}

.form-item--id-sja-checkout-info-pane-customer-info-pane-customer-info-name .form-item__icon {
  background-image: url('../../src/000_assets/checkout/person.svg');
}

.form-item--id-sja-checkout-info-pane-customer-info-pane-customer-info-address .form-item__icon {
  background-image: url('../../src/000_assets/checkout/maps-location-pin.svg');
}

.form-item--id-sja-checkout-info-pane-customer-info-pane-customer-info-phone-number .form-item__icon {
  background-image: url('../../src/000_assets/checkout/phone.svg');
}

.form-item--id-sja-checkout-info-pane-customer-info-pane-customer-info-email .form-item__icon {
  background-image: url('../../src/000_assets/checkout/email.svg');
}


// Order Total
.order-total-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #393E46;
  letter-spacing: 0;
  padding: rem(6px) rem(5px);
}

.order-total-line-label {
  font-size: rem(14px);
  font-weight: 500;
}

.order-total-line-value {
  font-size: rem(17px);
  font-weight: 600;
}

.order-total-line__total {
  border-top: solid 2px #ededed;
  padding: rem(12px) rem(5px);

  .order-total-line-label {
    font-weight: 800;
  }

  .order-total-line-value {
    font-weight: 800;
  }
}

.view--commerce-checkout-order-summary {
  margin-bottom: 1rem;

  .view__row {
    display: flex;
  }

  .views-field {
    font-size: rem(14px);
    padding: .75rem .75rem .75rem 0;
    border-top: solid 2px #ededed;
    color: #393E46;
    text-align: right;
    font-weight: 600;

    @include mappy-bp(med) {
      font-size: rem(17px);
    }

    &:last-child {
      padding-right: 0;
    }
  }

  .view__row:last-child .views-field {
    border-bottom: solid 2px #ededed;
  }

  .views-field-purchased-entity {
    flex: 1 1 100%;
    text-align: left;
  }

  .order-summary__total-price__number,
  .order-summary__quantity, {
    display: block;
  }

  // Sub Totals
  .order-total-line {
    padding: 0;
    font-size: 1rem;
    text-align: left;

      @include mappy-bp(land) {
        text-align: right;
      }

    .order-total-line-label {
      text-transform: uppercase;
      min-width: 80px;

      @include mappy-bp(land) {
        min-width: 0;
      }
    }

    .order-total-line-value {
      width: auto;
      margin: .5rem 0 0 4rem;
      @include mappy-bp(land) {
      min-width: 100px;
      }
    }
  }
}

.order-summary__item {
  display: flex;
  font-size: rem(14px);
  justify-content: space-between;
  text-align: left;
  color: #393E46;
  font-weight: 600;

  @include mappy-bp(med) {
    font-size: rem(17px);
  }

  span {
    padding: .75rem rem(16px) .75rem 0;
    padding-right: rem(16px);
    border-bottom: solid 2px #ededed;

    &:last-child {
      padding-right: 0;
    }
  }

  &__quantity {
    border-bottom-color: transparent;
    white-space: nowrap;
  }

  &__title {
    flex: 1 1 100%;
  }

  &__total {
    text-align: right;
  }

  &__adjustment-label {
    font-size: rem(14px);
    flex: 1 1 100%;
  }

  &__adjustment-amount {
    flex: 1 1 100%;
    text-align: right;
  }

  &__total-label {
    flex: 1 1 100%;
    font-size: rem(21px);
    font-weight: 800;
    text-transform: uppercase;
  }

  &__total-amount {
    flex: 1 1 100%;
    text-align: right;
    font-size: rem(21px);
    font-weight: 800;
    padding-top: rem(22px);
  }
}

.order-summary__total .order-summary__item:last-child span {
  border-bottom: 0;
}