.block--whirlwind-search-block {
  @extend %cf;

  @include mappy-bp(0 wide) {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    background: white;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
    transition: top 0.3s ease;

    &.open {
      top: 100%;
    }
  }

  .block__content {
    @include mappy-bp(0 wide) {
      padding: 0 1.75rem;
    }
  }

  .form-item--textfield {
    position: relative;
    margin: 0;

    &::after {
      content: url("../../src/000_assets/icons/search-alternate.svg");
      width: 19px;
      position: absolute;
      right: rem(8px);
      top: 0;
      bottom: 0;
      display: none;
      align-items: flex-end;

      @include mappy-bp(wide) {
        display: flex;
      }
    }
  }

  label {
    @extend %visually-hidden;
  }

  input[type="text"] {
    caret-color: $color__primary;
    width: 100%;
    border: 0;
    border-radius: 0;
    height: rem(50px);
    padding: 1rem 0;
    font-size: rem(16px);

    @include mappy-bp(wide) {
      width: 100%;
      font-size: rem(14px);
      padding: rem(14px) rem(36px) rem(12px) rem(16px);
      background: none;
      border: 1px solid rgba(112, 112, 122, 0.3);
      border-radius: 4px;
      height: 2.4rem;
    }

    &:focus {
      outline: none;
      // border-bottom: 1px solid white;
    }

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 1; /* Firefox */
      text-align: left;
    }

    &::-ms-clear {
      display: none;
    }

    &:focus::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 0; /* Firefox */
      transition: opacity 0.3s ease; /* Smooth transition for the placeholder disappearing */
    }
  }

  input[type="submit"] {
    position: absolute;
    transition: all 0.3s;
    width: 16px;
    height: 16px;
    margin: -8px 0 0 0;
    font-size: 0;
    background-image: url("../../src/000_assets/icons/search-arrow.svg");
    background-color: transparent;
    border: none;
    background-repeat: no-repeat;
    z-index: 1;
    right: 0;
    top: 50%;
    padding-right: 0;

    @include mappy-bp(wide) {
      display: none;
    }
  }
}

.search-toggle {
  transition: all 0.3s;
  width: rem(32px);
  height: rem(32px);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @include mappy-bp(wide) {
    display: none;
  }

  img {
    width: auto;
  }
}
