main {
  input[type='date'],
  input[type='email'],
  input[type='file'],
  input[type='month'],
  input[type='number'],
  input[type='password'],
  input[type='tel'],
  input[type='text'],
  input[type='time'],
  input[type='url'],
  input[type='week'],
  textarea,
  select {
    border: 1px solid $color__input-border--normal;
    max-width: 100%;
  }

  input {
    width: 100%;
    color: #000000;
    padding: .75rem 3.5rem .75rem 1rem;
  }


  input,
  select {
    height: 43px;
    font-size: .875rem;
    font-weight: 600;
    letter-spacing: .7px;
  }

  input,
  textarea {
    appearance: none;
    border-radius: 0;

    &:focus,
    &:active {
      border-color: $sja_green;
      outline: none;
    }
  }

  textarea {
    display: block;
    font-size: .875rem;
    width: 100%;
    max-width: 100%;
    padding: 1rem;
  }

  input[type='search'],
  select {
    appearance: none;
    border: 1px solid $color__input-border--normal;
    border-radius: 0;
  }

  select {
    height: 43px;
    background: url('/themes/custom/sja/src/000_assets/icons/icon__unfold_more.svg') no-repeat;
    background-color: #FFFFFF;
    background-position: 95%;
    background-size: 24px;
    padding: .75rem 3.5rem .75rem 1rem;
  }

  select::-ms-expand {
    display: none;
  }

  select,
  input[type='radio']:hover,
  input[type='checkbox']:hover {
    cursor: pointer;
  }


  *:focus,
  *[aria-expanded='true'],
  button,
  .btn,
  a.btn,
  a.btn:hover,
  a.btn:focus {
    transition: ease-in .2s;
  }

  // validation
  .has-error {
    input,
    textarea {
      border-color: $sja_red;
      margin-bottom: .5rem;
      padding: 1rem 3.5rem 1rem 1rem;
      background-image: url('/themes/custom/sja/src/000_assets/icons/icon__error.svg');
      background-repeat: no-repeat;
      background-size: 24px;
      background-position-x: calc(100% - 16px);
      background-position-y: center;

      &:focus,
      &:active {
        border-color: $sja_green;
      }
    }

    select {
      border-color: $sja_red;
      margin-bottom: .5rem;

      &:focus,
      &:active {
        border-color: $sja_green;
      }
    }
  }

  .form-item__error-message,
  .form-item--error-message .error {
    font-size: .75rem;
    color: $sja_red;
    font-weight: bold;
  }

}

