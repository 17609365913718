// @import url('https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900&display=swap');

.block-menu.menu--account {
  display: none;

  @include mappy-bp(wide) {
    display: inline-block;
    margin-left: auto;
  }

  ul.nav--account {
    padding: 0;
    margin: 0;

    li {
      list-style: none;
      display: inline-block;
      margin: 0 0.5rem;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-left: 0;
      }

      a {
        display: block;
        font-size: 12px;
        text-transform: uppercase;
        text-decoration: none;
        letter-spacing: 1.74px;
        line-height: 1.2;
        color: black;
        background-color: #f5f5f5;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 0.8rem;
        border-radius: 2px;
        font-weight: 800;
        padding-bottom: 0.8rem;
        font-family: "Lato", sans-serif;
        transition: box-shadow 0.3s ease; /* Smooth transition for the shadow */
        &:hover,
        &:focus {
          box-shadow: 0 0 1.5px rgba(0, 0, 0, 0.2); /* Slightly darker shadow on hover */
        }
        @include mappy-bp(wide) {
          font-size: 13px;
          position: relative;
        }
      }
    }
  }
}

.navigation {
  .block-menu.menu--main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include mappy-bp(wide) {
      position: inherit;
      top: inherit;
      left: inherit;
      transform: inherit;
    }

    ul.nav--main {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      position: relative;
      bottom: 0.5rem;

      li {
        list-style: none;
        position: relative;
        padding: 0 rem(45px);
        margin: 0 rem(30px);

        span {
          width: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: block;
          padding: 0 1rem;
          line-height: initial;
        }

        a:not(.donate) {
          display: none;
          text-align: center;
          $underline-color: #006633;
          text-decoration: none;
          font-size: 15px;
          font-family: "Lato", sans-serif;
          font-weight: 500;
          letter-spacing: 0.05em;
          color: #000000;
          transition: background-position 0.2s linear 0.2s,
            box-shadow 0.2s linear 0s;
          background-size: 0;
          background-repeat: no-repeat;
          background-position: 0 1.35em;
          padding-bottom: 1.35rem;
          overflow: hidden;

          @include mappy-bp(wide) {
            display: inline;
            text-align: left;
            font-size: 16px;
          }
        }

        a.donate {
          display: none;
          padding: 7px 38px;
          border-radius: 20px;
          border: solid 2px #e50020;
          text-align: center;
          text-decoration: none;
          text-transform: uppercase;
          font-size: 14px;
          font-weight: 900;
          letter-spacing: 0.05em;
          color: $color__white;
          transition: 0.2s all;
          background-color: #e50020;

          @include mappy-bp(wide) {
            display: inline-block;
          }

          &:hover,
          &:focus {
            background-color: #fff;
            color: #000000;
            border: solid 2px #000000;
          }
        }
      }

      > li {
        cursor: pointer;
        margin: 0;
        flex: 0 0 auto;
        text-align: center;
      }
    }
  }
}

@media (min-width: 1200px) {
  @keyframes slideDown {
    0% {
      opacity: 0;
      transform: translateY(-10px) translateX(-50%);
    }
    100% {
      opacity: 1;
      transform: translateY(0) translateX(-50%);
    }
  }

  .nav--main .nav__item.has-dropdown::after,
  .nav--main .nav__item.has-subnav::after {
    content: "";
    position: absolute;
    top: 0;
    left: -10px; /* Increase this value to widen the buffer zone */
    width: calc(100% + 20px); /* Increase this value to widen the buffer zone */
    height: calc(
      100% + 20px
    ); /* Increase this value to increase the buffer zone height */
    z-index: -1;
  }

  .nav--main .nav__subnav:hover {
    display: grid;
    justify-items: center;
    animation: slideDown 0.3s ease-in-out forwards;
    max-height: fit-content;
    width: 100vw; /* Adjusted to full width */
    position: fixed;
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Adjust for centering */
    -webkit-transform: translateX(-50%); /* For Safari */
    padding-top: 1rem;
  }

  .nav--main .nav__item.has-dropdown:hover .nav__subnav.js-loaded,
  .nav--main .nav__item.has-subnav:hover .nav__subnav.js-loaded,
  .nav--main .nav__item.has-dropdown .nav__subnav.js-loaded:hover,
  .nav--main .nav__item.has-subnav .nav__subnav.js-loaded:hover {
    display: grid;
    justify-items: center;
    animation: slideDown 0.3s ease-in-out forwards;
    max-height: fit-content;
    width: 100vw; /* Adjusted to full width */
    position: fixed;
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Adjust for centering */
    -webkit-transform: translateX(-50%); /* For Safari */
    padding-top: 1rem;
    visibility: visible;
    opacity: 1;
    transition-delay: 0.02s;
  }

  .nav-column-container {
    display: flex;
  }

  .nav-column {
    flex: 1; /* Adjust as needed for your design */
  }

  .nav--main .nav__subnav {
    z-index: -2 !important;
  }
  .nav--main .nav__item.has-dropdown:hover::before,
  .nav--main .nav__item.has-subnav:hover::before {
    content: "";
    display: block;
    position: absolute;
    top: 113%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 60%;
    height: 4px;
    background: linear-gradient(to right, #006633 100%, transparent 0);
    animation: expandBorder 0.4s cubic-bezier(0.22, 0.61, 0.36, 1.01) forwards;
  }

  @keyframes expandBorder {
    0% {
      width: 0;
      left: 50%;
    }
    100% {
      width: 60%;
      right: 20%;
    }
  }

  li.nav__item.subnav-item {
    padding-bottom: 0 !important;
  }
  /* Add buffer zone for smoother hovering */
  .nav__item::after {
    content: "";
    position: absolute;
    top: 0;
    left: -10px; /* Adjust left to increase buffer */
    width: calc(100% + 20px); /* Adjust width to increase buffer */
    height: calc(100% + 10px); /* Adjust height to increase buffer */
    z-index: -1; /* Ensure it doesn't interfere with actual content */
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .nav-column {
    margin-left: -10px;
    margin-right: -10px;
  }
}

@media (max-width: 1200px) {
  .nav--main .nav__item.has-dropdown:hover .nav__subnav,
  .nav--main .nav__item.has-subnav:hover .nav__subnav {
    display: none;
  }
}

a.nav-parent {
  font-weight: 900 !important;
}

.nav-header {
  text-underline-position: from-font;
  text-transform: uppercase;
  font-weight: 700 !important;
}

.nav-cta {
  text-transform: uppercase !important;
  font-weight: 700 !important;
}

.nav-cta::after {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-left: 5px;
  background-image: url("../../src/000_assets/icons/nav-cta-right-stream-20px.svg");
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  top: 2px;
}
@media (max-width: 1200px) {
  .nav-cta::after {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-left: 5px;
    background-image: url("../../src/000_assets/icons/nav-cta-right-stream-20px.svg");
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    top: 0;
  }
}

.nav--main .nav__subnav {
  display: none;
  position: absolute;
  background-color: white;
  padding: 10px 0;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  min-width: 250px;
  z-index: 100;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0 0 5px 5px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  transition-delay: 0s, 0.3s; /* Add delay for hiding */
}

.nav--main .nav__item:hover > .nav__subnav,
.nav--main .nav__item .nav__subnav:hover {
  // display: block;
  opacity: 1;
  visibility: visible;
  transition-delay: 0s, 0s; /* No delay for showing */
}

.nav--main .nav__subnav .nav__item {
  display: block;
  width: 100%;
}

.nav--main .nav__subnav .nav__link {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  text-decoration: none;
  color: black;
  white-space: nowrap;
}

.nav--main .nav__subnav .nav__link:hover {
  background-position: 0 23px !important;
}

.navigation .block-menu.menu--main ul.nav--main li.subnav-item {
  width: 100%;
  display: block;
  text-align: left;
  padding: 0 0.9rem;
}

.navigation .block-menu.menu--main ul.nav--main li.subnav-item:hover {
  background-color: #f0f0f0 !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.nav__link .nav__arrow {
  display: none;
  margin-left: 5px;
  font-size: 0.8em;
}
@media (max-width: 1200px) {
  .nav__item.has-subnav:hover > .nav__subnav {
    display: none;
  }
}

@media (min-width: 1201px) {
  .nav__link .nav__arrow {
    display: none !important;
  }
  span.arrow {
    display: none !important;
  }
}
@media (max-width: 1200px) {
  .nav__link .nav__arrow {
    display: inline;
  }
}

/* Set the display of the button to none by default */
.nav__arrow.dropdown-toggler {
  display: none;
}

@media screen and (min-width: 1200px) {
  /* This is where you can style the subnav items for desktop  */
  .nav__subnav > .nav__item > .link-container > .nav__link {
    font-size: 15px !important;
    margin: 2px !important;
    font-weight: 700 !important;
    text-transform: inherit !important;
  }
}
