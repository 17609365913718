.commerce-checkout-flow-multistep-default.commerce-checkout-flow {
  .checkout-pane-sja-coupon-redemption {
    #edit-sidebar-sja-coupon-redemption-form {
      > .form-item {
        margin-top: 1rem;
        margin-bottom: 1rem;
        input[readonly='readonly'] {
          background: $color__grey--lighter;
        }
      }
      > .form-item--id-sidebar-sja-coupon-redemption-form-code {
        margin-bottom: 0;
      }
      > .button {
        margin-top: 1.5rem;
      }
      > .coupon-redemption-form__coupons {
        @media only screen and (max-width: 640px) {
          /* Force table to not be like tables anymore */
          table,
          thead,
          tbody,
          th,
          td,
          tr {
            display: block;
          }

          /* Hide table headers (but not display: none;, for accessibility) */
          thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
          }

          tr {
            margin: 0 0 1rem 0;
          }

          td {
            /* Behave  like a "row" */
            border: none;
            position: relative;
            text-align: center;
            > input {
              margin: auto;
            }
            // padding-left: 50%;
          }

          td::before {
            /* Now like a table header */
            position: absolute;
            /* Top/left values mimic padding */
            top: 0;
            left: 6px;
            width: 45%;
            padding-right: 10px;
            white-space: nowrap;
          }
        }
      }
    }
  }
  .checkout-pane-order-summary {
      .view--commerce-checkout-order-summary {
        .view__footer {
          > div {
            >.order-total-line {
              // @media only screen and (max-width: 640px) {
                display: flex;
                align-items: flex-start;
                flex-wrap: nowrap;
                justify-content: space-between;

                  .order-total-line-label {
                    text-align: left;

                }
              //}
            }
          }
        }
      }
  }
}

.sja-company-code-portal {
  margin: 3rem auto;
  width: auto;
  @include mappy-bp('large' ) {
    margin: 0;
    width: 36%;
  }
  .code_message {
    .error_message {
      color: $sja_red;
      font-size: .75rem;
      font-weight: bold;
    }
  }
  > .form-item {
    margin-top: 1rem;
    margin-bottom: 1rem;
    input:disabled,
    input[readonly='readonly'] {
      background: $color__grey--lighter;
    }
  }
  input.form-submit {
    margin-top: 1rem;
  }
}
