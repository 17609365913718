.view--products.view--display-page_1 {
  margin-bottom: 3rem;
  // .view--products {
  > .view__filters {
    margin-bottom: 1rem;
    > form {
      display: flex;
      align-items: flex-start;
      flex-wrap: nowrap;
      justify-content: flex-end;
      .form-item--id-sort-by {
        width: 100%;
        @include mappy-bp(med) {
          width: 30%;
        }
        .form-item__label {
          font-weight: 600;
        }
        > .form-item__select {
          margin-top: 1rem;
          width: 100%;
          font-weight: normal;
        }
      }
      .form-item--id-keyword,
      .form-actions {
        display: none;
      }
    }
  }
  > .view__content {
    > .item-list {
      > ul {
        list-style-type: none;
        > .leaf {
          margin-left: 0;
          margin-bottom: 5rem;
          .field--name-field-variation-image {
            border: 1px solid #e5ebe9;
          }
          .storefornt-variation-price-list-price-container {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;
            margin: 1rem 0;
            > .field--name-price,
            > .field--name-list-price {
              width: 45%;
            }
            > .field--name-list-price {
              text-align: right;
              text-decoration: line-through;
            }
          }
          .field--name-title {
            margin-bottom: 1rem;
            a {
              text-decoration: none;
              letter-spacing: .4px;
              color: $sja_green;
              font-weight: bold;
              border-bottom: 2px solid white;
              transition: .5s;
              display: inline;
              cursor: pointer;
              &:hover,
              &:focus {
                border-bottom: 2px solid $sja_green;
              }
              &:visited {
                color: $color__primary;
              }
            }
          }
          .commerce-order-item-variation-cart-form-form {
            margin-top: 2rem;
            > div,
            > input {
              display: none;
            }
            > .form-actions {
              display: block;
              width: 55%;
              margin-left: auto;
              margin-right: auto;
              .button--add-to-cart {
                margin-top: 0;
                @include mappy-bp(large mega) {
                  padding: 9px 12px;
                }
              }
            }
          }
        }
        @include mappy-bp(large) {
          @media all and (-ms-high-contrast: none),
            (-ms-high-contrast: active) {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
          }
          display: grid;
          overflow: hidden;
          grid-template-columns: repeat(3, 1fr);
          grid-auto-rows: 1fr;
          grid-column-gap: 5%;
          grid-row-gap: 4rem;
          > .leaf {
            width: 100%;
            height: 100%;
            > div {
              position: relative;
              height: 100%;
              > .commerce-order-item-variation-cart-form-form {
                position: absolute;
                bottom: 1rem;
                @media all and (-ms-high-contrast: none),
                  (-ms-high-contrast: active) {
                  position: relative;
                }
              }
            }
          }
          @media all and (-ms-high-contrast: none),
            (-ms-high-contrast: active) {
            .leaf {
              width: 30%;
            }
            > li:nth-of-type(3n + 2) {
              margin: 0 5%;
            }
          }
        }
      }
    }
  }
  nav.pager {
    margin-top: 3rem;
  }
  // }
}

.product-image-container {
  position: relative;
  display: inline-block;
}

.product-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #e50020;
  color: #fff;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
}
