.view--wasip-order-items-export {
  .view__filters {
    .views-exposed-form {
      .form-item {
        margin: 2rem 0;
      }
      .form-actions {
        input {
          margin-top: 0;
        }
      }
      @include mappy-bp(med) {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        .form-item--id-placed-from {
          margin-right: 3rem;
        }
        .form-actions {
          flex: 0 0 100%;
        }
      }
    }
  }
  .view__content {
    table.tablesaw {
      max-width: 100% !important;
    }
  }
  .view__empty {
    margin-top: 3rem;
  }
  .feed-icons {
    margin: 1rem 0 3rem;

    .csv-feed {
     a {
      display: inline-block;
      width: auto;
      background: none;
      text-indent: 0;
      font-size: .875rem;
      font-weight: 800;
      color: $color__black;
      text-decoration: none;

      &:hover,
      &:focus {
       text-decoration: underline;
      }
     }
    }
  }
}

.page-node-type-page {
  .ui-datepicker {
    .ui-datepicker-calendar {
      .ui-datepicker-current-day {
        a {
          color: $sja_green;
        }
      }
      a.ui-state-hover {
        border: 1px solid $sja_green;
      }
    }
  }
}