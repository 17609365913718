.tx-tooltip {
  position: relative;
  display: inline-block;
  cursor: help;


  .tx-tooltip {
    border: none;
  }

  .tx-tooltip-text {
    visibility: hidden;
    width: 20rem;
    background-color: #fff;
    color: #222;
    text-align: left;
    border-radius: 6px;
    border: 2px solid #0070c9;
    padding: .5rem .3rem;
    position: absolute;
    z-index: 1;
    top: -5rem;
    left: 1rem;
    font-size: .75rem;
    text-transform: none;
  }

  @include mappy-bp(0 640px) {
    .tx-tooltip-text {
      width: 13rem;
    }
  }

  .tx-tooltip-text::after {
    content: '';
    position: absolute;
    top: 5rem;
    right: 100%;
    margin-top: -5px;
    border-width: 8px;
    border-style: solid;
    border-color: transparent #0070c9 transparent transparent;
  }
}

.tx-tooltip:hover .tx-tooltip-text,
.tx-tooltip:focus .tx-tooltip-text {
  visibility: visible;
}

.tx-tooltip:hover .tx-tooltip-text .tx-tooltip .tx-tooltip-text,
.tx-tooltip:focus .tx-tooltip-text .tx-tooltip .tx-tooltip-text {
  visibility: hidden;
}
