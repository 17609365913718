.maintenance-page-container {
  font-family: 'Lato', sans-serif;
  background-color: #F2F2F2;
  height: inherit;
}

.logo {
  // max-width: 200px;
  margin: 0 auto 20px;
}

.bilingual-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  background-color: #ffffff;
  text-align: center;
  margin: 1rem;
  padding: 1rem;
  border-radius: 5px;
}

.maintenance-message-container {
  flex-basis: 45%; /* Adjust this value based on your design preference */
  box-sizing: border-box;
}

.maintenance-message-header {
  font-family: 'Nunito Sans', sans-serif;
  font-size: x-large;
}

.maintenance-message {
  font-size: smaller;
}

.logo-svg {
  height: 11vh;
  margin-top: 1rem;
  width: -webkit-fill-available;
}

.svg-container-maintenance {
  background-color: #F2F2F2;
  height: 60vh;
  margin: auto;
}

.maintenance-image {
  width: -webkit-fill-available;
}

.maintenance-message-eta {
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: black;
  font-size: initial;
  font-family: 'Nunito Sans', sans-serif;
  background-color: #f2f2f2;
  border-radius: 21px;
  padding-bottom: 12px;
}
.maintenance-message-eta-header {
  color: black;
}

.maintenance-message-eta-container {
    border-radius: 5px;
    padding: 12px;
    padding-top: initial;
    padding-bottom: 10px;
}

svg.maintenance-message-icon {
  position: relative;
  top: 10px;
  right: 4px;
}

@media (max-width: 1200px) {
  .bilingual-container {
      flex-direction: column;
      align-items: center;
  }

  /* Adjust for mobile */
  .maintenance-message-container {
    flex-basis: 80%;
  }

   .bilingual-container {
   font-size: 14px;
   }

   .maintenance-message {
    font-size: larger;
}

}


  @media (min-width: 1800px) {
    .bilingual-container {
      margin-right: 14%;
      margin-left: 14%;
    }
    }

    @media (max-width: 1200px) {
      .maintenance-image {
      margin-top: -4rem;
    }
    }

    @media (max-width: 900px) {
      .maintenance-image {
      margin-top: -8rem;
    }
    }

    @media (max-width: 800px) {
      .maintenance-image {
      margin-top: -11rem;
    }
    }

    @media (max-width: 800px) {
      .maintenance-image {
      margin-top: -11rem;
    }
    }

    @media (max-width: 400px) {
      .maintenance-image {
      margin-top: -15rem;
    }
    }