.contact-us-block {

    .block__content {
        box-shadow: 0 0 24px 0 rgba(0, 0, 0, .16);
        background-color: #ffffff;
        padding: 28px;
        margin: 2rem 0;

        @include mappy-bp(med) {
          padding: 60px;
        }

        h3 {
          margin: 0;
          font-weight: 900;
          font-size: 20px;
          line-height: 1.28;
          letter-spacing: .8px;
          color: #000000;

          @include mappy-bp(med) {
            font-size: 32px;
          }
        }

        .field--name-field-name {
          margin-top: 38px;
          margin-bottom: 20px;
          display: inline-block;
          font-size: .875rem;
          font-weight: 900;
          line-height: 1;
          letter-spacing: 1.5px;
          text-transform: uppercase;
          color: $sja_green;

          @include mappy-bp(med) {
            font-size: 1.25rem;
          }
        }

        .address {
          span {
            font-size: 14px;
            line-height: 1.5;
            text-align: left;
            color: #000000;
            text-decoration: none;
            display: inline-block;
            margin-right: 5px;
            @include mappy-bp(med) {
              font-size: 16px;
            }
          }

          br {
            display: none;
          }
        }

        a {
          font-size: 14px;
          line-height: 1.5;
          text-align: left;
          color: #000000;
          text-decoration: none;
          display: block;
          margin-top: 12px;
          @include mappy-bp(med) {
            font-size: 16px;
          }
        }

        .field--name-field-email {
          margin: 12px 0;
          font-size: 16px;
          line-height: 1.5;
          text-align: left;
          color: #000000;
          text-decoration: none;
          display: block;
        }
    }
}