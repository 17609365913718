.block-views.block-views-blockregistrations-employer-registered-students,
.block-views-blockregistrations-employer-requiring-attendee,
.block-views-blockregistrations-employer-attended-and-passed,
.block-views-blockregistrations-employer-block-2,
.block-views-blockregistrations-employer-block-3,
.block-views-blockregistrations-employer-all-registration,
.block-views-blockregistrations-employer-online-classes {
  .views-exposed-form {
    .form-item--id-combine {
      margin-bottom: 1rem;
    }
    #edit-actions,
    .form-actions {
      input {
        margin: .5rem 0;
        @include mappy-bp(mobile) {
          margin: 1.5rem 0;
        }
      }
    }
  }
  .view__content {
    overflow-x: auto;
    .tablesaw-bar.tablesaw-mode-swipe {
      margin-top: 0 !important;
    }
    table {
      thead {
        background-color: $light_light_grey;
        th {
          padding: 1.25rem 1rem;
          font-size: 1rem;
          > a {
            display: flex;
            align-items: center;
            > .tablesort {
              background-position: center;
              background-repeat: no-repeat;
              background-size: auto;
            }
          }
        }
      }
      .register-attendee {
        >.webform-dialog.button.edit {
          background: url('/themes/custom/sja/src/000_assets/icons/icon__edit.svg') center no-repeat;
          background-size: auto;
          border-radius: 50%;
          overflow: hidden;
          width: 30px;
          height: 30px;
          margin-left: .75rem;
          display: inline-block;
          vertical-align: bottom;
          &:hover {
            filter: sepia(100);
          }
        }
        > .webform-dialog.webform-dialog-large.button.assign {
          background: url('/themes/custom/sja/src/000_assets/icons/icon__add.svg') center no-repeat;
          background-size: auto;
          border-radius: 50%;
          display: block;
          overflow: hidden;
          width: 30px;
          height: 30px;
          margin-left: 2rem;
          &:hover,
          &:focus,
          &:active {
            background-image: url('/themes/custom/sja/src/000_assets/icons/icon__add-hover.svg');
          }
        }
      }
    }
  }
  .view__empty {
    margin-bottom: 2rem;
  }
  .feed-icons {
    margin: 1rem 0 3rem;

    .csv-feed {
      a {
        display: inline-block;
        width: auto;
        background: none;
        text-indent: 0;
        font-size: .875rem;
        font-weight: 800;
        color: $color__black;
        text-decoration: none;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }
}

.webform-submission-employees-registration-with-filt-form {
  margin-bottom: 0 !important;
  padding: 0 !important;
  max-width: 100% !important;
  #employee-registrations-filter {
    select {
      width: 100%;
    }
    @include mappy-bp(med) {
      width: 50%;
    }
  }
}

.webform-submission-edit-or-remove-attendee-form,
.webform-submission-edit-attendee-user-order-view-form {
  select {
    width: 100%;
    height: 3rem;
  }
}
