.dropdown-toggler {
  display: none;
}

@media (max-width: 1200px) {
  .dropdown-toggler {
    display: inline-block;
  }
}
#mobile_menu {
  position: absolute;
  top: 100%;
  right: 0;
  transition: 0.3s all;
  background-color: white;
  float: right;
  width: 50%;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
  min-width: 240px;
  overflow-y: scroll;
  max-height: 0;
  &.open {
    display: block !important;
    max-height: 102vh;
    height: 102vh;
    padding-bottom: 50vh;
    width: 100vw;
    border-top: 2px solid #eeeeee;
    @include mappy-bp(med) {
      height: auto;
    }
    @include mappy-bp(wide) {
      height: auto;
      display: none !important;
    }
  }

  .menu--account {
    display: block;
    // border-bottom: solid 2px #e5ebe9;
    @include mappy-bp(wide) {
      display: none;
    }
    ul.nav--account {
      margin: 0;
      padding: 0 1rem;
      padding-right: 1rem;
      display: flex;
      flex-direction: column;
      @include mappy-bp(med) {
        padding: 0 2rem;
      }
      li {
        margin: 0.4rem 0;
        &:first-child {
          margin-top: 2rem;
        }
        &:last-child {
          margin-bottom: 0;
          padding-bottom: 1.5rem;
          border-bottom: solid 2px #e5ebe9;
        }
      }
      a {
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.05em;
        color: #000000;
        text-align: center;
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.08);
        margin-right: 5rem;
        margin-left: 5rem;
      }
    }
  }

  ul.nav--main {
    list-style: none;
    margin: 0;
    // padding: 0 1rem;
    // padding-right: 1rem;
    @include mappy-bp(med) {
      padding: 0 2rem;
    }
    li {
      margin: 0.75rem 0;
      &:first-child {
        margin-top: 0.5rem;
      }
      .logo {
        display: none;
      }
      .link-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      span:has(> a.donate) {
        display: inline-block;
      }
      .donate {
        display: inline-block;
        padding: 9px 38px;
        border-radius: 20px;
        border: solid 2px $color__red;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.05em;
        color: #000000;
        transition: 0.2s all;
        &:hover {
          background-color: $color__white;
          color: $color__black;
          border-color: $color__black;
        }
      }
      a:not(.logo):not(.donate) {
        width: auto;
        margin: 0.2rem;
        margin-left: 0;
        display: flex;
        align-items: center;
        $underline-color: #006633;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 900;
        letter-spacing: 0.05em;
        color: #000000;
        // transition: background-position .2s linear .15s, box-shadow .15s linear 0s;
        transition: background-position 0.2s linear 0.2s,
          box-shadow 0.2s linear 0s;
        // background-image: linear-gradient(to left, $underline-color 0%, $underline-color 100%);
        background-size: 0;
        background-repeat: no-repeat;
        background-position: 0 1.35em;
        padding-bottom: 0.35rem;
        overflow: hidden;
        padding-left: 15px;
        overflow-wrap: break-word;
        white-space: break-spaces;
      }
      span {
        display: flex;
        align-items: center;

        .arrow {
          margin-left: auto;
        }
      }
    }
  }
}
button.nav__arrow.dropdown-toggler {
  border: 0;
  position: relative;
  background: none; /* Remove any background color */
}

button.nav__arrow.dropdown-toggler::before {
  content: "";
  display: inline-block;
  width: 19px;
  height: 19px;
  background-image: url("../../src/000_assets/icons/chevron-down-black.svg");
  background-repeat: no-repeat !important;
  background-position: center;
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 2px;
  color: black;
  padding: 10px;
}

@media (max-width: 1200px) {
  .nav__subnav {
    display: table;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    padding: 0 18px;
  }
}

.nav__subnav.expanded {
  display: block;
}

.nav--main .nav__subnav.expanded {
  display: block !important;
  max-height: none;
  opacity: 1;
  // background-color:#f0f0f0;
  border-radius: 0;
  border-top: 2px solid #cac7c7;
  border-bottom: 2px solid #cac7c7;
  box-shadow: none;
  margin-top: 0.5rem;
}

@media (max-width: 1200px) {
  .nav__subnav {
    display: table;
    background-color: white;
    overflow: hidden;
    transition: max-height 0.4s ease-in-out;
    max-height: 0;
  }
  /* This is where you can style the subnav items for mobile  */
  .nav__subnav > .nav__item > .link-container > .nav__link {
    font-size: 13px !important;
    margin: 2px !important;
  }

  .nav__subnav.expanded {
    display: block;
    max-height: none; /* Remove the max-height value */
  }

  .nav--main .nav__subnav {
    position: relative !important;
  }

  //   .nav--main .nav__item.has-dropdown:hover .nav__subnav, .nav--main .nav__item.has-subnav:hover .nav__subnav {
  //     padding: 0px;
  // }
}

/* Use a media query to display the button only when inside a div with the ID "mobile_menu" and the viewport is within the mobile range */
@media screen and (max-width: 1200px) {
  #mobile_menu .nav__arrow.dropdown-toggler {
    display: inline-block;
    margin-right: 2rem;
  }

  li.nav__item.subnav-item:first-child {
    margin-top: 0.5rem !important;
  }
  button.nav__arrow.dropdown-toggler {
    border: 0;
  }
}

button.nav__arrow.dropdown-toggler.chevron-up::before {
  content: "";
  display: inline-block;
  width: 19px;
  height: 19px;
  background-image: url("../../src/000_assets/icons/chevron-up-black.svg");
  background-repeat: no-repeat !important;
  background-position: center;
  background-size: contain;
  padding-top: 2px;
}
@media screen and (max-width: 1200px) {
  .nav-column {
    background-color: #f0f0f0;
  }

  .nav-column-container {
    margin-top: -8px;
    margin-bottom: -8px;
  }
  .nav--main .nav__subnav {
    padding: 0;
  }

  a.nav-header.nav__link {
    font-weight: 900 !important;
    margin-bottom: -0.7rem !important;
  }

  a.nav__link.has-subnav.is-expanded {
    font-weight: 900 !important;
  }

  a.mobile-donate.nav__link {
    padding: 0.5rem 1.5rem 0.5rem 1.4rem !important;
    border-radius: 20px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.05em;
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
    background-color: #e50020;
    color: white !important;
    font-weight: 700 !important;
    margin: auto !important;
  }

  .nav-header {
    text-decoration: none !important;
  }
}
