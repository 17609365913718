.path-batch {
  .main-nav {
    h1 {
      text-align: center;
      color: $sja_green;
    }
    #header-page-navigation {
      display: none;
    }
  }
  #updateprogress.progress {
    .progress__track {
      border: 1px solid $sja_green;
      >.progress__bar {
        background-color: $sja_green;
      }
    }
  }
}