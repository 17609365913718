.skeleton-loader {

  p {
    height: 20px;
    margin-top: 10px;
  }

  p:nth-child(3n+1) {
    width: 90%;
  }

  p:nth-child(3n+2) {
    width: 75%;
  }

  p:nth-child(3n) {
    width: 60%;
  }

  p:last-child {
    width: 40%;
  }
}

.shimmer {
  background-color: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shimmer linear infinite;
}

@keyframes shimmer {
  to {
    background-position-x: -200%;
  }
}