.glb-sidebar {
  z-index: 300 !important;
}

.glb-button {
  height: auto !important;
  text-transform: none !important;
  letter-spacing: normal !important;
}

.glb-form-element--type-select {
  height: auto !important;
  letter-spacing: normal !important;
  font-weight: normal !important;
}

.glb-claro-details__summary {
  text-transform: none !important;
  margin-bottom: unset !important;
}

.gin--vertical-toolbar .glb-sidebar {
  top: 60px !important;
}

.glb-form-item__label,
.entity-browser-modal label {
  text-transform: none !important;
  letter-spacing: normal !important;
}

.toolbar-horizontal .gin-secondary-toolbar--frontend {
  top: var(--gin-toolbar-height, 53px);
  left: 0;
  right: 0;
  position: fixed !important;
}

@media (min-width: 61em) {
  .toolbar-loading.toolbar-horizontal .toolbar .toolbar-bar .toolbar-tab:last-child .toolbar-tray {
    position: absolute;
  }
}

// // Styles for layout builder administration off-canvas forms.

// // override layout builders js added styles


// .js-off-canvas-dialog-open {
//   > .ui-dialog-off-canvas.ui-dialog-position-side {
//     width: 90% !important;
//     left: initial !important;
//     right: 0 !important;

//     @include mappy-bp(large) {
//       max-width: 900px;
//       width: 100% !important;
//     }
//   }
// }

// .dialog-off-canvas-main-canvas {
//   // override off-canvas js added styles
//   padding-right: 0 !important;
// }


// // set the max-width of layout content
// .layout-builder-edit-page .layout-content {
//   max-width: $content-max-width;
//   margin: 2rem auto 0;
//   padding: 0 1rem;
// }

// .layout-builder-edit-page #drupal-off-canvas {
//   width: 100% !important;

//   input[type='checkbox'],
//   input[type='checkbox']:checked {
//     height: 100%;
//     margin-top: 2px;
//     opacity: 1;
//   }

//   input[type='checkbox']:checked + label,
//   input[type='checkbox'] + label {
//     background-image: none;
//     padding: 0 1.5rem;


//     ///IE10 hack
//     @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
//       background-image: none;
//     }
//   }

//   input[type='radio'],
//   input[type='radio']:checked {
//     height: 100%;
//     margin-top: 2px;
//   }


//   input[type='radio'],
//   input[type='radio']:checked + label,
//   input[type='radio'] + label {
//     // background: none;
//     padding: 0 1.5rem;

//     @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
//       background-image: none;
//     }


//   }

//   .ui-autocomplete {
//     background-color: $color__white;
//   }

//   .ui-menu-item-wrapper,
//   .ui-menu-item {
//     background-color: $color__white;
//   }

//   .field-multiple-table {
//     margin-bottom: .5rem;
//   }

//   label {
//     font-size: 1rem;
//     margin-bottom: .25rem;
//   }

//   fieldset {
//     border: 1px solid #ccc;
//     padding: 1rem;

//     legend {
//       font-size: 1rem;
//     }

//     input {
//       width: 100%;
//     }
//   }

//   // Giving inputs some margins
//   input,
//   input[type='submit'].button {
//     width: auto;
//     margin: 1rem 1rem 0 0;
//   }

//   * textarea,
//   :not(div) textarea {
//     padding: .5rem;
//   }

//   .form-item {
//     margin-top: 1rem;
//     margin-bottom: 1rem;
//   }

//   // fixes overlapping of filter help button.
//   .filter-wrapper {
//     .filter-help {
//       margin: 1rem 0;
//       padding-right: 1.25rem;
//     }
//   }

//   // For block list, open/close details
//   details {
//     width: 100%;
//     margin: 0;

//     summary {
//       padding-left: 2rem;

//       &::before {
//         width: 12px;
//         height: 12px;
//         background-image: url('/themes/custom/sja/src/000_assets/icons/theme-defaults/icon__minus--white.svg');
//         background-size: 12px 12px;
//         margin-top: -9px;
//       }

//       .details__content {
//         padding: 1rem 0;
//       }
//     }
//   }

//   .field--type-entity-reference {
//     > details {
//       width: 100%;
//       margin: 0;

//       details {
//         margin-top: 1rem;
//         padding-left: 0;
//         padding-right: 0;
//       }
//     }

//     table {
//       input {
//         width: 100%;
//       }
//     }
//   }

//   details[open] summary {
//     padding-left: 2rem;

//     &::before {
//       width: 12px;
//       height: 12px;
//       background-image: url('/themes/custom/sja/src/000_assets/icons/theme-defaults/icon__plus--white.svg');
//       background-size: 12px 12px;
//       margin-top: -7px;
//     }
//   }

//   // removes random background from table data in off canvas form paragraphs
//   table tbody tr:hover td {
//     background-color: transparent;
//   }

//   // Paragraph styling
//   .field--widget-entity-reference-paragraphs {

//     .paragraph-type-title {
//       font-size: 1.5rem;
//       flex-basis: 75%;
//     }
//     details {
//       margin: 1rem 0;
//       border: 1px solid #e5ebe9;

//       summary {
//         padding-bottom: .5rem;
//       }
//     }

//     .paragraphs-subform {
//       .form-wrapper {
//         margin: 1rem 0;
//       }
//     }

//     .dropbutton-wrapper {
//       position: relative;
//       display: inline-flex;
//       padding-right: 0;
//       margin-right: 0;
//       width: auto;

//       .dropbutton-widget {
//         background-color: transparent;
//         border-radius: 0;
//         width: auto;
//         height: auto;
//         padding-right: 2em;
//       }

//       .dropbutton-toggle {
//         background-color: #eee;
//         position: absolute;
//         top: 0;
//         right: 0;
//         bottom: 0;
//         width: 2em;
//         padding: 0;
//         white-space: nowrap;
//         text-indent: 110%;
//         border-left: 1px solid #a6a6a6;

//         button {
//           border-radius: 0;
//           border: none;
//           background: #eee;

//           &:hover,
//           &:focus {
//             border: none;
//           }

//           .dropbutton-arrow {
//             color: #333;
//             top: 13px;
//             right: 13px;
//           }
//         }
//       }

//       .dropbutton-action > .js-form-submit.form-submit {
//         border-radius: 0;
//         background-color: #eee;
//         box-shadow: none;
//         width: 100%;
//         padding: 8px 1.5em;
//         margin: 0;
//         color: #333;
//         font-weight: 600;
//         line-height: normal;
//         -webkit-font-smoothing: antialiased;
//       }
//       &.open {
//         .dropbutton-widget {
//           box-shadow: none;
//         }
//       }
//     }

//     .dropbutton-multiple.open {
//       .dropbutton .secondary-action {
//         padding-right: 0;
//       }
//     }

//     // paragraph inside a paragraph
//     .field--type-entity-reference-revisions {
//       table {
//         min-width: 0;
//         width: 100%;
//         margin: 0;

//         td {
//           padding: .5rem;
//           width: 100%;
//         }

//         td.field-multiple-drag,
//         td.tabledrag-hide {
//           width: 50px;
//           padding-left: .5rem;
//         }
//       }
//     }
//   }

//   .field--name-field-block-title {
//     margin: 0;
//     padding: 0;
//   }

//   // tabs
//   .field-group-tabs-wrapper {
//     margin: 2rem 0 1rem;

//     .horizontal-tabs-list {
//       margin: 0 0 1rem;
//     }

//     .horizontal-tab-button {
//       padding: .5rem;
//       background-color: transparent;
//       color: white;
//       margin-left: .25rem;

//       &:first-of-type {
//         margin-left: 0;
//       }

//       &:last-of-type {
//         margin-right: 0;
//       }


//       a,
//       a span,
//       a strong {
//         display: block;
//         background-color: transparent;
//         color: white;
//         text-align: center;
//       }

//       &:hover,
//       &:focus {
//         color: #444;

//         a,
//         a span,
//         a strong {
//           color: #ddd;
//         }
//       }

//       &.selected {
//         background-color: white;
//         color: #444;

//         a,
//         a span,
//         a strong {
//           color: #444;
//         }
//       }
//     }

//     .horizontal-tabs-panes {
//       background: transparent;

//       .details[open] {
//         background: transparent;
//         margin: 0;
//       }

//       .form-wrapper {
//         margin: 1rem 0;
//       }
//     }
//   }
//   .horizontal-tab-hidden {
//     display: none;
//   }

//     .form-actions {
//       display: flex !important;


//       .button {
//         margin-bottom: 0;
//       }

//       input[type='submit'].button {
//         margin-top: 0;
//       }
//     }
//   .layout-builder-remove-block {
//     .form-actions {
//       display: flex;
//       .button {
//         margin-bottom: 0;
//       }

//       input[type='submit'].button {
//         margin-top: 0;
//       }
//     }
//   }
// }

// // layout builder page specific changes
// .layout-builder-form {
//   .layout-builder__add-block.is-layout-builder-highlighted {
//     border: none;
//     background-color: #dddddd;
//   }

//   .layout-builder {
//     border-width: 1px;
//   }

//   a.layout-builder__link--remove {
//     margin-bottom: vr(.25);
//     display: block;
//     width: 24px;
//     height: 24px;
//     border-bottom: 1px solid #ccc;
//     margin-left: vr(0);
//   }

//   // layout builder save buttons
//   > .form-actions {
//     display: inline-block;
//     padding: vr(.25) 0;
//     margin: 0 0 .75rem;

//     > input {
//       background-color: transparent;
//       color: $color__grey--dark;
//       font-weight: 400;
//       border: none;
//       text-transform: none;
//       letter-spacing: normal;

//       &:hover,
//       &:focus {
//         border: none;
//         background-color: transparent;
//         text-decoration: underline;
//       }

//       &:first-of-type {
//         background-color: $sja_green;
//         color: $color__white;
//         padding: .5rem 1rem;
//         border: none;

//         &:hover,
//         &:focus {
//           background-color: darken($color__primary, 8%);
//           text-decoration: none;
//         }
//       }
//     }
//   }

//   // preview button
//   #edit-preview-toggle {
//     width: 100%;
//     margin-top: 1rem;
//   }

//   // moderation state and general select button
//   .field--name-moderation-state {
//     margin-bottom: 1rem;

//     @include mappy-bp(larger) {
//       float: right;
//       width: 60%;
//       margin-bottom: .5rem;
//     }

//     // current state and change form-items
//     > div {
//       @include mappy-bp(larger) {
//         text-align: right;
//       }

//       > div {
//         display: inline-block;
//         height: 40px;

//         > label {
//           font-size: .8rem;
//           padding-right: .5rem;
//         }

//         &:nth-of-type(1) {
//           margin-right: 2rem;
//         }

//         &:nth-of-type(1),
//         &:nth-of-type(2) {
//           > label::after {
//               content: ':';
//           }
//         }
//       }
//     }

//     input,
//     select {
//       background-color: #f9f9f9;
//       width: auto;
//       border: none;
//       height: 40px;
//       padding: .5rem .75rem;

//       &:focus {
//         outline: none;
//         box-shadow: none;
//         border-color: transparent;
//       }
//     }

//     select {
//       font-size: 1rem;
//       padding: .5rem 2.25rem .5rem .75rem;
//     }

//     .form-item--select {
//       position: relative;

//       select {
//         padding-right: 2.7rem;
//       }
//     }

//     transitionset {
//       display: block;

//       > a {
//         font-size: .8rem;
//       }
//     }

//     .scheduled-transition {
//       display: block;
//       margin: .5rem 0 1rem;
//       font-size: .8rem;

//       > label {
//         position: relative;
//         display: inline-block;
//         padding: 0 1rem;
//         margin-bottom: .5rem;

//         input,
//         select {
//           font-size: 1rem;
//         }

//         &:last-of-type {
//           padding-right: 0;
//         }
//       }

//       > span {
//         display: block;
//         margin: .5rem 0;

//         button {
//           background-color: $sja_green;
//           color: #FFFFFF;
//           padding: .5rem 1rem;
//           margin-right: 1rem;
//         }

//         a {
//           color: #414A51;
//           font-weight: 400;
//           padding: .5rem 1rem;
//           border-bottom: none;

//           &:hover,
//           &:focus {
//             text-decoration: underline;
//             border-bottom: none;
//           }
//         }
//       }
//     }
//   }

//   .layout-builder__message {
//     clear: both;
//   }
// }

// .layout-builder-update-block {
//   // paragraphs
//   .field--type-entity-reference-revisions {
//     .field-multiple-drag.paragraph-bullet {
//       cursor: move;
//       width: 60px !important;

//       &::after {
//         content: '';
//         width: 20px;
//         height: 20px;
//         padding: 10px;
//         background: url('/themes/custom/sja/src/000_assets/icons/theme-defaults/move--light-grey.svg') no-repeat
//           center;
//         background-size: 20px;
//       }
//     }
//   }
// }

// // make it so the paragraph title doesn't get cut off.
// .js .paragraph-top .paragraph-type {
//   grid-column: 1 / 4;
// }

// // layout builder page
// #layout-builder {
//   font-family: $system-font-family !important;
//   font-size: .9rem;

//   .layout-builder__add-section {
//     outline-width: 1px;
//     text-transform: uppercase;
//     font-size: .7rem;
//     font-weight: 600;

//     .layout-builder__link--add {
//       color: $color__grey--dark;
//     }
//   }

//   .layout-builder__link--add {
//     font-weight: 500;
//     border-bottom: none;
//     background-size: 12px;
//     background-position: center left;

//     &:hover,
//     &:focus {
//       text-decoration: underline;
//     }
//   }

//   .layout-builder__region {
//     padding: .5rem;
//     outline-width: 1px;
//   }

//   .layout-builder__add-block {
//     margin-top: 0;
//   }
// }
