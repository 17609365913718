.view--employees-of-corporate.view--display-block_1,
.view--contacts-of-coprporate.view--display-block_1 {
  .view__filters {
    > form {
      margin-bottom: 1.5rem;
      @include mappy-bp(large) {
        display: flex;
        align-items: flex-start;
        flex-wrap: nowrap;
      }
      > .form-item {
        @include mappy-bp(large) {
          margin-right: 2rem;
          width: 40%;
        }
        @include mappy-bp(large) {
          width: 50%;
        }
      }
      .form-actions {
        input {
          margin-top: 1.5rem;
        }
        @include mappy-bp(large) {
          input {
          }
        }
      }
    }
  }
}

.view--employees-of-corporate.view--display-block_1 {
  margin-bottom: 5rem;
  .vbo-view-form {
    .vbo-multipage-selector {
      display: none;
    }
    .form-item--id-select-all {
      display: none;
    }
  }
  input.form-checkbox {
    width: 20px;
    height: 20px;
    opacity: initial;
    appearance: auto;
    -webkit-appearance: auto;
    position: relative;
  }
  .form-item--checkbox {
    margin-bottom: 0;
  }
}
