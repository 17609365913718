@include mappy-bp(land large) {
  // 3 column
  // 50/25/25, 25/50/25, 25/25/50
  .layout--threecol-section--50-25-25 .layout__region-container > .layout__region--first,
  .layout--threecol-section--50-25-25 .layout__region-container > .layout__region--second,
  .layout--threecol-section--25-50-25 .layout__region-container > .layout__region--first,
  .layout--threecol-section--25-50-25 .layout__region-container > .layout__region--second,
  .layout--threecol-section--25-25-50 .layout__region-container > .layout__region--first,
  .layout--threecol-section--25-25-50 .layout__region-container > .layout__region--second,
  .layout--threecol-section--33-34-33 .layout__region-container > .layout__region--first,
  .layout--threecol-section--33-34-33 .layout__region-container > .layout__region--second {
    flex: 0 1 50%;
    margin: 10px auto;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
    padding: inherit;
  }
  .layout--threecol-section--50-25-25 .layout__region-container > .layout__region--third,
  .layout--threecol-section--25-50-25 .layout__region-container > .layout__region--third,
  .layout--threecol-section--25-25-50 .layout__region-container > .layout__region--third,
  .layout--threecol-section--33-34-33 .layout__region-container > .layout__region--third {
    flex: 0 1 100%;
  }
}

@include mappy-bp(large) {
  .layout--threecol--section .layout__region-container {
    > .layout__region--third {
      margin-top: 0;
    }
  }

  // 50/25/25, 25/50/25, 25/25/50
  .layout--threecol-section--25-50-25 .layout__region-container > .layout__region--first,
  .layout--threecol-section--25-50-25 .layout__region-container > .layout__region--third,
  .layout--threecol-section--25-25-50 .layout__region-container > .layout__region--first,
  .layout--threecol-section--25-25-50 .layout__region-container > .layout__region--second,
  .layout--threecol-section--50-25-25 .layout__region-container > .layout__region--second,
  .layout--threecol-section--50-25-25 .layout__region-container > .layout__region--third {
    flex: 0 1 25%;
  }
  .layout--threecol-section--25-50-25 .layout__region-container > .layout__region--second,
  .layout--threecol-section--25-25-50 .layout__region-container > .layout__region--third,
  .layout--threecol-section--50-25-25 .layout__region-container > .layout__region--first {
    flex: 0 1 50%;
  }
  // 33/34/33
  .layout--threecol-section--33-34-33 .layout__region-container > .layout__region--first,
  .layout--threecol-section--33-34-33 .layout__region-container > .layout__region--third {
    flex: 0 1 30%;
    margin: 10px auto;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
    padding: inherit;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .layout--threecol-section--33-34-33 .layout__region-container > .layout__region--second {
    flex: 0 1 30%;
    margin: 10px auto;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
    padding: inherit;
  }
}

/*
  * Weird ie11 issue where the flex-basis has to be explicitly specified with
  * the padding that will be added in order for the browser to properly render
  * the width. In this case, 16px on left and right side so 32px total.
  */
@include mappy-bp(land large) {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */

    // 50/25/25, 25/50/25, 25/25/50
    .layout--threecol-section--50-25-25 .layout__region-container > .layout__region.layout__region--first,
    .layout--threecol-section--50-25-25 .layout__region-container > .layout__region.layout__region--second,
    .layout--threecol-section--25-50-25 .layout__region-container > .layout__region.layout__region--first,
    .layout--threecol-section--25-50-25 .layout__region-container > .layout__region.layout__region--second,
    .layout--threecol-section--25-25-50 .layout__region-container > .layout__region.layout__region--first,
    .layout--threecol-section--25-25-50 .layout__region-container > .layout__region.layout__region--second,
    .layout--threecol-section--33-34-33 .layout__region-container > .layout__region.layout__region--first,
    .layout--threecol-section--33-34-33 .layout__region-container > .layout__region.layout__region--second {
      flex-basis: calc(50% - 32px);
    }
    .layout--threecol-section--50-25-25 .layout__region-container > .layout__region.layout__region--third,
    .layout--threecol-section--25-50-25 .layout__region-container > .layout__region.layout__region--third,
    .layout--threecol-section--25-25-50 .layout__region-container > .layout__region.layout__region--third,
    .layout--threecol-section--33-34-33 .layout__region-container > .layout__region.layout__region--third {
      flex-basis: calc(100% - 32px);
    }
  }
}

@include mappy-bp(large) {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */

    // 50/25/25, 25/50/25, 25/25/50
    .layout--threecol-section--25-50-25 .layout__region-container > .layout__region.layout__region--first,
    .layout--threecol-section--25-50-25 .layout__region-container > .layout__region.layout__region--third,
    .layout--threecol-section--25-25-50 .layout__region-container > .layout__region.layout__region--first,
    .layout--threecol-section--25-25-50 .layout__region-container > .layout__region.layout__region--second,
    .layout--threecol-section--50-25-25 .layout__region-container > .layout__region.layout__region--second,
    .layout--threecol-section--50-25-25 .layout__region-container > .layout__region.layout__region--third {
      flex-basis: calc(25% - 32px);
    }
    .layout--threecol-section--25-50-25 .layout__region-container > .layout__region.layout__region--second,
    .layout--threecol-section--25-25-50 .layout__region-container > .layout__region.layout__region--third,
    .layout--threecol-section--50-25-25 .layout__region-container > .layout__region.layout__region.layout__region--first {
      flex-basis: calc(50% - 32px);
    }
    // 33/34/33
    .layout--threecol-section--33-34-33 .layout__region-container > .layout__region.layout__region--first,
    .layout--threecol-section--33-34-33 .layout__region-container > .layout__region.layout__region--third {
      flex-basis: calc(33% - 32px);
    }
    .layout--threecol-section--33-34-33 .layout__region-container > .layout__region.layout__region--second {
      flex-basis: calc(33% - 32px);
    }
  }
}
