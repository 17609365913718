// Overrides layout region margin top and bottom.
// No padding-top since this is a full width element
.layout__region-container > .layout__region > .paragraph--type-banner-small {
  margin-top: 0;
  margin-bottom: 0;
}


.paragraph--type-banner-small {

 .small-banner {
  position: relative;
  text-align: center;

  .field--name-field-small-banner {

    .field__item {
      img {
      width: 100%;
      }
    }
  }

  .field--name-field-banner-title {
   margin: 0 auto;
   // display: inline-block;

   .field__item {
    font-size: 24px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.42;
    letter-spacing: .6px;
    text-align: left;
    color: #171918;
    padding: rem(10px) rem(14px);
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, .16);
    background-color: #ffffff;
    // position: absolute;
    // left: 5%;
    transform: translateY(-50%);
    max-width: 90%;
    margin: 0 auto;
    display: inline-block;
    width: auto;

    h1 {
     font-size: 24px;
     font-weight: 900;
     font-style: normal;
     font-stretch: normal;
     line-height: 1.42;
     letter-spacing: .6px;
    }

    @include mappy-bp(med) {
     padding: rem(20px) rem(40px);
     font-size: 36px;
     line-height: 1.14;
     letter-spacing: .9px;

     h1 {
      font-size: 36px;
      letter-spacing: .9px;
      line-height: 1.14;
     }

    }

    @include mappy-bp(large) {
     font-style: normal;
     font-stretch: normal;
     color: #000000;
     box-shadow: 0 0 24px 0 rgba(0, 0, 0, .16);
     background-color: #ffffff;
     padding: rem(20px) rem(40px);
     top: 80%;
     position: absolute;
     // transform: translateX(0);
     transform: translateY(-80%);
     left: rem(80px);
     max-width: 70%;
    }
    @include mappy-bp(larger) {
     max-width: 50%;
    }

    @include mappy-bp(2500px) {
     top: 87%;
    }
   }
  }
 }

}
