// Footer Section
.region-footer {
  border-top: 2px solid #e5ebe9;
  border-bottom: 2px solid $medium_dark_grey;
  padding: 0;
  width: 100%;
  background-color: black;
  // @include mappy-bp(larger) {
  //   padding: 0 5rem 2rem;
  // }
  @include mappy-bp(mega) {
    padding: 0 4rem 1rem;
    /*mazdemo*/
    // height: 80%;
    /*mazdemo*/
  }
  @include mappy-bp(widest) {
    padding: 0 2rem 0 2rem;
  }

  .l-constrain {
    max-width: $content-max-width;
    margin: 0 auto;
    padding: 2.25rem 1rem;
    transition: .5s all ease-in;

    @include mappy-bp(med) {
      padding: 2.75rem 3.75rem;
    }

    @include mappy-bp($content-max-width + 32px) {
      padding: 2rem 0;
      // margin-top: -10px;
      // margin-bottom: -30px;
    }

    .block--contact-information,
    #block-footercontactinformation--2 {
      width: 100%;
      margin: 0 auto;
      color: white;

      @include mappy-bp(med) {
        margin-top: 14px;
        @include span(4 of 12 first);
      }
      @media (max-width: 1200px) {
        margin-top: 14px;

      }

      @include mappy-bp(wide) {
        margin-left: 0;
        margin-top: 18px;
      }

      .block__title {
        font-size: 14px;
        font-weight: 900;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: 1.05px;
        text-align: left;
        color: white;
        text-transform: uppercase;
        margin-top: 0;
        margin-bottom: 20px;
        @include mappy-bp(med) {
          margin-bottom: 12px;
        }
      }
      p {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: white;
        @include mappy-bp(med) {
          margin-bottom: 1.75rem;
        }
      }
    }
    .menu--footer {
      @include mappy-bp(med) {
        @include span(7 of 12);
        margin-left: 8%;
      }
      @include mappy-bp(wide) {
        @include span(4 of 12);
      }
      ul.nav--footer {
        padding: 0;
        @include mappy-bp(med) {
          column-count: 3;
          // column-gap: 100px;
          /* mazdemo */
          position: relative;
          // right: 108%;
          // width: 134%;
          @media (max-width: 1200px) {
            // right: 37%;
            width: 138%;
            margin-top: 7%;
            margin-bottom: 7%;
          }


        }
      }
      li.nav__item {
        list-style: none;
        padding-top: 18px;
        width: max-content;
        @include mappy-bp(med) {
          padding-top: 26px;
          display: grid;
          justify-content: center;
        }
        a {
          height: 19px; // font-family: NunitoSans;
          font-size: 14px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.43;
          letter-spacing: .35px;
          text-align: left;
          color: black;
          text-decoration: none;
          &:hover,
          &:focus {
            border-bottom: 2px solid black;
            // color: #bec6c4;
          }
        }
      }
    }
  }
  p.footer-contact-info__phone-email a {
    color: white;
    font-weight: 800;
    text-decoration: none;
    &:hover,
    &:focus {
      border-bottom: 2px solid #bec6c4;
      color: #bec6c4;
    }
  }
  .menu--donate-link {
    @include mappy-bp(med) {
      @include span(4 of 12 first);
      clear: both;
      width: fit-content;
      float: none;
      margin: auto;
    }
    @include mappy-bp(wide) {
      @include span(4 of 12);
      clear: none;
      text-align: right;
    }
    ul {
      padding: 0; // margin:0px;
      li {
        list-style: none;
        padding-top: 8px;
        @include mappy-bp(wide) {
          padding-top: 28px;
        }
        a {
          display: inline-block;
          padding: 9px 38px;
          border-radius: 20px;
          text-align: center;
          text-decoration: none;
          text-transform: uppercase;
          font-size: rem(14px);
          font-weight: 900;
          letter-spacing: .05em;
          color: white;
          transition: .2s all;
          line-height: initial;
          border: 2px solid #e50020;
          background-color: #e50020;
          /* mazdemo */
          position: inherit;
          // bottom: 9rem;
          @media (min-width: 1600px) {
            right: 21%;
            position: relative;
            // height: 5vh;
            // width: 22vh;
            // line-height: 1.4rem;
            // bottom: 1rem;
          }
          @media (min-width: 1200px) {
            position: relative;
            bottom: 2rem;
            // height: 5vh;
            // line-height: 1.4rem;
            // width: 20vh;
          }
          /* mazdemo */
          &:hover,
          &:focus {
            background-color: #e50020;
            color: white;
            border: solid 2px #e50020;
          }
        }
      }
    }
  }
  .menu--social-media-menu {
    margin: 0 auto;
    width: 100%;
    float: right;
    padding-top: 38px;
    @include mappy-bp(med) {
      @include span(last 6 of 12);
      padding-left: 6%;
    }
    @include mappy-bp(wide) {
      @include span(12 of 12);
      width: 30%;
      float: right;
      position: relative;
      right: 45%;
      top: 9rem;
    }
    ul.nav--social-media-menu {
      padding: 0;
      margin: 0;
      @extend %cf;
      display: table;
      width: 100%;
      @include mappy-bp(wide) {
        display: inline-block;
        text-align: right;
      }
      li {
        text-decoration: none;
        list-style: none;
        display: table-cell;
        @include mappy-bp(wide) {
          display: inherit;
          padding-left: 4%;
        }
        a {
          display: block;
          font-size: 0;
          &.facebook {
            &::before {
              content: '';
              background-image: url('../../src/000_assets/icons/facebook-with-circle.svg');
              width: 44px;
              height: 44px;
              display: block;
              margin: 0 auto;
              background-repeat: no-repeat;
              // transition: all .3s;
              background-position: top center;
              -webkit-filter: invert(0%) brightness(20%); /* safari 6.0 - 9.0 */
              filter: invert(0%) brightness(20%);
              @include mappy-bp(wide) {
                background-position: right top;
              }
            }
            &:hover,
            &:focus {
              &::before {
                -webkit-filter: invert(0%) brightness(-150%);
                filter: invert(0%) brightness(-150%);
              }
            }
          }
          &.twitter {
            &::before {
              content: '';
              background-image: url('../../src/000_assets/icons/twitter-with-circle.svg');
              width: 44px;
              height: 44px;
              // transition: all .3s;
              display: block;
              margin: 0 auto;
              background-repeat: no-repeat;
              background-position: top center;
              -webkit-filter: invert(0%) brightness(20%); /* safari 6.0 - 9.0 */
              filter: invert(0%) brightness(20%);
              @include mappy-bp(wide) {
                background-position: right top;
              }
            }
            &:hover,
            &:focus {
              &::before {
                -webkit-filter: invert(0%) brightness(-150%);
                filter: invert(0%) brightness(-150%);
              }
            }
          }
          &.linkedin {
            &::before {
              content: '';
              background-image: url('../../src/000_assets/icons/linkedin-with-circle.svg');
              width: 44px;
              height: 44px;
              display: block;
              margin: 0 auto;
              background-repeat: no-repeat;
              background-position: top center;
              -webkit-filter: invert(0%) brightness(20%); /* safari 6.0 - 9.0 */
              filter: invert(0%) brightness(20%);
              @include mappy-bp(wide) {
                background-position: right top;
              }
            }
            &:hover,
            &:focus {
              &::before {
                -webkit-filter: invert(0%) brightness(-150%);
                filter: invert(0%) brightness(-150%);
              }
            }
          }
          &.instagram {
            &::before {
              content: '';
              background-image: url('../../src/000_assets/icons/instagram-white.svg');
              width: 44px;
              height: 44px;
              display: block;
              margin: 0 auto;
              background-repeat: no-repeat;
              background-position: top center;
              -webkit-filter: invert(0%) brightness(20%); /* safari 6.0 - 9.0 */
              filter: invert(0%) brightness(20%);
              @include mappy-bp(wide) {
                background-position: right top;
              }
            }
            &:hover,
            &:focus {
              &::before {
                -webkit-filter: invert(0%) brightness(-150%);
                filter: invert(0%) brightness(-150%);
              }
            }
          }
          &.youtube {
            &::before {
              content: '';
              background-image: url('../../src/000_assets/icons/youtube-with-circle.svg');
              width: 44px;
              height: 44px;
              display: block;
              margin: 0 auto;
              background-repeat: no-repeat;
              background-position: top center;
              -webkit-filter: invert(0%) brightness(20%); /* safari 6.0 - 9.0 */
              filter: invert(0%) brightness(20%);
              @include mappy-bp(wide) {
                background-position: right top;
              }
            }
            &:hover,
            &:focus {
              &::before {
                -webkit-filter: invert(0%) brightness(-150%);
                filter: invert(0%) brightness(-150%);
              }
            }
          }
        }
      }
    }
  }
}

// Copyright Footer Section
.copyright {
  // max-width: $content-max-width;
  margin: 0 auto;
  padding: 1rem;
  transition: .5s all ease-in;
  background-color: black;
  @extend %cf;

  @include mappy-bp(med) {
    // padding: 1rem 3.75rem 1.625rem;

        //Remove st. intl line
        padding: 2rem 3.75rem 1.625rem;
  }

  @include mappy-bp($content-max-width + 32px) {
    //Remove st. intl line
    padding: 2rem 5rem 1rem;
  }

  .copyright_content {
    @extend %cf;

    //Remove st. intl line
    display: none;

    .footer_logo {
      margin-left: 0 !important;
      text-align: -webkit-center;
      padding-bottom: 1rem;
      float: right !important;
      @media (max-width: 768px) {
          float: none !important;
        }


      @include mappy-bp(med) {
        @include span(3 of 12);
        text-align: left;
      }

      @include mappy-bp(wide) {
        @include span(3 of 12);
      }

      img {
        width: 182px;
        height: 67px;
        -webkit-filter: invert(100%);
        filter: invert(100%);
      }
    }

    .footer_content {
      padding-bottom: 1rem;

      @include mappy-bp(med) {
        @include span(9 of 12);
        width: 400px;
        padding-top: 18px;
        margin-left: 67px;
      }

      @include mappy-bp(wide) {
        @include span(9 of 12);
        padding-top: 28px;
      }

      p {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: .35px;
        text-align: left;
        color: white;
      }
    }
  }
  .copyright-year,
  .post-footer-links {
    padding-bottom: 1rem;
    @media (max-width: 768px) {
      text-align: center;
    }
  }

  .copyright-year {
    @include mappy-bp(med) {
      @include span(12 of 12);
    }

    @include mappy-bp(wide) {
      @include span(5 of 12);
      margin-left: 0;
      clear: both;
    }

    p {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: .35px;
      text-align: center;
      color: white;
    }
  }
  .post-footer-links {
    @include mappy-bp(med) {
      padding-bottom: 0;
      @include span(4 of 12);
      margin-left: 0;
    }

    @include mappy-bp(wide) {
      @include span(3 of 12);
    }

    ul {
      padding: 0;
      margin: 0;
      display: block;
      @include mappy-bp(wide) {
        display: flex;
        justify-content: space-between;
      }

      li {
        list-style: none;
        display: inline-block;

        &:nth-child(2) {
          padding-left: 28px;

          @include mappy-bp(wide) {
            float: right;
          }
        }

        a {
          display: block;
          font-size: 12px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.67;
          letter-spacing: .3px;
          text-align: left;
          color: white;
          text-decoration: none;

          @include mappy-bp(wide) {
            font-size: 14px;
          }
        }
      }
    }
  }
  .organization-number {
    width: 185px;
    @media (max-width: 768px) {
      width: auto;
    }


    @include mappy-bp(med) {
      @include span(6 of 12);
    }

    @include mappy-bp(wide) {
      @include span(4 of 12);
    }

    p {
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.67;
      letter-spacing: .3px;
      text-align: left;
      color: white;
      @media (max-width: 768px) {
        text-align: center;
        width: 100%;
      }

      @include mappy-bp(wide) {
        text-align: right;
        font-size: 14px;
      }
    }
  }
}

.footer-logo {
  max-width: 45%;
  height: auto;
  position: relative;
  left: 15%;
}
@media (min-width: 1300px) and (max-width: 1380px) {
  .footer-logo {
   left: 15%;
  }
}

@media (max-width: 1200px) {
  .footer-logo {
    margin: auto;
    width: 75%;
    max-width: fit-content;
  }
}

@media (max-width: 768px) {
  .footer-logo {
    right: 0%;
    width: 25%;
    bottom: 2rem;
    left: 0%;
  }

  .region-footer .menu--social-media-menu {
    top: 5rem;
    position: relative;
    margin-bottom: -14%;
  }
}

@media (max-width: 600px) {
  .footer-logo {
    width: 40%;

  }
}

@media (min-width: 1560px) {
  .footer-logo {
    left: 9%;

  }
}

@media (max-width: 768px) {
.region-footer .menu--donate-link ul li a {
  display: block;
  margin-right: 30%;
  margin-left: 30%;
}
}


@media (max-width: 768px) {
.region-footer .l-constrain .menu--footer ul.nav--footer {
  margin-top: 19%;
  margin-bottom: 4%;
}
}

@media (max-width: 1200px) {
  .region-footer .l-constrain .menu--footer {
    // width: 86.14286%;
    float: none;
    margin-left: 2.85714%;
    // margin-left: 39%;
    margin-top: 14%;
  }

  .region-footer .l-constrain .menu--footer ul.nav--footer {
left: 26%;
}
  }

  @media (min-width: 1200px) {
  .region-footer {
    // height: 34vh;
  }
}
@media (min-width: 1200px) {
.region-footer .menu--social-media-menu ul.nav--social-media-menu {
  display: flex;
  /* text-align: right; */
  right: 132%;
  position: relative;
  bottom: 2.5rem;
}


}

@media (min-width: 1200px) {
  .region-footer .l-constrain .menu--footer ul.nav--footer {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    column-gap: 14rem;
    position: relative;
    right: 14%;
}

.region-footer .l-constrain .menu--footer ul.nav--footer li:nth-child(3n+1) {
    margin-right: 3rem;
}
.region-footer .l-constrain .menu--footer ul.nav--footer li:nth-child(3n+2) {
    margin-right: 14rem;
}
}

