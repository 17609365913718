// @import url('https://fonts.googleapis.com/css?family=Lato:400,700');

.tiles-block  {
  margin: 0;
  font-family: 'Lato', sans-serif;
  background-color: #F5F5F5;
  padding: 1rem;
}

.tiles-title, .tiles-subtitle {
  text-align: center;
}

.tiles-main-title {
  font-size: 24px;
  font-weight: 700;
  margin-top: 1rem;
  margin: auto;
  width: fit-content;
}

.tiles-main-subtitle {
  font-size: 16px;
  color: #101010;
  margin: auto;
  text-align: center;
  margin-bottom: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.tiles-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  padding: 0 4.4rem;
}

.tile-link {
  text-decoration: none;
}

.tile {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 5px; /* Adjusted border-radius */
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
  transition: transform .3s ease, box-shadow .3s ease;
  color: #333;
}

.tile:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, .15);
}

.tile-icon {
  font-size: 30px;
  margin-right: 15px;
  margin-top: auto;
  margin-bottom: -1px;
}

.text-container {
  display: flex;
  flex-direction: column;
}

.tile-title, .tile-subtitle {
  margin: 0;
}

.tile-title {
  font-size: 18px;
}

.tile-subtitle {
  font-size: 14px;
  color: #666;
}

.explore-more-btn {
  display: block;
  width: max-content;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #006633;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
}

.show-more-tiles {
  padding: 10px 15px;
  background-color: #007bff; /* Example blue color */
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  margin-top: 15px; /* Adjust as needed */
  transition: background-color .3s;
}

.show-more-tiles:hover {
  background-color: #0056b3; /* Darker shade for hover effect */
}


@media (max-width: 1200px) and (min-width: 801px) {
  .tiles-grid {
      grid-template-columns: repeat(2, 1fr);
  }
  .tiles-block  {
    padding: .4rem;
  }
}

@media (max-width: 800px) {
  .tiles-grid {
      grid-template-columns: 1fr;
      padding: 20px;
  }
  .tiles-block  {
    padding: 3.5rem 2rem;
  }
}

@media (min-width: 1200px) and (max-width: 1375px) {
  .tile {
    height: 100px; /* Adjust the height as needed to accommodate your content */
  }

  .tile-icon {
    margin-bottom: 4px;
  }
}

@media (min-width: 800px) and (max-width: 946px) {
  .tile {
    height: 90px; /* Adjust the height as needed to accommodate your content */
  }

  .tile-icon {
    margin-top: none;
    margin-bottom: 0 !important;
}

}

@media (min-width: 1800px) {
  .tiles-block {
    padding: 3% 18%;
  }
}